/**
 * Shared coponent size constants
 * These constants are for Scrollpane to set its height
 * in a way the 10th child is cut in half to emphasize scrolling
 * @author Karen Dolan
 */
module.exports = {
  // Height that can fit preview plus one line of detail or one row badges
  EVENT_PREVIEW_MIN_HEIGHT: 64,
  // Additional height from padding
  EVENT_PREVIEW_BUFFER_HEIGHT: 8,
};
