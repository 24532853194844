/**
 * A box with a tab on the top that holds buttons and other content
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import style
import './TabBox.css';

class TabBox extends Component {
  /**
   * Render TabBox
   * @author Gabe Abrams
   */
  render() {
    const {
      title,
      children,
      noPaddingOnBottom,
    } = this.props;

    // Full UI
    return (
      <div>
        {/* Title */}
        <div className="TabBox-title-container">
          <div className="TabBox-title">
            {title}
          </div>
        </div>

        {/* Contents */}
        <div
          className={`TabBox-box ${noPaddingOnBottom ? 'pl-2 pt-2 pr-2' : 'p-2'}`}
        >
          <div className="TabBox-children">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

TabBox.propTypes = {
  // Title of the box
  title: PropTypes.node.isRequired,
  // Children/contents inside the box
  children: PropTypes.node.isRequired,
  // If true, no padding on bottom
  noPaddingOnBottom: PropTypes.bool,
};

TabBox.defaultProps = {
  // By default, padding is included
  noPaddingOnBottom: false,
};

export default TabBox;
