import visitServerEndpoint from './visitServerEndpoint';

/**
 * Add an error to the log. Fails silently if it can't send more than 3
 *   times
 * @author Gabe Abrams
 * @param {string} message - error message to log
 * @param {string} code - the error code
 * @param {object} [metadata={}] - a metadata object to attach to the log
 * @param {boolean} [ignoreSessionExpiry] - if true, catch session expiry
 *   separately
 */
export default async (opts) => {
  for (let i = 0; i < 3; i++) {
    try {
      // Send request to the server
      await visitServerEndpoint({
        path: '/api/logs/errors',
        method: 'POST',
        params: {
          message: opts.message || 'An unknown error occurred on the client.',
          code: opts.code || 'UNKNOWN_CLIENT',
          metadata: JSON.stringify(opts.metadata || {}),
        },
        ignoreSessionExpiry: opts.ignoreSessionExpiry,
      });

      // Success! Break
      break;
    } catch (err) {
      // Try again in half a second
      await new Promise((r) => {
        setTimeout(r, 500);
      });
    }
  }
};
