/**
 * Prepares for joining a lounge: loads info, attempts to add the user
 *   as a host, displays a warning if the user cannot be added as a host
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import shared components
import Modal from '../../shared/Modal';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ErrorAlert from '../../shared/ErrorAlert';
import ClaimAccountNotice from '../../shared/ClaimAccountNotice';

// Import helpers
import visitServerEndpoint from '../../helpers/visitServerEndpoint';

// Import shared props
import Lounge from '../../shared/propTypes/Lounge';

class LoungePreparationModal extends Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // True if loading, otherwise showing "claim account" notice
      loading: true,
      // Join link
      joinLink: null,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
    };
  }

  /**
   * Trigger startup actions on mount
   * @author Gabe Abrams
   */
  async componentDidMount() {
    await this.getJoinURL();
  }

  /**
   * Get the join URL
   * @author Gabe Abrams
   */
  async getJoinURL() {
    const {
      courseId,
      lounge,
      onContinue,
    } = this.props;

    // Update state
    this.setState({
      loading: true,
    });

    // Get the join URL
    try {
      const {
        link,
        willBeHost,
        errorCode,
      } = await visitServerEndpoint({
        path: `/api/courses/${courseId}/lounges/${lounge.loungeId}/join_url`,
        method: 'GET',
      });

      // Failure with known error code
      if (!willBeHost) {
        // Handle user not found error
        if (errorCode === 'ZOOM404-1113') {
          return this.setState({
            joinLink: link,
            loading: false,
          });
        }

        // Handle user not found during ZAK error
        if (errorCode === 'ZOOM404-1001') {
          return this.setState({
            joinLink: link,
            loading: false,
          });
        }
      }

      // Success! Continue
      onContinue(link);
    } catch (err) {
      // Save error message
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
        loading: false,
      });
    }
  }

  /**
   * Render LoungePreparationModal
   * @author Gabe Abrams
   */
  render() {
    const {
      onCancel,
      onContinue,
    } = this.props;
    const {
      loading,
      joinLink,
      fatalErrorMessage,
      fatalErrorCode,
    } = this.state;

    /*------------------------------------------------------------------------*/
    /*                                 Render                                 */
    /*------------------------------------------------------------------------*/

    /* ----------- Fatal Error ---------- */

    // Show error message if there is one
    if (fatalErrorMessage) {
      // Create full modal
      return (
        <Modal
          key="fatal-error"
          body={(
            <div>
              <ErrorAlert
                message={fatalErrorMessage}
                code={fatalErrorCode}
              />

              {/* Close Button */}
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  aria-label="close error and cancel"
                  onClick={onCancel}
                >
                  OK
                </button>
              </div>
            </div>
          )}
          onClose={onCancel}
          type={Modal.TYPES.NO_BUTTONS}
          noHeader
        />
      );
    }

    /* ------------- Loading ------------ */

    if (loading) {
      // Loading spinner
      return (
        <Modal
          key="loading"
          title="Preparing Study Lounge..."
          body={(
            <LoadingSpinner />
          )}
          onClose={onCancel}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    /* ---------- Claim Account --------- */

    return (
      <Modal
        key="claim-account"
        noHeader
        body={(
          <ClaimAccountNotice
            onTryAgain={() => {
              // Try get the link again
              this.getJoinURL();
            }}
            onSkip={() => {
              onContinue(joinLink);
            }}
            onCancel={onCancel}
          />
        )}
        onClose={onCancel}
        type={Modal.TYPES.NO_BUTTONS}
      />
    );
  }
}

LoungePreparationModal.propTypes = {
  // Id of the course
  courseId: PropTypes.number.isRequired,
  // The lounge to join
  lounge: Lounge.isRequired,
  /**
   * Handler to call when user is ready to join
   * @param {string} joinLink the link to use to join
   */
  onContinue: PropTypes.func.isRequired,
  // Handler to call when user cancels the join
  onCancel: PropTypes.func.isRequired,
};

export default LoungePreparationModal;
