/**
 * An expandable form drawer
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import shared components
import InputLabel from './InputLabel';

// Import styles
import './ExpandableDrawer.css';

class ExpandableDrawer extends Component {
  /**
   * Render ExpandableDrawer
   * @author Gabe Abrams
   */
  render() {
    const {
      icon,
      title,
      contents,
      expanded,
      renderDrawerContents,
      noMarginOnBottom,
      isAdminFeature,
    } = this.props;

    // The top (always visible)
    const topBar = (
      <div className="ExpandableDrawer input-group">
        <div className="input-group-prepend d-flex w-100">
          {/* Label */}
          <InputLabel
            icon={icon}
            title={title}
            isAdminFeature={isAdminFeature}
          />

          {/* Contents */}
          <span
            className={`ExpandableDrawer-contents input-group-text flex-grow-1 rounded-right ${isAdminFeature ? 'alert-success progress-bar-striped' : ''}`}
            style={{
              background: (
                isAdminFeature
                  ? undefined
                  : 'white'
              ),
            }}
          >
            {contents}
          </span>
        </div>
      </div>
    );

    // Drawer (only if expanded)
    const drawer = (
      expanded
        ? (
          <div
            className="alert alert-secondary ml-3 mr-3 ExpandableDrawer-drawer ExpandableDrawer-enter"
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTop: 0,
            }}
          >
            {renderDrawerContents()}
          </div>
        )
        : null
    );

    // Put everything together
    return (
      <div
        className={`ExpandableDrawer-outer-container ${noMarginOnBottom ? '' : 'mb-3'}`}
      >
        {topBar}
        {drawer}
      </div>
    );
  }
}

ExpandableDrawer.propTypes = {
  // An icon to display next to the title
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object, // FontAwesome icon
  // Title of the input field
  title: PropTypes.string.isRequired,
  // The contents to put in the input area
  contents: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  // True if drawer should be expanded
  expanded: PropTypes.bool,
  // Function that returns the contents of the drawer
  renderDrawerContents: PropTypes.func,
  // If true, no border on bottom
  noMarginOnBottom: PropTypes.bool,
  // If true, use admin feature theme
  isAdminFeature: PropTypes.bool,
};

ExpandableDrawer.defaultProps = {
  // No icon
  icon: null,
  // By default, drawer is empty
  renderDrawerContents: () => {
    return (
      <div>
        Nothing here yet!
      </div>
    );
  },
  // Include the margin by default
  noMarginOnBottom: false,
  // Drawer is not expanded
  expanded: false,
  // Not an admin feature
  isAdminFeature: false,
};

export default ExpandableDrawer;
