/**
 * A generic popup modal
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import other components
import BootstrapModal from 'react-bootstrap/Modal';

/*
To use this modal, import it:
import Modal from '../shared/Modal';

Add it in JSX:
<Modal
  title="Are you sure?"
  body="This is a dangerous action!"
  type={Modal.TYPES.OKAY_CANCEL}
  onClose={(button) => {
    if (button === Modal.BUTTONS.OKAY) {
      // Do something
    }
  }}
/>
*/

// Constants
const MS_ANIMATE_IN_DELAY = 10;
const MS_TO_ANIMATE = 400; // Time to animate in/out (defined by bootstrap)

class Modal extends Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // If true, the modal is shown
      visible: false,
      // True if currently animating in
      animatingIn: true,
    };

    // Hide after a certain amount of time
    if (props.autoCloseSec) {
      setTimeout(() => {
        this.handleClose(Modal.BUTTONS.CANCEL);
      }, props.autoCloseSec * 1000);
    }
  }

  /**
   * Upon mount, wait a moment then animate the modal in
   * @author Gabe Abrams
   */
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, MS_ANIMATE_IN_DELAY);

    // Wait then update the animatingIn feature
    setTimeout(() => {
      this.setState({
        animatingIn: false,
      });
    }, MS_ANIMATE_IN_DELAY + MS_TO_ANIMATE);
  }

  /**
   * Handles the closing of the modal
   * @author Gabe Abrams
   * @param {string} button - the button that was clicked when closing the
   *   modal
   */
  handleClose(button) {
    // Deconstruct
    const { onClose } = this.props;
    const {
      visible,
      animatingIn,
    } = this.state;

    // Don't close if animating in
    if (animatingIn) {
      return;
    }

    // Don't close if already closed
    if (!visible) {
      return;
    }

    // Update the state
    this.setState({ visible: false });

    // Call the handler after the modal has animated out
    if (onClose) {
      setTimeout(() => {
        onClose(button);
      }, MS_TO_ANIMATE);
    }
  }

  /**
   * Renders the modal
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props and state
    const {
      title,
      body,
      type,
      noHeader,
      okayLabel,
      okayColor,
      isAdminFeature,
    } = this.props;
    const {
      visible,
    } = this.state;

    // Create footer
    let footer;
    if (type === Modal.TYPES.BLOCKED) {
      footer = null;
    } else if (type === Modal.TYPES.NO_BUTTONS) {
      footer = null;
    } else if (type === Modal.TYPES.OKAY_CANCEL) {
      footer = (
        <div>
          {/* Okay Button */}
          <button
            type="button"
            className={`Modal-okay-button btn btn-${okayColor} mr-1`}
            onClick={() => {
              this.handleClose(Modal.BUTTONS.OKAY);
            }}
          >
            {okayLabel}
          </button>

          {/* Cancel Button */}
          <button
            type="button"
            className="Modal-cancel-button btn btn-secondary"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.CANCEL);
            }}
          >
            Cancel
          </button>
        </div>
      );
    } else if (type === Modal.TYPES.CANCEL) {
      footer = (
        <div>
          {/* Cancel Button */}
          <button
            type="button"
            className="Modal-cancel-button btn btn-secondary"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.CANCEL);
            }}
          >
            Cancel
          </button>
        </div>
      );
    } else if (type === Modal.TYPES.YES_NO) {
      footer = (
        <div>
          {/* Yes Button */}
          <button
            type="button"
            className="Modal-yes-button btn btn-secondary mr-1"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.YES);
            }}
          >
            Yes
          </button>

          {/* No */}
          <button
            type="button"
            className="Modal-no-button btn btn-secondary"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.NO);
            }}
          >
            No
          </button>
        </div>
      );
    } else if (type === Modal.TYPES.YES_NO_CANCEL) {
      footer = (
        <div>
          {/* Yes */}
          <button
            type="button"
            className="Modal-yes-button btn btn-secondary mr-1"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.YES);
            }}
          >
            Yes
          </button>

          {/* No */}
          <button
            type="button"
            className="Modal-no-button btn btn-secondary mr-1"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.NO);
            }}
          >
            No
          </button>

          {/* Cancel */}
          <button
            type="button"
            className="Modal-cancel-button btn btn-secondary"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.CANCEL);
            }}
          >
            Cancel
          </button>
        </div>
      );
    } else if (type === Modal.TYPES.BACK_CONTINUE) {
      footer = (
        <div>
          {/* Back */}
          <button
            type="button"
            className="Modal-back-button btn btn-secondary mr-1"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.BACK);
            }}
          >
            Back
          </button>

          {/* Continue */}
          <button
            type="button"
            className="Modal-continue-button btn btn-info"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.CONTINUE);
            }}
          >
            Continue
          </button>
        </div>
      );
    } else if (type === Modal.TYPES.ABANDON_CONTINUE) {
      footer = (
        <div>
          {/* Abandon */}
          <button
            type="button"
            className="Modal-abandon-button btn btn-dark mr-1"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.ABANDON);
            }}
          >
            Abandon Changes
          </button>

          {/* Continue */}
          <button
            type="button"
            className="Modal-continue-editing-button btn btn-secondary"
            onClick={() => {
              this.handleClose(Modal.BUTTONS.CONTINUE_EDITING);
            }}
          >
            Continue Editing
          </button>
        </div>
      );
    } else {
      // Okay modal
      footer = (
        <div>
          {/* Okay */}
          <button
            type="button"
            className={`Modal-okay-button btn btn-${okayColor} mr-1`}
            onClick={() => {
              this.handleClose(Modal.BUTTONS.OKAY);
            }}
          >
            {okayLabel}
          </button>
        </div>
      );
    }

    // Render the modal
    return (
      <BootstrapModal
        show={visible}
        size="lg"
        onHide={() => {
          if (type !== Modal.TYPES.BLOCKED) {
            this.handleClose(Modal.BUTTONS.CANCEL);
          }
        }}
        style={{ zIndex: 5000000000 }}
        backdropClassName="App-z-index-200000 Modal-backdrop"
        centered
      >
        {!noHeader && (
          <BootstrapModal.Header
            closeButton={type !== Modal.TYPES.BLOCKED}
            className={(
              isAdminFeature
                ? 'bg-success text-white progress-bar-striped'
                : undefined
            )}
          >
            <BootstrapModal.Title>
              {title}
            </BootstrapModal.Title>
          </BootstrapModal.Header>
        )}
        <BootstrapModal.Body
          className={(
            isAdminFeature
              ? 'alert-success progress-bar-striped'
              : undefined
          )}
        >
          {body}
        </BootstrapModal.Body>
        {footer && (
          <BootstrapModal.Footer
            className={(
              isAdminFeature
                ? 'alert-success progress-bar-striped'
                : undefined
            )}
          >
            {footer}
          </BootstrapModal.Footer>
        )}
      </BootstrapModal>
    );
  }
}

// Types of buttons that can be clicked
Modal.BUTTONS = {
  YES: 'yes',
  NO: 'no',
  CANCEL: 'cancel',
  CONTINUE: 'continue',
  BACK: 'back',
  ABANDON: 'abandon',
  CONTINUE_EDITING: 'continue-editing',
  OKAY: 'okay',
};

// Types of modals to show
Modal.TYPES = {
  OKAY: 'okay',
  CANCEL: 'cancel',
  OKAY_CANCEL: 'okay-cancel',
  YES_NO: 'yes-no',
  YES_NO_CANCEL: 'yes-no-cancel',
  BACK_CONTINUE: 'back-continue',
  ABANDON_CONTINUE: 'abandon-continue',
  NO_BUTTONS: 'no-buttons',
  BLOCKED: 'blocked', // no cancel, no X, no way to get out
};

// Prop types
Modal.propTypes = {
  // The body of the modal
  body: PropTypes.node.isRequired,
  // Handler to call when modal is closed
  onClose: PropTypes.func,
  // The title of the modal
  title: PropTypes.node,
  // If true, no header
  noHeader: PropTypes.bool,
  // Type of the modal
  type: PropTypes.string,
  // The text of the okay button
  okayLabel: PropTypes.string,
  // The bootstrap color of the okay button
  okayColor: PropTypes.string,
  // After this amount of time, automatically close the modal:
  autoCloseSec: PropTypes.number,
  // True if showing an admin feature
  isAdminFeature: PropTypes.bool,
};

// Default prop values
Modal.defaultProps = {
  // Modal.TYPES.BLOCKED does not pass an onClose Handler
  onClose: null,
  // Generic Title
  title: 'Prompt',
  // Header exists
  noHeader: false,
  // By default, the modal is an okay modal
  type: Modal.TYPES.OKAY,
  // By default, the okay button is labeled "OK"
  okayLabel: 'OK',
  // By default, the okay button is info color
  okayColor: 'dark',
  // No auto-hide
  autoCloseSec: null,
  // Not an admin feature
  isAdminFeature: false,
};

export default Modal;
