/**
 * A decorative lounge door
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import style
import './LoungeDoor.css';

class LoungeDoor extends Component {
  /**
   * Render LoungeDoor
   * @author Gabe Abrams
   */
  render() {
    const { onLeft } = this.props;

    return (
      <div
        className="LoungeDoor-container d-none d-md-block"
        style={{
          [onLeft ? 'left' : 'right']: '30px',
        }}
      >
        <div className="LoungeDoor-left" />
        <div className="LoungeDoor-right" />
        <div className="LoungeDoor-knob" />
        <div className="LoungeDoor-mat">
          welcome
        </div>
      </div>
    );
  }
}

LoungeDoor.propTypes = {
  // True if on left, otherwise on right
  onLeft: PropTypes.bool,
};

LoungeDoor.defaultProps = {
  // On right
  onLeft: false,
};

export default LoungeDoor;
