/**
 * Create a time string from a date object
 * @author Gabe Abrams
 * @param {Date} date - the date to format
 * @return {string} formatted time string
 */
export default (date) => {
  return date
    // Create US date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    })
    // Remove space between time and AM/PM
    .replace(/ /g, '')
    // Make AM/PM lowercase
    .toLowerCase();
};
