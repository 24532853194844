/**
 * Instructions on how to set up a Google Sheet
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// Import helpers
import toHeaderCase from '../helpers/toHeaderCase';

// Import constant helpers
import getColumnHeaders from '../constants/getColumnHeaders';

// Import constants
import GATHER_TAGS from '../constants/GATHER_TAGS';

// Import shared components
import Modal from '../../../shared/Modal';
import CSVDownloadButton from '../../../shared/CSVDownloadButton';

/* -------------------------- Component ------------------------- */

class GoogleSheetInstructionsModal extends Component {
  /**
   * Render GoogleSheetInstructionsModal
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      eventType,
      onClose,
    } = this.props;

    /* ------------------ Examples and Descriptions ----------------- */

    // Get the list of headers
    let headers = getColumnHeaders(eventType);

    // Add a human-readable header name to the headers
    headers = headers.map((header) => {
      const newHeader = header;
      // > Capitalize the name
      const text = (
        header.names[0].toLowerCase() === 'crn'
          ? 'CRN'
          : toHeaderCase(header.names[0])
      );
      // > Figure out which tag to use
      const tag = (header.isWriteColumn ? GATHER_TAGS.WRITE : GATHER_TAGS.READ);
      // > Concatenate the name and tag
      newHeader.name = `${text} ${tag}`;
      return newHeader;
    });

    // Turn the list of headers into descriptions
    // > List of header descriptions (read-only columns)
    const readHeaderDescriptions = [];
    // > List of header descriptions (write-only columns)
    const writeHeaderDescriptions = [];
    // > Header row of the example spreadsheet
    const exampleHeader = [];
    // > First row of the example spreadsheet
    const exampleRow1 = [];
    // > Second row of the example spreadsheet
    const exampleRow2 = [];
    // > Third row of the example spreadsheet (ellipses)
    const exampleRow3 = [];
    // CSV information
    const exampleHeaderMap = {};
    const exampleData = [{}, {}];
    headers.forEach((header) => {
      const description = (
        <div
          key={header.prop}
          className="alert small alert-light p-1 mb-2 text-dark"
          id={`GoogleSheetInstructionsModal-header-description-${header.prop}`}
        >
          {/* Title */}
          <div>
            <strong>
              {header.name}
            </strong>
            &nbsp;
            <em>
              {header.description}
            </em>
          </div>

          {/* Tags */}
          <div>
            {/* Required/optional tag */}
            <div
              className={`badge badge-${header.required ? 'danger' : 'info'} mr-1`}
              id={`GoogleSheetInstructionsModal-header-description-${header.prop}-${header.required ? 'required' : 'optional'}-tag`}
            >
              {header.required ? 'Required' : 'Optional'}
            </div>

            {/* Type */}
            <div
              className="badge badge-secondary mr-1"
              id={`GoogleSheetInstructionsModal-header-description-${header.prop}-type-${header.type}-tag`}
            >
              Type:&nbsp;
              {header.type}
            </div>
          </div>
        </div>
      );

      // Add to the appropriate list
      if (header.isWriteColumn) {
        writeHeaderDescriptions.push(description);
      } else {
        readHeaderDescriptions.push(description);
      }

      // Add examples
      exampleHeader.push(
        <th
          scope="col"
          key={header.prop}
          className="text-center border"
          style={{ whiteSpace: 'nowrap' }}
          id={`GoogleSheetInstructionsModal-${header.prop}-example-header`}
        >
          {header.name}
        </th>
      );
      exampleRow1.push(
        <td
          key={header.prop}
          className="text-center border"
          style={{ whiteSpace: 'nowrap' }}
          id={`GoogleSheetInstructionsModal-${header.prop}-example-r1`}
        >
          {header.examples[0]}
        </td>
      );
      exampleRow2.push(
        <td
          key={header.prop}
          className="text-center border"
          style={{ whiteSpace: 'nowrap' }}
          id={`GoogleSheetInstructionsModal-${header.prop}-example-r2`}
        >
          {header.examples[1]}
        </td>
      );
      exampleRow3.push(
        <td
          key={header.prop}
          className="text-center border text-muted"
          id={`GoogleSheetInstructionsModal-${header.prop}-example-r3`}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </td>
      );

      // Add to CSV
      exampleHeaderMap[header.prop] = header.name;
      exampleData[0][header.prop] = (
        typeof header.examples[0] === 'object'
          ? 'Add Checkbox Here'
          : header.examples[0]
      );
      exampleData[1][header.prop] = (
        typeof header.examples[1] === 'object'
          ? 'Add Checkbox Here'
          : header.examples[1]
      );
    });

    // Example table
    const exampleSheet = (
      <div style={{ overflowX: 'auto' }}>
        <table
          className="table table-striped"
          id="GoogleSheetInstructionsModal-example-sheet-table"
        >
          {/* Header row */}
          <thead>
            <tr>
              {exampleHeader}
            </tr>
          </thead>

          {/* Body */}
          <tbody>
            <tr>
              {exampleRow1}
            </tr>
            <tr>
              {exampleRow2}
            </tr>
            <tr>
              {exampleRow3}
            </tr>
          </tbody>
        </table>
      </div>
    );

    // Create the example download button
    const exampleDownloadButton = (
      <CSVDownloadButton
        filename="Example Batch Creation Spreadsheet"
        headerMap={exampleHeaderMap}
        data={exampleData}
        id="GoogleSheetInstructionsModal-download-example-spreadsheet"
        title="Example Spreadsheet"
      />
    );

    /* ------------------------- Assemble UI ------------------------ */

    const body = (
      <div>
        {/* Note */}
        <div
          className="alert alert-warning mb-5 GoogleSheetInstructionsModal-help"
          id={`GoogleSheetInstructionsModal-help-for-${eventType}`}
        >
          Showing help for creating
          {' '}
          <strong>
            {eventType}
          </strong>
          {' '}
          events. To switch types,
          close this popup and change your answer to
          {' '}
          <em>
            3. Choose an Event Type
          </em>
          .
        </div>

        {/* Tab Instructions */}
        <div
          className="mb-5"
          id="GoogleSheetInstructionsModal-tab-instructions"
        >
          {/* Step */}
          <h4>
            1. Mark your tab
          </h4>

          {/* Explanation */}
          <div>
            End your spreadsheet tab with the &quot;
            {GATHER_TAGS.READ}
            &quot; mark. See the example below:
          </div>

          {/* Visual example */}
          <div>
            <ul
              className="nav nav-tabs"
              style={{ pointerEvents: 'none' }}
              id="GoogleSheetInstructionsModal-tab-instructions-example"
            >
              <li className="nav-item">
                <span className="nav-link">
                  First Tab
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link active">
                  Main&nbsp;
                  {GATHER_TAGS.READ}
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link">
                  Another Tab
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Header Descriptions */}
        <div className="mb-5">
          <h4>
            2. Add columns
          </h4>
          <div className="mb-1">
            If you leave out an
            {' '}
            <div className="badge badge-info">
              Optional
            </div>
            {' '}
            column,
            Gather will treat it as every row having an
            unchecked checkbox.
          </div>
          <div className="mb-2">
            You can also add your own columns. Gather will ignore them.
          </div>

          {/* Read */}
          <div className="text-center">
            <strong>
              Read-only Columns Marked With &quot;
              {GATHER_TAGS.READ}
              &quot;
            </strong>
            &nbsp;(Gather reads from these):
          </div>
          <div
            className="alert alert-dark pl-2 pr-2 pt-2 pb-0"
            id="GoogleSheetInstructionsModal-read-header-descriptions"
          >
            {readHeaderDescriptions}
          </div>

          {/* Write */}
          <div className="text-center">
            <strong>
              Write-only Columns Marked With &quot;
              {GATHER_TAGS.WRITE}
              &quot;
            </strong>
            &nbsp;(Gather writes to these):
          </div>
          <div
            className="alert alert-dark pl-2 pr-2 pt-2 pb-0"
            id="GoogleSheetInstructionsModal-write-header-descriptions"
          >
            {writeHeaderDescriptions}
          </div>
        </div>

        {/* Example */}
        <div>
          <h4>
            3. Check Out This Example
          </h4>
          {exampleSheet}
          <div className="text-center mt-2">
            {exampleDownloadButton}
          </div>
        </div>
      </div>
    );

    /* ------------------------ Create Modal ------------------------ */

    return (
      <Modal
        title="How to Set Up the Spreadsheet"
        body={body}
        type={Modal.TYPES.NO_BUTTONS}
        onClose={onClose}
      />
    );
  }
}

GoogleSheetInstructionsModal.propTypes = {
  // The event type to show instructions for
  eventType: PropTypes.string.isRequired,
  // Function to call when modal is closed
  onClose: PropTypes.func.isRequired,
};

export default GoogleSheetInstructionsModal;
