import visitServerEndpoint from './visitServerEndpoint';

/**
 * Add an error to the log. Fails silently if it can't send more than 3
 *   times
 * @author Gabe Abrams
 * @param {string} type - action type
 * @param {string} [description="N/A"] - human-readable description of
 *   the action
 * @param {object} [metadata={}] - a metadata object to attach to the log
 * @param {boolean} [ignoreSessionExpiry] - if true, catch session expiry
 *   separately
 */
export default async (opts) => {
  for (let i = 0; i < 3; i++) {
    try {
      // Send request to the server
      await visitServerEndpoint({
        path: '/api/logs/actions',
        method: 'POST',
        params: {
          type: opts.type || 'unknown',
          description: opts.description || 'N/A',
          metadata: JSON.stringify(opts.metadata || {}),
        },
        ignoreSessionExpiry: opts.ignoreSessionExpiry,
      });

      // Success! Break
      break;
    } catch (err) {
      // Try again in half a second
      await new Promise((r) => {
        setTimeout(r, 500);
      });
    }
  }
};
