/**
 * Generate a shareable link for an event
 * @author Gabe Abrams
 * @param {number} courseId - the Canvas id of the course
 * @param {string} ihid - the ihid for the event we're linking to
 * @return {string} shareable link
 */
export default (courseId, ihid) => {
  return `https://${window.location.hostname}/courses/${courseId}/events/${ihid}`;
};
