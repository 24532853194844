import React from 'react';

// Import FontAwesome
import {
  faSave,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to create an event (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Automatically Record Events',
  startWarning: 'This will not affect events that are currently in progress',
  steps: [
    (
      <div>
        Find the event in the list and click its
        {' '}
        <ExampleButton
          icon={faCog}
          variant="secondary"
          explanation="edit event button"
        />
        {' '}
        button to edit the event
      </div>
    ),
    (
      <div>
        Under &quot;Zoom Meeting Settings&quot;,
        find the &quot;Auto Record&quot; section and click
        {' '}
        <ExampleButton
          text="Click to Load"
          variant="secondary"
        />
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          text="On"
          isRadio
          variant="light"
        />
        {' '}
        to turn on &quot;Auto Record&quot;
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          icon={faSave}
          text="Save Changes"
          variant="dark"
        />
      </div>
    ),
  ],
  endMessage: 'You\'re done! All future events will automatically be recorded',
};
