/**
 * The Prospective event details
 * Includes event skip reasons and event create error reasons
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import Propspective event badges component
import ShowMoreToggle from './ShowMoreToggle';

// Import style
import './style.css';

class EventDetails extends Component {
  constructor(props) {
    super(props);

    // State for the show more skipped reason toggle
    this.state = {
      // False to hide more skip reasons, true to show
      showAllReasons: false,
    };
  }

  /**
   * Handler for showMore button click event
   * @author Karen Dolan
   */
  handleShowMoreClick() {
    this.setState((prevState) => {
      return {
        showAllReasons: !prevState.showAllReasons,
      };
    });
  }

  /**
   * Render ProspectiveEvent component
   * @author Karen Dolan
   */
  render() {
    const {
      skipReasons,
      errorMessage,
      warningMessage,
      crn,
    } = this.props;
    // The toggle boolean to show more skip reasons
    const { showAllReasons } = this.state;

    // If a null crn was passed in, generate a fake one up to 4 digits
    // The CRN is needed here and ShowMoreToggle for QA testing of the UI
    const theCrn = crn || Math.floor(Math.random() * 9999);

    // Format the skip list and error message, if any
    const details = [];

    // Push error message if something to push (avoid pushing "false")
    if (errorMessage && errorMessage.length > 0) {
      details.push(errorMessage);
    }

    // Push warning message if there is one
    if (warningMessage) {
      details.push(warningMessage);
    }

    // Create skip reason detail component
    skipReasons.forEach((reasonText, i) => {
      const reasonSequence = `skip-reason-${i}`;
      const idClassName = `EventDetails-skip-reason-crn-${theCrn}`;
      const visibleClassName = showAllReasons || i === 0 ? 'd-inherit' : 'd-none';
      const hasButtonClassName = skipReasons.length > 1 && i === 0 ? 'has-button' : '';
      details.push(
        <div
          className="EventDetails-row"
          key={reasonText}
        >
          <div className="EventDetails-row-item">
            <div
              className={`${idClassName} ${reasonSequence} ${visibleClassName} ${hasButtonClassName} text-truncate`}
            >
              {reasonText}
            </div>
          </div>
          {/* Put "show more" button on first row when there's more than one */}
          {(i === 0 && skipReasons.length > 1) && (
            <ShowMoreToggle
              showMore={showAllReasons}
              onClick={(() => {
                this.handleShowMoreClick();
              })}
              crn={theCrn}
            />
          )}
        </div>
      );
    });
    // Add  details to the return statement
    return (
      <div className={`EventDetails EventDetails-${theCrn}`}>
        {details}
      </div>
    );
  }
}

EventDetails.propTypes = {
  // The course number associated to the course
  // Needed for element class id hooks in QA testing
  crn: PropTypes.number,
  // The array of skip reasons
  skipReasons: PropTypes.arrayOf(PropTypes.string),
  // The error message string
  errorMessage: PropTypes.string,
  // The warning message
  warningMessage: PropTypes.string,
};

EventDetails.defaultProps = {
  // Default to not being created
  skipReasons: [],
  // Don't show error message
  errorMessage: undefined,
  // Don't show warning message
  warningMessage: null,
  // If CRN not supplied (because missing on event),
  // or it is supplied but is null, ensure the null
  // value gets passed in.
  crn: null,
};

export default EventDetails;
