/**
 * Comparator for sorting events
 * @author Gabe Abrams
 * @param {Event} firstEvent - the first event to compare
 * @param {Event} secondEvent - the second event to compare
 * @return {number} comparator result
 */
export default (a, b) => {
  // First sort highlighted events to top
  if (a.highlighted && !b.highlighted) {
    return -1;
  }
  if (!a.highlighted && b.highlighted) {
    return 1;
  }

  // Second, sort pinned events to the top
  if (a.pinned && !b.pinned) {
    return -1;
  }
  if (!a.pinned && b.pinned) {
    return 1;
  }

  // Sort by IHID
  const aIHID = a.ihid;
  const bIHID = b.ihid;

  // Split into letters and numbers
  // > Find index dividing letters from numbers
  const aDivIndex = aIHID.match(/[0-9]/).index;
  const bDivIndex = bIHID.match(/[0-9]/).index;
  // > Extract letters
  const aIHIDLetters = (
    aIHID
      .substring(0, aDivIndex)
      .toLowerCase()
  );
  const bIHIDLetters = (
    bIHID
      .substring(0, bDivIndex)
      .toLowerCase()
  );
  // > Extract numbers
  const aIHIDNumber = Number.parseInt(aIHID.substring(aDivIndex));
  const bIHIDNumber = Number.parseInt(bIHID.substring(aDivIndex));

  // Compare letters first
  if (aIHIDLetters < bIHIDLetters) {
    return -1;
  }
  if (aIHIDLetters > bIHIDLetters) {
    return 1;
  }

  // Compare numbers second
  if (aIHIDNumber < bIHIDNumber) {
    return -1;
  }
  if (aIHIDNumber > bIHIDNumber) {
    return 1;
  }

  // Events are equal
  return 0;
};
