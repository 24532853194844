import DEFAULT_LOUNGE_NAME from '../constants/DEFAULT_LOUNGE_NAME';

/**
 * Generate an empty lounge object shell (must be filled with data)
 * @author Gabe Abrams
 * @param {number} courseId - the id of the course containing the lounge
 * @param {string} loungeId - the id of the lounge
 * @return {object} lounge object
 */
export default (courseId, loungeId) => {
  // Initialize the lounge name
  return {
    courseId,
    loungeId,
    name: DEFAULT_LOUNGE_NAME,
    key: `${courseId}-${loungeId}`, // The key for unique indexing
    currentZoomId: null, // Current Zoom meeting id
    currentZoomHost: null, // Host id or email for the current meeting
    openZoomLink: null, // Link to open Zoom room
    archived: false, // Not archived
  };
};
