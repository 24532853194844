/**
 * Form element for a lounge email
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

// Shared components
import InputLabel from '../InputLabel';

/* -------------------------- Component ------------------------- */

class LoungeEmail extends Component {
  /**
   * Render LoungeEmail
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      email,
      onChange,
    } = this.props;

    // Full UI
    return (
      <div className="input-group mb-3" key="name">
        {/* Label */}
        <div className="input-group-prepend">
          <InputLabel
            icon={faHeadset}
            title="Zoom"
            isAdminFeature
          />
        </div>

        {/* Text input field for title */}
        <input
          type="text"
          className="form-control border-success"
          aria-label="event email"
          id="LoungeEmail-input"
          placeholder={'e.g. "Study Lounge" or "Open Area"'}
          value={email}
          onChange={(e) => {
            // Update state in parent
            return onChange(e.target.value);
          }}
        />
      </div>
    );
  }
}

LoungeEmail.propTypes = {
  // Current value of the email
  email: PropTypes.string.isRequired,
  /**
   * Handler for when the email changes
   * @param {string} email - the new email
   */
  onChange: PropTypes.func.isRequired,
};

export default LoungeEmail;
