// Import FontAwesome Icons
import {
  faUniversity,
  faVial,
  faUsers,
  faCalendarAlt,
  faHandshake,
  faUserFriends,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';

// Lookup Map: type => icon
const typeToIcon = {
  class: faUniversity,
  lab: faVial,
  'office-hours': faUserClock,
  section: faUsers,
  event: faCalendarAlt,
  meeting: faHandshake,
  other: faUserFriends,
};

/**
 * Get the associated icon for a type
 * @author Gabe Abrams
 * @param {string} type - the event type
 * @return {object} FontAwesome Icon
 */
export default (type) => {
  // Get the type
  return typeToIcon[type];
};
