/**
 * An item with a checkbox
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

class CheckItem extends Component {
  /**
   * Render CheckItem
   * @author Gabe Abrams
   */
  render() {
    const {
      text,
      checked,
      onChange,
      ariaLabel,
      id,
      noMarginOnRight,
    } = this.props;

    return (
      <button
        type="button"
        id={id}
        className={`btn btn-sm btn-${checked ? 'warning' : 'light'} ${noMarginOnRight ? '' : 'mr-2'}`}
        aria-label={`${ariaLabel}: currently ${checked ? 'checked' : 'unchecked'}`}
        onClick={() => {
          onChange(!checked);
        }}
      >
        <FontAwesomeIcon
          icon={checked ? faCheckSquare : faSquare}
          className="mr-2"
        />
        {text}
      </button>
    );
  }
}

CheckItem.propTypes = {
  // Text of the item
  text: PropTypes.string.isRequired,
  // True if checked
  checked: PropTypes.bool.isRequired,
  /**
   * Handler to call when CheckItem is changed
   * @param {boolean} newChecked - new value of checked
   */
  onChange: PropTypes.func.isRequired,
  // Aria label
  ariaLabel: PropTypes.string.isRequired,
  // The id of the CheckItem
  id: PropTypes.string.isRequired,
  // If true, no margin on right
  noMarginOnRight: PropTypes.bool,
};

CheckItem.defaultProps = {
  // There is a right margin
  noMarginOnRight: false,
};

export default CheckItem;
