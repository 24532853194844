import WEEKDAY_TO_CODE from './WEEKDAY_TO_CODE';

const CODE_TO_WEEKDAY = {};

// Swap the key and value
Object.keys(WEEKDAY_TO_CODE).forEach((key) => {
  const val = WEEKDAY_TO_CODE[key];

  CODE_TO_WEEKDAY[val] = key;
});

/**
 * Dynamically created weekday map
 * @author Gabe Abrams
 */
export default CODE_TO_WEEKDAY;
