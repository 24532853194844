/**
 * Form element for a lounge name
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { faFont } from '@fortawesome/free-solid-svg-icons';

// Shared components
import InputLabel from '../InputLabel';

/* -------------------------- Component ------------------------- */

class LoungeName extends Component {
  /**
   * Render LoungeName
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      name,
      onChange,
    } = this.props;

    // Full UI
    return (
      <div
        className="input-group mb-3"
        key="name"
      >
        {/* Label */}
        <div className="input-group-prepend">
          <InputLabel
            icon={faFont}
            title="Name"
            isAdminFeature
          />
        </div>

        {/* Text input field for title */}
        <input
          type="text"
          className="form-control border-success"
          aria-label="event name"
          id="LoungeName-input"
          placeholder={'e.g. "Study Lounge" or "Open Area"'}
          value={name}
          onChange={(e) => {
            // Update stat in parent
            return onChange(e.target.value);
          }}
        />
      </div>
    );
  }
}

LoungeName.propTypes = {
  // Current value of the name
  name: PropTypes.string.isRequired,
  /**
   * Handler for when the name changes
   * @param {string} name - the new name
   */
  onChange: PropTypes.func.isRequired,
};

export default LoungeName;
