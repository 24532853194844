/**
 * Modal explaining that the event cannot be deleted
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../shared/Modal';

// Import shared constants
import SUPPORT_EMAILS from '../constants/SUPPORT_EMAILS';

class CannotDeleteModal extends Component {
  /**
   * Render CannotDeleteModal
   * @author Gabe Abrams
   */
  render() {
    const { onClose } = this.props;
    return (
      <Modal
        key="cannot-delete-modal"
        title={(
          <span>
            {/* Icon */}
            <FontAwesomeIcon
              icon={faLock}
              className="mr-2"
            />

            {/* Title */}
            This Event Cannot be Deleted
          </span>
        )}
        body={(
          <div>
            {/* Explanation */}
            To keep the student experience secure and consistent,
            you cannot delete this event.
            If you have questions,
            email support at
            {' '}
            <a
              href={`mailto:${SUPPORT_EMAILS.FOR_TTMS}`}
              aria-label="email DCE web conference support"
            >
              {SUPPORT_EMAILS.FOR_TTMS}
            </a>
          </div>
        )}
        type={Modal.TYPES.NO_BUTTONS}
        onClose={onClose}
      />
    );
  }
}

CannotDeleteModal.propTypes = {
  // Handler for when the modal is closed
  onClose: PropTypes.func.isRequired,
};

export default CannotDeleteModal;
