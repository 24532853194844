// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import tooltip components
// Note regarding OverlayTrigger "findDOMNode is deprecated in StrictMode"
// https://github.com/react-bootstrap/react-bootstrap/issues/5075
import {
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Import shared components
import ButtonLock from './ButtonLock';

// Import css
import './TooltipButton.css';

class TooltipButton extends Component {
  /**
   * Render the tooltip button
   * @author Karen Dolan
   * @author Gabe Abrams
   */
  render() {
    const {
      id,
      className,
      ariaLabel,
      title,
      onClick,
      contents,
      locked,
    } = this.props;

    return (
      <span>
        <OverlayTrigger
          placement="top"
          rootClose
          overlay={(props) => {
            /* eslint-disable react/jsx-props-no-spreading */
            return (
              <Tooltip
                className="TooltipButton-tooltip TooltipButton-tooltip-top"
                {...props}
              >
                {title}
              </Tooltip>
            );
          }}
        >
          <Button
            variant="secondary"
            id={id}
            className={`${className} position-relative`}
            aria-label={ariaLabel}
            onClick={() => {
              onClick();
            }}
          >
            {contents}

            {/* Show lock icon if button is locked */}
            {locked && (
              <ButtonLock />
            )}
          </Button>
        </OverlayTrigger>
      </span>
    );
  }
}

TooltipButton.propTypes = {
  // ID of the button
  id: PropTypes.string,
  // the classes for the button except btn-lg
  className: PropTypes.string.isRequired,
  // the aria-label for the button
  ariaLabel: PropTypes.string.isRequired,
  // the title of the button
  title: PropTypes.string.isRequired,
  // the onClick handler for the button
  onClick: PropTypes.func.isRequired,
  // the contents of the button
  contents: PropTypes.node.isRequired,
  // if true, the button will display a lock icon (but will not block clicks)
  locked: PropTypes.bool,
};

TooltipButton.defaultProps = {
  // No id
  id: undefined,
  // default to not locked
  locked: false,
};

export default TooltipButton;
