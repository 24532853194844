/**
 * UI for setting up the batch creation process
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faInfoCircle,
  faLink,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import GoogleSheetInstructionsModal from './GoogleSheetInstructionsModal';

// Import constants
import EVENT_TYPES_MAP from '../../../constants/EVENT_TYPES_MAP';

// Import shared components
import EventType from '../../../shared/EventFormItem/EventType';
import BatchCreationFooter from '../shared/BatchCreationFooter';

/* -------------------------- Constants ------------------------- */

const now = new Date();

// Choices of years to choose from
const YEARS = [
  now.getFullYear() - 1, // Last year
  now.getFullYear(), // This year
  now.getFullYear() + 1, // Next year
];

/* -------------------------- Component ------------------------- */

class BatchEventCreatorSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The text typed into the URL field
      url: '',
      // The type of event being created
      eventType: EVENT_TYPES_MAP.CLASS,
      // Year to create the courses in
      year: YEARS[1], // current year
      // If true, show Google Sheet instructions
      showGoogleSheetInstructions: false,
    };
  }

  /**
   * Render BatchEventCreatorSetup
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      robotEmail,
      onDone,
    } = this.props;

    // Deconstruct state
    const {
      url,
      eventType,
      year,
      showGoogleSheetInstructions,
    } = this.state;

    /* ---------------------------- Modal --------------------------- */

    let modal;

    // Instructions on setting up the google sheet
    if (showGoogleSheetInstructions) {
      modal = (
        <GoogleSheetInstructionsModal
          eventType={eventType}
          onClose={() => {
            this.setState({
              showGoogleSheetInstructions: false,
            });
          }}
        />
      );
    }

    /* ----------------------------- UI ----------------------------- */

    // Validate the URL
    const urlInvalid = (
      // URL must exist
      !url
      // URL must have some text
      || url.length === 0
      // URL must be a Google Sheets URL
      || !url.startsWith('https://docs.google.com/spreadsheets/')
      // URL cannot have spaces
      || url.trim().indexOf(' ') >= 0
    );

    // Create the UI
    return (
      <div>
        {/*  Add the modal */}
        {modal}

        {/* Content */}
        <div className="alert alert-light text-dark mt-3">
          {/* Instructions */}
          <h3>
            1. Set Up your Sheet
          </h3>
          {/* Spreadsheet */}
          <div className="mb-2">
            First, read our
            {' '}
            <button
              type="button"
              className="btn btn-secondary btn-sm pt-0 pb-0"
              aria-label="Open google sheet instructions"
              id="BatchEventCreatorSetup-show-google-sheet-instructions"
              onClick={() => {
                this.setState({
                  showGoogleSheetInstructions: true,
                });
              }}
            >
              Spreadsheet Guide
            </button>
            {' '}
            for help creating your spreadsheet.
          </div>
          {/* Gather Robot Instructions */}
          <div>
            Second, share your sheet with the Gather robot (as an editor):
          </div>
          <div className="mb-5">
            <FontAwesomeIcon
              icon={faRobot}
              className="text-muted"
            />
            <span
              className="badge badge-light text-muted user-select-all"
              id="BatchEventCreatorSetup-robot-email"
            >
              {robotEmail}
            </span>
          </div>

          {/* Spreadsheet link */}
          <h3 className="m-0">
            2. Get the Sheet Link
          </h3>
          <div className="mb-2">
            Paste a link to your spreadsheet below:
          </div>
          <div className="input-group mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon
                  icon={faLink}
                  className="mr-2"
                />
                Google Sheet URL
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="e.g. https://docs.google.com/spreadsheets/..."
              aria-label="link to the google sheet containing batch creator information"
              value={url}
              id="BatchEventCreatorSetup-google-sheet-url-field"
              onChange={(e) => {
                // Save and force a trim so no whitespace is in the field
                this.setState({
                  url: String(e.target.value).trim(),
                });
              }}
            />
          </div>

          {/* Event Type */}
          <h3 className="m-0">
            3. Choose an Event Type
          </h3>
          <div className="mb-2">
            Are you batch-creating classes or sections?
          </div>
          <div className="mb-5">
            <EventType
              type={eventType}
              onChange={(newType) => {
                this.setState({
                  eventType: newType,
                });
              }}
              onlyClassAndSection
            />
          </div>

          {/* Calendar Year */}
          <h3 className="m-0">
            4. Choose a Calendar Year
          </h3>
          <div className="mb-2">
            During which calendar year
            {' '}
            <strong>
              (NOT the academic year)
            </strong>
            {' '}
            will the courses take place?
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="mr-2"
                />
                Calendar Year
              </span>
            </div>
            <select
              className="custom-select"
              value={year}
              id="BatchEventCreatorSetup-calendar-year-chooser"
              onChange={(e) => {
                this.setState({
                  year: YEARS[e.target.selectedIndex],
                });
              }}
            >
              {/* Create a list of years for the dropdown */}
              {
                YEARS.map((yearOption) => {
                  return (
                    <option
                      key={yearOption}
                      value={yearOption}
                      id={`BatchEventCreatorSetup-calendar-year-chooser-${yearOption}`}
                    >
                      {yearOption}
                      {' '}
                      Calendar Year
                    </option>
                  );
                })
              }
            </select>
          </div>
        </div>

        {/* Footer */}
        <BatchCreationFooter
          continueButton={(
            !urlInvalid
              ? {
                onClick: () => {
                  // Create a dataStoreInfo object
                  const dataStoreInfo = {
                    url,
                  };

                  // Alert the parent
                  onDone(dataStoreInfo, eventType, year);
                },
                description: [
                  'Next up: review and confirm info',
                  'before starting the batch process.',
                ],
              }
              : null
          )}
          customContent={urlInvalid && (
            <div
              className="text-danger font-weight-bold"
              id="BatchEventCreatorSetup-add-valid-url-message"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              To continue, add a valid Google Sheet URL.
            </div>
          )}
        />
      </div>
    );
  }
}

BatchEventCreatorSetup.propTypes = {
  // Email address for the Gather robot
  robotEmail: PropTypes.string.isRequired,
  /**
   * Function to call when done
   * @param {object} dataStoreInfo - a data store object to use to load the list
   *   of events
   * @param {string} eventType - the event type to batch-create
   * @param {number} year - the calendar year for the courses
   */
  onDone: PropTypes.func.isRequired,
};

export default BatchEventCreatorSetup;
