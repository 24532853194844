/**
 * Popup to ask user if they want to host the meeting then directs them
 *   to the proper link
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../../shared/Modal';
import LoadingSpinner from '../../shared/LoadingSpinner';

// Import helpers
import visitServerEndpoint from '../../helpers/visitServerEndpoint';

// Import shared prop-types
import Event from '../../shared/propTypes/Event';
import ClaimAccountNotice from '../../shared/ClaimAccountNotice';
import ErrorAlert from '../../shared/ErrorAlert';

// Import style
import './ChooseJoinTypeModal.css';

// Import shared constants
import SUPPORT_EMAILS from '../../constants/SUPPORT_EMAILS';

class ChooseJoinTypeModal extends Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // True if loading
      loading: false,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
      // True if showing the participant notice
      showParticipantNotice: false,
      // True if showing the host notice
      showHostNotice: false,
      // True if showing the "claim your account" notice
      showClaimAccountNotice: false,
      // True if showing "user deactivated" notice
      showUserDeactivatedNotice: false,
    };
  }

  /**
   * Get the startURL for the host
   * @author Gabe Abrams
   */
  async getStartURL() {
    const {
      courseId,
      event,
      onJoinAsHost,
      onJoinAsPanelist,
    } = this.props;

    // Start loading
    this.setState({ loading: true });

    // Get the start URL (add as alt-host if needed, log in, get URL)
    try {
      const startURL = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${event.ihid}/start_url`,
        method: 'GET',
      });

      // Alert parent that user wants to join
      if (event.isWebinar) {
        onJoinAsPanelist(startURL);
      } else {
        onJoinAsHost(startURL);
      }
    } catch (err) {
      // Handle user not found error
      if (err.code && err.code === 'ZOOM404-1113') {
        return this.setState({
          showClaimAccountNotice: true,
          loading: false,
        });
      }

      // Handle user not found during ZAK error
      if (err.code && err.code === 'ZOOM404-1001') {
        return this.setState({
          showClaimAccountNotice: true,
          loading: false,
        });
      }

      // Handle user is deactivated error
      if (err.code && err.code === 'ZAC14') {
        return this.setState({
          showUserDeactivatedNotice: true,
          loading: false,
        });
      }

      // Save error message
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
        loading: false,
      });
    }
  }

  /**
   * Render ChooseJoinTypeModal
   * @author Gabe Abrams
   */
  render() {
    const {
      onCancel,
      onJoinAsParticipant,
      userEmail,
      event,
      isLearner,
    } = this.props;
    const {
      loading,
      fatalErrorMessage,
      fatalErrorCode,
      showParticipantNotice,
      showHostNotice,
      showClaimAccountNotice,
      showUserDeactivatedNotice,
    } = this.state;

    // Body
    let body;

    // Show error message if there is one
    if (fatalErrorMessage) {
      // Create full modal
      return (
        <Modal
          body={(
            <div>
              <ErrorAlert
                message={fatalErrorMessage}
                code={fatalErrorCode}
              />

              {/* Close Button */}
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  aria-label="close error and cancel"
                  onClick={onCancel}
                >
                  OK
                </button>
              </div>
            </div>
          )}
          onClose={onCancel}
          type={Modal.TYPES.NO_BUTTONS}
          noHeader
        />
      );
    }
    if (loading) {
      // Loading spinner
      body = (
        <LoadingSpinner />
      );
    }

    /* -------------------- Claim Account Message ------------------- */

    if (showClaimAccountNotice) {
      return (
        <Modal
          key="claim-account-modal"
          noHeader
          body={(
            <ClaimAccountNotice
              onTryAgain={() => {
                // Reset
                this.setState(
                  {
                    showClaimAccountNotice: false,
                  },
                  () => {
                    // Try get the link again
                    this.getStartURL();
                  }
                );
              }}
              onCancel={onCancel}
            />
          )}
          onClose={onCancel}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    /* ------------------- User Deactivated Notice ------------------ */

    if (showUserDeactivatedNotice) {
      body = (
        <div className="alert alert-danger m-0">
          <h3>
            Oops! Your account has been deactivated.
          </h3>
          <div>
            It seems your Harvard Zoom account has been deactivated.
            Please email
            {' '}
            <a
              href={`mailto:${isLearner ? SUPPORT_EMAILS.FOR_STUDENTS : SUPPORT_EMAILS.FOR_TTMS}?subject=Please activate my Zoom account: ${userEmail}`}
              aria-label="send an email to DCE support"
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              {
                isLearner
                  ? SUPPORT_EMAILS.FOR_STUDENTS
                  : SUPPORT_EMAILS.FOR_TTMS
              }
            </a>
            {' '}
            with the subject
            {' '}
            &quot;Please activate my Zoom account:
            {' '}
            {userEmail}
            &quot;
          </div>
        </div>
      );
    }

    /* --------------------------- Chooser -------------------------- */

    if (!body) {
      // Create startAsParticipant button
      const startAsParticipantButton = (
        <button
          type="button"
          id="ChooseJoinTypeModal-join-as-participant-button"
          className="ChooseJoinTypeModal-participant-button btn btn-dark btn-block btn-lg"
          aria-label="continue to Zoom as participant"
          onClick={onJoinAsParticipant}
          onMouseOver={() => {
            this.setState({
              showParticipantNotice: true,
            });
          }}
          onMouseOut={() => {
            this.setState({
              showParticipantNotice: false,
            });
          }}
          onFocus={() => {
            this.setState({
              showParticipantNotice: true,
            });
          }}
          onBlur={() => {
            this.setState({
              showParticipantNotice: false,
            });
          }}
        >
          <div>
            <FontAwesomeIcon
              icon={faUserAlt}
              className="mr-2"
            />
            <strong>
              Participant
            </strong>
          </div>

          {/* Notice for joining as a participant */}
          <div
            className="small"
            style={{
              opacity: (
                showParticipantNotice
                  ? 1
                  : 0
              ),
              maxHeight: (
                showParticipantNotice
                  ? undefined
                  : 0
              ),
              overflow: 'hidden',
            }}
          >
            Make sure you&apos;re logged out of Zoom first!
          </div>
        </button>
      );

      // Create the body for the modal
      body = (
        <div>
          {/* Choice buttons */}
          <div>
            <div className="row">
              <div className="col">
                {startAsParticipantButton}
              </div>
              <div className="col">
                <button
                  type="button"
                  id={(
                    event.isWebinar
                      ? 'ChooseJoinTypeModal-i-am-the-panelist-button'
                      : 'ChooseJoinTypeModal-i-am-the-host-button'
                  )}
                  className="ChooseJoinTypeModal-i-am-the-host-button btn btn-dark btn-block btn-lg"
                  aria-label={`join the ${event.isWebinar ? 'webinar as a panelist' : 'meeting as a host'}`}
                  onMouseOver={() => {
                    this.setState({
                      showHostNotice: true,
                    });
                  }}
                  onMouseOut={() => {
                    this.setState({
                      showHostNotice: false,
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      showHostNotice: true,
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      showHostNotice: false,
                    });
                  }}
                  onClick={() => {
                    this.getStartURL();
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="mr-2"
                    />
                    <strong>
                      {event.isWebinar ? 'Panelist' : 'Host'}
                    </strong>
                  </div>

                  {/* Notice for joining as a host */}
                  <div
                    className="small"
                    style={{
                      opacity: (
                        showHostNotice
                          ? 1
                          : 0
                      ),
                      maxHeight: (
                        showHostNotice
                          ? undefined
                          : 0
                      ),
                      overflow: 'hidden',
                    }}
                  >
                    <div>
                      {
                        event.isWebinar
                          ? 'You may need to wait for DCE'
                          : 'If another host joined first,'
                      }
                    </div>
                    <div>
                      {
                        event.isWebinar
                          ? 'staff to start the webinar.'
                          : 'you will be a co-host.'
                      }
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          {/* Note */}
          <div className="text-left mt-3">
            <strong>
              Note:&nbsp;
            </strong>
            Students don&apos;t get a choice.
            They can only join as participants.
          </div>
        </div>
      );
    }

    /* --------------------- Create the UI/Modal -------------------- */

    // Create full UI
    return (
      <Modal
        key="main-modal"
        title="How do you want to join?"
        body={body}
        onClose={onCancel}
        type={Modal.TYPES.NO_BUTTONS}
      />
    );
  }
}

ChooseJoinTypeModal.propTypes = {
  // Handler to call when user cancels the join
  onCancel: PropTypes.func.isRequired,
  // Id of the course
  courseId: PropTypes.number.isRequired,
  // The event to join
  event: Event.isRequired,
  // If true, user is a learner
  isLearner: PropTypes.bool.isRequired,
  // The user's zoom email
  userEmail: PropTypes.string.isRequired,
  // Handler to call when user opts to join the webinar as a panelist
  onJoinAsPanelist: PropTypes.func.isRequired,
  // Handler to call when user opts to join the meeting as a participant
  onJoinAsParticipant: PropTypes.func.isRequired,
  /**
   * Handler to call when user opts to join the meeting as a host
   * @param {string} startURL - the url to start the meeting
   */
  onJoinAsHost: PropTypes.func.isRequired,
};

export default ChooseJoinTypeModal;
