/**
 * Modal with the results of the process
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import shared components
import Modal from '../../shared/Modal';
import Event from '../../shared/propTypes/Event';

class RecoverEventResultsModal extends Component {
  /**
   * Render RecoverEventResultsModal
   * @author Karen Dolan
   */
  render() {
    // Deconstruct props
    const {
      event,
      onClose,
    } = this.props;

    const zoomText = (
      event.currentZoomId
        ? ` (Zoom ID: ${event.currentZoomId})`
        : null
    );

    return (
      <Modal
        title="Success!"
        body={(
          <div>
            <strong>
              Event successfully recovered:&nbsp;
            </strong>
            <span
              title={event.name}
            >
              {event.name}
              {zoomText}
            </span>
          </div>
        )}
        type={Modal.TYPES.NO_BUTTONS}
        onClose={onClose}
      />
    );
  }
}

RecoverEventResultsModal.propTypes = {
  // The recovered event
  event: Event.isRequired,
  // Handler for when the modal is closed
  onClose: PropTypes.func.isRequired,
};

export default RecoverEventResultsModal;
