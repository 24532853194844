/**
 * Map of event type constants
 * @author Gabe Abrams
 */
export default {
  CLASS: 'class',
  SECTION: 'section',
  OFFICE_HOURS: 'office-hours',
  LAB: 'lab',
  MEETING: 'meeting',
  EVENT: 'event',
  OTHER: 'other',
};
