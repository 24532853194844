/**
 * Example button (not clickable, just for demonstration purposes)
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

class ExampleButton extends Component {
  /**
   * Render ExampleButton
   * @author Gabe Abrams
   */
  render() {
    const {
      text,
      isRadio,
      explanation,
      variant,
    } = this.props;

    // Get icon
    let { icon } = this.props;
    if (isRadio) {
      icon = faCircle;
    }

    let className = `btn btn-${variant} btn-sm`;
    const style = {
      pointerEvents: 'none',
    };
    if (variant === 'admin') {
      className = 'btn btn-success btn-sm progress-bar-striped text-white';
    } else if (variant === 'dashed') {
      className = 'btn btn-light btn-sm';
      style.border = '1px dashed black';
    } else if (variant === 'light') {
      style.border = '1px solid black';
    }

    return (
      <button
        type="button"
        className={className}
        style={style}
        aria-label={`Example button for tutorial (not clickable): ${explanation || `${text} Button`}`}
        title={explanation || `${text} Button`}
        onClick={() => {
          // Nothing will happen upon click
        }}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className={text ? 'mr-1' : ''}
          />
        )}
        {text}
      </button>
    );
  }
}

ExampleButton.propTypes = {
  // Text inside the button
  text: PropTypes.node,
  // Icon to left of text
  icon: PropTypes.objectOf(PropTypes.any),
  // True if is radio (overrides icon)
  isRadio: PropTypes.bool,
  // Explanation of the button
  explanation: PropTypes.string,
  // Button variant or "admin" for an admin button
  variant: PropTypes.string,
};

ExampleButton.defaultProps = {
  // No text
  text: null,
  // No icon
  icon: null,
  // Not a radio button
  isRadio: false,
  // Secondary
  variant: 'secondary',
  // No explanation
  explanation: null,
};

export default ExampleButton;
