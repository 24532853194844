/**
 * A decorative lounge window
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

// Import style
import './LoungeWindow.css';

class LoungeWindow extends Component {
  /**
   * Render LoungeWindow
   * @author Gabe Abrams
   */
  render() {
    const { onLeft } = this.props;

    return (
      <div
        className="LoungeWindow-container d-none d-md-block"
        style={{
          transform: `scaleY(1.6) rotate(${onLeft ? 180 : 0}deg)`,
          top: (onLeft ? '25px' : '10px'),
          left: (onLeft ? '-17px' : undefined),
          right: (onLeft ? undefined : '-17px'),
        }}
      >
        <FontAwesomeIcon
          icon={faWindows}
        />
      </div>
    );
  }
}

LoungeWindow.propTypes = {
  // True if on left, otherwise on right
  onLeft: PropTypes.bool,
};

LoungeWindow.defaultProps = {
  // On right
  onLeft: false,
};

export default LoungeWindow;
