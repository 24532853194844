/**
 * Explanation that user has to go claim their Zoom account
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

// Import shared constants
import SUPPORT_EMAILS from '../constants/SUPPORT_EMAILS';

class ClaimAccountNotice extends Component {
  constructor() {
    super();

    this.state = {
      // True if user clicked to claim
      clickedToClaim: false,
    };
  }

  /**
   * Render ClaimAccountNotice
   * @author Gabe Abrams
   */
  render() {
    const {
      onTryAgain,
      onSkip,
      onCancel,
      isLearner,
    } = this.props;
    const {
      clickedToClaim,
    } = this.state;

    /*----------------------------------------*/
    /*            Try Again Notice            */
    /*----------------------------------------*/

    if (clickedToClaim) {
      return (
        <div className="text-center">
          <h4 className="mb-0">
            Welcome back!
          </h4>
          <div className="mb-2">
            If you successfully signed in, continue.
            {onSkip && (
              <span>
                {' '}
                If you encountered an error,
                {' '}
                <button
                  type="button"
                  id="ClaimAccountNotice-skip-button"
                  className="btn btn-link p-0 m-0 text-dark"
                  aria-label="skip process of claiming account"
                  onClick={onSkip}
                  style={{
                    fontSize: 'inherit',
                    verticalAlign: 'baseline',
                    textDecoration: 'underline',
                  }}
                >
                  skip this step
                </button>
              </span>
            )}
            {!onSkip && (
              <span>
                {' '}
                If you encountered an error,
                {' '}
                email
                {' '}
                <a
                  href={`mailto:${isLearner ? SUPPORT_EMAILS.FOR_STUDENTS : SUPPORT_EMAILS.FOR_TTMS}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'underline',
                    color: 'inherit',
                  }}
                >
                  {
                    isLearner
                      ? SUPPORT_EMAILS.FOR_STUDENTS
                      : SUPPORT_EMAILS.FOR_TTMS
                  }
                </a>
              </span>
            )}
          </div>
          <div>
            <button
              type="button"
              id="ClaimAccountNotice-try-again-button"
              className="btn btn-dark"
              aria-label="try to continue again"
              onClick={onTryAgain}
            >
              Continue
            </button>
            {onCancel && (
              <button
                type="button"
                id="ClaimAccountNotice-cancel-button"
                className="btn btn-secondary ml-2"
                aria-label="cancel process"
                onClick={onCancel}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      );
    }

    /*----------------------------------------*/
    /*              Claim Notice              */
    /*----------------------------------------*/

    return (
      <div>
        <div className="alert alert-danger m-0">
          {/* Header */}
          <h4>
            Sign In and Come Back
          </h4>

          {/* Explanation */}
          <div>
            For
            {' '}
            <strong>
              screen share
            </strong>
            {' '}
            and other important features,
            sign in to Harvard Zoom and
            {' '}
            <strong>
              return to this page
            </strong>
          </div>
        </div>

        {/* Skip Button */}
        {onSkip && (
          <div className="small text-center mt-1">
            Encountered a sign in error?
            {' '}
            <button
              type="button"
              id="ClaimAccountNotice-skip-button"
              className="btn btn-link p-0 m-0 text-dark"
              aria-label="skip process of claiming account"
              onClick={onSkip}
              style={{
                fontSize: 'inherit',
                verticalAlign: 'baseline',
                textDecoration: 'underline',
              }}
            >
              Skip this step
            </button>
          </div>
        )}

        {/* Claim Button */}
        <div className="text-center mt-3">
          <a
            className="btn btn-lg btn-dark"
            href="https://harvard.zoom.us/"
            id="ClaimAccountNotice-claim-zoom-link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="go to claim Harvard Zoom account"
            onClick={() => {
              this.setState({
                clickedToClaim: true,
              });
            }}
          >
            <FontAwesomeIcon
              icon={faSignInAlt}
              className="mr-2"
            />
            Open Sign In Page
          </a>
          {onCancel && (
            <button
              type="button"
              id="ClaimAccountNotice-cancel-button"
              className="btn btn-secondary btn-lg ml-2"
              aria-label="cancel process"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    );
  }
}

ClaimAccountNotice.propTypes = {
  // Handler to call when "try again" button is clicked
  onTryAgain: PropTypes.func.isRequired,
  // If included, allow the user to skip the notice
  onSkip: PropTypes.func,
  // If included, show a cancel button
  onCancel: PropTypes.func,
  // If true, user is a learner
  isLearner: PropTypes.bool.isRequired,
};

ClaimAccountNotice.defaultProps = {
  // No skip function
  onSkip: null,
  // No cancel function
  onCancel: null,
};

export default ClaimAccountNotice;
