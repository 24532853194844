/**
 * A customizable header at the top of the page
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faBug } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../Modal';

// Import constants
import VERSION from '../../constants/VERSION';
import IS_IFRAMED from '../../constants/IS_IFRAMED';
import SUPPORT_EMAILS from '../../constants/SUPPORT_EMAILS';

// Import style
import './style.css';

// Import logo
import logo from './logo.svg';

// Get version number
const versionNumber = VERSION.value;

class Header extends Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // True if report issue modal is visible
      reportIssueModalVisible: false,
    };
  }

  /**
   * Render Header
   * @author Gabe Abrams
   */
  render() {
    const {
      adminPanel,
      leftButton,
      rightButton,
      isLearner,
    } = this.props;
    const {
      reportIssueModalVisible,
    } = this.state;

    // Create buttons
    const leftButtonElem = (
      leftButton
        ? (
          <button
            type="button"
            className="Header-left-button btn btn-light text-dark"
            onClick={leftButton.onClick}
          >
            {/* Icon */}
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mr-1"
            />

            {/* Label */}
            {leftButton.contents}
          </button>
        )
        : null
    );
    const rightButtonElem = (
      <button
        type="button"
        className={(
          (rightButton && rightButton.isAdminFeature)
            ? 'Header-right-button btn btn-success progress-bar-striped text-white'
            : 'Header-right-button btn btn-light text-dark'
        )}
        onClick={(
          rightButton
            // Included button
            ? rightButton.onClick
            // Report issue
            : () => {
              this.setState({
                reportIssueModalVisible: true,
              });
            }
        )}
        aria-label={(
          (rightButton && rightButton.contents)
            ? undefined
            : 'show information on how to report an issue'
        )}
      >
        {
          rightButton
            // Included button
            ? rightButton.contents
            // Report issue
            : (
              <span>
                {/* Extra Small Screen View */}
                <span className="d-inline d-sm-none">
                  <FontAwesomeIcon
                    icon={faBug}
                  />
                </span>

                {/* Larger Screen View */}
                <span className="d-none d-sm-inline">
                  <FontAwesomeIcon
                    icon={faBug}
                    className="mr-1"
                  />
                  Report Issue
                </span>
              </span>
            )
        }
      </button>
    );

    // Create the title
    const title = (
      adminPanel
        ? (
          <span>
            {/* Extra Small Screen View */}
            <span className="d-inline d-sm-none">
              Admin
            </span>

            {/* Small Screen View */}
            <span className="d-none d-sm-inline d-md-none">
              Admin Panel
            </span>

            {/* Large Screen View */}
            <span className="d-none d-md-inline">
              Gather&nbsp;
              <span className="font-weight-lighter">
                | Admin Panel
              </span>
            </span>
          </span>
        )
        : (
          <span>
            Gather
          </span>
        )
    );
    // Issue modal
    const modal = (
      reportIssueModalVisible
        ? (
          <Modal
            title={(
              <span>
                <FontAwesomeIcon
                  icon={faBug}
                  className="mr-2"
                />
                Report Issue
              </span>
            )}
            body={(
              <div>
                <p className="lead mb-3">
                  We want to help! Just send us an email.
                </p>
                <p className="lead m-0">
                  Please include &quot;Issue with Gather
                  {' '}
                  v
                  {versionNumber}
                  &quot;
                  in the subject and email&nbsp;us&nbsp;at
                  {' '}
                  <a
                    href={`mailto:${isLearner ? SUPPORT_EMAILS.FOR_STUDENTS : SUPPORT_EMAILS.FOR_TTMS}?subject=Issue with Gather v${versionNumber}`}
                    aria-label="email DCE support"
                    id="Header-report-issue-modal-contact-email"
                  >
                    {
                      isLearner
                        ? SUPPORT_EMAILS.FOR_STUDENTS
                        : SUPPORT_EMAILS.FOR_TTMS
                    }
                  </a>
                </p>
              </div>
            )}
            onClose={() => {
              this.setState({
                reportIssueModalVisible: false,
              });
            }}
            type={Modal.TYPES.NO_BUTTONS}
          />
        )
        : null
    );

    return (
      <div className="Header-container">
        {modal}
        <nav className={`navbar bg-secondary text-light ${IS_IFRAMED ? 'rounded-lg' : 'shadow'}`}>
          {leftButtonElem}
          <h2 className="m-0 text-center w-100">
            {/* logo */}
            <div className="Header-icon-container">
              <img
                src={logo}
                alt=""
                className="Header-icon"
              />
            </div>

            {/* Title */}
            {title}
          </h2>
          {rightButtonElem}
        </nav>

        {/* Version Number */}
        <div className="Header-version-number d-none d-lg-block">
          Version&nbsp;
          {versionNumber}
        </div>
      </div>
    );
  }
}

// List or properties and their types
Header.propTypes = {
  // True if we are in the admin panel
  adminPanel: PropTypes.bool,
  // Button on left of navbar
  leftButton: PropTypes.shape({
    contents: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  // Button on right of navbar
  rightButton: PropTypes.shape({
    contents: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    isAdminFeature: PropTypes.bool,
  }),
  // True if the current user is a student
  isLearner: PropTypes.bool,
};

Header.defaultProps = {
  // By default, there are no buttons
  leftButton: null,
  rightButton: null,
  // By default, not in the admin panel
  adminPanel: false,
  // By default, we assume the user is not a student
  isLearner: false,
};

export default Header;
