import EVENT_TYPES_MAP from './EVENT_TYPES_MAP';

/**
 * Order of event types, starting with null (which represents the "all event
 *   types" option)
 * @author Gabe Abrams
 */
export default [
  null, // Used to represent "all event types"
  EVENT_TYPES_MAP.CLASS,
  EVENT_TYPES_MAP.SECTION,
  EVENT_TYPES_MAP.OFFICE_HOURS,
  EVENT_TYPES_MAP.LAB,
  EVENT_TYPES_MAP.MEETING,
  EVENT_TYPES_MAP.EVENT,
  EVENT_TYPES_MAP.OTHER,
];
