import React from 'react';

// Import FontAwesome
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to join an event (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Join an Event',
  steps: [
    (
      <div>
        Find the event in the list and click the
        {' '}
        <ExampleButton
          icon={faSignInAlt}
          text="Join"
          variant="dark"
        />
        {' '}
        button
      </div>
    ),
    (
      <div>
        Choose to join as a participant or host
        (you get to choose but students always join as participants)
      </div>
    ),
  ],
  endMessage: (
    <span>
      That&apos;s it! If having trouble, make sure to
      update the
      {' '}
      <a
        href="https://zoom.us/download"
        rel="noopener noreferrer"
        target="_blank"
        style={{ color: 'black', textDecoration: 'underline' }}
      >
        Zoom App
      </a>
    </span>
  ),
};
