/**
 * A radio selection button
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

class RadioButton extends Component {
  /**
   * Render RadioButton
   * @author Gabe Abrams
   */
  render() {
    const {
      text,
      title,
      onSelected,
      ariaLabel,
      selected,
      id,
      noMarginOnRight,
    } = this.props;

    return (
      <button
        type="button"
        id={id}
        title={title}
        className={`btn btn-${selected ? 'warning selected' : 'light'} btn-sm m-0${noMarginOnRight ? '' : ' mr-2'}`}
        aria-label={`${ariaLabel}${selected ? ': currently selected' : ''}`}
        onClick={() => {
          if (!selected) {
            onSelected();
          }
        }}
      >
        <FontAwesomeIcon
          icon={selected ? faDotCircle : faCircle}
          className="mr-1"
        />
        {text}
      </button>
    );
  }
}

RadioButton.propTypes = {
  // Text of the button
  text: PropTypes.string.isRequired,
  // Handler for when this item is selected (not called if already selected)
  onSelected: PropTypes.func.isRequired,
  // Aria label
  ariaLabel: PropTypes.string.isRequired,
  // Button title
  title: PropTypes.string,
  // True if selected
  selected: PropTypes.bool,
  // The id of the button
  id: PropTypes.string,
  // If true, no margin on right
  noMarginOnRight: PropTypes.bool,
};

RadioButton.defaultProps = {
  // No title
  title: null,
  // Not selected
  selected: false,
  // No id
  id: undefined,
  // There is a right margin
  noMarginOnRight: false,
};

export default RadioButton;
