import PropTypes from 'prop-types';

export default PropTypes.shape({
  // Id of the originating Zoom meeting
  zoomId: PropTypes.number.isRequired,
  // Canvas course id
  courseId: PropTypes.number.isRequired,
  // Default URL to play video
  defaultPlayURL: PropTypes.string.isRequired,
  // Alternate views for playing the video
  otherPlayURLs: PropTypes.objectOf(PropTypes.string).isRequired,
  // IHID for the associated event
  ihid: PropTypes.string.isRequired,
  // Name of the recording
  name: PropTypes.string.isRequired,
  // Timestamp for when the recording was created
  timestamp: PropTypes.number.isRequired,
  // Duration of the video in minutes
  durationMin: PropTypes.number.isRequired,
  // The meeting instance UUID
  meetingInstanceUUID: PropTypes.string.isRequired,
});
