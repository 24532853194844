/**
 * Help and tutorials panel
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import TabBox from '../shared/TabBox';

// Import other components
import TextBasedTutorials from './TextBasedTutorials';

// Import video tutorials
import tutorials from './tutorials';

// Import helpers
import setPagePath from '../helpers/setPagePath';

// Import style
import './style.css';

class HelpPanel extends Component {/**
   * Render HelpPanel
   * @author Gabe Abrams
   */
  render() {
    // Set page path
    setPagePath('Help and Tutorials', '/tutorials');

    /* ---------------------- Create Tutorials ---------------------- */

    const tutorialElems = tutorials.map((tutorial) => {
      const {
        title,
        durationSec,
        youTubeId,
        taller,
      } = tutorial;

      const videoPlayer = (
        <div className={`HelpPanel-video-responsive ${taller ? 'HelpPanel-video-responsive-taller' : ''}`}>
          <iframe
            title={title}
            src={`https://www.youtube-nocookie.com/embed/${youTubeId}?color=white&controls=1&modestbranding=1&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="HelpPanel-video-container"
          />
        </div>
      );

      return (
        <div
          key={youTubeId}
          className="mb-3"
        >
          <TabBox
            title={(
              <span>
                {/* Tutorial Title */}
                <strong>
                  {title}
                </strong>

                {/* Duration Tag */}
                <div className="badge badge-secondary ml-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="mr-1"
                  />
                  {durationSec}
                  s
                </div>
              </span>
            )}
          >
            {videoPlayer}
          </TabBox>
        </div>
      );
    });

    /* --------------------------- Full UI -------------------------- */

    return (
      <div>
        <h2 className="mb-3">
          Help &amp; Tutorials
        </h2>

        {/* Videos */}
        <div className="HelpPanel-tutorial-container">
          {tutorialElems}
        </div>

        {/* Text-based Tutorials Header */}
        <div className="HelpPanel-tutorial-container">
          <TextBasedTutorials />
        </div>
      </div>
    );
  }
}

export default HelpPanel;
