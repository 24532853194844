/**
 * Generate an empty event object shell (must be filled with data)
 * @author Gabe Abrams
 * @param {number} courseId - the id of the course containing the event
 * @return {object} event object
 */
export default (courseId) => {
  return {
    courseId,
    ihid: null, // Must be added
    name: '', // Empty name
    type: null, // No type
    archived: false, // Not archived
    currentZoomId: null, // No zoom
    currentZoomHost: null, // No host
    openZoomLink: null, // No open zoom link
    pastZoomIds: [], // No past zoom ids
    isWebinar: null, // No Zoom
    pointWindow: null, // No point window
    date: null, // Whenever
    daysOfWeek: null, // No recurrence
    maxPoints: 0, // No attendance
    lockZoomToggle: false, // Not locked
    lockAutoRecordSetting: false, // Not locked
    banDCEStudents: false, // No ban
    banFASStudents: false, // No ban
    pinned: false, // Not pinned
  };
};
