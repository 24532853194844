import PropTypes from 'prop-types';

export default PropTypes.shape({
  // Course CanvasId
  courseId: PropTypes.number.isRequired,
  // Unique, LX id for the lounge
  loungeId: PropTypes.string.isRequired,
  // Human-readable title
  name: PropTypes.string.isRequired,
  // Current Zoom meeting id
  currentZoomId: PropTypes.number.isRequired,
  // Host id or email for the current meeting
  currentZoomHost: PropTypes.string.isRequired,
  // Link to join the meeting
  openZoomLink: PropTypes.string.isRequired,
  // List of the names of current visitors
  visitorFirstNames: PropTypes.arrayOf(PropTypes.string).isRequired,
});
