import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faSave,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to pin an event to the top (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Pin an Event to the Top',
  steps: [
    (
      <div>
        Find the event in the list and click its
        {' '}
        <ExampleButton
          icon={faCog}
          variant="secondary"
          explanation="edit event button"
        />
        {' '}
        button to edit the event
      </div>
    ),
    (
      <div>
        At the top of the page, next to the words &quot;Edit Event&quot;,
        find the
        {' '}
        <FontAwesomeIcon icon={faThumbtack} />
        {' '}
        button and click it
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          icon={faSave}
          text="Save Changes"
          variant="dark"
        />
      </div>
    ),
  ],
  endMessage: 'Done! The event will show up on the top of the list along with any other pinned events',
};
