/**
 * Warning line for a text tutorial
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class TextBasedTutorialWarning extends Component {
  /**
   * Render TextBasedTutorialWarning
   * @author Gabe Abrams
   */
  render() {
    const {
      children,
    } = this.props;

    return (
      <div className="d-flex rounded bg-warning p-1 align-items-center justify-content-center mb-2">
        <div className="d-inline-block text-black ml-2 mr-2">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
          />
        </div>
        <div
          className="text-left"
          style={{
            lineHeight: '100%',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

TextBasedTutorialWarning.propTypes = {
  // Contents of the warning
  children: PropTypes.node.isRequired,
};

export default TextBasedTutorialWarning;
