/**
 * An individual archived event item
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';

// Import propTypes
import Event from '../../shared/propTypes/Event';

// Import helpers
import eventTypeToIcon from '../../helpers/eventTypeToIcon';

// Import event type to title helper
import eventTypeToTitle from '../../helpers/eventTypeToTitle';

/* -------------------------- Component ------------------------- */

class ArchivedEventItem extends Component {
  /**
   * Render ArchivedEventItem
   * @author Karen Dolan
   */
  render() {
    const {
      event,
      onRecover,
    } = this.props;

    // Extract event attributes
    const {
      currentZoomId,
      name,
      ihid,
      type,
    } = event;

    // Choose the event icon based on the type
    const eventIcon = eventTypeToIcon(type);

    // Container for the event type icon
    const eventTypeIconContainer = (
      <div
        title={`This event's type is: "${eventTypeToTitle(type)}"`}
        style={{ minWidth: '50px' }}
        className="text-center"
      >
        <FontAwesomeIcon
          icon={eventIcon || faCalendarAlt}
          className="m-1"
          size="2x"
        />
      </div>
    );

    // Set Zoom Id text if zoom event
    const zoomDiv = (
      currentZoomId
        ? (
          <div className={`ArchivedEventItem-zoom-meeting-id ArchivedEventItem-zoom-meeting-id-${ihid}`}>
            Zoom Meeting ID:
            {' '}
            {currentZoomId}
          </div>
        )
        : null
    );

    // Container for the event title and Zoom ID text
    const titleAndZoomIdContainer = (
      <div className="ArchivedEventItem-title-container flex-grow-1 text-left ml-2 mr-1">
        <h3 className={`ArchivedEventItem-title ArchivedEventItem-title-${ihid} m-0`}>
          {name}
        </h3>
        {zoomDiv}
      </div>
    );

    // The recover button
    const button = (
      <button
        type="button"
        className="btn btn-lg btn-dark"
        id={`ArchivedEventItem-recover-${event.ihid}`}
        aria-label={`Recover event: ${name}`}
        onClick={onRecover}
      >
        <FontAwesomeIcon
          icon={faTrashRestore}
          className="mr-1"
        />
        Recover
      </button>
    );

    return (
      <div className="alert alert-secondary pl-1 pr-1 pt-1 pb-1 mb-2">
        {/* Spacer that Appears for md+ Screens */}
        <div className="d-none mt-1" />

        {/* Flex Container */}
        <div className="ArchivedEventItem-container d-flex justify-content-around align-items-center">
          {/* Event Type Icon */}
          {eventTypeIconContainer}
          {/* Title */}
          {titleAndZoomIdContainer}
          {/* Button */}
          <div
            className="ArchivedEventItem-button-container mr-1"
            style={{ whiteSpace: 'nowrap' }}
          >
            {button}
          </div>
        </div>
      </div>
    );
  }
}

ArchivedEventItem.propTypes = {
  // The event
  event: Event.isRequired,
  // Handler to call when user wants to recover an event
  onRecover: PropTypes.func.isRequired,
};

export default ArchivedEventItem;
