import JoinAnEventTutorial from './JoinAnEventTutorial';
import CreateAnEventTutorial from './CreateAnEventTutorial';
import EditAnEventTutorial from './EditAnEventTutorial';
import LinkToEventTutorial from './LinkToEventTutorial';
import AutoRecordTutorial from './AutoRecordTutorial';
import PublishRecordingsTutorial from './PublishRecordingsTutorial';
import PinTutorial from './PinTutorial';

/**
 * All text-based tutorials
 * @author Gabe Abrams
 */
export default [
  JoinAnEventTutorial,
  CreateAnEventTutorial,
  EditAnEventTutorial,
  LinkToEventTutorial,
  AutoRecordTutorial,
  PublishRecordingsTutorial,
  PinTutorial,
];
