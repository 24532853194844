/**
 * Attach an existing zoom meeting or create a new zoom meeting then
 *   return the results
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faChalkboardTeacher,
  faFont,
  faStar,
  faCogs,
  faSave,
  faExclamationCircle,
  faHeadset,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import Modal from '../shared/Modal';
import ClaimAccountNotice from '../shared/ClaimAccountNotice';
import ErrorAlert from '../shared/ErrorAlert';

// Import helpers
import genZoomMeetingTitle from '../helpers/genZoomMeetingTitle';
import visitServerEndpoint from '../helpers/visitServerEndpoint';

/* -------------------------- Constants ------------------------- */

// VIEWS
const VIEWS = {
  CHOOSE_ATTACHMENT_METHOD: 'choose-attachment-method',
  ADD_INFO: 'add-info',
};

// Regexes
const EMAIL_REGEX = /\S+@\S+\.\S+/;

/*------------------------------------------------------------------------*/
/*                                 Helpers                                */
/*------------------------------------------------------------------------*/

/**
 * Parse the meetingId text given by a user
 * @author Gabe Abrams
 * @param {string} meetingIdText - text of the meetingId input field
 * @return {number} meetingId
 */
const parseMeetingId = (meetingIdText) => {
  // Just get the numbers in the meeting text (remove hyphens and spaces)
  const justNumbers = (
    meetingIdText
      .trim()
      .replace(/-/g, '')
      .replace(/ /g, '')
  );

  // Check for an invalid number
  if (Number.isNaN(Number.parseInt(justNumbers))) {
    return null;
  }

  // Parse the number
  return Number.parseInt(justNumbers);
};

/*------------------------------------------------------------------------*/
/*                                Component                               */
/*------------------------------------------------------------------------*/

class AttachZoomMeetingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
      // Current view
      currentView: VIEWS.CHOOSE_ATTACHMENT_METHOD,
      // True if meeting exists
      meetingExists: null,
      // True if attaching webinar and webinar exists
      webinarExists: null,
      // True if showing user the "claim your account" button
      claimAccountMessageVisible: false,
      // Meeting id text
      meetingIdText: '',
      // The email of the host account
      hostEmail: props.userEmail,
      // The title of the meeting
      meetingTitle: '',
    };
  }

  /**
   * Create the meeting title upon mount
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const {
      courseId,
      courseName,
      termName,
      eventName,
    } = this.props;

    try {
      // Load the CRN
      const crn = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/crn`,
        method: 'GET',
      });

      // Generate the meeting title
      const meetingTitle = genZoomMeetingTitle({
        eventName,
        courseName,
        crn,
        termName,
      });

      // Update the state
      this.setState({
        meetingTitle,
        loading: false,
      });
    } catch (err) {
      // Unknown error
      this.setState({
        fatalErrorMessage: `An error occurred while we were trying to get the CRN for this course: ${err.message}`,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Create a new meeting
   * @author Gabe Abrams
   */
  async createMeeting() {
    const {
      courseId,
      onDone,
      school,
    } = this.props;
    const {
      hostEmail,
      meetingTitle,
    } = this.state;

    // Start the loading
    this.setState({
      loading: true,
    });

    // Set values
    const waitingRoomOn = false; // Hardcoded for now
    const autoRecordOn = false; // Hardcoded for now

    // Ask the server to create the meeting
    try {
      const createdMeetingInfo = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/meetings`,
        method: 'POST',
        params: {
          hostEmail,
          school,
          waitingRoomOn,
          autoRecordOn,
          title: meetingTitle,
        },
      });

      // Get meeting info
      const {
        zoomId,
        joinURL,
        hostId,
      } = createdMeetingInfo;

      return onDone({
        waitingRoomOn,
        autoRecordOn,
        currentZoomId: zoomId,
        openZoomLink: joinURL,
        currentZoomHost: hostId,
      });
    } catch (err) {
      // Handle "user not found" message separately
      if (err.code && err.code === 'ZOOM404-1001') {
        // Show the "claim your account" message
        return this.setState({
          claimAccountMessageVisible: true,
          loading: false,
        });
      }

      // This is a normal error. Just show it.
      this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Attach an existing meeting or webinar
   * @author Gabe Abrams
   */
  async attachExisting() {
    // Deconstruct
    const { courseId, onDone } = this.props;
    const { meetingIdText, webinarExists } = this.state;

    // Start loading indicator
    this.setState({
      loading: true,
    });

    // Process meeting/webinar id
    const currentZoomId = parseMeetingId(meetingIdText);

    if (webinarExists) {
      // Load the webinar info
      try {
        const webinarText = await visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/webinars/${currentZoomId}`,
        });

        // Parse the webinar
        const webinar = JSON.parse(webinarText);

        // Extract data from the webinar
        const currentZoomHost = webinar.host_id;
        const openZoomLink = webinar.join_url;
        const autoRecordOn = (webinar.settings.auto_recording === 'cloud');
        const waitingRoomOn = false;

        // Finish and return to parent
        return onDone({
          openZoomLink,
          waitingRoomOn,
          autoRecordOn,
          currentZoomHost,
          currentZoomId,
          isWebinar: true,
        });
      } catch (err) {
        return this.setState({
          fatalErrorMessage: err.message,
          fatalErrorCode: err.code,
        });
      }
    } else {
      // Load the meeting info
      try {
        const meetingText = await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/meetings/${currentZoomId}`,
        });

        // Parse the meeting
        const meeting = JSON.parse(meetingText);

        // Extract data from the meeting
        const currentZoomHost = meeting.host_id;
        const openZoomLink = meeting.join_url;
        const autoRecordOn = (meeting.settings.auto_recording === 'cloud');
        const waitingRoomOn = meeting.settings.waiting_room;

        // Finish and return to parent
        return onDone({
          openZoomLink,
          waitingRoomOn,
          autoRecordOn,
          currentZoomHost,
          currentZoomId,
          isWebinar: false,
        });
      } catch (err) {
        return this.setState({
          fatalErrorMessage: err.message,
          fatalErrorCode: err.code,
        });
      }
    }
  }

  /**
   * Render AttachZoomMeetingModal
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct
    const {
      onCancel,
      isAdmin,
      otherEvents,
      isLearner,
    } = this.props;
    const {
      loading,
      fatalErrorMessage,
      fatalErrorCode,
      currentView,
      meetingExists,
      webinarExists,
      meetingIdText,
      hostEmail,
      meetingTitle,
      claimAccountMessageVisible,
    } = this.state;

    // Create a placeholder for the body
    let body;

    /* ---------------------- Generate the Body --------------------- */

    // Fatal error
    if (fatalErrorMessage) {
      body = (
        <div>
          <ErrorAlert
            message={fatalErrorMessage}
            code={fatalErrorCode}
          />
          <button
            id="AttachZoomMeetingModal-close-fatal-error-button"
            type="button"
            className="btn btn-secondary"
            aria-label="cancel the zoom meeting attach process"
            onClick={onCancel}
          >
            OK
          </button>
        </div>
      );
    } else if (loading) {
      // Loading Spinner
      body = (
        <div>
          <LoadingSpinner />
        </div>
      );
    } else if (claimAccountMessageVisible) {
      // Ask user to claim their account
      return (
        <Modal
          key="claim-account-modal"
          noHeader
          body={(
            <ClaimAccountNotice
              onTryAgain={() => {
                this.createMeeting();
              }}
              onCancel={onCancel}
              isLearner={isLearner}
            />
          )}
          onClose={onCancel}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    } else if (currentView === VIEWS.CHOOSE_ATTACHMENT_METHOD) {
      // Decide whether the meeting exists (manual vs recommended setup)
      body = (
        <div>
          {/* Header */}
          <h4>
            How do you want to set up Zoom?
          </h4>

          {/* Recommended Setup Button */}
          <button
            id="AttachZoomMeetingModal-recommended-setup-button"
            type="button"
            className="btn btn-dark btn-lg btn-block"
            aria-label="create a new zoom meeting"
            onClick={() => {
              if (isAdmin) {
                this.setState({
                  meetingExists: false,
                  currentView: VIEWS.ADD_INFO,
                });
              } else {
                this.createMeeting();
              }
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className="mr-2"
            />
            Recommended Setup
          </button>

          {/* Manual Setup Button */}
          <button
            id="AttachZoomMeetingModal-manual-setup-button"
            type="button"
            className="btn btn-secondary btn-lg btn-block"
            aria-label="attach an existing meeting"
            onClick={() => {
              this.setState({
                meetingExists: true,
                currentView: VIEWS.ADD_INFO,
              });
            }}
          >
            <FontAwesomeIcon
              icon={faCogs}
              className="mr-2"
            />
            Manual Setup (not recommended)
          </button>

          {/* Webinar Button */}
          {isAdmin && (
            <button
              id="AttachZoomMeetingModal-manual-setup-webinar"
              type="button"
              className="btn btn-lg btn-block btn btn-success progress-bar-striped text-white"
              aria-label="attach an existing webinar manually"
              onClick={() => {
                this.setState({
                  webinarExists: true,
                  currentView: VIEWS.ADD_INFO,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faVideo}
                className="mr-2"
              />
              Manually Attach Webinar
            </button>
          )}
        </div>
      );
    } else if (currentView === VIEWS.ADD_INFO) {
      // Add extra info

      // Handle existing/new cases separately
      if (meetingExists || webinarExists) {
        /* ------------------ Meeting or Webinar Exists ----------------- */

        // Parse meeting id
        const meetingId = parseMeetingId(meetingIdText);

        // Validate
        let invalidMessage;

        // ID must be valid
        if (!meetingId || String(meetingId).length < 9) {
          invalidMessage = `Add a valid ${webinarExists ? 'Webinar' : 'Meeting'} ID to continue`;
        }

        // Meeting id must not already be used
        let nameOfEventUsingThisMeeting;
        for (let i = 0; i < otherEvents.length; i++) {
          const otherEvent = otherEvents[i];

          if (
            otherEvent.currentZoomId
            && otherEvent.currentZoomId === meetingId
          ) {
            nameOfEventUsingThisMeeting = otherEvent.name;
            invalidMessage = `That ${webinarExists ? 'Webinar' : 'Meeting'} ID is already being used by "${nameOfEventUsingThisMeeting}"`;
            break;
          }
        }

        // Create the body
        body = (
          <div>
            {/* Header */}
            <h4 className="mb-0">
              What&apos;s the
              {' '}
              {webinarExists ? 'Webinar' : 'Meeting'}
              {' '}
              ID?
            </h4>
            <div className="mb-2">
              Create the
              {' '}
              {webinarExists ? 'webinar' : 'meeting'}
              {' '}
              and paste the ID below.
            </div>

            {/* Input Field */}
            <div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {webinarExists ? 'Webinar' : 'Meeting'}
                    {' '}
                    ID
                  </span>
                </div>
                <input
                  id="AttachZoomMeetingModal-meeting-id-field"
                  type="text"
                  className="form-control"
                  aria-label={`id or link for ${webinarExists ? 'webinar' : 'meeting'}`}
                  value={meetingIdText || ''}
                  placeholder="e.g. 123456789"
                  onChange={(e) => {
                    this.setState({
                      meetingIdText: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            {/* Already Used Message */}
            {invalidMessage && (
              <div className="text-center">
                <div
                  id="AttachZoomMeetingModal-meeting-id-validation-message"
                  className="alert alert-warning d-inline-block m-0"
                >
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="mr-2"
                  />
                  {invalidMessage}
                </div>
              </div>
            )}

            {/* Confirm Button */}
            {!invalidMessage && (
              <div>
                <button
                  id="AttachZoomMeetingModal-save-button"
                  type="button"
                  className="btn btn-warning btn-lg"
                  aria-label={`attach the ${webinarExists ? 'webinar' : 'meeting'}`}
                  onClick={() => {
                    this.attachExisting();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    className="mr-2"
                  />
                  Save
                </button>
              </div>
            )}
          </div>
        );
      } else {
        /* ------------------------- New Meeting ------------------------ */

        body = [];

        // Title
        body.push(
          <h4
            key="info-title"
            className="m-0"
          >
            Meeting Info:
          </h4>
        );

        // Subtitle
        body.push(
          <p
            key="info-subtitle"
            className="lead mb-3"
          >
            This customization screen is only for admins.
          </p>
        );

        // Get the meeting title
        body.push(
          <div key="meeting-title" className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon
                  icon={faFont}
                  className="mr-2"
                />
                Zoom Meeting Title
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="title of the zoom meeting"
              id="AttachZoomMeetingModal-meeting-title-field"
              value={meetingTitle}
              onChange={(e) => {
                this.setState({
                  meetingTitle: e.target.value,
                });
              }}
            />
          </div>
        );

        // Get the host email
        body.push(
          <div key="host-email" className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="mr-2"
                />
                Host Zoom Account Email
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="email of the host"
              id="AttachZoomMeetingModal-host-email-field"
              value={hostEmail}
              placeholder="must be the host's zoom account email"
              onChange={(e) => {
                this.setState({
                  hostEmail: e.target.value.trim(),
                });
              }}
            />
          </div>
        );

        // Validate
        const valid = (
          // Host email must be a valid email
          hostEmail
          && hostEmail.length > 0
          && EMAIL_REGEX.test(hostEmail.toLowerCase())
          // Title must be defined
          && meetingTitle
          && meetingTitle.trim().length >= 3
        );

        // Add the confirm button
        body.push(
          <div key="buttons">
            <button
              type="button"
              className={`btn btn-${valid ? 'warning' : 'secondary'} btn-lg`}
              aria-label="create and attach the meeting"
              id="AttachZoomMeetingModal-attach-and-create-meeting-button"
              disabled={!valid}
              onClick={() => {
                this.createMeeting();
              }}
            >
              {/* Button Text Depends Upon Validity */}
              {
                valid
                  ? (
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="mr-2"
                      />
                      Create and Attach Meeting
                    </span>
                  )
                  : 'Fill out form to continue'
              }
            </button>
          </div>
        );
      }
    }

    /* ---------------------------- Modal --------------------------- */

    // Create the modal with the body
    return (
      <Modal
        key="main-modal"
        title={(
          <span>
            <FontAwesomeIcon
              icon={faHeadset}
              className="mr-2"
            />
            Set Up Zoom
          </span>
        )}
        type={(
          loading
            ? Modal.TYPES.BLOCKED
            : Modal.TYPES.NO_BUTTONS
        )}
        body={(
          <div className="text-center">
            {body}
          </div>
        )}
        onClose={onCancel}
        isAdminFeature={(
          currentView === VIEWS.ADD_INFO
          && !meetingExists
        )}
      />
    );
  }
}

AttachZoomMeetingModal.propTypes = {
  // The id of the current course
  courseId: PropTypes.number.isRequired,
  // The name of the current course
  courseName: PropTypes.string.isRequired,
  // Name of the current term
  termName: PropTypes.string.isRequired,
  // The meeting school tracking code
  school: PropTypes.string.isRequired,
  // Email of the user
  userEmail: PropTypes.string.isRequired,
  // True if user is an admin
  isAdmin: PropTypes.bool.isRequired,
  // True if user is a learner
  isLearner: PropTypes.bool.isRequired,
  // Handler to call when canceling
  onCancel: PropTypes.func.isRequired,
  /**
   * Handler to call when done attaching
   * @return {object} zoom results in the form:
   *   { currentZoomId, openZoomLink, currentZoomHost,
   *   waitingRoomOn, autoRecordOn }
   */
  onDone: PropTypes.func.isRequired,
  // The name of the event
  eventName: PropTypes.string.isRequired,
  // List of other events
  otherEvents: PropTypes.arrayOf(Event),
};

AttachZoomMeetingModal.defaultProps = {
  // No other events
  otherEvents: [],
};

export default AttachZoomMeetingModal;
