/**
 * Visualizer for attendance, AttendanceTableHeader
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

// Import constants
import WEEKDAY_TO_ABBREVIATION from '../../../constants/WEEKDAY_TO_ABBREVIATION';

/* -------------------------- Component ------------------------- */

class AttendanceTableHeader extends Component {
  /**
   * Render AttendanceTable
   * @author Gabe Abrams
   */
  render() {
    const {
      dateKeys,
      sortBy,
      changeSortColumn,
    } = this.props;

    /* ------------------------ Date cells -------------------------- */
    const dateCells = (
      dateKeys
        .map((dateKey) => {
          // Create prettier date
          const date = new Date(dateKey);
          const year = String(date.getFullYear());
          const fullDateText = `${date.getMonth() + 1}/${date.getDate()}/${year}`;
          const dateText = `${date.getMonth() + 1}/${date.getDate()}`;
          const weekday = date.toLocaleString(
            'en-US',
            {
              timeZone: 'America/New_York',
              weekday: 'long',
            }
          );
          const weekdayAbbreviation = WEEKDAY_TO_ABBREVIATION[weekday];
          return (
            <th
              key={dateKey}
              className="bg-secondary text-nowrap text-light"
              title={fullDateText}
            >
              <div>
                {weekdayAbbreviation}
              </div>
              {dateText}
              <button
                type="button"
                className={`btn btn-sm pt-0 pb-0 pl-1 pr-1 ml-1 btn-${sortBy === dateKey ? 'warning' : 'secondary'}`}
                aria-label={`sort by attendance on date column: ${dateKey}`}
                id={`AttendanceTable-sort-by-${dateKey.replace(/\//g, '-')}`}
                data-sort={dateKey}
                onClick={() => {
                  return changeSortColumn(dateKey);
                }}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </th>
          );
        })
    );

    /* --------- Item Toggle -------------------- */

    const itemToggleCell = (
      <th className="bg-secondary text-nowrap text-light">
        <div style={{ marginBottom: '11px' }}>
          Attendee Name

          {/* Sort Button */}
          <button
            type="button"
            className={`btn btn-sm pt-0 pb-0 pl-1 pr-1 ml-2 btn-${!sortBy ? 'warning' : 'secondary'}`}
            aria-label="sort by attendee name"
            id="AttendanceTable-sort-by-name"
            onClick={() => {
              return changeSortColumn(null);
            }}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>
      </th>
    );

    return (
      <thead>
        <tr>
          {itemToggleCell}
          {dateCells}
        </tr>
      </thead>
    );
  }
}

AttendanceTableHeader.propTypes = {
  // Array of date string
  dateKeys: PropTypes.arrayOf(PropTypes.node).isRequired,
  // Column to sort by
  sortBy: PropTypes.string,
  /**
   * Change the sort column
   * @param {string} [dateKey] - date key to sort by or null to sort by name
   */
  changeSortColumn: PropTypes.func.isRequired,
};

AttendanceTableHeader.defaultProps = {
  // SortBy null by default, sory by name
  sortBy: null,
};

export default AttendanceTableHeader;
