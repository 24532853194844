import React from 'react';

// Import FontAwesome
import {
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';
import ExampleToggle from '../ExampleToggle';

/**
 * How to publish recordings for students (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Publish Recordings for Students',
  startWarning: 'If you have a producer, they may be publishing videos for you. Check to make sure you\'re not duplicating effort',
  steps: [
    (
      <div>
        After the event, wait a few hours for the recording to process
      </div>
    ),
    (
      <div>
        Find the event in the &quot;Course Events &quot; list and click the
        {' '}
        <ExampleButton
          icon={faFileVideo}
          explanation="View/Publish Recordings"
          variant="secondary"
        />
        {' '}
        button
      </div>
    ),
    (
      <div>
        You&apos;ll see a list of available recordings.
        If the recording isn&apos;t there,
        you may need to wait longer
      </div>
    ),
    (
      <div>
        Find the recording you want to publish and click the
        {' '}
        <ExampleToggle
          text="Not Published"
          ariaLabel="Publish toggle button"
        />
        {' '}
        toggle switch
      </div>
    ),
    (
      <div>
        A popup will allow you to change the name of the recording.
        When done, click
        {' '}
        <ExampleButton
          icon={faFileVideo}
          text="Publish as..."
          variant="secondary"
        />
      </div>
    ),
  ],
  endMessage: (
    <span>
      Done! Students can watch the video by
      clicking
      {' '}
      <ExampleButton
        icon={faFileVideo}
        text="Published Recordings"
        variant="secondary"
      />
    </span>
  ),
};
