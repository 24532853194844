/**
 * Footer for batch event creation panels
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

class BatchCreationFooter extends Component {
  /**
   * Render BatchCreationFooter
   * @author Gabe Abrams
   */
  render() {
    const {
      continueButton,
      customContent,
    } = this.props;

    /* ----------------------- Create content ----------------------- */

    // List of items in the footer
    const contentItems = [];

    // Continue button
    if (continueButton) {
      const { onClick, description } = continueButton;
      const label = (continueButton.label || 'Continue');

      contentItems.push(
        <div
          key="continue-button"
          className="d-flex align-items-center justify-content-center"
        >
          {/* Continue Button */}
          <div>
            <button
              type="button"
              className="btn btn-dark btn-lg"
              aria-label="continue to next step of batch creation process"
              id="BatchCreationFooter-continue-button"
              onClick={onClick}
            >
              {/* Icon before if custom icon */}
              {continueButton.icon && (
                <FontAwesomeIcon
                  icon={continueButton.icon}
                  className="mr-2"
                />
              )}

              {/* Button Label */}
              {label}

              {/* Icon after if no custom icon */}
              {!continueButton.icon && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="ml-2"
                />
              )}
            </button>
          </div>

          {/* Description of next step */}
          {description && (
            <div className="BatchCreationFooter-continue-text text-muted text-left pl-2">
              {description.map((text) => {
                return (
                  <div key={text}>
                    {text}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    // Custom content
    if (customContent) {
      contentItems.push(
        <div key="custom-content">
          {customContent}
        </div>
      );
    }

    /* ---------------------- Assemble full UI ---------------------- */

    return (
      <div className="BatchCreationFooter-container alert alert-light text-dark mt-2">
        {contentItems}
      </div>
    );
  }
}

BatchCreationFooter.propTypes = {
  // Info on the continue button
  continueButton: PropTypes.shape({
    // Handler to call when clicked
    onClick: PropTypes.func.isRequired,
    // Label of the button
    label: PropTypes.string,
    // Description text for the button
    description: PropTypes.arrayOf(PropTypes.string),
    // FontAwesome icon
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.object,
  }),
  // Custom footer content
  customContent: PropTypes.node,
};

BatchCreationFooter.defaultProps = {
  // No continue button
  continueButton: null,
  // No custom contents
  customContent: null,
};

export default BatchCreationFooter;
