/**
 * Show more button component for a Prospective Event
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import style
import './ShowMoreToggle.css';

class ShowMoreToggle extends Component {
  /**
   * Render ShowMoreToggle
   * @author Karen Dolan
   */
  render() {
    const {
      showMore,
      onClick,
      crn,
    } = this.props;
    return (
      <div className="d-inline-block">
        <button
          className="ShowMoreToggle btn btn-light btn-sm ml-2 pt-0 pb-0"
          id={`ShowMoreToggle-button-crn-${crn}`}
          aria-label="Show more details toggle"
          onClick={onClick}
          type="button"
        >
          {showMore ? 'Show less' : 'Show more'}
        </button>
      </div>
    );
  }
}

ShowMoreToggle.propTypes = {
  // if the button is toggled on or off
  showMore: PropTypes.bool.isRequired,
  // The method to call on click
  onClick: PropTypes.func.isRequired,
  // The course CRN associated to this toggle
  crn: PropTypes.number.isRequired,
};

export default ShowMoreToggle;
