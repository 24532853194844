/**
 * Box containing a singular text-based tutorial
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

class TextBasedTutorialBox extends Component {
  /**
   * Render TextBasedTutorialBox
   * @author Gabe Abrams
   */
  render() {
    const {
      title,
      children,
      selectedTutorial,
      onSelect,
    } = this.props;

    // Determine if this tutorial is selected
    const selected = (title === selectedTutorial);

    return (
      <div>
        <button
          type="button"
          className="btn-light"
          style={{
            border: 0,
            padding: 0,
            margin: 0,
            display: 'block',
            background: 'transparent',
          }}
          aria-label={`${selected ? 'Hide tutorial: ' : 'Show tutorial: '} "${title}"`}
          onClick={() => {
            if (selected) {
              onSelect(null);
            } else {
              onSelect(title);

              setTimeout(() => {
                if (this.bottomAnchor) {
                  this.bottomAnchor.scrollIntoView();
                }
              }, 50);
            }
          }}
        >
          <h4 className="d-flex m-0">
            <div
              className="d-inline-block text-center mr-2"
              style={{ minWidth: '15px' }}
            >
              <FontAwesomeIcon
                icon={(
                  selected
                    ? faCaretDown
                    : faCaretRight
                )}
              />
            </div>
            <div className="text-left flex-grow-1">
              {title}
            </div>
          </h4>
        </button>
        {selected && (
          <div className="ml-4 mt-2 mb-2">
            {children}

            {/* Anchor for Scrolling Down */}
            <span
              ref={(e) => {
                this.bottomAnchor = e;
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

TextBasedTutorialBox.propTypes = {
  // Title of the tutorial
  title: PropTypes.string.isRequired,
  // Contents of the tutorial
  children: PropTypes.node.isRequired,
  /**
   * Handler for selection toggle
   * @param {string|null} newSelectedTutorial - title of the tutorial if it
   *   was selected, null if it was deselected
   */
  onSelect: PropTypes.func.isRequired,
  // Title of the selected tutorial
  selectedTutorial: PropTypes.string,
};

TextBasedTutorialBox.defaultProps = {
  // No selected tutorial
  selectedTutorial: null,
};

export default TextBasedTutorialBox;
