/**
 * Progress bar and numerical indicator of progress
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BatchCreationProgress extends Component {
  /**
   * Render BatchCreationProgress
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      numFinished,
      numTasks,
      ended,
    } = this.props;

    // Calculate the percentage of finished events
    const percentFinished = Math.ceil(
      (100 * numFinished)
      / numTasks
    );

    // Create the UI
    return (
      <div className="d-flex align-items-center justify-content-center mb-2">
        {/* Progress Bar */}
        <div className="flex-grow-1">
          <div className="progress">
            <div
              className={`progress-bar progress-bar-striped bg-warning ${ended ? '' : 'progress-bar-animated'}`}
              role="progressbar"
              style={{
                width: `${percentFinished}%`,
              }}
              aria-label={`progress bar indicating that ${percentFinished} percent of the job has been finished`}
              aria-valuenow={numFinished}
              aria-valuemin={0}
              aria-valuemax={numTasks}
            />
          </div>
        </div>

        {/* Status Count */}
        <div className="font-weight-bold pl-2">
          <span id="BatchCreationProgress-num-finished">
            {numFinished}
          </span>
          /
          <span id="BatchCreationProgress-num-tasks">
            {numTasks}
          </span>
          &nbsp;Finished
        </div>
      </div>
    );
  }
}

BatchCreationProgress.propTypes = {
  // The number of finished tasks
  numFinished: PropTypes.number.isRequired,
  // The total number of tasks
  numTasks: PropTypes.number.isRequired,
  // True if the batch creation has ended
  ended: PropTypes.bool.isRequired,
};

export default BatchCreationProgress;
