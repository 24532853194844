/**
 * Example toggle switch
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import shared components
import ToggleSwitch from '../../shared/ToggleSwitch';

class ExampleToggle extends Component {
  /**
   * Render ExampleToggle
   * @author Gabe Abrams
   */
  render() {
    const {
      text,
      ariaLabel,
      className,
      isOn,
      size,
    } = this.props;

    const label = {
      text,
      title: ariaLabel,
      ariaLabel,
      className,
    };

    const dummyLabel = {
      text: 'N/A',
      title: 'N/A',
      ariaLabel: 'N/A',
    };

    return (
      <span style={{ pointerEvents: 'none' }}>
        <ToggleSwitch
          labelIfOn={isOn ? label : dummyLabel}
          labelIfOff={isOn ? dummyLabel : label}
          isOn={!!isOn}
          size={size}
        />
      </span>
    );
  }
}

ExampleToggle.propTypes = {
  // Text for the button
  text: PropTypes.node.isRequired,
  // Aria label
  ariaLabel: PropTypes.string.isRequired,
  // Class names to add to the toggle button
  className: PropTypes.string,
  // True if toggle switch is on
  isOn: PropTypes.bool,
  // Toggle size ("lg" or "sm")
  size: PropTypes.string,
};

ExampleToggle.defaultProps = {
  // No additional className
  className: undefined,
  // No specified size
  size: undefined,
  // Toggle is off
  isOn: false,
};

export default ExampleToggle;
