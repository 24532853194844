/**
 * Modal for opening a link to a recording or a meeting (or anything else)
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../Modal';
import LoadingSpinner from '../LoadingSpinner';

// Import shared constants
import ZOOM_TRAINING_PDF_URL from '../../constants/ZOOM_TRAINING_PDF_URL';

// Import other components
import VisitLinkAgreements from './VisitLinkAgreements';

// Import style
import './style.css';

class VisitLinkModal extends Component {
  /**
   * Render VisitLinkModal
   * @author Gabe Abrams
   */
  render() {
    const {
      onClose,
      title,
      label,
      body,
      buttonText,
      loading,
      icon,
      link,
      showAgreements,
      useWebinarAgreements,
      isJoiningLounge,
    } = this.props;

    /*----------------------------------------*/
    /*                  Help                  */
    /*----------------------------------------*/

    const help = (
      isJoiningLounge
        ? (
          <div className="VisitLinkModal-lounge-help alert alert-info">
            <div>
              <div className="font-weight-bold">
                Need help?
              </div>
              <div
                className="d-inline-block text-left"
                style={{
                  maxWidth: '600px',
                  width: '100%',
                }}
              >
                <div className="d-flex mb-1">
                  <div className="VisitLinkModal-icon">
                    <FontAwesomeIcon
                      icon={faHeadset}
                    />
                  </div>
                  <div className="flex-grow-1">
                    For help with Zoom, check out our
                    {' '}
                    <a
                      href={ZOOM_TRAINING_PDF_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        color: 'inherit',
                      }}
                    >
                      Zoom Help Guide
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-1">
                  <div className="VisitLinkModal-icon">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-grow-1">
                    If you witness a code of conduct violation,
                    please contact your instructor or teaching assistant
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="VisitLinkModal-event-help mb-2">
            <strong>
              Need help with Zoom?
            </strong>
            {' '}
            Check out our
            {' '}
            <a
              href={ZOOM_TRAINING_PDF_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark"
              style={{ textDecoration: 'underline' }}
            >
              Zoom Help Guide
            </a>
          </div>
        )
    );

    /*----------------------------------------*/
    /*                 Full UI                */
    /*----------------------------------------*/

    // Body
    const modalBody = (
      loading
        ? (
          <div className="VisitLinkModal-loading text-center">
            <LoadingSpinner />
          </div>
        )
        : (
          <div className="text-center">
            {/* Agreements */}
            {showAgreements && (
              <VisitLinkAgreements
                useWebinarAgreements={useWebinarAgreements}
                isJoiningLounge={isJoiningLounge}
              />
            )}

            {/* Help */}
            {help}

            {/* Body */}
            {body && (
              <div className="VisitLinkModal-body">
                {body}
              </div>
            )}

            {/* Button */}
            <div className="mt-2">
              <a
                href={link}
                aria-label={`open the ${label} in another tab`}
                title={`Open the ${label} in another tab`}
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-dark btn-lg"
                id="VisitLinkModal-button"
                onClick={onClose}
              >
                {buttonText}
              </a>
            </div>
          </div>
        )
    );

    return (
      <Modal
        key="visit-link-modal"
        title={(
          <span className="VisitLinkModal-title">
            {/* Icon */}
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className="mr-2"
              />
            )}

            {/* Title */}
            {title}
          </span>
        )}
        body={modalBody}
        type={Modal.TYPES.NO_BUTTONS}
        onClose={onClose}
      />
    );
  }
}

VisitLinkModal.propTypes = {
  // Handler for when the modal is closed
  onClose: PropTypes.func.isRequired,
  // Title of the modal
  title: PropTypes.string.isRequired,
  // The description of the location being visited (e.g. "recording")
  label: PropTypes.string.isRequired,
  // Body (just above the button)
  body: PropTypes.node,
  // Name of the button
  buttonText: PropTypes.string.isRequired,
  // True if the link should be shown as loading
  loading: PropTypes.bool,
  // Icon for the title
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object,
  // Link for the button
  link: PropTypes.string,
  // If true, show agreements
  showAgreements: PropTypes.bool,
  // If true, show agreements for webinars
  useWebinarAgreements: PropTypes.bool,
  // If true, user is joining a lounge
  isJoiningLounge: PropTypes.bool,
};

VisitLinkModal.defaultProps = {
  // No body
  body: null,
  // Not loading
  loading: false,
  // No icon
  icon: null,
  // No link
  link: '',
  // No agreements
  showAgreements: false,
  // Agreements are for a meeting
  useWebinarAgreements: false,
  // Not joining a lounge
  isJoiningLounge: false,
};

export default VisitLinkModal;
