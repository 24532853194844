/**
 * UI for creating or editing a lounge
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faPlus,
  faExclamationTriangle,
  faTrash,
  faQuestionCircle,
  faExclamationCircle,
  faDoorOpen,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import shared prop types
import Lounge from '../shared/propTypes/Lounge';

// Shared components
import ErrorAlert from '../shared/ErrorAlert';
import LoadingSpinner from '../shared/LoadingSpinner';
import Modal from '../shared/Modal';
import LoungeName from '../shared/LoungeFormItem/LoungeName';
import RadioButton from '../shared/RadioButton';
import ExpandableDrawer from '../shared/ExpandableDrawer';
import LoungeEmail from '../shared/LoungeFormItem/LoungeEmail';

// Import helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import logError from '../helpers/logError';
import logAction from '../helpers/logAction';
import scroll from '../helpers/scroll';
import genEmptyLoungeObj from '../helpers/genEmptyLoungeObj';
import genZoomMeetingTitle from '../helpers/genZoomMeetingTitle';

// Import constants
import ERROR_CODES from '../constants/ERROR_CODES';
import SUPPORT_EMAILS from '../constants/SUPPORT_EMAILS';

/* -------------------------- Component ------------------------- */

class CreateEditLounge extends Component {
  constructor(props) {
    super(props);

    const creating = !props.lounge;

    // Initialize state
    this.state = {
      // True if we're creating a new lounge
      creating,
      // True if loading
      loading: creating, // if creating, figure out loungeId
      // Fatal error message
      fatalErrorMessage: null,
      // True if confirming cancel
      confirmingCancel: false,
      // True if confirming lounge delete
      confirmingDelete: false,
      // Validation errors
      validationErrors: null,
      // User changed something
      userChangedSomething: false,
      // The lounge to edit/create
      lounge: (
        props.lounge
        || {}
      ),
      // True if confirming that the user wants to lock the lounge
      confirmingAddLock: false,
      // Zoom account email who owns the lounge (only relevant if creating)
      zoomHostEmail: props.defaultZoomHostEmail,
    };
  }

  /**
   * Load the loungeId if creating a new lounge
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;
    const { creating } = this.state;

    // Scroll to top
    scroll.toTop(true);

    // Only load if creating
    if (!creating) {
      return;
    }

    // Load the full list of lounges
    let lounges;
    try {
      lounges = await visitServerEndpoint({
        path: `/api/courses/${courseId}/lounges`,
        method: 'GET',
        params: {
          includeArchived: true,
        },
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }

    // Extract the list of loungeIds
    const loungeIds = lounges.map((loadedLounge) => {
      return loadedLounge.loungeId;
    });

    // Generate a new loungeId by incrementing the sequence
    let highestNumber = 0;
    loungeIds.forEach((loungeId) => {
      // Extract the number
      const num = Number.parseInt(loungeId.substring(1));

      // Keep highest
      if (highestNumber < num) {
        highestNumber = num;
      }
    });
    const loungeId = `L${highestNumber + 1}`;

    // Create an empty lounge
    const lounge = genEmptyLoungeObj(courseId, loungeId);

    // Update the state
    this.setState({
      lounge,
      loading: false,
    });
  }

  /**
   * Delete the lounge
   * @author Gabe Abrams
   */
  async onDelete() {
    const { lounge } = this.state;

    // Start loading
    this.setState({
      loading: true,
    });

    // Mark the lounge as archived
    lounge.archived = true;

    // Save the state
    this.setState({
      lounge,
    }, () => {
      // Done! Now save and return
      this.save();
    });

    // Log this
    logAction({
      type: 'delete',
      description: 'lounge',
      metadata: {
        loungeId: lounge.loungeId,
        name: lounge.name,
      },
    });
  }

  /**
   * Save the lounge
   * @author Gabe Abrams
   */
  async save() {
    const {
      courseId,
      onDone,
      courseName,
      termName,
    } = this.props;
    const {
      creating,
      lounge,
      zoomHostEmail,
    } = this.state;

    // Set loading to true while we save the lounge
    this.setState({
      loading: true,
    });

    // Create/save the lounge
    try {
      // Save via the API
      if (creating) {
        await visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/lounges`,
          method: 'POST',
          params: {
            lounge: JSON.stringify(lounge),
            loungeZoomName: genZoomMeetingTitle({
              eventName: `Gather Study Lounge: ${lounge.name}`,
              courseName,
              termName,
              crn: 'PUT_CRN_HERE',
            }),
            hostEmail: zoomHostEmail,
          },
        });
      } else {
        await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/lounges/${lounge.loungeId}`,
          method: 'PUT',
          params: {
            lounge: JSON.stringify(lounge),
          },
        });
      }
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }

    // Exit the create/save screen
    onDone(lounge);

    // Log this
    const prevLounge = (
      creating
        ? null
        // eslint-disable-next-line react/destructuring-assignment
        : this.props.lounge
    );
    logAction({
      type: (
        creating
          ? 'create'
          : 'edit'
      ),
      description: 'lounge',
      metadata: {
        prevLounge,
        lounge,
        loungeId: lounge.loungeId,
        name: lounge.name,
      },
    });
  }

  /**
   * Render CreateEditLounge
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      onDone,
      isAdmin,
    } = this.props;

    // Deconstruct state
    const {
      creating,
      lounge,
      loading,
      fatalErrorMessage,
      validationErrors,
      confirmingCancel,
      confirmingDelete,
      userChangedSomething,
      confirmingAddLock,
      zoomHostEmail,
    } = this.state;

    /*------------------------------------------------------------------------*/
    /*                              Short Bodies                              */
    /*------------------------------------------------------------------------*/

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
          showReloadButton
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                            Create the Modal                            */
    /*------------------------------------------------------------------------*/

    let modal;

    // Create confirm cancel modal
    if (confirmingCancel) {
      modal = (
        <Modal
          key="confirm-cancel"
          title={(
            <span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mr-2"
              />
              Abandon Changes?
            </span>
          )}
          type={Modal.TYPES.ABANDON_CONTINUE}
          body="Are you sure you want to abandon your changes?"
          onClose={(button) => {
            if (button === Modal.BUTTONS.ABANDON) {
              // Log this
              logAction({
                type: 'cancel',
                description: 'edit lounge',
                metadata: {
                  loungeId: lounge.loungeId,
                  name: lounge.name,
                },
              });

              // Exit without any changes
              return onDone(null);
            }

            // Back to editing
            this.setState({
              confirmingCancel: false,
            });
          }}
        />
      );
    } else if (confirmingDelete) {
      // Confirm delete modal
      modal = (
        <Modal
          key="confirm-delete"
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />
              Confirm Permanent Delete
            </span>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          body={(
            <div>
              <div>
                Once you delete this study lounge,&nbsp;
                <strong>
                  it cannot be undone
                </strong>
                .
              </div>
              {!isAdmin && (
                <div className="mt-2 text-danger font-weight-bold">
                  If you want a new study lounge,
                  you will need to contact DCE online support at
                  {' '}
                  <a
                    href={`mailto:${SUPPORT_EMAILS.FOR_TTMS}`}
                    style={{
                      color: 'inherit',
                      textDecoration: 'underline',
                    }}
                  >
                    {SUPPORT_EMAILS.FOR_TTMS}
                  </a>
                  .
                </div>
              )}
            </div>
          )}
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed delete
              return this.onDelete();
            }

            // Not deleting
            this.setState({
              confirmingDelete: false,
            });
          }}
          okayLabel="Delete Lounge"
          okayColor="dark"
        />
      );
    } else if (validationErrors) {
      // Validation errors modal
      const errorLines = validationErrors.map((validationError) => {
        return (
          <div className="CreateEditLounge-validation-error">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2"
            />
            {validationError}
          </div>
        );
      });

      // Create a modal
      modal = (
        <Modal
          key="validation errors"
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="mr-2"
              />
              Please make the following changes:
            </span>
          )}
          type={Modal.TYPES.OKAY}
          body={(
            <div
              id="CreateEditLounge-validation-errors"
              className="alert alert-warning m-0 text-dark"
            >
              {errorLines}
            </div>
          )}
          onClose={() => {
            this.setState({
              validationErrors: null,
            });
          }}
        />
      );
    } else if (confirmingAddLock) {
      modal = (
        <Modal
          key="confirm-add-lock"
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />
              Are you sure?
            </span>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          body={(
            <div>
              <div className="mb-2">
                To create a consistent place for collaboration,
                we don&apos;t recommend locking lounges unless
                absolutely necessary
                (during exams, etc.)
              </div>
              <div>
                <strong>
                  Remember to unlock
                </strong>
                {' '}
                the lounge as soon as possible!
              </div>
            </div>
          )}
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Apply lock
              lounge.locked = true;

              return this.setState({
                lounge,
                userChangedSomething: true,
                confirmingAddLock: false,
              });
            }

            // Cancelled
            this.setState({
              confirmingAddLock: false,
            });
          }}
          okayLabel="Lock Lounge"
          okayColor="dark"
        />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                               Lounge Form                              */
    /*------------------------------------------------------------------------*/

    const loungeName = (
      isAdmin
        ? (
          <LoungeName
            name={lounge.name}
            onChange={(name) => {
              // Update the state
              lounge.name = name;
              this.setState({
                lounge,
                userChangedSomething: true,
              });
            }}
          />
        )
        : undefined
    );

    const loungeEmail = (
      (creating && isAdmin)
        ? (
          <LoungeEmail
            email={zoomHostEmail}
            onChange={(email) => {
              this.setState({
                zoomHostEmail: email.trim(),
              });
            }}
          />
        )
        : undefined
    );

    const loungeLock = (
      <div>
        <ExpandableDrawer
          icon={faDoorOpen}
          title="Access"
          noMarginOnBottom
          contents={(
            <span>
              {/* Unlock button */}
              <RadioButton
                text="Open 24/7"
                selected={!lounge.locked}
                id="CreateEditLounge-unlock-lounge"
                ariaLabel="indicate that this lounge should be open 24/7"
                onSelected={() => {
                  lounge.locked = false;

                  this.setState({
                    lounge,
                    userChangedSomething: true,
                  });
                }}
              />

              {/* Spacer for Small Screens */}
              <span className="d-block d-md-none mb-2" />

              {/* No Zoom toggle */}
              <RadioButton
                text="Locked"
                title="After this is selected, no new members can join the lounge (until unlocked)"
                selected={!!lounge.locked}
                id="CreateEditLounge-lock-lounge"
                ariaLabel="indicate that this lounge should be locked"
                onSelected={() => {
                  // Confirm before changing
                  this.setState({
                    confirmingAddLock: true,
                  });
                }}
              />
            </span>
          )}
        />

        {/* Explanation of "Lock" */}
        <div className="text-left text-muted small mb-2">
          To create a consistent place for collaboration,
          we don&apos;t recommend locking lounges unless
          absolutely necessary
          (during exams, etc.)
        </div>
      </div>
    );

    /* ----------------------- Compile Full UI ---------------------- */

    return (
      <div className="mt-1">
        {/* Add the modal if there is one */}
        {modal}

        <div className="mb-4">
          {/* Header section */}
          <div className="CreateEditLounge-header align-items-center">
            {/* Custom title based on whether creating or editing a lounge */}
            <h3 className="">
              {creating ? 'New' : 'Edit'}
              &nbsp;Study Lounge
            </h3>
          </div>

          {/* Explanation for when creating lounges */}
          {creating && (
            <div>
              Study lounges are Zoom spaces that are open 24/7 for students.
              Encourage students to use the lounge for studying,
              networking, meeting peers, and reviewing course
              material.
            </div>
          )}
        </div>

        {/* Reveal */}
        {(isAdmin && !creating) && (
          <div className="mb-3">
            <strong>
              Zoom Meeting ID:
            </strong>
            {' '}
            {lounge.currentZoomId}
            {' '}
            <a
              href={`https://harvard.zoom.us/user/${lounge.currentZoomHost}/meeting/${lounge.currentZoomId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="CreateEditLounge-reveal-in-zoom-dashboard btn progress-bar-striped font-weight-bold bg-success text-white pt-0 pb-0 btn-sm"
              aria-label="reveal the meeting in Zoom dashboard"
              id="CreateEditLounge-reveal-in-zoom-dashboard"
              style={{
                textDecoration: 'none',
              }}
            >
              Reveal in Zoom Admin Panel
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className="ml-1"
              />
            </a>
          </div>
        )}

        {/* Entire lounge form */}
        {loungeName}
        {loungeEmail}
        {loungeLock}

        {/* Save/Cancel buttons */}
        <div className="text-center mt-4">
          {/* Save/Create button */}
          <button
            id="CreateEditLounge-save-or-create-button"
            type="button"
            className="btn btn-lg btn-dark mr-2"
            aria-label="save changes"
            onClick={() => {
              // Validate the lounge
              const newValidationErrors = [];
              // > Name
              if (lounge.name.trim().length < 3) {
                // Name too short
                newValidationErrors.push('The study lounge name must be at least 3 chars long.');
              }
              if (lounge.name.trim().length > 20) {
                // Name too long
                newValidationErrors.push('The study lounge name cannot be longer than 20 characters.');
              }
              // Show validation errors if there are any
              if (newValidationErrors.length > 0) {
                // Log validation errors
                logError({
                  message: 'Please make the following changes:',
                  code: ERROR_CODES.LOUNGE_VALIDATION_FAILED,
                  metadata: {
                    type: 'validation',
                    lounge: {
                      loungeId: lounge.loungeId,
                      name: lounge.name,
                    },
                    validationErrors: newValidationErrors,
                  },
                });

                return this.setState({
                  validationErrors: newValidationErrors,
                });
              }

              // Save the trimmed name to the lounge
              lounge.name = lounge.name.trim();
              this.setState(
                { lounge },
                () => {
                  // All ready to save! Continue
                  this.save();
                }
              );
            }}
          >
            {/* Dynamic icon based on task */}
            <FontAwesomeIcon
              icon={creating ? faPlus : faSave}
            />

            {/* Dynamic text based on task */}
            {
              creating
                ? (
                  <span>
                    {/* Small Screen View */}
                    <span className="d-inline d-sm-none ml-2">
                      Create
                    </span>
                    {/* Large Screen View */}
                    <span className="d-none d-sm-inline ml-2">
                      Create Study Lounge
                    </span>
                  </span>
                )
                : (
                  <span>
                    {/* Small Screen View */}
                    <span className="d-inline d-md-none ml-2">
                      Save
                    </span>
                    {/* Large Screen View */}
                    <span className="d-none d-md-inline ml-2">
                      Save Changes
                    </span>
                  </span>
                )
            }
          </button>

          {/* Delete button */}
          {!creating && (
            <button
              id="CreateEditLounge-delete-button"
              type="button"
              className="btn btn-secondary btn-lg mr-2 position-relative"
              aria-label="delete lounge"
              onClick={async () => {
                // Ask user to confirm
                this.setState({
                  confirmingDelete: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              <span className="d-none d-md-inline ml-2">
                Delete
              </span>
            </button>
          )}

          {/*  Cancel button */}
          <button
            id="CreateEditLounge-cancel-button"
            type="button"
            className="btn btn-lg btn-secondary"
            aria-label="cancel and return to home page"
            onClick={async () => {
              if (userChangedSomething) {
                // Ask user to confirm
                this.setState({
                  confirmingCancel: true,
                });
              } else {
                // Log this
                logAction({
                  type: 'cancel',
                  description: 'edit lounge',
                  metadata: {
                    loungeId: lounge.loungeId,
                    name: lounge.name,
                  },
                });

                // Just cancel
                return onDone(null);
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

CreateEditLounge.propTypes = {
  /**
   * Handler for when the user returns to the home page
   * @return {Lounge} the updated/created lounge
   */
  onDone: PropTypes.func.isRequired,
  // Course id
  courseId: PropTypes.number.isRequired,
  // The lounge to edit (leave out if creating a lounge)
  lounge: Lounge,
  // Default email address for Zoom host
  defaultZoomHostEmail: PropTypes.string.isRequired,
  // True if the user is an admin
  isAdmin: PropTypes.bool,
  // The name of the current course
  courseName: PropTypes.string.isRequired,
  // Name of the current term
  termName: PropTypes.string.isRequired,
};

CreateEditLounge.defaultProps = {
  // No lounge (creating a lounge)
  lounge: null,
  // Not an admin
  isAdmin: false,
};

export default CreateEditLounge;
