import React from 'react';

// Import FontAwesome
import {
  faPlus,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to create an event (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Create an Event',
  startWarning: 'Do not create your main class event: we will do that for you',
  steps: [
    (
      <div>
        Click the
        {' '}
        <ExampleButton
          icon={faPlus}
          text="Create Event"
          variant="dashed"
        />
        {' '}
        button under &quot;Course Events&quot;
      </div>
    ),
    (
      <div>
        Add a name for the event
      </div>
    ),
    (
      <div>
        Choose an event type (Section, Office Hours, etc.)
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          text="Held in Zoom"
          variant="light"
          isRadio
        />
        {' '}
        to add Zoom
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          icon={faStar}
          text="Recommended Setup"
          variant="dark"
        />
        {' '}
        to finish configuring Zoom
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          icon={faPlus}
          text="Create Event"
          variant="dark"
        />
        {' '}
        to finish
      </div>
    ),
  ],
  endMessage: 'You\'re done! You can immediately start using the event',
};
