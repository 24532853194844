/**
 * UI for creating or editing an event
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faPlus,
  faExclamationTriangle,
  faTrash,
  faLock,
  faQuestionCircle,
  faExclamationCircle,
  faDotCircle,
  faInfoCircle,
  faBan,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

// Import shared prop types
import Event from '../shared/propTypes/Event';

// Import Event Form Items
import EventType from '../shared/EventFormItem/EventType';
import EventName from '../shared/EventFormItem/EventName';

// Advanced Features
import ZoomMeeting from '../shared/EventFormItem/ZoomMeeting';

// Shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import Modal from '../shared/Modal';
import ExpandableDrawer from '../shared/ExpandableDrawer';
import ErrorAlert from '../shared/ErrorAlert';
import TooltipButton from '../shared/TooltipButton';
import RadioButton from '../shared/RadioButton';
import ButtonLock from '../shared/ButtonLock';

// Import other components
import AttachZoomMeetingModal from './AttachZoomMeetingModal';
import CannotDeleteModal from './CannotDeleteModal';
import NotEditableModal from './NotEditableModal';

// Import helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import genIHID from '../helpers/genIHID';
import eventTypeToTitle from '../helpers/eventTypeToTitle';
import logError from '../helpers/logError';
import logAction from '../helpers/logAction';
import scroll from '../helpers/scroll';
import genEmptyEventObj from '../helpers/genEmptyEventObj';

// Import constants
import ERROR_CODES from '../constants/ERROR_CODES';

// Import style
import './style.css';

/* -------------------------- Component ------------------------- */

class CreateEditEvent extends Component {
  constructor(props) {
    super(props);

    const creating = !props.event;

    // Initialize state
    this.state = {
      // True if we're creating a new event
      creating,
      // True if showing loading spinner
      loading: creating, // Load initially if creating (get IHID)
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
      // True if the edited event started without a Zoom event
      editStartedWithoutZoom: (props.event && !props.event.currentZoomId),
      // True if confirming cancel
      confirmingCancel: false,
      // True if confirming event delete
      confirmingDelete: false,
      // True if confirming save without a Zoom meeting
      confirmingNoZoom: false,
      // True if confirming removal of a Zoom meeting
      confirmingZoomRemoval: false,
      // Validation errors
      validationErrors: null,
      // True if showing a popup telling user to fill out name first
      fillOutNameFirst: false,
      // User changed something
      userChangedSomething: false,
      // True if the user changed the name
      userChangedName: !creating, // starts false if creating
      // True if attaching a zoom meeting via the modal
      attachingZoom: false,
      // True if the pin changed confirmation modal should be displayed
      showPinChangeConfirmation: false,
      // True if zoom settings are loaded
      zoomSettingsLoaded: false,
      // Meeting settings object
      meetingObj: null,
      // True if the zoom meeting's settings have been changed
      meetingSettingsChanged: false,
      // True if waiting room is on
      waitingRoomOn: false,
      // True if auto record is on
      autoRecordOn: false,
      // If true, show a modal explaining that a feature is not editable
      notEditableModalVisible: false,
      // If true, show a modal explaining that the event cannot be deleted
      notAllowedToDeleteModalVisible: false,
      // The pastZoomIds state upon start
      pastZoomIdsAtStart: (
        props.event
          ? props.event.pastZoomIds || [] // use event's zoom ids if it has them
          : [] // no event so there's no past zoom ids
      ),
      // The pastZoomHosts state upon start
      pastZoomHostsAtStart: (
        props.event
          ? props.event.pastZoomHosts || []
          : []
      ),
      // The event to edit/create
      event: (
        props.event // Either use the given event
        || genEmptyEventObj(props.courseId)
      ),
    };
  }

  /**
   * Load the IHID if creating a new event
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;
    const { creating, event } = this.state;

    // Scroll to top
    scroll.toTop(true);

    // Only load if creating
    if (!creating) {
      return;
    }

    // Load the full list of events
    let events;
    try {
      events = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'GET',
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }

    // Extract the list of IHIDs
    const ihids = events.map((loadedEvent) => {
      return loadedEvent.ihid;
    });

    // Generate the IHID for this event
    const ihid = genIHID(ihids);

    // Store the ihid in the event
    event.ihid = ihid;

    // Update the state
    this.setState({
      event,
      loading: false,
    });
  }

  /**
   * Delete the event
   * @author Gabe Abrams
   */
  async onDelete() {
    const { event } = this.state;

    // Start loading
    this.setState({
      loading: true,
    });

    // Mark the event as archived
    event.archived = true;

    // Save the state
    this.setState({
      event,
    }, () => {
      // Done! Now save and return
      this.save();
    });

    // Log this
    logAction({
      type: 'delete',
      description: 'event',
      metadata: {
        ihid: event.ihid,
        name: event.name,
        type: event.type,
      },
    });
  }

  /**
   * Load Zoom meeting object and its settings
   * @author Gabe Abrams
   */
  async loadZoomSettings() {
    // Deconstruct
    const { courseId } = this.props;
    const { event } = this.state;

    // Show the loading progress
    this.setState({
      loading: true,
    });

    try {
      const meetingText = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/meetings/${event.currentZoomId}`,
        method: 'GET',
      });

      // Try to parse the meeting
      let meetingObj;
      try {
        meetingObj = JSON.parse(meetingText);

        if (!meetingObj || !meetingObj.settings) {
          throw new Error();
        }
      } catch (err) {
        const fatalErrorMessage = 'The meeting we just got from Zoom isn\'t set up the way we expected. Please contact an admin.';

        // Log the error
        logError({
          message: fatalErrorMessage,
          code: ERROR_CODES.MALFORMED_ZOOM_MEETING,
          metadata: {
            event,
            meeting: meetingObj,
            type: 'zoom',
          },
        });

        // Show error to user
        return this.setState({ fatalErrorMessage });
      }

      // Success! Save to state
      this.setState({
        meetingObj,
        zoomSettingsLoaded: true,
        autoRecordOn: (meetingObj.settings.auto_recording === 'cloud'),
        waitingRoomOn: !!meetingObj.settings.waiting_room,
        loading: false,
      });

      // Log this
      logAction({
        type: 'load',
        description: 'zoom settings',
        metadata: {
          ihid: event.ihid,
          name: event.name,
          type: event.type,
          zoomId: event.currentZoomId,
        },
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: (
          err.message
          || 'An unknown error occurred while loading event settings.'
        ),
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Save Zoom meeting settings
   * @author Gabe Abrams
   * @return {boolean} true if successful
   */
  async saveZoomSettings() {
    // Deconstruct props
    const { courseId } = this.props;

    // Deconstruct state
    const {
      event,
      autoRecordOn,
      waitingRoomOn,
      meetingSettingsChanged,
    } = this.state;
    let { meetingObj } = this.state;

    // Skip save if no changes
    if (!meetingSettingsChanged) {
      return true; // Success (no changes to save)
    }

    // Skip if Zoom meeting was detached
    if (!event.currentZoomId) {
      return true; // Success (no changes to save)
    }

    // Load settings if they aren't loaded yet
    if (!meetingObj) {
      try {
        const meetingObjStr = await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/meetings/${event.currentZoomId}`,
          method: 'GET',
        });
        meetingObj = JSON.parse(meetingObjStr);
      } catch (err) {
        this.setState({
          fatalErrorMessage: err.message,
          fatalErrorCode: err.code,
        });
        return false; // Failure
      }
    }

    // Make sure the meeting settings object is as we expect it
    if (!meetingObj || !meetingObj.settings) {
      this.setState({
        fatalErrorMessage: 'When we asked Zoom to verify meeting settings, Zoom responded strangely.',
        fatalErrorCode: ERROR_CODES.NO_MEETING_SETTINGS,
      });
      return false; // Failure
    }

    // Update the settings object
    meetingObj.settings.auto_recording = (
      autoRecordOn
        ? 'cloud'
        : 'none'
    );
    meetingObj.settings.waiting_room = !!waitingRoomOn;

    // Save the changes back to Zoom
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/meetings/${event.currentZoomId}`,
        method: 'PUT',
        params: {
          meeting: JSON.stringify(meetingObj),
        },
      });
    } catch (err) {
      this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
      return false; // Failure
    }

    // Log this
    logAction({
      type: 'edit',
      description: 'zoom settings',
      metadata: {
        ihid: event.ihid,
        name: event.name,
        type: event.type,
        zoomId: event.currentZoomId,
        waitingRoomOn: !!waitingRoomOn,
        autoRecordOn: !!autoRecordOn,
      },
    });

    // Success!
    return true;
  }

  /**
   * Remove the current zoom id and put it in pastZoomIds (if applicable)
   * @author Gabe Abrams
   */
  async detachZoomId() {
    const {
      event,
      pastZoomIdsAtStart,
      pastZoomHostsAtStart,
    } = this.state;

    if (!event.currentZoomId) {
      // Nothing to detach
      return;
    }

    // Move the current meeting into the pastZoomIds if it's not in there
    if (
      event.currentZoomId
      && pastZoomIdsAtStart.indexOf(event.currentZoomId) < 0
    ) {
      // Duplicate past zoom ids and add an item
      event.pastZoomIds = pastZoomIdsAtStart.concat(
        [event.currentZoomId]
      );
    }

    // Move the current zoom host into the pastZoomHosts if it's not in there
    if (
      event.currentZoomHost
      && pastZoomHostsAtStart.indexOf(event.currentZoomHost) < 0
    ) {
      // Duplicate past zoom hosts and add an item
      event.pastZoomHosts = pastZoomHostsAtStart.concat(
        [event.currentZoomHost]
      );
    }

    // Remove the current zoom id
    event.currentZoomId = null;

    // Remove the current zoom host
    event.currentZoomHost = null;

    // Remove the open zoom link
    event.openZoomLink = null;

    // Unset isWebinar
    event.isWebinar = null;

    await new Promise((resolve) => {
      this.setState({
        event,
        zoomSettingsLoaded: false,
        meetingObj: null,
        waitingRoomOn: false,
        autoRecordOn: false,
      }, resolve);
    });
  }

  /**
   * Save the event
   * @author Gabe Abrams
   */
  async save() {
    const {
      courseId,
      onDone,
    } = this.props;
    const { event } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    const isEditing = !!this.props.event;

    // Set loading to true while we save the event
    this.setState({
      loading: true,
    });

    // Save Zoom settings
    const success = await this.saveZoomSettings();
    if (!success) {
      // An error message is already there. Just stop.
      return;
    }

    // Save the event
    try {
      // Save via the ttm API
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'POST',
        params: {
          event: JSON.stringify(event),
        },
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }

    // Exit the create/save screen
    onDone(event);

    // Log this
    const prevEvent = (
      isEditing
        // eslint-disable-next-line react/destructuring-assignment
        ? this.props.event
        : null
    );
    logAction({
      type: (
        isEditing
          ? 'edit'
          : 'create'
      ),
      description: 'event',
      metadata: {
        prevEvent,
        event,
        ihid: event.ihid,
        name: event.name,
        type: event.type,
      },
    });
  }

  /**
   * Render CreateEditEvent
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      onDone,
      courseId,
      courseName,
      termName,
      school,
      userEmail,
      isAdmin,
      isLearner,
      otherEvents,
    } = this.props;

    // Deconstruct state
    const {
      creating,
      event,
      loading,
      fatalErrorMessage,
      fatalErrorCode,
      editStartedWithoutZoom,
      confirmingCancel,
      confirmingDelete,
      confirmingZoomRemoval,
      fillOutNameFirst,
      validationErrors,
      attachingZoom,
      showPinChangeConfirmation,
      userChangedName,
      confirmingNoZoom,
      zoomSettingsLoaded,
      waitingRoomOn,
      autoRecordOn,
      userChangedSomething,
      notEditableModalVisible,
      notAllowedToDeleteModalVisible,
    } = this.state;

    // Constants
    const somethingIsLocked = (
      event.lockZoomToggle
      || event.lockAutoRecordSetting
    );

    /*------------------------------------------------------------------------*/
    /*                              Short Bodies                              */
    /*------------------------------------------------------------------------*/

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
          code={fatalErrorCode}
          showReloadButton
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                            Create the Modal                            */
    /*------------------------------------------------------------------------*/

    let modal;

    // Create confirm cancel modal
    if (confirmingCancel) {
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mr-2"
              />
              Abandon Changes?
            </span>
          )}
          type={Modal.TYPES.ABANDON_CONTINUE}
          body="Are you sure you want to abandon your changes?"
          onClose={(button) => {
            if (button === Modal.BUTTONS.ABANDON) {
              // Log this
              logAction({
                type: 'cancel',
                description: 'edit event',
                metadata: {
                  ihid: event.ihid,
                  name: event.name,
                  type: event.type,
                },
              });

              // Exit without any changes
              return onDone(null);
            }

            // Back to editing
            this.setState({
              confirmingCancel: false,
            });
          }}
        />
      );
    } else if (confirmingDelete) {
      // Confirm delete modal
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />
              Confirm Permanent Delete
            </span>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          body={(
            <div>
              <div>
                Once you delete this event,&nbsp;
                <strong>
                  it cannot be undone
                </strong>
                .
              </div>
              <div>
                <strong>
                  Note:
                </strong>
                &nbsp;we will not delete any published recordings for this
                event.
              </div>
            </div>
          )}
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed delete
              return this.onDelete();
            }

            // Not deleting
            this.setState({
              confirmingDelete: false,
            });
          }}
          okayLabel="Delete Event"
          okayColor="dark"
        />
      );
    } else if (confirmingNoZoom) {
      // Confirm sav without Zoom
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mr-2"
              />
              No Zoom?
            </span>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          body="Save this event without Zoom video conferencing? You can always add Zoom later."
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed! Run save function
              return this.save();
            }

            // Not saving. Just close the modal
            this.setState({
              confirmingNoZoom: false,
            });
          }}
          okayLabel="Save Without Zoom"
          okayColor="warning"
        />
      );
    } else if (confirmingZoomRemoval) {
      // Confirm remove Zoom
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mr-2"
              />
              Remove Zoom?
            </span>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          body="Are you sure you want to remove Zoom from this event?"
          onClose={async (button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed! Remove the zoom meeting
              await this.detachZoomId();
            }

            // Close the modal
            this.setState({
              confirmingZoomRemoval: false,
            });
          }}
          okayLabel="Remove Zoom"
          okayColor="dark"
        />
      );
    } else if (fillOutNameFirst) {
      // Fill out name first modal
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="mr-2"
              />
              Fill out the Event Name first.
            </span>
          )}
          type={Modal.TYPES.OKAY}
          body={(
            <div className="alert alert-warning m-0 text-dark">
              <div>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2"
                />
                The Event Name must be at least 3 characters long
                to set up Zoom.
              </div>
            </div>
          )}
          onClose={() => {
            this.setState({
              fillOutNameFirst: false,
            });
          }}
        />
      );
    } else if (validationErrors) {
      // Validation errors modal
      const errorLines = validationErrors.map((validationError) => {
        return (
          <div className="CreateEditEvent-validation-error">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2"
            />
            {validationError}
          </div>
        );
      });

      // Create a modal
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="mr-2"
              />
              Please make the following changes:
            </span>
          )}
          type={Modal.TYPES.OKAY}
          body={(
            <div
              id="CreateEditEvent-validation-errors"
              className="alert alert-warning m-0 text-dark"
            >
              {errorLines}
            </div>
          )}
          onClose={() => {
            this.setState({
              validationErrors: null,
            });
          }}
        />
      );
    } else if (attachingZoom) {
      // Attach zoom modal
      modal = (
        <AttachZoomMeetingModal
          otherEvents={otherEvents}
          courseId={courseId}
          courseName={courseName}
          termName={termName}
          school={school}
          userEmail={userEmail}
          isAdmin={isAdmin}
          isLearner={isLearner}
          eventName={event.name}
          onCancel={() => {
            this.setState({
              attachingZoom: false,
            });
          }}
          onDone={async (results) => {
            // Detach the current zoom id
            await this.detachZoomId();

            // Update the event
            event.openZoomLink = results.openZoomLink;

            // Add the current zoom id
            event.currentZoomId = results.currentZoomId;

            // Add hostId to the event
            event.currentZoomHost = results.currentZoomHost;

            // Update isWebinar value
            event.isWebinar = !!results.isWebinar;

            // Filter current zoom id from the past ones
            event.pastZoomIds = (event.pastZoomIds || []).filter((id) => {
              return (id !== event.currentZoomId);
            });

            // Filter current zoom host from the past ones
            event.pastZoomHosts = (event.pastZoomHosts || []).filter((host) => {
              return (host !== event.currentZoomHost);
            });

            // Save results to state
            this.setState({
              event,
              zoomSettingsLoaded: true,
              meetingSettingsChanged: false, // No changes yet!
              waitingRoomOn: results.waitingRoomOn, // Copy settings from modal
              autoRecordOn: results.autoRecordOn, // Copy settings from modal
              attachingZoom: false, // Hide modal
            });

            // Log this
            logAction({
              type: 'add',
              description: 'zoom',
              metadata: {
                ihid: event.ihid,
                name: event.name,
                type: event.type,
                isWebinar: event.isWebinar,
                zoomId: results.currentZoomId,
                zoomHost: results.currentZoomHost,
              },
            });
          }}
        />
      );
    } else if (notEditableModalVisible) {
      // Not editable modal
      modal = (
        <NotEditableModal
          onClose={() => {
            this.setState({
              notEditableModalVisible: false,
            });
          }}
        />
      );
    } else if (notAllowedToDeleteModalVisible) {
      // Not allowed to delete modal
      modal = (
        <CannotDeleteModal
          onClose={() => {
            this.setState({
              notAllowedToDeleteModalVisible: false,
            });
          }}
        />
      );
    } else if (showPinChangeConfirmation) {
      modal = (
        <Modal
          title={(
            event.pinned
              ? (
                <span>
                  <FontAwesomeIcon
                    icon={faThumbtack}
                    className="CreateEditEvent-pin-button-icon mr-3"
                  />
                  Pinned to Top
                </span>
              )
              : (
                <span>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mr-2"
                  />
                  Unpinned
                </span>
              )
          )}
          type={Modal.BUTTONS.OKAY}
          body={(
            <div>
              {/* Explanation of what pin does */}
              {event.pinned && (
                <p>
                  This event will now appear near the top of the event list
                  along with any other pinned events
                </p>
              )}

              {/* Reminder to save changes */}
              <div>
                Remember to click &quot;Save Changes&quot;
              </div>
            </div>
          )}
          onClose={() => {
            // Log pin-unpin event
            logAction({
              type: 'click',
              description: (
                event.pinned
                  ? 'pin event button'
                  : 'unpin event button'
              ),
              metadata: {
                ihid: event.ihid,
                name: event.name,
                type: event.type,
              },
            });

            // Back to editing
            this.setState({
              showPinChangeConfirmation: false,
              event,
            });
          }}
        />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                               Event Form                               */
    /*------------------------------------------------------------------------*/

    // List of elements in the form
    const eventForm = [];

    /* ---------------------------- Name ---------------------------- */

    eventForm.push(
      <EventName
        key="event-name"
        name={event.name}
        onChange={(name) => {
          // Update the state
          event.name = name;
          this.setState({
            event,
            userChangedName: true,
            userChangedSomething: true,
          });
        }}
      />
    );

    /* ---------------------------- Pin button -------------------------- */

    // Create pin button
    const pinTooltipText = (
      event.pinned
        ? 'Click to unpin this event'
        : 'Pin to top of event list'
    );
    const pinButton = (
      <div className="CreateEditEvent-pin-button d-inline-block ml-2">
        <TooltipButton
          contents={(
            <span>
              {/* Pin Icon */}
              <FontAwesomeIcon
                icon={faThumbtack}
                className={`CreateEditEvent-pin-button-icon CreateEditEvent-${event.pinned ? 'pinned' : 'unpinned'} ${event.pinned ? 'text-dark' : 'text-secondary'}`}
              />
              {/* Pin Text (only when pinned) */}
              {event.pinned && (
                <span className="ml-1">
                  Pinned to Top
                </span>
              )}
            </span>
          )}
          id="CreateEditEvent-pin-toggle-button"
          className={`btn btn-sm ${event.pinned ? 'btn-warning' : 'CreateEditEvent-pin-button-transparent btn-light text-secondary'}`}
          ariaLabel={pinTooltipText}
          title={pinTooltipText}
          onClick={() => {
            // Update the state
            event.pinned = !event.pinned;
            // Open the zoom attach modal if editing existing event
            this.setState({
              event,
              showPinChangeConfirmation: !creating,
              userChangedSomething: true,
            });
          }}
        />
      </div>
    );

    /* ---------------------------- Type ---------------------------- */

    eventForm.push(
      <EventType
        key="event-type"
        type={event.type}
        onChange={(type) => {
          // Update the event type
          event.type = type;

          // Automatically set the name based on the title if the user hasn't
          //   already changed the name
          if (!userChangedName) {
            event.name = eventTypeToTitle(type);
          }

          // Save the state
          this.setState({
            event,
            userChangedSomething: true,
          });
        }}
      />
    );

    /* ---------------------------- Zoom ---------------------------- */

    let currentZoomLink = null;
    if (isAdmin && event.currentZoomId) {
      currentZoomLink = (
        event.isWebinar
          ? `https://harvard.zoom.us/user/${event.currentZoomHost}/webinar/${event.currentZoomId}`
          : `https://harvard.zoom.us/user/${event.currentZoomHost}/meeting/${event.currentZoomId}`
      );
    }

    eventForm.push(
      <ZoomMeeting
        key="zoom-meeting"
        currentZoomId={event.currentZoomId}
        currentZoomLink={currentZoomLink}
        onAttach={() => {
          // Don't continue if the title isn't filled out
          if (event.name.trim().length < 3) {
            // Show modal
            return this.setState({
              fillOutNameFirst: true,
            });
          }

          // Open the zoom attach modal
          this.setState({
            attachingZoom: true,
            userChangedSomething: true,
          });
        }}
        onDetach={() => {
          // Confirm
          this.setState({
            confirmingZoomRemoval: true,
            userChangedSomething: true,
          });
        }}
        lockZoomToggle={!isAdmin && event.lockZoomToggle}
        lockAutoRecordSetting={!isAdmin && event.lockAutoRecordSetting}
        onLockedFeatureClicked={() => {
          this.setState({
            notEditableModalVisible: true,
          });
        }}
        settingsLoaded={zoomSettingsLoaded}
        onStartLoad={() => {
          this.loadZoomSettings();
        }}
        autoRecordOn={autoRecordOn}
        onAutoRecordChanged={(newAutoRecordOn) => {
          this.setState({
            autoRecordOn: newAutoRecordOn,
            meetingSettingsChanged: true,
            userChangedSomething: true,
          });
        }}
        waitingRoomOn={waitingRoomOn}
        isWebinar={event.isWebinar}
        onWaitingRoomChanged={(newWaitingRoomOn) => {
          this.setState({
            waitingRoomOn: newWaitingRoomOn,
            meetingSettingsChanged: true,
            userChangedSomething: true,
          });
        }}
      />
    );

    /* ---------------------------- Locks --------------------------- */

    if (isAdmin) {
      eventForm.push(
        <div key="locks">
          <ExpandableDrawer
            icon={faLock}
            title="Locks"
            isAdminFeature
            noMarginOnBottom
            contents={(
              <span>
                {/* Unlock button */}
                <RadioButton
                  text="No Locks"
                  selected={!somethingIsLocked}
                  id="CreateEditEvent-unlock-event"
                  ariaLabel="indicate that this event may be fully editable by teaching team members"
                  onSelected={() => {
                    event.lockAutoRecordSetting = false;
                    event.lockZoomToggle = false;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                />

                {/* Spacer for Small Screens */}
                <span className="d-block d-md-none mb-2" />

                {/* No Zoom toggle */}
                <RadioButton
                  text="Lock Zoom Toggle"
                  title="If selected, non-admins cannot toggle Zoom for this event (they can't add or remove Zoom)"
                  selected={(
                    event.lockZoomToggle
                    && !event.lockAutoRecordSetting
                  )}
                  id="CreateEditEvent-lock-zoom-toggle"
                  ariaLabel="indicate that teaching team members may not toggle whether this event is held in zoom"
                  onSelected={() => {
                    event.lockAutoRecordSetting = false;
                    event.lockZoomToggle = true;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                />

                {/* Spacer for Small Screens */}
                <span className="d-block d-md-none mb-2" />

                {/* No Zoom toggle or Zoom auto record edits */}
                <RadioButton
                  text="Lock Auto Record and Zoom Toggle"
                  title="If selected, non-admins cannot edit auto record or toggle Zoom for this event (they can't add or remove Zoom)"
                  selected={event.lockAutoRecordSetting && event.lockZoomToggle}
                  id="CreateEditEvent-lock-auto-record-and-toggle"
                  ariaLabel="indicate that teaching team members may not edit auto record or toggle whether this event is held in zoom"
                  onSelected={() => {
                    event.lockAutoRecordSetting = true;
                    event.lockZoomToggle = true;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                />
              </span>
            )}
          />

          {/* Explanation of "Lock" */}
          <div className="text-left text-muted small mb-2">
            Locks apply only to non-admins.
            Non-admins will see a
            {' '}
            <FontAwesomeIcon
              icon={faLock}
              aria-label="lock icon"
            />
            {' '}
            on locked buttons.
            All locks also prevent the event from being
            deleted.
          </div>
        </div>
      );
    }

    /* ---------------------------- Bans ---------------------------- */

    if (isAdmin) {
      eventForm.push(
        <div key="bans">
          <ExpandableDrawer
            icon={faBan}
            title="Bans"
            isAdminFeature
            noMarginOnBottom
            contents={(
              <span>
                {/* No Ban */}
                <button
                  type="button"
                  id="CreateEditEvent-no-ban"
                  className={`btn btn-${(!event.banDCEStudents && !event.banFASStudents) ? 'warning selected' : 'light'} btn-sm m-0 mr-2`}
                  aria-label={`remove all bans on the event${(!event.banDCEStudents && !event.banFASStudents) ? ': currently selected' : ''}`}
                  onClick={() => {
                    event.banDCEStudents = false;
                    event.banFASStudents = false;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      (!event.banDCEStudents && !event.banFASStudents)
                        ? faDotCircle
                        : faCircle
                    }
                    className="mr-1"
                  />
                  No Ban (Everyone Can Join)
                </button>

                {/* Spacer for Small Screens */}
                <span className="d-block d-md-none mb-2" />

                {/* Ban DCE Students */}
                <button
                  type="button"
                  id="CreateEditEvent-ban-dce"
                  className={`btn btn-${event.banDCEStudents ? 'warning selected' : 'light'} btn-sm mr-2 m-0`}
                  aria-label={`indicate that DCE students cannot attend${event.banDCEStudents ? ': currently selected' : ''}`}
                  onClick={() => {
                    event.banDCEStudents = true;
                    event.banFASStudents = false;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      event.banDCEStudents
                        ? faDotCircle
                        : faCircle
                    }
                    className="mr-1"
                  />
                  Ban DCE Students
                </button>

                {/* Spacer for Small Screens */}
                <span className="d-block d-md-none mb-2" />

                {/* Ban FAS Students */}
                <button
                  type="button"
                  id="CreateEditEvent-ban-fas"
                  className={`btn btn-${event.banFASStudents ? 'warning selected' : 'light'} btn-sm m-0`}
                  aria-label={`indicate that FAS students cannot attend${event.banFASStudents ? ': currently selected' : ''}`}
                  onClick={() => {
                    event.banFASStudents = true;
                    event.banDCEStudents = false;

                    this.setState({
                      event,
                      userChangedSomething: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      event.banFASStudents
                        ? faDotCircle
                        : faCircle
                    }
                    className="mr-1"
                  />
                  Ban FAS Students
                </button>
              </span>
            )}
          />

          {/* Explanation of "Lock" */}
          <div className="text-left text-muted small mb-2">
            When someone is banned from an event,
            the event doesn&apos;t even show up in their list.
          </div>
        </div>
      );
    }

    /* ----------------------- Compile Full UI ---------------------- */

    return (
      <div className="mt-1">
        {/* Add the modal if there is one */}
        {modal}

        <div className="mb-4">
          {/* Header section for aligning h3 and pin to top */}
          <div className="CreateEditEvent-header align-items-center">
            {/* Custom title based on whether creating or editing an event */}
            <h3 className="">
              {creating ? 'New' : 'Edit'}
              &nbsp;Event
              {/* Show button to pin and unpin event from top of event list */}
              {pinButton}
            </h3>
          </div>

          {/* Explanation for when creating events */}
          {creating && (
            <div>
              Events can be recurring like&nbsp;
              <small>
                &quot;Class&quot;
              </small>
              &nbsp;or&nbsp;
              <small>
                &quot;Monday Lab&quot;
              </small>
              , or they can be one-off like&nbsp;
              <small>
                &quot;Midterm Review Session&quot;
              </small>
              .
            </div>
          )}
        </div>

        {/* Entire event form */}
        {eventForm}

        {/* Save/Cancel buttons */}
        <div className="text-center mt-4">
          {/* Save/Create button */}
          <button
            id="CreateEditEvent-save-or-create-button"
            type="button"
            className="btn btn-lg btn-dark mr-2"
            aria-label="save changes"
            onClick={() => {
              // Validate the event
              const newValidationErrors = [];
              // > Name
              if (event.name.trim().length < 3) {
                // Name too short
                newValidationErrors.push('Add an Event Name that is at least 3 characters long.');
              }
              if (event.name.trim().length > 50) {
                // Name too long
                newValidationErrors.push('The Event Name was too long: it can\'t be longer than 50 chars.');
              }
              // > Type
              if (event.type === null) {
                // No type chosen
                newValidationErrors.push('Choose an event type.');
              }
              // Show validation errors if there are any
              if (newValidationErrors.length > 0) {
                // Log validation errors
                logError({
                  message: 'Please make the following changes:',
                  code: ERROR_CODES.EVENT_VALIDATION_FAILED,
                  metadata: {
                    type: 'validation',
                    event: {
                      ihid: event.ihid,
                      type: event.type,
                      name: event.name,
                    },
                    validationErrors: newValidationErrors,
                  },
                });

                return this.setState({
                  validationErrors: newValidationErrors,
                });
              }

              // Confirm if there is no zoom meeting and the event either is
              // new or didn't have one when starting the edit
              if (!event.currentZoomId && !editStartedWithoutZoom) {
                return this.setState({
                  confirmingNoZoom: true,
                });
              }

              // All ready to save! Continue
              this.save();
            }}
          >
            {/* Dynamic icon based on task */}
            <FontAwesomeIcon
              icon={creating ? faPlus : faSave}
            />

            {/* Dynamic text based on task */}
            {
              creating
                ? (
                  <span>
                    {/* Small Screen View */}
                    <span className="d-inline d-sm-none ml-2">
                      Create
                    </span>
                    {/* Large Screen View */}
                    <span className="d-none d-sm-inline ml-2">
                      Create Event
                    </span>
                  </span>
                )
                : (
                  <span>
                    {/* Small Screen View */}
                    <span className="d-inline d-md-none ml-2">
                      Save
                    </span>
                    {/* Large Screen View */}
                    <span className="d-none d-md-inline ml-2">
                      Save Changes
                    </span>
                  </span>
                )
            }
          </button>

          {/* Delete button */}
          {!creating && (
            <button
              id="CreateEditEvent-delete-button"
              type="button"
              className="btn btn-secondary btn-lg mr-2 position-relative"
              aria-label="delete event"
              onClick={async () => {
                // Do nothing if locked
                if (somethingIsLocked && !isAdmin) {
                  return this.setState({
                    notAllowedToDeleteModalVisible: true,
                  });
                }

                // Ask user to confirm
                this.setState({
                  confirmingDelete: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              <span className="d-none d-md-inline ml-2">
                Delete
              </span>

              {/* Show lock icon if this button is locked */}
              {(somethingIsLocked && !isAdmin) && (
                <ButtonLock />
              )}
            </button>
          )}

          {/*  Cancel button */}
          <button
            id="CreateEditEvent-cancel-button"
            type="button"
            className="btn btn-lg btn-secondary"
            aria-label="cancel and return to event list"
            onClick={async () => {
              if (userChangedSomething) {
                // Ask user to confirm
                this.setState({
                  confirmingCancel: true,
                });
              } else {
                // Log this
                logAction({
                  type: 'cancel',
                  description: 'edit event',
                  metadata: {
                    ihid: event.ihid,
                    type: event.type,
                    name: event.name,
                  },
                });

                // Just cancel
                return onDone(null);
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

CreateEditEvent.propTypes = {
  /**
   * Handler for when the user returns to the main panel
   * @return {Event} the updated/created event
   */
  onDone: PropTypes.func.isRequired,
  // Course id
  courseId: PropTypes.number.isRequired,
  // The name of the current course
  courseName: PropTypes.string.isRequired,
  // Name of the current term
  termName: PropTypes.string.isRequired,
  // The meeting school tracking code
  school: PropTypes.string.isRequired,
  // Email of the user
  userEmail: PropTypes.string.isRequired,
  // True if the user is an admin
  isAdmin: PropTypes.bool,
  // True if the user is a learner
  isLearner: PropTypes.bool.isRequired,
  // The event to edit (leave out if creating an event)
  event: Event,
  // List of other events
  otherEvents: PropTypes.arrayOf(Event),
};

CreateEditEvent.defaultProps = {
  // No event (creating an event)
  event: null,
  // Not an admin
  isAdmin: false,
  // No other events
  otherEvents: [],
};

export default CreateEditEvent;
