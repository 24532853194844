/**
 * A decorative lounge chair
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import style
import './LoungeChair.css';

class LoungeChair extends Component {
  /**
   * Render LoungeChair
   * @author Gabe Abrams
   */
  render() {
    const {
      angle,
      top,
      left,
      bottom,
      right,
    } = this.props;

    return (
      <div
        className="LoungeChair-container"
        style={{
          top: (top ? `${top}px` : undefined),
          left: (left ? `${left}px` : undefined),
          bottom: (bottom ? `${bottom}px` : undefined),
          right: (right ? `${right}px` : undefined),
          transform: `rotate(${angle}deg)`,
        }}
      >
        <div className="LoungeChair-left" />
        <div className="LoungeChair-back" />
        <div className="LoungeChair-right" />
        <div className="LoungeChair-seat" />
        <div className="LoungeChair-pillow" />
      </div>
    );
  }
}

LoungeChair.propTypes = {
  // Angle that the chair faces
  angle: PropTypes.number,
  // Position in pixels
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
};

LoungeChair.defaultProps = {
  // Facing to the right
  angle: 0,
  // No positioning
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
};

export default LoungeChair;
