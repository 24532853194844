/**
 * A blinking smiley face
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import tooltip components
// Note regarding OverlayTrigger "findDOMNode is deprecated in StrictMode"
// https://github.com/react-bootstrap/react-bootstrap/issues/5075
import {
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Import style
import './Smiley.css';

class Smiley extends Component {
  /**
   * Create randomized animation values
   * @author Gabe Abrams
   */
  constructor(props) {
    super(props);

    // Determine random offsets for animations
    this.eyeAnimationDelay = `-${Math.round(Math.random() * 40 * 100) / 100}s`;
    this.eyeAnimationDirection = (
      Math.random() > 0.5
        ? 'normal'
        : 'reversed'
    );
    this.eyeAnimationDuration = `${40 + Math.round(Math.random() * 10)}s`;
    this.blinkAnimationDelay = `-${Math.round(Math.random() * 30 * 100) / 100}s`;
    this.blinkAnimationDuration = `${30 + Math.round(Math.random() * 10)}s`;
  }

  /**
   * Render Smiley
   * @author Gabe Abrams
   */
  render() {
    const { name } = this.props;

    return (
      <div
        className="d-inline-block"
        style={{ width: '28px' }}
      >
        <OverlayTrigger
          placement="left"
          rootClose
          overlay={(props) => {
            /* eslint-disable react/jsx-props-no-spreading */
            return (
              <Tooltip
                className="Smiley-tooltip Smiley-tooltip-top"
                style={{ pointerEvents: 'none' }}
                {...props}
              >
                {name}
              </Tooltip>
            );
          }}
        >
          <div className="Smiley-container">
            <div
              className="Smiley-eyes"
              style={{
                animationDelay: this.eyeAnimationDelay,
                animationDirection: this.eyeAnimationDirection,
                animationDuration: this.eyeAnimationDuration,
              }}
            >
              <div
                className="Smiley-left-eye"
                style={{
                  animationDelay: this.blinkAnimationDelay,
                  animationDuration: this.blinkAnimationDuration,
                }}
              />
              <div
                className="Smiley-right-eye"
                style={{
                  animationDelay: this.blinkAnimationDelay,
                  animationDuration: this.blinkAnimationDuration,
                }}
              />
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

Smiley.propTypes = {
  // Student name
  name: PropTypes.string.isRequired,
};

export default Smiley;
