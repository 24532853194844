/**
 * Attendance filter for specific day(s) of week
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faChevronUp,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import ExpandableDrawer from '../../../shared/ExpandableDrawer';

// Import days of week
import WEEKDAY_TO_CODE from '../../../constants/WEEKDAY_TO_CODE';
import CODE_TO_WEEKDAY from '../../../constants/CODE_TO_WEEKDAY';
import WEEKDAY_TO_ABBREVIATION from '../../../constants/WEEKDAY_TO_ABBREVIATION';

// Style
import './AttendanceDayFilter.css';
import CheckItem from '../../../shared/CheckItem';

class AttendanceDayFilter extends Component {
  /**
   * Constructor
   * @author Gabe Abrams
   */
  constructor(props) {
    super(props);

    this.state = {
      // True if filter drawer is expanded
      expanded: false,
    };
  }

  /**
   * Render AttendanceDayFilter
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      onChange,
      daysOfWeek,
    } = this.props;
    // Deconstruct state
    const {
      expanded,
    } = this.state;

    // True if any filter has been applied
    const filtering = (
      daysOfWeek.length > 0
      && daysOfWeek.length < 7
    );

    // Create a status message
    let statusMessage;
    if (!filtering) {
      statusMessage = 'Any day of the week';
    } else if (daysOfWeek.length === 1) {
      statusMessage = `Only ${CODE_TO_WEEKDAY[daysOfWeek[0]]}`;
    } else if (daysOfWeek.length === 2) {
      statusMessage = (
        <span>
          <span className="d-none d-sm-inline">
            Only
            {' '}
          </span>
          {CODE_TO_WEEKDAY[daysOfWeek[0]]}
          {' '}
          or
          {' '}
          {CODE_TO_WEEKDAY[daysOfWeek[1]]}
        </span>
      );
    } else {
      statusMessage = `Only ${daysOfWeek.join('/')}`;
    }

    return (
      <div className="mb-2">
        <ExpandableDrawer
          icon={faCalendarAlt}
          title="Day"
          noMarginOnBottom
          contents={(
            <div className="d-flex align-items-center w-100">
              <div className="flex-grow-1 text-left">
                {statusMessage}
              </div>

              <div>
                {/* Cancel Button */}
                {expanded && (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    aria-label="hide options for the date filter"
                    onClick={() => {
                      // Update state
                      this.setState({
                        expanded: false,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      className="mr-2"
                    />
                    Hide
                  </button>
                )}

                {/* Expand Button */}
                {!expanded && (
                  <button
                    type="button"
                    className={`btn btn-sm btn-secondary ${filtering ? 'd-none d-sm-inline' : ''}`}
                    aria-label="show event filter options (either filter by specific date or by days of the week)"
                    title={filtering ? 'Edit Filter' : 'Add a new filter'}
                    onClick={() => {
                      // Update state
                      this.setState({
                        expanded: true,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFilter}
                      className="mr-2 d-none d-sm-inline"
                    />
                    {
                      filtering
                        ? 'Edit'
                        : (
                          <span>
                            <span className="d-none d-md-inline">
                              Add&nbsp;
                            </span>
                            Filter
                          </span>
                        )
                    }
                  </button>
                )}

                {/* Reset Button */}
                {filtering && (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary ml-1"
                    aria-label="reset this filter (do not filter by specific date or by days of the week)"
                    onClick={() => {
                      // Update state
                      this.setState({
                        expanded: false,
                      });

                      onChange([]); // No filter
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    {/* Text hidden on small screen */}
                    <span className="d-none d-md-inline ml-2">
                      Reset
                    </span>
                  </button>
                )}
              </div>
            </div>
          )}
          expanded={expanded}
          renderDrawerContents={() => {
            // Create list of type items
            const dayItemsWeek = [];
            const dayItemsWeekend = [];
            Object.keys(WEEKDAY_TO_CODE).forEach((weekday) => {
              const code = WEEKDAY_TO_CODE[weekday];
              const item = (
                <CheckItem
                  key={code}
                  text={(
                    <span>
                      {/* Abbreviated for small screens */}
                      <span className="d-inline d-md-none">
                        {WEEKDAY_TO_ABBREVIATION[weekday]}
                      </span>

                      {/* Full names for larger screens */}
                      <span className="d-none d-md-inline">
                        {weekday}
                      </span>
                    </span>
                  )}
                  checked={daysOfWeek.indexOf(code) >= 0}
                  onChange={(checked) => {
                    let newDaysOfWeek;
                    if (!checked) {
                      // Remove from list
                      newDaysOfWeek = (
                        daysOfWeek
                          .filter((day) => {
                            return (day !== code);
                          })
                      );
                    } else {
                      // Add to list (add in order)
                      newDaysOfWeek = (
                        Object.keys(CODE_TO_WEEKDAY)
                          .filter((currCode) => {
                            return (
                              // Already in the list
                              daysOfWeek.indexOf(currCode) >= 0
                              // This is the code we're adding
                              || code === currCode
                            );
                          })
                      );
                    }

                    // Save
                    onChange(newDaysOfWeek);
                  }}
                />
              );

              // Add to appropriate list
              if (code === 'S' || code === 'U') {
                dayItemsWeekend.push(item);
              } else {
                dayItemsWeek.push(item);
              }
            });

            return (
              <div id="AttendanceDayFilter-drawer">
                {/* Title */}
                <h5>
                  Choose days of the week:
                </h5>

                {/* List of days */}
                <div className="AttendanceDayFilter-scrollpane alert alert-light text-dark mb-1">
                  <div className="mb-1">
                    {dayItemsWeek}
                  </div>
                  <div>
                    {dayItemsWeekend}
                  </div>
                </div>

                {/* Hide button */}
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    aria-label="apply and hide weekday selection"
                    onClick={() => {
                      this.setState({
                        expanded: false,
                      });
                    }}
                  >
                    Done (hide filter)
                  </button>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

AttendanceDayFilter.propTypes = {
  /**
   * Handler for changes
   * @param {string[]} newDaysOfWeek - new list of days of the week
   */
  onChange: PropTypes.func.isRequired,
  // Days of week (codes, empty list means all)
  daysOfWeek: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AttendanceDayFilter;
