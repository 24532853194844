import React from 'react';

// Import FontAwesome
import {
  faCog,
  faSave,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to edit an event (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Edit an Event',
  steps: [
    (
      <div>
        Find the event in the list and click its
        {' '}
        <ExampleButton
          icon={faCog}
          variant="secondary"
          explanation="edit event button"
        />
        {' '}
        button
      </div>
    ),
    (
      <div>
        Make the desired changes
      </div>
    ),
    (
      <div>
        Click
        {' '}
        <ExampleButton
          icon={faSave}
          text="Save Changes"
          variant="dark"
        />
      </div>
    ),
  ],
  endMessage: 'You\'re done!',
};
