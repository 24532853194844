// Sort order
const VIEW_SORT_ORDER = [
  'Shared Screen with Speaker (CC)',
  'Shared Screen with Speaker',
  'Shared Screen with Gallery',
  'Active Speaker',
  'Just the Speaker',
  'Just the Gallery',
  'Just the Shared Screen',
  'Other View',
];

/**
 * Pre-process recordings
 * @author Gabe Abrams
 * @param {RecordingPair[]} recordingPairs - the recording pairs to process
 * @return {RecordingPair[]} processed recording pairs
 */
export default (recordings) => {
  // Order the recording views
  return recordings.map((recordingPair) => {
    const { recording } = recordingPair;
    const { otherPlayURLs } = recording;

    // Sort the otherPlayURLs
    const orderedPlayURLs = {};
    const urlTitles = Object.keys(otherPlayURLs);
    urlTitles.sort((a, b) => {
      // Figure out the sort order indexes
      let aOrder;
      let bOrder;
      for (let i = 0; i < VIEW_SORT_ORDER.length; i++) {
        if (a.includes(VIEW_SORT_ORDER[i])) {
          aOrder = i;
        }
        if (b.includes(VIEW_SORT_ORDER[i])) {
          bOrder = i;
        }
      }

      // Sort by index
      if (aOrder < bOrder) {
        return -1;
      }
      if (aOrder > bOrder) {
        return 1;
      }
      return 0;
    });

    // Add ordered play URLs in their order
    urlTitles.forEach((key) => {
      const value = otherPlayURLs[key];
      orderedPlayURLs[key] = value;
    });

    // Replace the otherPlayURLs
    const newRecordingPair = recordingPair;
    newRecordingPair.recording.otherPlayURLs = orderedPlayURLs;
    return newRecordingPair;
  });
};
