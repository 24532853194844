/**
 * Visualizer for attendance
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileVideo,
  faSignInAlt,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';

// Import constants
import ATTENDANCE_METHODS from '../../../../constants/ATTENDANCE_METHODS';

// Import shared components
import FadedTitleContainer from '../../../../shared/FadedTitleContainer';

// Import other components
import AttendanceMethodTooltip from './AttendanceMethodTooltip';

// Import style
import './style.css';

/* -------------------------- Component ------------------------- */

class AttendanceTableBody extends Component {
  /**
   * Render AttendanceTable
   * @author Gabe Abrams
   */
  render() {
    const {
      valueLists,
      valueWeightMap,
      sortBy,
      rowInfos,
      dateKeys,
    } = this.props;

    /* -------------- Create packaged row info objects -------------- */

    const rowPacks = valueLists.map((rowValueList, i) => {
      return {
        rowInfo: rowInfos[i],
        valueList: rowValueList,
      };
    });

    /* --------------------------- Sorting -------------------------- */

    if (sortBy) {
      // Sort by date column
      rowPacks.sort((a, b) => {
        const aRowId = a.rowInfo.id;
        const bRowId = b.rowInfo.id;
        const aWeight = valueWeightMap[aRowId][sortBy];
        const bWeight = valueWeightMap[bRowId][sortBy];

        return (bWeight - aWeight); // Descending
      });
    } else {
      // Sort by name ASCENDING
      rowPacks.sort((a, b) => {
        const aRowName = a.rowInfo.name;
        const bRowName = b.rowInfo.name;
        return (aRowName.localeCompare(bRowName));
      });
    }

    /* ------------------------- Create rows ------------------------ */

    const dataRows = rowPacks.map((pack, i) => {
      const {
        valueList,
        rowInfo,
      } = pack;

      // Create cells
      const cells = valueList.map((value, j) => {
        const dateKey = dateKeys[j];

        let contents;
        let bgVariant;
        if (typeof value === 'number') {
          // Number in attendance
          contents = (
            <div>
              {value}
            </div>
          );
          bgVariant = 'info';
        } else if (value === null) {
          // Not in attendance
          contents = (
            <div className="sr-only">
              Not in Attendance
            </div>
          ); // Blank cell
        } else {
          // In attendance
          contents = (
            <div>
              <AttendanceMethodTooltip
                tooltipText={(
                  value === ATTENDANCE_METHODS.LIVE
                    ? 'Joined Live'
                    : 'Watched Recording'
                )}
                icon={(
                  value === ATTENDANCE_METHODS.LIVE
                    ? faSignInAlt
                    : faFileVideo
                )}
              />
            </div>
          );
          bgVariant = 'info';
        }

        return (
          <td
            key={dateKey}
            className={`${bgVariant ? `bg-${bgVariant}` : ''} text-light`}
          >
            {contents}
          </td>
        );
      });

      // Create row
      const {
        name,
        id,
        icon,
      } = rowInfo;

      return (
        <tr key={id}>
          { /* A row consists of one header and data cells */ }
          <th
            scope="row"
            key={id}
            className="AttendanceTableBody-header-column-cell text-nowrap text-left font-weight-bold align-items-center"
            style={{ zIndex: 100 }}
          >
            <FadedTitleContainer
              fadeWidthPx={30}
              alertVariant={(i % 2 === 0) ? 'light' : 'secondary'}
            >
              <div
                className="AttendanceTableBody-icon d-inline-block text-center mr-2"
                title={(
                  icon === faUserGraduate
                  // TODO: come up with more robust way of titling this
                    ? 'Student'
                    : 'Teaching Team Member'
                )}
              >
                <FontAwesomeIcon
                  icon={icon}
                />
              </div>
              {name}
            </FadedTitleContainer>
          </th>
          {cells}
        </tr>
      );
    });

    /* --------------------------- Return --------------------------- */

    return (
      <tbody>
        {dataRows}
      </tbody>
    );
  }
}

AttendanceTableBody.propTypes = {
  // Map of row data
  rowInfos: PropTypes.arrayOf(PropTypes.any).isRequired,
  // Array of Arrays of String, null, or numbers
  valueLists: PropTypes.arrayOf(PropTypes.node).isRequired,
  // Values for comparator
  valueWeightMap: PropTypes.objectOf(PropTypes.object).isRequired,
  // Array of date string
  dateKeys: PropTypes.arrayOf(PropTypes.node).isRequired,
  // Column to sort by
  sortBy: PropTypes.string,
};

AttendanceTableBody.defaultProps = {
  // SortBy null by default, which is name ASC
  sortBy: null,
};

export default AttendanceTableBody;
