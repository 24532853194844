/**
 * Text-based tutorials and a UI to show/hide tutorials
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import TabBox from '../../shared/TabBox';

// Import other components
import TextBasedTutorialBox from './TextBasedTutorialBox';
import TextBasedTutorialStep from './TextBasedTutorialStep';
import TextBasedTutorialWarning from './TextBasedTutorialWarning';
import tutorials from './tutorials';

// Import style
import './style.css';

class TextBasedTutorials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Which tutorial is selected, if any
      selectedTutorial: null,
    };
  }

  /**
   * Render TextBasedTutorials
   * @author Gabe Abrams
   */
  render() {
    const { selectedTutorial } = this.state;

    return (
      <TabBox title="How to...">
        <div className="pl-2">
          {
            tutorials
              .map((tutorial) => {
                const {
                  title,
                  startWarning,
                  steps,
                  endMessage,
                } = tutorial;

                return (
                  <TextBasedTutorialBox
                    key={title}
                    title={title}
                    selectedTutorial={selectedTutorial}
                    onSelect={(newSelectedTutorial) => {
                      this.setState({
                        selectedTutorial: newSelectedTutorial,
                      });
                    }}
                  >
                    {/* Start Warning */}
                    {startWarning && (
                      <TextBasedTutorialWarning>
                        {startWarning}
                      </TextBasedTutorialWarning>
                    )}

                    {/* Steps */}
                    {/* We're customizing list style, which may trick */}
                    {/* screen readers. Thus, we add a role. */}
                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                    <ol className="TextBasedTutorials-step-list" role="list">
                      {
                        steps.map((step) => {
                          return (
                            <TextBasedTutorialStep key={step}>
                              {step}
                            </TextBasedTutorialStep>
                          );
                        })
                      }
                    </ol>

                    {/* End Message */}
                    {endMessage && (
                      <div className="d-flex">
                        {/* Check Mark */}
                        <div className="TextBasedTutorials-finish-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                          />
                        </div>
                        {/* Message Contents */}
                        <div>
                          {endMessage}
                        </div>
                      </div>
                    )}
                  </TextBasedTutorialBox>
                );
              })
          }
        </div>
      </TabBox>
    );
  }
}

export default TextBasedTutorials;
