/**
 * Emergency auth shutoff switch panel
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faRadiationAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import Modal from '../shared/Modal';
import ErrorAlert from '../shared/ErrorAlert';

// Constants
const AUTO_REFRESH_MS = 5000; // 5 seconds
const APPLY_TIME_MS = 20000; // 20 seconds (15s cache + 5s refresh)

// Confirmation text
const CONFIRMATION_TEXT = 'I know what I am doing';

class EmergencyAuthSwitch extends Component {
  /**
   * Create a new instance of the auth bypass switch panel
   * @author Gabe Abrams
   */
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error message if one has occurred
      fatalErrorMessage: null,
      // True if auth is on
      authIsOn: null,
      // Percent finished of change application (if null, don't show progress)
      applicationProgressPercent: null,
      // True if showing a confirmation screen for turning off auth
      confirmingTurnOffAuth: false,
      // Confirmation text input
      confirmationTextInput: '',
    };
  }

  /**
   * Start the regular loading process
   * @author Gabe Abrams
   */
  async componentDidMount() {
    this.mounted = true;

    // Start the update process
    this.performUpdate();
  }

  /**
   * Record that the component is unmounted
   * @author Gabe Abrams
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Update auth to be turned on or off
   * @author Gabe Abrams
   * @param {boolean} authIsOn - true if auth is being turned on
   */
  async setAuthStatus(authIsOn) {
    // Show loading indicator
    this.setState({
      loading: true,
      confirmingTurnOffAuth: false,
    });

    // Send update to server
    try {
      await visitServerEndpoint({
        method: 'PUT',
        path: '/api/admin/auth/on',
        params: {
          authIsOn,
        },
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: `We couldn't change auth due to an error: ${err.message}`,
      });
    }

    // Show the progress indicator
    for (let i = 1; i <= 100; i++) {
      // Wait for 1 percent of progress
      await new Promise((r) => {
        setTimeout(r, APPLY_TIME_MS / 100);
      });

      // Update state
      this.setState({
        loading: false,
        applicationProgressPercent: i,
      });
    }

    // Show the progress bar for another moment
    await new Promise((r) => {
      setTimeout(r, 500);
    });

    // Hide progress
    this.setState({
      applicationProgressPercent: null,
    });
  }

  /**
   * Perform an update
   * @author Gabe Abrams
   */
  async performUpdate() {
    if (!this.mounted) {
      return;
    }

    try {
      // Get status
      const authIsOn = await visitServerEndpoint({
        method: 'GET',
        path: '/api/admin/auth/on',
      });

      // Save to state
      this.setState({
        authIsOn,
        loading: false,
      });

      // Wait and then do the next update
      await new Promise((r) => {
        setTimeout(r, AUTO_REFRESH_MS);
      });
      this.performUpdate();
    } catch (err) {
      this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Render EmergencyAuthSwitch
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct state
    const {
      loading,
      fatalErrorMessage,
      authIsOn,
      applicationProgressPercent,
      confirmingTurnOffAuth,
      confirmationTextInput,
    } = this.state;

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    // Application of change spinner
    if (applicationProgressPercent) {
      return (
        <div className="alert alert-light text-dark">
          <h2 className="mb-0">
            Applying Change
          </h2>
          <p className="lead mb-3">
            Don&apos;t leave this page!
          </p>

          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
              role="progressbar"
              aria-label="progress bar for change application"
              aria-valuenow={applicationProgressPercent}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${applicationProgressPercent}%` }}
            />
          </div>
        </div>
      );
    }

    // Modal
    let modal;
    if (confirmingTurnOffAuth) {
      const confirmed = (
        confirmationTextInput.trim().toLowerCase()
        === CONFIRMATION_TEXT.toLowerCase()
      );
      modal = (
        <Modal
          title="Turn off auth?"
          body={(
            <div>
              {/* Warning */}
              <div className="text-danger">
                <p className="lead m-0">
                  Once auth is off...
                </p>
                <ul>
                  <li>
                    All DCE classes will be at risk!
                  </li>
                  <li>
                    Attendance will not be taken.
                  </li>
                  <li>
                    Anyone, anywhere can join an event by
                    visiting its shareable link!
                  </li>
                  <li>
                    Bans (e.g. FAS students cannot attend) will not be enforced.
                  </li>
                  <li>
                    ASAP, an admin must return to this page to re-enable auth.
                  </li>
                </ul>
              </div>

              {/* Confirmation Text */}
              {!confirmed && (
                <div className="mt-3">
                  <p className="lead text-center mb-0">
                    If you know what you&apos;re doing,
                    type &quot;
                    {CONFIRMATION_TEXT}
                    &quot; in the box below:
                  </p>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Confirm:
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      aria-label={`type the text "${CONFIRMATION_TEXT}" into this box if you know what you are doing`}
                      value={confirmationTextInput}
                      onChange={(e) => {
                        const newValue = e.target.value;

                        // Detect copy/paste
                        if (
                          newValue.length > confirmationTextInput.length
                          && newValue.length > confirmationTextInput.length + 1
                        ) {
                          // Paste detected!
                          // eslint-disable-next-line no-alert
                          alert('No cheating! Do not copy/paste.');
                          return this.setState({
                            confirmationTextInput: '',
                          });
                        }

                        // Update
                        this.setState({
                          confirmationTextInput: newValue,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {confirmed && (
                <div className="mt-4">
                  <button
                    type="button"
                    className="btn btn-lg btn-danger"
                    aria-label="turn off auth across all of Gather"
                    onClick={() => {
                      this.setAuthStatus(false);
                    }}
                  >
                    Click to Turn Off Auth
                  </button>
                </div>
              )}
            </div>
          )}
          onClose={() => {
            this.setState({
              confirmingTurnOffAuth: false,
            });
          }}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    return (
      <div>
        {modal}
        <h2 className="mb-3">
          Emergency Auth Bypass Switch
        </h2>

        <div className="alert alert-light text-dark mb-4">
          <p className="lead font-weight-normal m-0">
            Current auth status:
          </p>

          {/* Auth Status Indicator */}
          {authIsOn && (
            <h1 className="text-success">
              <FontAwesomeIcon
                icon={faLock}
                className="mr-2"
              />
              <strong>
                Gather is Fully Secured
              </strong>
              {' '}
              (Auth is On)
            </h1>
          )}
          {!authIsOn && (
            <h1 className="text-danger font-weight-bold">
              <FontAwesomeIcon
                icon={faRadiationAlt}
                className="mr-2"
              />
              Gather Auth is OFF!
            </h1>
          )}
        </div>

        <div className="alert alert-light text-dark">
          {/* Warning */}
          <p className="lead font-weight-normal mb-1">
            <strong>
              Careful!
            </strong>
            {' '}
            The button below toggles authentication
            and authorization for all of Gather.
            {' '}
            <strong>
              Do not click it
            </strong>
            {' '}
            unless you know what you are doing!
          </p>

          {/* Toggler */}
          <div className="mt-3">
            {authIsOn && (
              <button
                type="button"
                className="btn btn-lg btn-danger"
                aria-label="turn off auth across all of Gather"
                onClick={() => {
                  this.setState({
                    confirmingTurnOffAuth: true,
                    confirmationTextInput: '',
                  });
                }}
              >
                Click to Turn Off Auth
              </button>
            )}
            {!authIsOn && (
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-lg btn-dark"
                  aria-label="turn auth back on for all of Gather"
                  onClick={() => {
                    this.setAuthStatus(true);
                  }}
                >
                  Turn Auth Back On
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EmergencyAuthSwitch;
