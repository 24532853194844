/**
 * An alert showing an error
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRedo } from '@fortawesome/free-solid-svg-icons';

class ErrorAlert extends Component {
  /**
   * Render ErrorAlert
   * @author Gabe Abrams
   */
  render() {
    const {
      message,
      code,
      showReloadButton,
    } = this.props;

    // Get link to start over
    const reloadButton = (
      (showReloadButton && window.location.href.includes('/courses/'))
        ? (
          <a
            className="btn btn-secondary btn-sm"
            aria-label="dismiss error and restart"
            target="_self"
            href={window.location.href}
          >
            <FontAwesomeIcon
              icon={faRedo}
              className="mr-2"
            />
            Start Over
          </a>
        )
        : null
    );

    return (
      <div className="alert alert-warning text-center text-dark">
        {/* Error Message */}
        <p className="lead m-0">
          <span className="font-weight-bold">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2"
            />
            Oops!
          </span>
          {' '}
          {message}
        </p>

        {/* Error Code */}
        {code && (
          <div className="text-center">
            <div
              className="badge badge-light"
              style={{ color: 'inherit' }}
            >
              <strong>
                Error Code:
              </strong>
              {' '}
              {code}
            </div>
          </div>
        )}

        {/* Reload Button */}
        {reloadButton && (
          <div className="text-center mt-2">
            {reloadButton}
          </div>
        )}
      </div>
    );
  }
}

ErrorAlert.propTypes = {
  // Message
  message: PropTypes.string.isRequired,
  // Error code
  code: PropTypes.string,
  // If true, include a "reload" button
  showReloadButton: PropTypes.bool,
};

ErrorAlert.defaultProps = {
  // No code by default
  code: null,
  // No restart button
  showReloadButton: false,
};

export default ErrorAlert;
