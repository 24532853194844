/**
 * COMPONENT_DESCRIPTION_HERE
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextBasedTutorialStep extends Component {
  /**
   * Render TextBasedTutorialStep
   * @author Gabe Abrams
   */
  render() {
    const {
      children,
    } = this.props;

    return (
      <li>
        {children}
      </li>
    );
  }
}

TextBasedTutorialStep.propTypes = {
  // Description of the step
  children: PropTypes.node.isRequired,
};

export default TextBasedTutorialStep;
