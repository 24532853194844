import PropTypes from 'prop-types';

export default PropTypes.shape({
  // Event id (unique to each class)
  ihid: PropTypes.string.isRequired,
  // Human-readable name of the event
  name: PropTypes.string.isRequired,
  // True if archived
  archived: PropTypes.bool,
  // Event type
  type: PropTypes.oneOf([
    'class',
    'section',
    'lab',
    'office-hours',
    'meeting',
    'event',
    'other',
  ]).isRequired,
  // The current ZoomId if there is one
  currentZoomId: PropTypes.number,
  // The current host id if there is one
  currentZoomHost: PropTypes.string,
  // Link to open Zoom (must have password embedded)
  openZoomLink: PropTypes.string,
  // List of past Zoom meeting ids
  pastZoomIds: PropTypes.arrayOf(PropTypes.number),
  // Lis tof past Zoom host ids
  pastZoomHosts: PropTypes.arrayOf(PropTypes.string),
  // Window of when users can get points for this event
  pointWindow: PropTypes.oneOf([
    'day',
    'week',
    'once',
  ]),
  // Days of the week that the event occurs
  daysOfWeek: PropTypes.arrayOf(PropTypes.oneOf([
    'M',
    'T',
    'W',
    'R',
    'F',
    'S',
    'U',
  ])),
  // Maximum number of points allowed
  maxPoints: PropTypes.number,
  // If true, TTMs cannot toggle whether the event is held in Zoom
  // (cannot add or remove Zoom)
  lockZoomToggle: PropTypes.bool,
  // If true, TTMs cannot edit Zoom auto record setting
  lockAutoRecordSetting: PropTypes.bool,
});
