/**
 * Allows user to filter by arrival time
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { faClock } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import ExpandableDrawer from '../../../shared/ExpandableDrawer';

// Constants
const MIN_WINDOW_SIZE = 1; // hours
const STEP_SIZE = 0.25; // hours

// Create time options
const TIME_OPTIONS = [];
const EARLIEST_HOUR = 1; // Start at 1am
const LATEST_HOUR = 24; // End at midnight
for (let i = EARLIEST_HOUR; i <= LATEST_HOUR; i += STEP_SIZE) {
  // Calculate hours
  const hours = Math.floor(i);
  // Calculate minutes
  let minutes = String((i % 1) * 60);
  const onTheHour = (i % 1 === 0);
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  // Calculate AM/PM and hours12
  const ampm = ((hours >= 12 && hours < 24) ? 'pm' : 'am');
  let hours12 = (hours % 12);
  if (hours12 === 0) {
    hours12 = 12;
  }

  // Create label
  let suffix = '';
  if ((hours % 24) === 0 && onTheHour) {
    suffix = ' (midnight)';
  } else if (hours === 12 && onTheHour) {
    suffix = ' (noon)';
  }
  const label = `${hours12}:${minutes} ${ampm}${suffix}`;

  // Add to list of options
  TIME_OPTIONS.push({
    label,
    hours,
    minutes,
    onTheHour,
    value: i,
  });
}

class AttendanceTimeFilter extends Component {
  /**
   * Render AttendanceTimeFilter
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      onChange,
      earliestTime,
      latestTime,
    } = this.props;

    // Create early time options
    const earlyTimeOptions = [];
    TIME_OPTIONS
      // Filter out minimum window size
      .filter((option) => {
        return (option.value <= (LATEST_HOUR - MIN_WINDOW_SIZE));
      })
      .forEach((option) => {
        // Add spacer
        if (option.onTheHour && option.value > EARLIEST_HOUR) {
          earlyTimeOptions.push(
            <option
              key={`spacer-${option.value}`}
              disabled
            >
              ––––––––––––––––
            </option>
          );
        }

        // Add item
        earlyTimeOptions.push(
          <option
            key={option.value}
            value={option.value}
            id={`AttendanceTimeFilter-earliest-time-${option.hours}-${option.minutes}`}
          >
            {option.label}
          </option>
        );
      });

    // Create latest time options
    const latestTimeOptions = [];
    TIME_OPTIONS
      // Filter out minimum window size
      .filter((option) => {
        return (option.value >= (EARLIEST_HOUR + MIN_WINDOW_SIZE));
      })
      .forEach((option) => {
        // Add spacer
        if (
          option.onTheHour
          && option.value > EARLIEST_HOUR + MIN_WINDOW_SIZE
        ) {
          latestTimeOptions.push(
            <option
              key={`spacer-${option.value}`}
              disabled
            >
              ––––––––––––––––
            </option>
          );
        }

        // Add item
        latestTimeOptions.push(
          <option
            key={option.value}
            value={option.value}
            id={`AttendanceTimeFilter-latest-time-${option.hours}-${option.minutes}`}
          >
            {option.label}
          </option>
        );
      });

    return (
      <div className="mb-2">
        <ExpandableDrawer
          icon={faClock}
          title="Time"
          noMarginOnBottom
          contents={(
            <div>
              {/* Earliest Time */}
              <span className="mr-3">
                {/* Responsive Text */}
                <span className="d-inline d-md-none">
                  Start:&nbsp;
                </span>
                <span className="d-none d-md-inline">
                  Start Time:&nbsp;
                </span>

                {/* Selector */}
                <select
                  id="AttendanceTimeFilter-earliest-time-selector"
                  className="form-select border-secondary"
                  style={{
                    /* Use a button-style look */
                    '-webkit-appearance': 'menulist-button',
                    /* Increase the height to match other filters */
                    height: '31px',
                  }}
                  aria-label="Arrival time filter: earliest time to include (minus one hour)"
                  value={earliestTime}
                  onChange={(e) => {
                    const newEarliestTime = Number.parseFloat(e.target.value);

                    // Adjust latest time if needed
                    const newLatestTime = (
                      (latestTime < newEarliestTime + MIN_WINDOW_SIZE)
                        ? newEarliestTime + MIN_WINDOW_SIZE // Adhere to window
                        : latestTime
                    );

                    onChange(newEarliestTime, newLatestTime);
                  }}
                >
                  {earlyTimeOptions}
                </select>
              </span>

              {/* Spacer for small screens */}
              <div className="d-block d-md-none">
                <div className="mb-1" />
              </div>

              {/* Latest Time */}
              <span>
                {/* Responsible Text */}
                <span className="d-inline d-md-none">
                  End:&nbsp;
                </span>
                <span className="d-none d-md-inline">
                  End Time:&nbsp;
                </span>

                {/* Selector */}
                <select
                  id="AttendanceTimeFilter-latest-time-selector"
                  className="form-select border-secondary"
                  style={{
                    /* Use a button-style look */
                    '-webkit-appearance': 'menulist-button',
                    appearance: 'menulist-button',
                    /* Increase the height to match other filters */
                    height: '31px',
                  }}
                  aria-label="Arrival time filter: latest time to include"
                  value={latestTime}
                  onChange={(e) => {
                    const newLatestTime = Number.parseFloat(e.target.value);

                    // Adjust earliest time if needed
                    const newEarliestTime = (
                      (earliestTime > newLatestTime - MIN_WINDOW_SIZE)
                        ? newLatestTime - MIN_WINDOW_SIZE // Adhere to window
                        : earliestTime
                    );

                    onChange(newEarliestTime, newLatestTime);
                  }}
                >
                  {latestTimeOptions}
                </select>
              </span>
            </div>
          )}
        />
        <div className="small text-muted">
          We also include people who joined up to 1 hour before the start time.
        </div>
      </div>
    );
  }
}

AttendanceTimeFilter.propTypes = {
  /**
   * Handler to call when change occurs
   * @param {number} newEarliestTime - new early time value
   * @param {number} newLatestTime - new latest time value
   */
  onChange: PropTypes.func.isRequired,
  // Earliest time (hours with fraction for minutes)
  earliestTime: PropTypes.number.isRequired,
  // Latest time (hours with fraction for minutes)
  latestTime: PropTypes.number.isRequired,
};

AttendanceTimeFilter.EARLIEST_HOUR = EARLIEST_HOUR;
AttendanceTimeFilter.LATEST_HOUR = LATEST_HOUR;

export default AttendanceTimeFilter;
