/**
 * A lock that appears on the top right of a button, indicating that the
 *   button represents a locked feature. For this to work, the parent must
 *   be position: relative
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

// Import styles
import './ButtonLock.css';

class ButtonLock extends Component {
  /**
   * Render ButtonLock
   * @author Gabe Abrams
   */
  render() {
    return (
      <div className="bg-dark text-warning ButtonLock-container">
        <FontAwesomeIcon
          icon={faLock}
          className="ButtonLock-icon"
        />
      </div>
    );
  }
}

export default ButtonLock;
