/**
 * List of tutorial videos
 * @author Gabe Abrams
 */
export default [
  {
    title: 'Overview Video',
    durationSec: 180,
    youTubeId: 'hfXpCXNheQU',
    taller: true,
  },
  {
    title: 'Study Lounge',
    durationSec: 200,
    youTubeId: 'IF1VMsMbD1M',
  },
];
