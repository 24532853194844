/**
 * Faded text title (not used  in the event list, those are customized)
 *   NOTE: parent must be a div with "overflow: hidden"
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FadedTitleContainer extends Component {
  /**
   * Render FadedTitleContainer
   * @author Gabe Abrams
   */
  render() {
    const {
      children,
      fadeHeightPx,
      fadeWidthPx,
      alertVariant,
    } = this.props;

    // Create the background image based on the variant
    let rgb;
    if (alertVariant === 'dark') {
      rgb = '214, 216, 217';
    } else if (alertVariant === 'warning') {
      rgb = '255, 243, 205';
    } else if (alertVariant === 'light') {
      rgb = '254, 254, 254';
    } else {
      // Assume "secondary"
      rgb = '226, 227, 229';
    }
    const backgroundImage = `linear-gradient(to right, rgba(${rgb}, 0), rgba(${rgb}, 1))`;

    return (
      <div
        className="d-flex"
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {/* Text */}
        <div
          className="flex-grow-1"
          style={{
            whiteSpace: 'nowrap',
            position: 'relative',
          }}
        >
          {children}
        </div>

        {/* Fader */}
        <div
          style={{
            width: 0, // Take up no space (overlap with text)
            height: 0,
            pointerEvents: 'none', // Pass through mouse events
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 10000,
          }}
        >
          <div
            style={{
              backgroundImage,
              width: `${fadeWidthPx}px`,
              height: `${fadeHeightPx}px`,
              position: 'relative',
              transform: `translate(-${fadeWidthPx}px, 0)`,
            }}
          />
        </div>
      </div>
    );
  }
}

FadedTitleContainer.propTypes = {
  // Contents
  children: PropTypes.node.isRequired,
  // Width of the fade
  fadeWidthPx: PropTypes.number,
  // Height of the fade
  fadeHeightPx: PropTypes.number,
  // Variant color of the fade (alert background)
  // currently supported: secondary, dark, light, warning
  alertVariant: PropTypes.string,
};

FadedTitleContainer.defaultProps = {
  // Default sizing
  fadeWidthPx: 80,
  fadeHeightPx: 40,
  // Secondary alert background
  alertVariant: 'secondary',
};

export default FadedTitleContainer;
