/**
 * Create Event Badges component for a Prospective Event
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import prop element
import ProspectiveEvent from '../helpers/ProspectiveEvent';

/* ---------- Constants --------- */
const BADGE_MAP = {
  LOCK_AUTO_RECORD_SETTING: {
    color: 'info',
    text: 'Lock Auto Record',
    name: 'lock-auto-record-setting',
  },
  AUTO_RECORD: {
    color: 'success',
    text: 'Auto Record',
    name: 'auto-record',
  },
  BAN_DCE: {
    color: 'danger',
    text: 'Ban DCE',
    name: 'ban-dce',
  },
  BAN_FAS: {
    color: 'danger',
    text: 'Ban FAS',
    name: 'ban-fas',
  },
  WAITING_ROOM: {
    color: 'primary',
    text: 'Waiting Room',
    name: 'waiting-room',
  },
  EMERGENCY_EVENT: {
    color: 'warning',
    text: 'Emergency Event',
    name: 'emergency-event',
  },
  ENSURE_LOUNGE_EXISTS: {
    color: 'secondary',
    text: 'Ensure Lounge',
    name: 'ensure-lounge',
  },
  HOST_VIDEO_DISABLED: {
    color: 'danger',
    text: 'Host Video Disabled',
    name: 'host-video-disabled',
  },
  IS_WEBINAR: {
    color: 'warning',
    text: 'Webinar',
    name: 'webinar',
  },
};

/**
 * Helper to make a single badge based on badge type
 * @author Karen Dolan
 * @param {object} badgeType - the type of badge to make
 * @param {string} crn - the course CRN for element id
 * @return {Node} the html fragement of the badge
 */
const makeBadge = (badgeType, crn) => {
  return (
    <span
      className={`EventBadges-badge-crn-${crn} EventBadges-badge-type-${badgeType.name} badge badge-pill badge-${badgeType.color} mr-2`}
      id={`EventBadges-badge-crn-${crn}-type-${badgeType.name}`}
      key={badgeType.text}
    >
      {badgeType.text}
    </span>
  );
};

/**
 * Helper to create badge html based on settings in the event
 * @author Karen Dolan
 * @param {ProspectiveEvent} pEvent - the event with settings
 * @return {Node[]} array of badge html or empty array
 */
const getBadge = (pEvent) => {
  // Iterate over badge options and add if needed
  const badges = [];
  const crn = pEvent.getCRN();
  if (pEvent.isAutoRecordOn()) {
    badges.push(makeBadge(BADGE_MAP.AUTO_RECORD, crn));
  }
  if (pEvent.isWaitingRoomOn()) {
    badges.push(makeBadge(BADGE_MAP.WAITING_ROOM, crn));
  }
  if (pEvent.isLockAutoRecordSettingOn()) {
    badges.push(makeBadge(BADGE_MAP.LOCK_AUTO_RECORD_SETTING, crn));
  }
  if (pEvent.isDCEBanOn()) {
    badges.push(makeBadge(BADGE_MAP.BAN_DCE, crn));
  }
  if (pEvent.isFASBanOn()) {
    badges.push(makeBadge(BADGE_MAP.BAN_FAS, crn));
  }
  if (pEvent.isEmergencyEvent()) {
    badges.push(makeBadge(BADGE_MAP.EMERGENCY_EVENT, crn));
  }
  if (pEvent.isEnsuringLoungeExists()) {
    badges.push(makeBadge(BADGE_MAP.ENSURE_LOUNGE_EXISTS, crn));
  }
  if (pEvent.isHostVideoDisabled()) {
    badges.push(makeBadge(BADGE_MAP.HOST_VIDEO_DISABLED, crn));
  }
  if (pEvent.isWebinar()) {
    badges.push(makeBadge(BADGE_MAP.IS_WEBINAR, crn));
  }
  return badges;
};

class EventBadges extends Component {
  /**
   * The Event Badges component
   * @author Karen Dolan
   */
  render() {
    const { pEvent, showSettings } = this.props;

    return (
      <div className="EventBadges-container">
        {showSettings && getBadge(pEvent)}
      </div>
    );
  }
}

EventBadges.propTypes = {
  // The Prospective event to preview
  pEvent: PropTypes.instanceOf(ProspectiveEvent).isRequired,
  // show Prospective event settings
  showSettings: PropTypes.bool.isRequired,
};

export default EventBadges;
