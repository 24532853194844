/**
 * A decorative lounge table
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';

// Import style
import './LoungeTable.css';

class LoungeTable extends Component {
  /**
   * Render LoungeTable
   * @author Gabe Abrams
   */
  render() {
    const {
      top,
      left,
      bottom,
      right,
    } = this.props;

    return (
      <div
        className="LoungeTable-container"
        style={{
          top: (top ? `${top}px` : undefined),
          left: (left ? `${left}px` : undefined),
          bottom: (bottom ? `${bottom}px` : undefined),
          right: (right ? `${right}px` : undefined),
        }}
      >
        <FontAwesomeIcon
          icon={faStickyNote}
          className="LoungeTable-paper"
        />
      </div>
    );
  }
}

LoungeTable.propTypes = {
  // Position in pixels
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
};

LoungeTable.defaultProps = {
  // No positioning
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
};

export default LoungeTable;
