/**
 * Create a Zoom meeting title
 * @author Gabe Abrams
 * @param {string} eventName - the name of the event
 * @param {string} courseName - the name of the course
 * @param {number} crn - the CRN for the course
 * @param {string} termName - the name of the term the course is in
 */
export default (opts) => {
  const {
    eventName,
    courseName,
    crn,
    termName,
  } = opts;

  // Remove CRN from course name
  const adjustedCourseName = (
    courseName
      // Split into word
      .split(' ')
      // Modify words
      .map((word) => {
        // Remove word if it's the CRN
        if (word.includes(crn)) {
          return '';
        }

        // Leave word unchanged
        return word;
      })
      // Filter out empty words
      .filter((word) => {
        return word.trim().length > 0;
      })
      // Put back into string
      .join(' ')
  );

  // Create a Zoom name with the event name, course name, and CRN
  return `${eventName} [${adjustedCourseName} – ${crn} – ${termName}]`;
};
