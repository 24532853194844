/**
 * Attendance dashboard
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

// Import propTypes
import Event from '../../shared/propTypes/Event';

// Import helpers
import logAction from '../../helpers/logAction';
import eventComparator from '../../helpers/eventComparator';

// Import constants
import USER_ROLES from '../../constants/USER_ROLES';
import ATTENDANCE_METHODS from '../../constants/ATTENDANCE_METHODS';
import EVENT_TYPES_MAP from '../../constants/EVENT_TYPES_MAP';

// Import filters
import AttendanceTimeFilter from './filters/AttendanceTimeFilter';
import AttendanceDayFilter from './filters/AttendanceDayFilter';

// Import shared components
import CSVDownloadButton from '../../shared/CSVDownloadButton';

// Import other components
import AttendanceTable from './AttendanceTable';
import AttendanceEventChooser from './AttendanceEventChooser';

// Import style
import './style.css';

// Dynamic constants
const NUM_EVENT_TYPES = Object.keys(EVENT_TYPES_MAP).length;

/* -------------------------- Component ------------------------- */

class AttendanceDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /*----------------------------------------*/
      /*                  Event                 */
      /*----------------------------------------*/
      // Specific event to filter to
      specificEvent: null,
      // List of event types that are allowed (empty list means all)
      eventTypes: [],
      /*----------------------------------------*/
      /*                  Date                  */
      /*----------------------------------------*/
      // Specific date (key form)
      dateKey: null,
      // Days of week (codes, empty list means all)
      daysOfWeek: [],
      /*----------------------------------------*/
      /*                  Time                  */
      /*----------------------------------------*/
      // Earliest time (hours with fraction for minutes)
      earliestTime: AttendanceTimeFilter.EARLIEST_HOUR,
      // Latest time (hours with fraction for minutes)
      latestTime: AttendanceTimeFilter.LATEST_HOUR,
      /*----------------------------------------*/
      /*                 Method                 */
      /*----------------------------------------*/
      // True if including live attendance
      includeLive: true,
      // True if including async attendance
      includeAsync: false,
      /*----------------------------------------*/
      /*                  Role                  */
      /*----------------------------------------*/
      // True if TTMs included
      includeTTMs: false,
      // True if Students included
      includeStudents: true,
      /*----------------------------------------*/
      /*               Other State              */
      /*----------------------------------------*/
      // True if showing filters
      showFilters: false,
    };
  }

  /**
   * Render AttendanceDashboard
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      events,
      attendanceLogMap,
    } = this.props;
    // Deconstruct state
    const {
      specificEvent,
      eventTypes,
      dateKey,
      daysOfWeek,
      earliestTime,
      latestTime,
      includeLive,
      includeAsync,
      includeTTMs,
      includeStudents,
      showFilters,
    } = this.state;

    // Sort the events
    events.sort(eventComparator);

    /*------------------------------------------------------------------------*/
    /*                             Attendance Logs                            */
    /*------------------------------------------------------------------------*/

    /* --------------------------- Filter --------------------------- */

    // Create event type lookup map
    const ihidToType = {}; // ihid => type
    events.forEach((event) => {
      ihidToType[event.ihid] = event.type;
    });

    // New map with filtered results
    const filteredAttendanceLogMap = {}; // date => ihid => userId => log

    // Loop through dates
    Object.keys(attendanceLogMap).forEach((currDateKey) => {
      // Loop through event IHIDs
      Object.keys(attendanceLogMap[currDateKey]).forEach((ihid) => {
        // Get current event type
        const eventType = ihidToType[ihid];

        // Loop through users
        Object.keys(attendanceLogMap[currDateKey][ihid]).forEach((userId) => {
          const {
            method,
            userRole,
            eventDayOfWeek,
            eventFractionalHour,
          } = attendanceLogMap[currDateKey][ihid][userId];

          // Check if specific date doesn't match
          if (dateKey && currDateKey !== dateKey) {
            return;
          }

          // Check if day of week doesn't match
          if (
            daysOfWeek.length > 0
            && daysOfWeek.length < 7
            && daysOfWeek.indexOf(eventDayOfWeek) < 0
          ) {
            return;
          }

          // Check if specific event doesn't match
          if (specificEvent && specificEvent.ihid !== ihid) {
            return;
          }

          // Check if event type isn't in the list
          if (
            // At least one selected
            eventTypes.length > 0
            // Not all selected
            && eventTypes.length < NUM_EVENT_TYPES
            // Type not in the list
            && eventTypes.indexOf(eventType) < 0
          ) {
            return;
          }

          // Check if method doesn't match
          if (
            (method === ATTENDANCE_METHODS.LIVE && !includeLive)
            || (method === ATTENDANCE_METHODS.ASYNCHRONOUS && !includeAsync)
          ) {
            return;
          }

          // Check if before earliest time (add 1 hour buffer)
          if (earliestTime - 1 > eventFractionalHour) {
            return;
          }

          // Check if after latest time
          if (latestTime < eventFractionalHour) {
            return;
          }

          // Check if role doesn't match
          if (
            (userRole === USER_ROLES.TTM && !includeTTMs)
            || (userRole === USER_ROLES.STUDENT && !includeStudents)
            || (userRole === USER_ROLES.ADMIN)
          ) {
            return;
          }

          // All checks passed! Add entry

          /*----------------------------------------*/
          /*                Add Entry               */
          /*----------------------------------------*/

          // Add parent maps
          if (!filteredAttendanceLogMap[currDateKey]) {
            filteredAttendanceLogMap[currDateKey] = {};
          }
          if (!filteredAttendanceLogMap[currDateKey][ihid]) {
            filteredAttendanceLogMap[currDateKey][ihid] = {};
          }

          // Add log entry
          const logEntry = attendanceLogMap[currDateKey][ihid][userId];
          filteredAttendanceLogMap[currDateKey][ihid][userId] = logEntry;
        });
      });
    });

    /* ------------------------ CSV Download ------------------------ */

    // Create raw data CSV
    const rawDataHeaders = {
      userName: 'User Name',
      userId: 'User Canvas ID',
      userRole: 'User Role',
      inAttendance: 'Attended',
      userWasHost: 'User Joined as Host',
      eventId: 'Event ID',
      eventName: 'Event Name',
      eventDate: 'Occurrence Date',
      method: 'Attendance Method',
      joinTimesString: 'Join Times',
      groupNumber: 'Group Number',
    };

    // Check if there's at least one group number
    // Also add join times string
    let atLeastOneGroupNumber;
    const rawData = [];
    Object.values(filteredAttendanceLogMap).forEach((dateEntry) => {
      Object.values(dateEntry).forEach((eventEntry) => {
        Object.values(eventEntry).forEach((userEntry) => {
          // Add joinTimesString
          const updatedUserEntry = userEntry;
          updatedUserEntry.joinTimesString = (
            userEntry
              .joinTimes
              // Filter out excluded attendance
              .filter((joinTime) => {
                const { method } = joinTime;

                return (
                  // Check live attendance
                  (
                    method === ATTENDANCE_METHODS.LIVE
                    && includeLive // Check if included
                  )
                  // Check async attendance
                  || (
                    method === ATTENDANCE_METHODS.ASYNCHRONOUS
                    && includeAsync // Check if included
                  )
                );
              })
              // Extract descriptions
              .map((joinTime) => {
                return joinTime.description;
              })
              // Join descriptions together
              .join(', ')
          );

          // Add the entry to the list
          rawData.push(updatedUserEntry);

          // Keep track of group number existence
          if (userEntry.groupNumber && userEntry.groupNumber !== 'N/A') {
            atLeastOneGroupNumber = true;
          }
        });
      });
    });

    // Decide whether to include the group number column
    if (!atLeastOneGroupNumber) {
      delete rawDataHeaders.groupNumber;
    }

    const dateStamp = (
      (new Date())
        .toLocaleDateString('en-US')
        .replace(/\//g, '-')
    );
    const rawDataDownloadButton = (
      <CSVDownloadButton
        id="AttendanceDashboard-download-csv-attendance"
        headerMap={rawDataHeaders}
        data={rawData}
        title={(
          <span className="d-none d-sm-inline">
            as CSV
          </span>
        )}
        noMarginOnRight
        filename={`Attendance - Exported on ${dateStamp}.csv`}
        onClick={() => {
          // Log this
          logAction({
            type: 'click',
            description: 'download raw attendance button',
          });
        }}
      />
    );

    let attendanceLogs;
    if (specificEvent) {
      attendanceLogs = (
        <div
          className="text-center position-relative overflow-hidden"
        >
          {/* Attendance Drawer */}
          <div
            className={`alert alert-secondary p-2 mt-0 AttendanceDashboard-drawer AttendanceDashboard-drawer-${specificEvent ? 'open' : 'closed'}`}
            style={{
              borderTop: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <div className="AttendanceDashboard-drawer-inner alert alert-light text-dark m-0">
              <div>
                {/* Title Text */}
                <p className="lead font-weight-bold text-center m-0">
                  Event Attendance:
                </p>

                {/* Buttons */}
                <div className="text-center mb-2">
                  {/* Filters Button */}
                  <button
                    type="button"
                    id="AttendanceDashboard-show-or-hide-filters-button"
                    className="btn btn-secondary mr-2"
                    aria-label={`${showFilters ? 'hide' : 'show'} filters`}
                    onClick={() => {
                      // Toggle showFilters
                      this.setState({
                        showFilters: !showFilters,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFilter}
                      className="mr-2"
                    />
                    {
                      showFilters
                        ? 'Hide'
                        : 'Show'
                    }
                    &nbsp;Filters
                  </button>

                  {/* Download Button */}
                  {rawDataDownloadButton}
                </div>
              </div>

              {/* Filters */}
              {showFilters && (
                <div>
                  <AttendanceDayFilter
                    onChange={(newDaysOfWeek) => {
                      // Update state
                      this.setState({
                        daysOfWeek: newDaysOfWeek,
                      });

                      // Log this
                      if (
                        daysOfWeek.length > 0
                        && daysOfWeek.length < 7
                      ) {
                        // Yes, we're filtering by days of the week
                        logAction({
                          type: 'filter',
                          description: 'attendance day of week filter',
                          metadata: {
                            daysOfWeek: newDaysOfWeek,
                          },
                        });
                      }
                    }}
                    dateKey={dateKey}
                    daysOfWeek={daysOfWeek}
                  />
                  <AttendanceTimeFilter
                    onChange={(newEarliestTime, newLatestTime) => {
                      // Update state
                      this.setState({
                        earliestTime: newEarliestTime,
                        latestTime: newLatestTime,
                      });

                      // Log this
                      logAction({
                        type: 'filter',
                        description: 'attendance time filter',
                        metadata: {
                          earliestTime: newEarliestTime,
                          latestTime: newLatestTime,
                        },
                      });
                    }}
                    earliestTime={earliestTime}
                    latestTime={latestTime}
                  />
                </div>
              )}

              <AttendanceTable
                attendanceLogMap={filteredAttendanceLogMap}
              />
            </div>
          </div>
        </div>
      );
    }

    /*------------------------------------------------------------------------*/
    /*                                 Full UI                                */
    /*------------------------------------------------------------------------*/

    return (
      <div>
        <div className="mb-4">
          {/* Event Chooser */}
          <AttendanceEventChooser
            onChosen={(newSpecificEvent) => {
              // Update state
              this.setState({
                specificEvent: newSpecificEvent,
              });

              // Log this
              if (newSpecificEvent) {
                logAction({
                  type: 'filter',
                  description: 'attendance specific event filter',
                  metadata: {
                    event: {
                      ihid: newSpecificEvent.ihid,
                      name: newSpecificEvent.name,
                      type: newSpecificEvent.type,
                    },
                  },
                });
              }
            }}
            events={events}
            chosenEvent={specificEvent}
          />

          {/* Logs */}
          <div id="AttendanceDashboard-event-analytics">
            {attendanceLogs}
          </div>
        </div>
      </div>
    );
  }
}

AttendanceDashboard.propTypes = {
  // Map of attendance (date => ihid => userId => record)
  attendanceLogMap: PropTypes.objectOf(PropTypes.any).isRequired,
  // List of events
  events: PropTypes.arrayOf(Event).isRequired,
};

export default AttendanceDashboard;
