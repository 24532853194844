/**
 * Display customized agreements language
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCar,
  faComments,
  faLaugh,
  faLeaf,
  faTshirt,
  faUniversity,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';

// Import style
import './VisitLinkAgreements.css';

class VisitLinkAgreements extends Component {
  /**
   * Render VisitLinkAgreements
   * @author Gabe Abrams
   */
  render() {
    const {
      useWebinarAgreements,
      isJoiningLounge,
    } = this.props;

    /*----------------------------------------*/
    /*                 Lounge                 */
    /*----------------------------------------*/

    if (isJoiningLounge) {
      return (
        <div className="VisitLinkAgreements-lounge-agreements alert alert-warning text-dark mb-4">
          <div className="font-weight-bold">
            Code of Conduct:
          </div>
          <div className="text-left mb-2">
            This space has been created to allow for interactions
            between students outside course meeting times.
            In joining this room, you are taking responsibility
            for following
            {' '}
            <a
              href="https://extension.harvard.edu/registration-admissions/for-students/student-policies-conduct/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'underline',
                color: 'inherit',
              }}
            >
              DCE&apos;s code of conduct
            </a>
            {' '}
            and for creating a positive and supportive environment.
            In order to keep this room safe and welcoming for
            everyone, you must agree to the following before
            joining:
          </div>
          <div
            className="d-inline-block text-left"
            style={{
              maxWidth: '600px',
              width: '100%',
            }}
          >
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faUniversity}
                />
              </div>
              <div className="flex-grow-1">
                You will abide by DCE standards of academic integrity
                and the collaboration policies set forth by your class
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faLaugh}
                />
              </div>
              <div className="flex-grow-1">
                You will use appropriate language and treat all classmates
                with respect
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faTshirt}
                />
              </div>
              <div className="flex-grow-1">
                You will dress in clothing you would
                wear to class if you attended in person
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faCar}
                />
              </div>
              <div className="flex-grow-1">
                You will refrain from driving
                a vehicle while in the Study Lounge
              </div>
            </div>
          </div>
        </div>
      );
    }

    /*----------------------------------------*/
    /*                 Webinar                */
    /*----------------------------------------*/

    if (useWebinarAgreements) {
      return (
        <div className="VisitLinkAgreements-webinar-agreements alert alert-warning text-dark mb-4">
          <div className="font-weight-bold mb-2">
            During your HELIX Live Stream class webinar:
          </div>
          <div className="d-inline-block text-left">
            {/* Vehicle */}
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faCar}
                />
              </div>
              <div className="flex-grow-1">
                You refrain from driving or riding
                in a vehicle for the duration of class
              </div>
            </div>
            {/* Camera and Mic */}
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faVideoSlash}
                />
              </div>
              <div className="flex-grow-1">
                Your camera and microphone will not be live
              </div>
            </div>
            {/* Chat */}
            <div className="d-flex mb-1">
              <div className="VisitLinkAgreements-icon">
                <FontAwesomeIcon
                  icon={faComments}
                />
              </div>
              <div className="flex-grow-1">
                Ask your teacher if the text chat will be monitored for
                questions from the campus classroom
              </div>
            </div>
          </div>
        </div>
      );
    }

    /*----------------------------------------*/
    /*             Normal Meeting             */
    /*----------------------------------------*/

    return (
      <div className="VisitLinkAgreements-meeting-agreements alert alert-warning text-dark mb-4">
        <div className="font-weight-bold mb-2">
          To make this the best experience for everyone, please make sure:
        </div>
        <div className="d-inline-block text-left">
          <div className="d-flex mb-1">
            <div className="VisitLinkAgreements-icon">
              <FontAwesomeIcon
                icon={faTshirt}
              />
            </div>
            <div className="flex-grow-1">
              You&apos;re dressed in clothing you would
              wear to class if you attended in person
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="VisitLinkAgreements-icon">
              <FontAwesomeIcon
                icon={faLeaf}
              />
            </div>
            <div className="flex-grow-1">
              You&apos;re in a quiet,
              suitable location that won&apos;t distract your classmates
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="VisitLinkAgreements-icon">
              <FontAwesomeIcon
                icon={faCar}
              />
            </div>
            <div className="flex-grow-1">
              You refrain from driving or riding
              in a vehicle for the duration of class
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VisitLinkAgreements.propTypes = {
  // If true, show agreements for webinars
  useWebinarAgreements: PropTypes.bool,
  // True if user is joining a lounge
  isJoiningLounge: PropTypes.bool,
};

VisitLinkAgreements.defaultProps = {
  // Agreements are for a meeting
  useWebinarAgreements: false,
  // Not joining a lounge
  isJoiningLounge: false,
};

export default VisitLinkAgreements;
