/**
 * Batch creation pane (the parent that handles the high-level logic)
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faEyeSlash,
  faHammer,
  faPlay,
  faStop,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';

// Import helpers
import visitServerEndpoint from '../../helpers/visitServerEndpoint';
import listProspectiveAndSkippedEvents from './helpers/listProspectiveAndSkippedEvents';
import genZoomMeetingTitle from '../../helpers/genZoomMeetingTitle';
import genEmptyEventObj from '../../helpers/genEmptyEventObj';
import genIHID from '../../helpers/genIHID';
import logAction from '../../helpers/logAction';
import logError from '../../helpers/logError';
import genShareableLink from '../../helpers/genShareableLink';

// Import shared components
import LoadingSpinner from '../../shared/LoadingSpinner';
import BatchEventCreationResultsModal from './BatchEventCreationResultsModal';
import BatchCreationFooter from './shared/BatchCreationFooter';

// Import other components
import BatchEventCreatorSetup from './BatchEventCreatorSetup';
import ProspectiveEventPreview from './ProspectiveEventPreview';
import EventScrollpane from './EventScrollpane';
import BatchCreationProgress from './BatchCreationProgress';

// Import constants
import EVENT_STATUSES from './constants/EVENT_STATUSES';
import BATCH_CREATE_SCHOOL from './constants/BATCH_CREATE_SCHOOL';
import ERROR_CODES from '../../constants/ERROR_CODES';
import GATHER_TAGS from './constants/GATHER_TAGS';
import genEmptyLoungeObj from '../../helpers/genEmptyLoungeObj';
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../constants/IN_CASE_OF_EMERGENCY_SUFFIX';

/* -------------------------- Constants ------------------------- */

const STATUSES = {
  // Choose google sheet, event type, etc.
  SETUP: 'setup',
  // Confirm the events that will be skipped
  CONFIRMING_SKIPPED_EVENTS: 'confirming-skipped-events',
  // Confirm the events that will be created
  CONFIRMING_PROSPECTIVE_EVENTS: 'confirming-prospective-events',
  // Currently creating events
  RUNNING: 'running',
  // Finished (halted or done) creating events
  ENDED: 'ended',
};

/* -------------------------- Component ------------------------- */

class BatchEventCreator extends Component {
  /**
   * Create a new instance of the Batch event creator
   * @author Gabe Abrams
   */
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      // True if loading
      loading: true,
      // A fatal error message if one has occurred
      fatalErrorMessage: null,
      // Current status
      status: STATUSES.SETUP,
      // The type of event to create
      eventType: null, // either 'section' or 'class'
      // The calendar year for the courses
      year: null,
      // If true, show the skipped events that already have results
      showSkippedThatAlreadyHaveResults: false,
      // If true, the process was halted
      halted: false,
      // If true, autoscroll is on
      autoscroll: true,
      // List of events to skip
      skippedEvents: null,
      // List of prospective events to create
      prospectiveEvents: null,
      // The id of the event that is current being created
      idOfEventBeingCreated: null,
      // If true, show the results modal
      showResultsPopup: false,
    };
  }

  /**
   * Perform initial load
   * @author Gabe Abrams
   */
  async componentDidMount() {
    // Load the robot email
    try {
      const robotEmail = await visitServerEndpoint({
        path: '/api/admin/sheets/robot/email',
        method: 'GET',
      });

      // Update state
      this.setState({
        robotEmail,
        loading: false,
      });
    } catch (err) {
      // Show the error message
      this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Perform actions that happen after a render
   * @author Gabe Abrams
   */
  componentDidUpdate() {
    const { autoscroll } = this.state;

    if (autoscroll) {
      // Scroll to the current event
      this.scrollToEventBeingCreated();
    }
  }

  /**
   * Get the result numbers
   * @author Gabe Abrams
   * @return {object} results in the form
   *   { numSuccessful, numFailed, numSkipped }
   */
  getResults() {
    const { prospectiveEvents } = this.state;

    // Count the number of successful events
    const numSuccessful = (
      prospectiveEvents
        .filter((event) => {
          return (event.getStatus() === EVENT_STATUSES.SUCCESSFUL);
        })
        .length
    );

    // Count the number of events created with warnings
    const numWarning = (
      prospectiveEvents
        .filter((event) => {
          return (event.getStatus() === EVENT_STATUSES.CREATED_WITH_WARNING);
        })
        .length
    );

    // Count the number of failed events
    const numFailed = (
      prospectiveEvents
        .filter((event) => {
          return (event.getStatus() === EVENT_STATUSES.FAILED);
        })
        .length
    );

    // Calculate the number of skipped events
    const numSkipped = (
      prospectiveEvents.length
      - numSuccessful
      - numWarning
      - numFailed
    );

    // Return the results object
    return {
      numSuccessful,
      numWarning,
      numFailed,
      numSkipped,
    };
  }

  /**
   * Load the list of prospective and skipped events
   * @author Gabe Abrams
   * @param {object} dataStoreInfo - the new data store info to use in load
   *   process
   * @param {string} eventType - the type of event to create
   * @param {number} year - the calendar year for the courses
   */
  async load(dataStoreInfo, eventType, year) {
    // Save the data store info and show the loading indicator
    this.setState({
      eventType,
      year,
      loading: true,
    });

    try {
      // Load the list of skipped and prospective events
      const {
        prospectiveEvents,
        skippedEvents,
      } = await listProspectiveAndSkippedEvents(dataStoreInfo, eventType);

      // Update state
      this.setState({
        prospectiveEvents,
        skippedEvents,
        status: (
          // Check if there are any skipped events
          (skippedEvents && skippedEvents.length > 0)
            ? STATUSES.CONFIRMING_SKIPPED_EVENTS // Confirm skipped events
            : STATUSES.CONFIRMING_PROSPECTIVE_EVENTS // No skipped events
        ),
        // Take away the loading indicator
        loading: false,
      });
    } catch (err) {
      this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Create an event
   * @author Gabe Abrams
   */
  async createEvent(prospectiveEvent) {
    /*----------------------------------------*/
    /*          Set State to Pending          */
    /*----------------------------------------*/

    prospectiveEvent.setResults(EVENT_STATUSES.PENDING);
    try {
      await prospectiveEvent.save();
    } catch (err) {
      // Could not write
      throw new Error('We couldn\'t write to the Google Sheet. Please make sure the Gather robot is an editor.');
    }

    /*----------------------------------------*/
    /*            Helper Functions            */
    /*----------------------------------------*/

    /**
     * Update the prospective event in the state
     * @author Gabe Abrams
     */
    const updateEventInState = async () => {
      this.setState((prevState) => {
        return {
          // Swap in the event in the list
          prospectiveEvents: prevState.prospectiveEvents.map((event) => {
            if (prospectiveEvent.getID() === event.getID()) {
              // Found the event to replace
              return prospectiveEvent;
            }

            // This is not the event. Just return the existing one
            return event;
          }),
        };
      });
    };

    /**
     * Set the status of the prospective event to failed, including an
     *   error message
     * @author Gabe Abrams
     * @param {string} errorMessage - the error message to attach to the
     *   prospective event
     */
    const setStatusToFailed = async (errorMessage) => {
      // Update the prospective event
      prospectiveEvent.setResults(
        EVENT_STATUSES.FAILED,
        errorMessage
      );
      try {
        await prospectiveEvent.save();
      } catch (err) {
        throw new Error('Oops! We couldn\'t write results to the Google Sheet. Please make sure the Gather robot is an editor.');
      }

      // Log the error
      logError({
        message: errorMessage,
        code: ERROR_CODES.BATCH_EVENT_CREATION_FAILED,
        metadata: {
          type: 'create',
          event: {
            crn: prospectiveEvent.getCRN(),
            courseCode: prospectiveEvent.getCourseCode(),
            hostEmail: prospectiveEvent.getHostEmail(),
            isLockAutoRecordSetting: (
              prospectiveEvent
                .isLockAutoRecordSettingOn()
            ),
            isWaitingRoomOn: prospectiveEvent.isWaitingRoomOn(),
            isAutoRecordOn: prospectiveEvent.isAutoRecordOn(),
            isDCEBanOn: prospectiveEvent.isDCEBanOn(),
            isFASBanOn: prospectiveEvent.isFASBanOn(),
            isEmergencyEvent: prospectiveEvent.isEmergencyEvent(),
          },
        },
      });
    };

    /*----------------------------------------*/
    /*                  Logic                 */
    /*----------------------------------------*/

    // Get info from state
    const {
      year,
      eventType,
    } = this.state;

    // Create a name for the event
    const customEventName = prospectiveEvent.getCustomEventName();
    let eventName = (
      customEventName
      || (
        eventType === 'class'
          ? 'Class' // Class
          : 'Section' // Not class
      )
    );

    // Append emergency suffix
    if (prospectiveEvent.isEmergencyEvent()) {
      eventName += ` ${IN_CASE_OF_EMERGENCY_SUFFIX}`;
    }

    // Get the Canvas Course ID
    let courseId;
    let crn;
    try {
      // Get the CRN for the event
      crn = prospectiveEvent.getCRN();

      // Look up the Canvas courseId
      courseId = await visitServerEndpoint({
        path: `/api/admin/crns/${crn}/years/${year}`,
        method: 'GET',
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't find the Canvas course. ${err.message}`);
      await updateEventInState();
      return;
    }

    // Get the course info object
    let course;
    try {
      course = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/canvas_object`,
        method: 'GET',
        params: {
          includeTerm: true, // Get the term so we can add it to the zoom title
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't get info on the course (${err.message})`);
      await updateEventInState();
      return;
    }

    // Create a Zoom meeting/webinar
    let zoomId;
    let password;
    let joinURL;
    let hostId;
    try {
      // Get the Zoom settings/metadata
      const waitingRoomOn = prospectiveEvent.isWaitingRoomOn();
      const autoRecordOn = prospectiveEvent.isAutoRecordOn();
      const hostEmail = prospectiveEvent.getHostEmail();
      const hostVideoDisabled = prospectiveEvent.isHostVideoDisabled();
      const isWebinar = prospectiveEvent.isWebinar();

      // Create a Zoom meeting/webinar title
      const zoomTitle = genZoomMeetingTitle({
        eventName,
        crn,
        courseName: (course.course_code || course.name),
        termName: course.term.name,
      });

      // Create the Zoom meeting or webinar
      if (isWebinar) {
        const results = await visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/webinars`,
          method: 'POST',
          params: {
            hostEmail,
            autoRecordOn,
            hostVideoDisabled,
            title: zoomTitle,
            allowCourseMismatch: true, // Admin panel can access all courses
          },
        });

        // Destructure results
        ({
          zoomId,
          password,
          hostId,
        } = results);
        joinURL = results.startURL;
      } else {
        ({
          zoomId,
          password,
          joinURL,
          hostId,
        } = await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/meetings`,
          method: 'POST',
          params: {
            hostEmail,
            waitingRoomOn,
            autoRecordOn,
            hostVideoDisabled,
            school: BATCH_CREATE_SCHOOL, // Use value from constants
            title: zoomTitle,
            allowCourseMismatch: true, // Admin panel can access all courses
          },
        }));
      }
    } catch (err) {
      await setStatusToFailed(`We couldn't create a Zoom ${prospectiveEvent.isWebinar() ? 'webinar' : 'meeting'} (${err.message})`);
      await updateEventInState();
      return;
    }

    // Create a lounge
    const ensureLoungeExists = prospectiveEvent.isEnsuringLoungeExists();
    if (ensureLoungeExists) {
      try {
        // Check if the lounge exists
        const lounges = await visitServerEndpoint({
          path: `/api/courses/${courseId}/lounges`,
          method: 'GET',
          params: {
            includeArchived: true,
            allowCourseMismatch: true,
          },
        });

        // Only create a lounge if none exist
        const allLoungesArchived = lounges.every((lounge) => {
          return lounge.archived;
        });
        if (allLoungesArchived) {
          // Figure out next lounge id
          let highestLoungeNumber = 1;
          lounges.forEach((lounge) => {
            const loungeNumber = Number.parseInt(lounge.loungeId.substring(1));
            if (loungeNumber > highestLoungeNumber) {
              highestLoungeNumber = loungeNumber;
            }
          });
          const nextLoungeId = `L${highestLoungeNumber + 1}`;

          // Create a lounge object
          const lounge = genEmptyLoungeObj(courseId, nextLoungeId);

          // Create the lounge
          await visitServerEndpoint({
            path: `/api/admin/courses/${courseId}/lounges`,
            method: 'POST',
            params: {
              lounge: JSON.stringify(lounge),
              loungeZoomName: genZoomMeetingTitle({
                eventName: `Gather Study Lounge: ${lounge.name}`,
                courseName: (course.course_code || course.name),
                termName: course.term.name,
                crn: 'PUT_CRN_HERE',
              }),
              hostEmail: prospectiveEvent.getHostEmail(),
              allowCourseMismatch: true,
            },
          });

          // Log the lounge creation
          logAction({
            type: 'create',
            description: 'lounge',
            metadata: {
              lounge,
              loungeId: lounge.loungeId,
              name: lounge.name,
              batchCreated: true,
            },
          });
        }
      } catch (err) {
        await setStatusToFailed(`We couldn't create a study lounge because an error occurred: ${err.message}`);
        await updateEventInState();
        return;
      }
    }

    // Get the IHID for the meeting
    // > Load the list of other events in the course
    let existingEvents;
    try {
      existingEvents = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'GET',
        params: {
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't get the list of existing events in the course (${err.message})`);
      await updateEventInState();
      return;
    }
    // > Extract the list of IHIDs
    const existingIHIDs = existingEvents.map((existingEvent) => {
      return existingEvent.ihid;
    });
    // > Generate the IHID for this event
    const ihid = genIHID(existingIHIDs);

    // Create the event object
    const event = genEmptyEventObj(courseId);
    event.ihid = ihid;
    event.name = eventName;
    event.type = eventType;
    event.currentZoomId = zoomId;
    event.currentZoomHost = hostId;
    event.openZoomLink = joinURL.replace('/s/', '/j/');
    event.lockAutoRecordSetting = prospectiveEvent.isLockAutoRecordSettingOn();
    event.lockZoomToggle = true; // always true for now from batch create
    event.banDCEStudents = prospectiveEvent.isDCEBanOn();
    event.banFASStudents = prospectiveEvent.isFASBanOn();
    event.isWebinar = !!prospectiveEvent.isWebinar();

    // Validate the join url
    const validJoinURL = (joinURL.indexOf('pwd=') >= 0);

    // Update the prospective event
    // > Add values
    prospectiveEvent.setOpenZoomLink(joinURL);
    prospectiveEvent.setGatherLink(genShareableLink(courseId, ihid));
    prospectiveEvent.setZoomPassword(password);
    prospectiveEvent.setCanvasLink(`https://canvas.harvard.edu/courses/${courseId}`);
    // > Add result
    if (validJoinURL) {
      prospectiveEvent.setResults(EVENT_STATUSES.SUCCESSFUL);
    } else {
      prospectiveEvent.setResults(
        EVENT_STATUSES.CREATED_WITH_WARNING,
        `Zoom ${prospectiveEvent.isWebinar() ? 'webinar' : 'meeting'} does not have a password embedded URL!`
      );
    }
    // > Save to google sheet
    try {
      await prospectiveEvent.save();
    } catch (err) {
      await setStatusToFailed(`We couldn't save the results to the google sheet (but the event was created). Error: ${err.message}`);
      await updateEventInState();
      return;
    }

    // Store the event in the list (DB)
    try {
      // Save via the ttm API
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'POST',
        params: {
          event: JSON.stringify(event),
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't save the event to the course event list (${err.message})`);
      await updateEventInState();
      return;
    }

    // Log the action asynchronously
    logAction({
      type: 'create',
      description: 'event',
      metadata: {
        event,
        ihid: event.ihid,
        name: event.name,
        type: event.type,
        batchCreated: true,
      },
    });

    // Update the event
    return updateEventInState();
  }

  /**
   * Start the batch creation process
   * @author Gabe Abrams
   */
  async start() {
    const { allowNoConfirmExit } = this.props;

    // Update the state
    this.setState({
      status: STATUSES.RUNNING,
    });

    // Go through the prospective events and create them one by one
    const { prospectiveEvents } = this.state;
    for (let i = 0; i < prospectiveEvents.length; i++) {
      // Check if halted
      const { halted } = this.state;
      if (halted) {
        // Indicate that the user can leave without confirmation
        allowNoConfirmExit();

        // Stop now
        return this.setState({
          status: STATUSES.ENDED,
          showResultsPopup: true,
        });
      }

      // Update the state
      this.setState({
        idOfEventBeingCreated: prospectiveEvents[i].getID(),
      });

      // Continue the next event
      try {
        await this.createEvent(prospectiveEvents[i]);
      } catch (err) {
        // An unrecoverable error occurred
        return this.setState({
          fatalErrorMessage: err.message,
        });
      }

      // Update the state
      this.setState({
        idOfEventBeingCreated: null,
      });
    }

    // Indicate that the user can leave without confirmation
    allowNoConfirmExit();

    // Finished!
    this.setState({
      status: STATUSES.ENDED,
      showResultsPopup: true,
      halted: false,
    });
  }

  /**
   * Halt the batch creation process
   * @author Gabe Abrams
   */
  halt() {
    // Update the state property so the system will stop after the current item
    this.setState({ halted: true });
  }

  /**
   * Scroll to a specific event
   * @author Gabe Abrams
   */
  scrollToEventBeingCreated() {
    const { idOfEventBeingCreated } = this.state;

    // Scroll to the current element only if it exists
    const elemId = `ProspectiveEventPreview-${idOfEventBeingCreated}`;
    const elem = document.getElementById(elemId);
    if (idOfEventBeingCreated && elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * Render BatchEventCreator
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props and state
    const { allowNoConfirmExit } = this.props;
    const {
      loading,
      fatalErrorMessage,
      status,
      robotEmail,
      halted,
      autoscroll,
      skippedEvents,
      showSkippedThatAlreadyHaveResults,
      prospectiveEvents,
      idOfEventBeingCreated,
      showResultsPopup,
    } = this.state;

    /* --------------------- Fatal Error Message -------------------- */

    if (fatalErrorMessage) {
      return (
        <div className="alert alert-warning d-inline-block">
          <h3>An error occurred:</h3>
          {fatalErrorMessage}
        </div>
      );
    }

    /* ----------------------- Loading Screen ----------------------- */

    if (loading) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
    }

    /*------------------------------------------------------------------------*/
    /*                                  Modal                                 */
    /*------------------------------------------------------------------------*/

    let modal;

    if (showResultsPopup) {
      // Get the results
      const {
        numSuccessful,
        numWarning,
        numFailed,
        numSkipped,
      } = this.getResults();

      // Create the modal
      modal = (
        <BatchEventCreationResultsModal
          halted={halted}
          numSuccessful={numSuccessful}
          numWarning={numWarning}
          numFailed={numFailed}
          numSkipped={numSkipped}
          onClose={() => {
            this.setState({
              showResultsPopup: false,
            });
          }}
        />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                                Main View                               */
    /*------------------------------------------------------------------------*/

    let body;

    /* ------------------------ Setup Screen ------------------------ */

    if (status === STATUSES.SETUP) {
      body = (
        <BatchEventCreatorSetup
          robotEmail={robotEmail}
          onDone={(newDataStoreInfo, eventType, year) => {
            // Start the loading process
            this.load(newDataStoreInfo, eventType, year);
          }}
        />
      );
    }

    /* --------------- Confirmation and Running Screen -------------- */

    if (
      status === STATUSES.CONFIRMING_SKIPPED_EVENTS
      || status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS
      || status === STATUSES.RUNNING
      || status === STATUSES.ENDED
    ) {
      // Create a subtitle
      let subtitle;
      let subtitleIcon;
      if (status === STATUSES.CONFIRMING_SKIPPED_EVENTS) {
        subtitle = 'Events to skip:';
      } else if (status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS) {
        subtitle = 'Events to create:';
      } else if (status === STATUSES.RUNNING) {
        subtitle = 'Working! Don\'t edit the sheet or let the machine sleep.';
        subtitleIcon = faHammer;
      } else if (status === STATUSES.ENDED) {
        subtitle = (
          halted
            ? 'Halted. Results:'
            : 'All finished! Results:'
        );
        subtitleIcon = (
          halted
            ? faStopCircle
            : faCheckCircle
        );
      }

      // Booleans for state
      const confirming = (
        status === STATUSES.CONFIRMING_SKIPPED_EVENTS
        || status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS
      );

      // Create a list of event previews
      let eventPreviews;
      if (confirming) {
        // Confirmation previews

        // Get the list of events based on the type we're confirming
        let events;
        if (status === STATUSES.CONFIRMING_SKIPPED_EVENTS) {
          // Confirming skipped events
          events = (
            skippedEvents
              // Filter events that already have results (if not being shown)
              .filter((event) => {
                return (
                  showSkippedThatAlreadyHaveResults
                  || !event.alreadyHasResults()
                );
              })
          );
        } else {
          // Confirming prospective events
          events = prospectiveEvents;
        }

        // Create event previews
        eventPreviews = events.map((event) => {
          return (
            <ProspectiveEventPreview
              key={event.getID()}
              event={event}
              showSettings={status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS}
            />
          );
        });

        // Add a "show more" button if not showing events with results
        // (only if there is at least one that has results)
        if (
          // Events with results are hidden
          !showSkippedThatAlreadyHaveResults
          // We are currently confirming skipped events
          && status === STATUSES.CONFIRMING_SKIPPED_EVENTS
          // There is at least one event that has results
          && skippedEvents.some((event) => {
            return event.alreadyHasResults();
          })
        ) {
          eventPreviews.push(
            <div key="show-more">
              <div className={`alert alert-warning text-dark mt-${eventPreviews.length > 0 ? '3' : '0'} mb-1`}>
                {/* Explanation */}
                <h5 className="m-0">
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="mr-2"
                  />
                  Events with Results Hidden
                </h5>
                <div className="mb-2">
                  We hid events that already have text in one of the results
                  columns, marked by the
                  {' '}
                  {GATHER_TAGS.WRITE}
                  {' '}
                  tag.
                </div>

                {/*  Show More Button */}
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  aria-label="show events that were already created"
                  onClick={() => {
                    this.setState({
                      showSkippedThatAlreadyHaveResults: true,
                    });
                  }}
                >
                  Show Events with Results
                </button>
              </div>
            </div>
          );
        }
      } else {
        // Running/ended status previews
        eventPreviews = prospectiveEvents.map((event) => {
          return (
            <ProspectiveEventPreview
              key={event.getID()}
              event={event}
              beingCreated={event.getID() === idOfEventBeingCreated}
            />
          );
        });
      }

      /* --------------------------- Footer --------------------------- */

      // Create a footer
      // NOTE: for SETUP, footer is already added by BatchEventCreatorSetup
      let footer;
      if (status === STATUSES.CONFIRMING_SKIPPED_EVENTS) {
        footer = (
          <BatchCreationFooter
            continueButton={{
              onClick: () => {
                this.setState({
                  status: STATUSES.CONFIRMING_PROSPECTIVE_EVENTS,
                });
              },
              description: [
                'Next up: confirm events to create',
                'before starting the batch process.',
              ],
            }}
          />
        );
      } else if (status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS) {
        footer = (
          <BatchCreationFooter
            continueButton={{
              onClick: () => {
                this.start();
              },
              label: 'Start Batch Creation',
              description: [
                'This will immediately begin',
                'the batch creation process.',
              ],
              icon: faPlay,
            }}
          />
        );
      } else if (status === STATUSES.RUNNING) {
        // Count the number of finished events
        const numFinished = (
          prospectiveEvents
            // Filter out any pending events
            .filter((event) => {
              return (event.getStatus() !== EVENT_STATUSES.PENDING);
            })
            // Count the number of events
            .length
        );

        // Create the footer
        footer = (
          <BatchCreationFooter
            customContent={(
              <div>
                {/* Progress Bar */}
                <BatchCreationProgress
                  numFinished={numFinished}
                  numTasks={prospectiveEvents.length}
                  ended={status === STATUSES.ENDED}
                />

                {/* Halt Button */}
                <div>
                  <button
                    type="button"
                    className={`btn btn-lg btn-${halted ? 'secondary active' : 'dark'}`}
                    aria-label="halt the batch create process"
                    onClick={() => {
                      this.halt();
                    }}
                    disabled={halted}
                  >
                    {
                      halted
                        ? 'Halting...'
                        : (
                          <span>
                            <FontAwesomeIcon
                              icon={faStop}
                              className="mr-2"
                            />
                            Halt Event Creation
                          </span>
                        )
                    }
                  </button>
                </div>

                {/* Autoscroll Toggle */}
                <div className="mt-3">
                  <div className="custom-control custom-switch">
                    {/* Checkbox */}
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="BatchEventCreator-autoscroll-toggle"
                      checked={autoscroll}
                      aria-label={`turn ${autoscroll ? 'off' : 'on'} autoscrolling`}
                      onChange={() => {
                        this.setState({
                          autoscroll: !autoscroll,
                        });
                      }}
                    />

                    {/* Explanation */}
                    {/* eslint-disable-next-line max-len */}
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label
                      className="custom-control-label"
                      htmlFor="BatchEventCreator-autoscroll-toggle"
                    >
                      Auto-scroll to event currently being created.
                    </label>
                  </div>
                </div>
              </div>
            )}
          />
        );
      } else if (status === STATUSES.ENDED) {
        // Get the results
        const {
          numSuccessful,
          numWarning,
          numFailed,
          numSkipped,
        } = this.getResults();

        // Create the footer
        footer = (
          <BatchCreationFooter
            customContent={(
              <div>
                {/* Title */}
                <h3>
                  Finished!
                </h3>

                {/* Results Message */}
                <div id="BatchEventCreator-results-summary-text">
                  <strong>
                    Results:
                  </strong>
                  {' '}
                  {`${numSuccessful} event${numSuccessful === 1 ? '' : 's'} created successfully, ${numWarning} created with warnings, and ${numFailed} failed.`}
                </div>

                {/* Skipped Items Message */}
                {numSkipped > 0 && (
                  <div>
                    {`Also, ${numSkipped} event${numSkipped === 1 ? ' was' : 's were'} skipped because the process was halted`}
                  </div>
                )}
              </div>
            )}
          />
        );
      }

      /* ---------------------- Assemble Full UI ---------------------- */

      // Assemble body
      body = (
        <div>
          {/* Content */}
          <div className="alert alert-light text-dark">
            {/* Subtitle */}
            <h3 className="mb-3 font-weight-bold">
              {(subtitleIcon && (
                <FontAwesomeIcon
                  icon={subtitleIcon}
                  className="mr-2"
                />
              ))}
              {subtitle}
            </h3>

            {/* List of events */}
            <EventScrollpane>
              {eventPreviews}
            </EventScrollpane>
          </div>

          {/* Footer */}
          {footer}
        </div>
      );
    }

    /* ------------------------ Detect Errors ----------------------- */

    // No prospective events to create
    if (
      status === STATUSES.CONFIRMING_PROSPECTIVE_EVENTS
      && prospectiveEvents.length === 0
    ) {
      // Allow exit without confirmation
      allowNoConfirmExit();

      body = (
        <div className="alert alert-light text-dark">
          <strong>
            Oops!
          </strong>
          &nbsp;
          There are no events to batch create.
          Please check your spreadsheet and try again.
        </div>
      );
    }

    /* ---------------------- Assemble Full UI ---------------------- */
    return (
      <div className="BatchEventCreator-container">
        {modal}

        {/* Header */}
        <div className="mb-3">
          <h1 className="font-weight-bold">
            Batch Event Creator
          </h1>
        </div>

        {body}
      </div>
    );
  }
}

BatchEventCreator.propTypes = {
  // Handler to call if the user has made changes
  allowNoConfirmExit: PropTypes.func.isRequired,
};

export default BatchEventCreator;
