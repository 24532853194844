import React from 'react';

// Import FontAwesome
import {
  faLink,
} from '@fortawesome/free-solid-svg-icons';

// Import other components
import ExampleButton from '../ExampleButton';

/**
 * How to get a link to an event (text tutorial)
 * @author Gabe Abrams
 */
export default {
  title: 'Get a Link to an Event',
  startWarning: 'Share links only with people who are part of the course',
  steps: [
    (
      <div>
        Click the
        {' '}
        <ExampleButton
          icon={faLink}
          explanation="Get Shareable Link"
          variant="secondary"
        />
        {' '}
        button next to the event of interest
      </div>
    ),
    (
      <div>
        If the link is for guest speakers or others who don&apos;t
        appear in Canvas, follow instructions at the bottom of
        the popup
      </div>
    ),
  ],
  endMessage: 'Copy the link and you\'re done!',
};
