// Highest error: IMCL11

/**
 * List of error codes for logging
 * @author Gabe Abrams
 */
export default {
  SESSION_EXPIRED: 'IMCL1',
  MALFORMED_ZOOM_MEETING: 'IMCL2',
  ATTENDANCE_NOT_SAVED: 'IMCL3',
  RECORDING_WATCH_NOT_SAVED: 'IMCL4',
  COPY_FAILED: 'IMCL5',
  EVENT_VALIDATION_FAILED: 'IMCL6',
  MANUALLY_JOINED_ZOOM: 'IMCL7',
  MANUALLY_OPENED_RECORDING: 'IMCL8',
  NO_MEETING_SETTINGS: 'IMCL9',
  BATCH_EVENT_CREATION_FAILED: 'IMCL10',
  LOUNGE_VALIDATION_FAILED: 'IMCL11',
};
