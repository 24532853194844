// Import React
import React from 'react';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

// Import constants
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../../constants/IN_CASE_OF_EMERGENCY_SUFFIX';

// Create checkmark examples
const checkedExample = (
  <FontAwesomeIcon icon={faCheckSquare} />
);
const uncheckedExample = (
  <FontAwesomeIcon icon={faSquare} />
);

/**
 * Get a list of column headers
 * @author Gabe Abrams
 * @param {string} type - the type of event to get
 */
export default (type) => {
  const typeWord = type.trim().toLowerCase();

  /**
   * List of column objects where each column has the form:
   *   { prop, type, required, defaultValue, isWriteColumn, name }
   *   - prop is the machine-readable name to use when storing cell data
   *   - type is one of ['boolean', 'number', 'string'], the col data type
   *   - allowSpaces is a boolean. If true, allow the cell to contain spaces
   *       during the read process
   *   - required is a boolean. If true, the column must exist in the sheet
   *   - defaultValue is a value to use if cell is empty or undefined to require
   *       all cells in the column to have a value
   *   - isWriteColumn is a boolean. If true, Gather can write from this column
   *       and Gather will only read from this column in order to determine
   *       whether it will skip the row
   *   - names is a string[] list of potential column names (excluding the tag)
   *   - description is a human-readable description of the column info
   *   - examples is an array of length 2 with examples of what cell contents
   *       might look like
   * @author Gabe Abrams
   */
  return [
    /* ------------------- Required Input Columns ------------------- */

    {
      prop: 'batchCreate',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: true,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `batch create ${typeWord}?`,
      ],
      description: 'Rows with a checked checkbox here will be read by Gather.',
      examples: [checkedExample, uncheckedExample],
    },
    {
      prop: 'crn',
      type: 'number',
      allowSpaces: undefined, // Not relevant to numbers
      required: true,
      defaultValue: undefined, // No default value! Cells cannot be empty.
      isWriteColumn: false,
      names: [
        'crn', // Primary
        'course crn',
      ],
      description: 'The course CRN.',
      examples: [13856, 39482],
    },
    {
      prop: 'courseCode',
      type: 'string',
      allowSpaces: true,
      required: true,
      defaultValue: undefined, // No default value! Cells cannot be empty.
      isWriteColumn: false,
      names: [
        'course', // Primary
        'course code',
      ],
      description: 'A short text description of the course.',
      examples: ['CS 900 Algorithms', 'ENGL 100 Creative Writing'],
    },
    {
      prop: 'hostEmail',
      type: 'string',
      allowSpaces: false,
      required: true,
      defaultValue: undefined, // No default value! Cells cannot be empty.
      isWriteColumn: false,
      names: [
        `${typeWord} zoom host email`, // Primary
        `${typeWord} zoom email`,
        `${typeWord} zoom host`,
        `${typeWord} host email`,
        `${typeWord} host`,
      ],
      description: 'The Zoom account email for the user who will own the event.',
      examples: ['teacher@harvard.edu', 'ta@fas.harvard.edu'],
    },

    /* ------------------ Optional Columns ------------------ */

    {
      prop: 'isLockAutoRecordSettingOn',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `lock auto record setting for ${typeWord}?`, // Primary
      ],
      description: 'If checked, the event auto record setting will be editable by non-admins.',
      examples: [checkedExample, uncheckedExample],
    },
    {
      prop: 'isDCEBanOn',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `ban dce students from ${typeWord}?`, // Primary
      ],
      description: 'If checked, DCE students will not be allowed to join the event.',
      examples: [uncheckedExample, uncheckedExample],
    },
    {
      prop: 'isFASBanOn',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `ban fas students from ${typeWord}?`, // Primary
      ],
      description: 'If checked, FAS students will not be allowed to join the event.',
      examples: [uncheckedExample, uncheckedExample],
    },
    {
      prop: 'isAutoRecordOn',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `auto record ${typeWord}?`, // Primary
      ],
      description: 'If checked, auto record will be turned on.',
      examples: [uncheckedExample, uncheckedExample],
    },
    {
      prop: 'isWaitingRoomOn',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `${typeWord} waiting room on?`, // Primary
      ],
      description: 'If checked, waiting room will be turned on (webinars cannot have waiting rooms).',
      examples: [uncheckedExample, uncheckedExample],
    },
    {
      prop: 'isEmergencyEvent',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `${typeWord} is emergency event?`, // Primary
      ],
      description: `If checked, event title will get an "${IN_CASE_OF_EMERGENCY_SUFFIX}" suffix.`,
      examples: [checkedExample, uncheckedExample],
    },
    {
      prop: 'ensureLoungeExists',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        'ensure lounge exists?', // Primary
      ],
      description: 'If checked and no study lounge exists, create a study lounge using the same Zoom host.',
      examples: [checkedExample, uncheckedExample],
    },
    {
      prop: 'hostVideoDisabled',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        'force host video off?', // Primary
      ],
      description: 'If checked, host/panelist video will be disabled. Otherwise, setting will match user setting.',
      examples: [uncheckedExample, checkedExample],
    },
    {
      prop: 'customEventName',
      type: 'string',
      allowSpaces: true,
      required: false,
      defaultValue: `${typeWord.charAt(0).toUpperCase()}${typeWord.substring(1)}`,
      isWriteColumn: false,
      names: [
        'custom event name', // Primary
      ],
      description: `Custom text for the event name, defaults to "${typeWord.charAt(0).toUpperCase()}${typeWord.substring(1)}".`,
      examples: ['Class: HELIX Live Stream', 'Class: HELIX Classroom'],
    },
    {
      prop: 'isWebinar',
      type: 'boolean',
      allowSpaces: undefined, // Not relevant to booleans
      required: false,
      defaultValue: false,
      isWriteColumn: false,
      names: [
        `${typeWord} is a webinar?`, // Primary
      ],
      description: 'If checked, the event will be created as a webinar. Otherwise, created as a meeting.',
      examples: [uncheckedExample, checkedExample],
    },

    /* ----------------------- Output Columns ----------------------- */

    {
      prop: 'zoomPassword',
      type: 'string',
      allowSpaces: undefined, // Not relevant to write columns
      required: true,
      defaultValue: undefined, // Not relevant to write columns
      isWriteColumn: true,
      names: [
        `${typeWord} password`, // Primary
        `${typeWord} passcode`,
      ],
      description: 'The password for the event.',
      examples: ['(Gather will fill)', '(Gather will fill)'],
    },
    {
      prop: 'openZoomLink',
      type: 'string',
      allowSpaces: undefined, // Not relevant to write columns
      required: true,
      defaultValue: undefined, // Not relevant to write columns
      isWriteColumn: true,
      names: [
        `${typeWord} zoom link`, // Primary
      ],
      description: 'A link to join the Zoom event.',
      examples: ['(Gather will fill)', '(Gather will fill)'],
    },
    {
      prop: 'gatherLink',
      type: 'string',
      allowSpaces: undefined, // Not relevant to write columns
      required: true,
      defaultValue: undefined, // Not relevant to write columns
      isWriteColumn: true,
      names: [
        `${typeWord} gather link`, // Primary
      ],
      description: 'A link to open Gather for this event.',
      examples: ['(Gather will fill)', '(Gather will fill)'],
    },
    {
      prop: 'canvasLink',
      type: 'string',
      allowSpaces: undefined, // Not relevant to write columns
      required: true,
      defaultValue: undefined, // Not relevant to write columns
      isWriteColumn: true,
      names: [
        'canvas course link', // Primary
      ],
      description: 'A link to the Canvas course.',
      examples: ['(Gather will fill)', '(Gather will fill)'],
    },
    {
      prop: 'results',
      type: 'string',
      allowSpaces: undefined, // Not relevant to write columns
      required: true,
      defaultValue: undefined, // Not relevant to write columns
      isWriteColumn: true,
      names: [
        `gather ${typeWord} results`, // Primary
      ],
      description: 'Results of the batch creation process.',
      examples: ['(Gather will fill)', '(Gather will fill)'],
    },
  ];
};
