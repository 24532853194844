/**
 * Tooltip indicating the total attendance for the date
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import tooltip components
// Note regarding OverlayTrigger "findDOMNode is deprecated in StrictMode"
// https://github.com/react-bootstrap/react-bootstrap/issues/5075
import {
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Import style
import './AttendanceTotalTooltip.css';

class AttendanceTotalTooltip extends Component {
  /**
   * Render AttendanceTotalTooltip
   * @author Gabe Abrams
   */
  render() {
    const {
      tooltipText,
      children,
    } = this.props;
    return (
      <OverlayTrigger
        placement="top"
        rootClose
        overlay={(props) => {
          /* eslint-disable react/jsx-props-no-spreading */
          return (
            <Tooltip
              className="AttendanceTotalTooltip-tooltip AttendanceTotalTooltip-tooltip-top"
              {...props}
            >
              {tooltipText}
            </Tooltip>
          );
        }}
      >
        {children}
      </OverlayTrigger>
    );
  }
}

AttendanceTotalTooltip.propTypes = {
  // Text to show in tooltip
  tooltipText: PropTypes.string.isRequired,
  // Children to wrap
  children: PropTypes.node.isRequired,
};

export default AttendanceTotalTooltip;
