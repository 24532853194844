/**
 * Admin panel navigator (moves you around between admin panels)
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSchool,
  faCogs,
  faExclamationTriangle,
  faListAlt,
  faTrashRestore,
  faDoorClosed,
  faToggleOn,
  faLink,
  faBan,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Header from '../shared/Header';
import Modal from '../shared/Modal';
import TabBox from '../shared/TabBox';

// Import other components
import CourseChooser from './CourseChooser';
import EditCourseSettings from './EditCourseSettings';
import BatchEventCreator from './BatchEventCreator';
import RecoverEvent from './RecoverEvent';
import EmergencyAuthSwitch from './EmergencyAuthSwitch';
import LoungeBanManager from './LoungeBanManager';

// Import helpers
import setPagePath from '../helpers/setPagePath';
import logAction from '../helpers/logAction';
import scroll from '../helpers/scroll';

// Style
import './style.css';
import BreakthroughLinkManager from './BreakthroughLinkManager';

// Views
const VIEWS = {
  HOME: 'home',
  COURSE_CHOOSER: 'course-chooser',
  EDIT_COURSE_SETTINGS: 'edit-course-settings',
  BATCH_EVENT_CREATOR: 'batch-event-creator',
  RECOVER_EVENT: 'recover-event',
  EMERGENCY_AUTH_SWITCH: 'emergency-auth-switch',
  BREAKTHROUGH_LINK_MANAGER: 'breakthrough-link-manager',
  LOUNGE_BAN_MANAGER: 'lounge-ban-manager',
};

class AdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The current view
      currentView: VIEWS.HOME,
      // True if allowed to exit a admin sub panel
      adminSubPanelExitable: true,
      // True if "are you sure you want to exit" modal is visible
      adminSubPanelExitBeingConfirmed: false,
    };
  }

  /**
   * Render AdminPanel
   * @author Gabe Abrams
   */
  render() {
    const {
      courseId,
      courseName,
      onExit,
    } = this.props;
    const {
      currentView,
      adminSubPanelExitBeingConfirmed,
      adminSubPanelExitable,
    } = this.state;

    // Set the page path and title
    setPagePath('Admin Panel', '/');

    let modal;
    /* ------------------------- Build modal ------------------------ */
    if (adminSubPanelExitBeingConfirmed) {
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />
              Unsaved Changes!
            </span>
          )}
          body="Are you sure you want to leave this page? All unsaved changes will be permanently lost."
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Exit the sub panel
              this.setState({
                currentView: VIEWS.HOME,
                adminSubPanelExitBeingConfirmed: false,
                adminSubPanelExitable: true,
              });

              // Log cancel
              logAction({
                type: 'cancel',
                description: currentView.replace(/-/g, ' '),
                metadata: {
                  isAdminFeature: true,
                },
              });
            } else {
              // Just close the "are you sure" modal
              this.setState({
                adminSubPanelExitBeingConfirmed: false,
              });
            }
          }}
          type={Modal.TYPES.OKAY_CANCEL}
          okayLabel={(
            <span>
              <strong>
                Leave
              </strong>
              &nbsp;(Discard Changes)
            </span>
          )}
          okayColor="dark"
        />
      );
    }

    /* ------------------ Build Header Left Button ------------------ */

    let headerLeftButton;
    if (currentView === VIEWS.HOME) {
      // Exit admin panel button
      headerLeftButton = {
        onClick: onExit,
        contents: (
          <span>
            {/* Small Screen View */}
            <span className="d-inline d-md-none">
              Exit
            </span>
            {/* Large Screen View */}
            <span className="d-none d-md-inline">
              Exit Admin Panel
            </span>
          </span>
        ),
      };
    } else {
      // In a sub panel that has to be exitable
      headerLeftButton = {
        onClick: () => {
          // Exit button was clicked
          if (adminSubPanelExitable) {
            // Immediately exit
            this.setState({
              currentView: VIEWS.HOME,
            });

            // Log this
            logAction({
              type: 'close',
              description: currentView.replace(/-/g, ' '),
              metadata: {
                isAdminFeature: true,
              },
            });
          } else {
            // Show a modal asking user to confirm
            this.setState({
              adminSubPanelExitBeingConfirmed: true,
            });
          }
        },
        contents: (
          <span>
            <span className="d-inline d-sm-none">
              Home
            </span>
            <span className="d-none d-sm-inline d-md-none">
              Admin Home
            </span>
            <span className="d-none d-md-inline">
              Admin Panel Home
            </span>
          </span>
        ),
      };
    }

    /* ---------------------------- Body ---------------------------- */

    let body;
    if (currentView === VIEWS.HOME) {
      // Home page
      body = (
        <div>
          <h1 className="mb-0">
            Admin Panel&nbsp;
            <strong>
              Home
            </strong>
          </h1>
          <p className="lead mb-5">
            This admin panel is available to Canvas admins
          </p>

          {/* Global Actions */}
          <div className="mb-4">
            <TabBox title="Global Actions">
              {/* Course Chooser Button */}
              <button
                type="button"
                id="AdminPanel-jump-to-another-course-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="view the admin panel for jumping to another course"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.COURSE_CHOOSER,
                    // With this view, start by allowing exit
                    adminSubPanelExitable: true,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'course chooser',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faSchool}
                    className="mr-2"
                  />
                  Jump to Another Course
                </h2>
              </button>

              {/* Batch Event Creator Button */}
              <button
                type="button"
                id="AdminPanel-batch-event-creator-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="start the batch event creation process"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.BATCH_EVENT_CREATOR,
                    // With this view, do not start by allowing exit
                    adminSubPanelExitable: false,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'batch event creator',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faListAlt}
                    className="mr-2"
                  />
                  Batch Event Creator
                </h2>
              </button>

              {/* Batch Event Creator Button */}
              <button
                type="button"
                id="AdminPanel-manage-lounge-bans-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="manage lounge bans"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.LOUNGE_BAN_MANAGER,
                    // With this view, start by allowing exit
                    adminSubPanelExitable: true,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'lounge ban manager',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faBan}
                    className="mr-2"
                  />
                  Study Lounge Ban Manager
                </h2>
              </button>

              {/* SideDoor Key */}
              <a
                id="AdminPanel-side-door-key-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="view your side door key"
                href={`https://${window.location.hostname}/admin/side-door/generate`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faDoorClosed}
                    className="mr-2"
                  />
                  SideDoor Key
                </h2>
              </a>
            </TabBox>
          </div>

          {/* Course-specific Actions */}
          <div className="mb-4">
            <TabBox title="Current Course Actions">
              {/* Breakthrough Link Manager */}
              <button
                type="button"
                id="AdminPanel-breakthrough-link-manager"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="manage breakthrough links for the course"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.BREAKTHROUGH_LINK_MANAGER,
                    // With this view, start by allowing exit
                    adminSubPanelExitable: true,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'breakthrough link manager',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faLink}
                    className="mr-2"
                  />
                  Breakthrough Link Manager
                </h2>
              </button>

              {/* Edit Course Settings Button */}
              <button
                type="button"
                id="AdminPanel-edit-settings-for-current-course-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="edit course settings for a specific course"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.EDIT_COURSE_SETTINGS,
                    // With this view, start by allowing exit
                    adminSubPanelExitable: true,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'edit course settings',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faCogs}
                    className="mr-2"
                  />
                  Edit Settings for Current Course
                </h2>
              </button>

              {/* Recover Event Button */}
              <button
                type="button"
                id="AdminPanel-recover-deleted-events-button"
                className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
                aria-label="recover deleted events in the current course"
                onClick={() => {
                  this.setState({
                    currentView: VIEWS.RECOVER_EVENT,
                    // With this view, start by allowing exit
                    adminSubPanelExitable: true,
                  });

                  // Log this
                  logAction({
                    type: 'open',
                    description: 'recover event panel',
                    metadata: {
                      isAdminFeature: true,
                    },
                  });
                }}
              >
                <h2 className="m-0">
                  <FontAwesomeIcon
                    icon={faTrashRestore}
                    className="mr-2"
                  />
                  Recover Deleted Course Events
                </h2>
              </button>
            </TabBox>
          </div>

          {/* Emergency Actions */}
          <TabBox title="Emergency Actions">
            {/* Emergency Auth Bypass Switch */}
            <button
              type="button"
              id="AdminPanel-emergency-auth-switch-button"
              className="btn btn-secondary btn-lg btn-block pt-3 pb-3"
              aria-label="show settings for the emergency auth bypass switch"
              onClick={() => {
                this.setState({
                  currentView: VIEWS.EMERGENCY_AUTH_SWITCH,
                  // With this view, start by allowing exit
                  adminSubPanelExitable: true,
                });

                // Log this
                logAction({
                  type: 'open',
                  description: 'emergency auth bypass switch panel',
                  metadata: {
                    isAdminFeature: true,
                  },
                });
              }}
            >
              <h2 className="m-0">
                <FontAwesomeIcon
                  icon={faToggleOn}
                  className="mr-2"
                />
                Emergency Auth Bypass Switch
              </h2>
            </button>
          </TabBox>
        </div>
      );
    } else if (currentView === VIEWS.COURSE_CHOOSER) {
      // Show course chooser
      body = (
        <CourseChooser />
      );
    } else if (currentView === VIEWS.EDIT_COURSE_SETTINGS) {
      // Show course settings editor
      body = (
        <EditCourseSettings
          courseId={courseId}
          courseName={courseName}
          onChangesMade={() => {
            this.setState({
              // With this view, start by not allowing exit
              adminSubPanelExitable: false,
            });
          }}
          onDone={() => {
            this.setState({
              // With this view, start by not allowing exit
              adminSubPanelExitable: false,
              currentView: VIEWS.HOME,
            });

            // Log this
            logAction({
              type: 'close',
              description: 'edit course settings',
              metadata: {
                isAdminFeature: true,
              },
            });
          }}
        />
      );
    } else if (currentView === VIEWS.BATCH_EVENT_CREATOR) {
      // Show batch event creator
      body = (
        <BatchEventCreator
          allowNoConfirmExit={() => {
            if (!adminSubPanelExitable) {
              this.setState({
                // With this view, start by allowing exit
                adminSubPanelExitable: true,
              });
            }
          }}
        />
      );
    } else if (currentView === VIEWS.RECOVER_EVENT) {
      // Show the recover event page
      body = (
        <RecoverEvent
          courseId={courseId}
          courseName={courseName}
        />
      );
    } else if (currentView === VIEWS.EMERGENCY_AUTH_SWITCH) {
      // Show the emergency auth bypass switch
      body = (
        <EmergencyAuthSwitch />
      );
    } else if (currentView === VIEWS.BREAKTHROUGH_LINK_MANAGER) {
      // Show the breakthrough link manager
      body = (
        <BreakthroughLinkManager
          courseId={courseId}
          courseName={courseName}
        />
      );
    } else if (currentView === VIEWS.LOUNGE_BAN_MANAGER) {
      // Show the lounge ban manager
      body = (
        <LoungeBanManager
          courseId={courseId}
          courseName={courseName}
        />
      );
    }

    /* --------------------------- Full UI -------------------------- */
    return (
      <div className="AdminPanel-striped-admin-background alert-success progress-bar-striped">
        <div className="content-container">
          {modal}
          <Header
            leftButton={headerLeftButton}
            adminPanel
          />

          {/* Top element (so we can scroll to top of page) */}
          <div ref={(el) => { scroll.saveTopRef(el); }} />

          {/* Content below header */}
          <div className="content-below-header text-center">
            {/* Content */}
            {body}

            {/* Bottom element (so we can scroll to bottom of page) */}
            <div ref={(el) => { scroll.saveBottomRef(el); }} />
          </div>
        </div>
      </div>
    );
  }
}

AdminPanel.propTypes = {
  // CourseId for the current course
  courseId: PropTypes.number.isRequired,
  // Name of the current course
  courseName: PropTypes.string.isRequired,
  // Function to call when exiting the admin panel
  onExit: PropTypes.func.isRequired,
};

export default AdminPanel;
