/**
 * AttendanceTableFooter for attendance table
 * @author Karen Dolan
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import other components
import AttendanceTotalTooltip from './AttendanceTotalTooltip';

// Constants
const TOTAL_BAR_HEIGHT = 100; // pixels
const TOTAL_BAR_WIDTH = 68; // pixels

class AttendanceTableFooter extends Component {
  /**
  * Render AttendanceTable AttendanceTableFooter
  * @author Karen Dolan
  * @author Gabe Abrams
  */
  render() {
    const {
      dateKeys,
      valueLists,
    } = this.props;

    // Count up totals
    let largestTotal = 0;
    const totals = dateKeys.map((date, i) => {
      // Sum up the whole column
      const total = valueLists.reduce((a, b) => {
        // Count the value of the current cell
        let cellValue;
        if (typeof b[i] === 'number') {
          // Add the total number of people attending this event
          cellValue = b[i];
        } else {
          // Add 1 if in attendance, 0 otherwise
          cellValue = (b[i] ? 1 : 0);
        }

        return a + cellValue;
      }, 0);

      // Keep track of largest total
      if (largestTotal < total) {
        largestTotal = total;
      }

      // Add to list
      return total;
    });

    // Make a list of totals cells
    const totalsCells = totals.map((total, i) => {
      const date = new Date(dateKeys[i]);
      const shortDate = `${date.getMonth() + 1}/${date.getDate()}`;
      const idFriendlyShortDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

      // Decide if label is above
      const labelAbove = (total < (largestTotal / 3));

      // Create a bar
      const barElem = (
        <AttendanceTotalTooltip
          tooltipText={`Total attendance on ${shortDate}`}
        >
          <div
            className="d-flex flex-col align-items-end justify-content-center"
            style={{
              height: `${TOTAL_BAR_HEIGHT}px`,
            }}
          >
            <div
              className="d-inline-block bg-light position-relative"
              style={{
                width: `${TOTAL_BAR_WIDTH}px`,
                height: `${Math.round((total / largestTotal) * TOTAL_BAR_HEIGHT)}px`,
              }}
            >
              {/* Label */}
              <div
                className={`text-${labelAbove ? 'light' : 'dark'} font-weight-bold text-center AttendanceTableFooter-total-attendance-label`}
                id={`AttendanceTableFooter-total-attendance-on-${idFriendlyShortDate}`}
                style={{
                  position: 'absolute',
                  top: (
                    labelAbove
                      ? '-25px'
                      : undefined
                  ),
                  width: `${TOTAL_BAR_WIDTH}px`,
                  fontSize: '16px',
                }}
              >
                {total}
              </div>
            </div>
          </div>
        </AttendanceTotalTooltip>
      );
      return (
        <td
          className="bg-secondary text-light font-weight-bold"
          key={date}
          style={{
            padding: 0,
          }}
          aria-label={`total attendance on ${shortDate} is ${total}`}
        >
          {barElem}
        </td>
      );
    });

    return (
      <tfoot>
        <tr>
          <th
            scope="row"
            className="bg-secondary text-light"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: `${TOTAL_BAR_HEIGHT}px` }}
            >
              <div>
                Total Attendance:
              </div>
            </div>
          </th>
          {totalsCells}
        </tr>
      </tfoot>
    );
  }
}

AttendanceTableFooter.propTypes = {
  // Array of date string
  dateKeys: PropTypes.arrayOf(PropTypes.node).isRequired,
  // Array of Arrays of String, null, or numbers
  valueLists: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default AttendanceTableFooter;
