/**
 * Show a list of events to recover (unarchive)
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons';

// Import other components
import ArchivedEventItem from './ArchivedEventItem';
import RecoverEventResultsModal from './RecoverEventResultsModal';

// Import helpers
import visitServerEndpoint from '../../helpers/visitServerEndpoint';
import logAction from '../../helpers/logAction';

// Import shared components
import ErrorAlert from '../../shared/ErrorAlert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import NothingHereNotice from '../../shared/NothingHereNotice';
import TabBox from '../../shared/TabBox';

class RecoverEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error message
      fatalErrorMessage: null,
      // List of archived events
      archivedEvents: [],
    };
  }

  /**
   * Load the list of archived events on mount
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    try {
      // Get the list of archived events
      const archivedEvents = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events/archived`,
        method: 'GET',
        params: {
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });

      // Update the state
      return this.setState({
        archivedEvents,
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Recover an event
   * @author Gabe Abrams
   * @param {string} ihid - the id of the event to recover
   */
  async recoverEvent(ihid) {
    const { courseId } = this.props;
    let { archivedEvents } = this.state;

    // Update the state
    this.setState({ loading: true });

    try {
      // Recover the event
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events/${ihid}/recover`,
        method: 'PUT',
        params: {
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });

      // Keep recovered event for success modal
      const recoveredEvent = archivedEvents.find((event) => {
        return (event.ihid === ihid);
      });

      // Remove the event from the list
      archivedEvents = archivedEvents.filter((event) => {
        return (event.ihid !== ihid);
      });

      // Update the state
      return this.setState({
        archivedEvents,
        recoveredEvent,
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Render RecoverEvent
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props and state
    const {
      courseName,
    } = this.props;
    const {
      fatalErrorMessage,
      loading,
      archivedEvents,
      recoveredEvent,
    } = this.state;

    /* ----------------------- Error Screen ----------------------- */

    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
        />
      );
    }

    /* ----------------------- Loading Screen ----------------------- */

    if (loading) {
      return (
        <LoadingSpinner />
      );
    }
    /*-----------------------------------------------------------------*/
    /*                    Recover Success Modal                        */
    /*-----------------------------------------------------------------*/

    let modal;

    if (recoveredEvent) {
      // Create the recovered event modal
      modal = (
        <RecoverEventResultsModal
          event={recoveredEvent}
          onClose={() => {
            this.setState({
              recoveredEvent: null,
            });

            // Log this
            logAction({
              type: 'close',
              description: 'recover success modal',
              metadata: {
                isAdminFeature: true,
              },
            });
          }}
        />
      );
    }

    /*-----------------------------------------------------------------*/
    /*                  Archive Event List                             */
    /*-----------------------------------------------------------------*/

    // Create the archived event list
    const eventItems = archivedEvents.map((event) => {
      return (
        <ArchivedEventItem
          key={event.ihid}
          event={event}
          onRecover={() => {
            this.recoverEvent(event.ihid);

            // Log this
            logAction({
              type: 'click',
              description: 'recover course event button',
              metadata: {
                event,
                isAdminFeature: true,
              },
            });
          }}
        />
      );
    });

    return (
      <div>
        {modal}
        {/* Title */}
        <h3>
          <FontAwesomeIcon
            icon={faTrashRestore}
            className="mr-2"
          />
          Recover Deleted Course Events
        </h3>

        {/* Current Course */}
        <p className="lead">
          Current course:&nbsp;
          {courseName}
        </p>

        {/* List of recoverable events */}
        <div className="mt-4">
          <TabBox
            title="Deleted Course Events"
            noPaddingOnBottom
          >
            {archivedEvents.length === 0 && (
              <NothingHereNotice
                title="No deleted events"
                subtitle="This course has no deleted events right now. Please check back later."
              />
            )}
            {eventItems}
          </TabBox>
        </div>
      </div>
    );
  }
}

RecoverEvent.propTypes = {
  // Canvas ID for the current course
  courseId: PropTypes.number.isRequired,
  // Name of the current course
  courseName: PropTypes.string.isRequired,
};

export default RecoverEvent;
