/**
 * A scrollable div that events will contain events
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import Constants
import COMPONENT_SIZE from './constants/COMPONENT_SIZE';

class EventScrollpane extends Component {
  /**
   * Render EventScrollpane
   * @author Gabe Abrams
   */
  render() {
    const { children } = this.props;
    // Limit hight to cut 10th child in half to emphasize scrolling
    const maxHeight = (COMPONENT_SIZE.EVENT_PREVIEW_MIN_HEIGHT
      + COMPONENT_SIZE.EVENT_PREVIEW_BUFFER_HEIGHT)
      * 9.4;
    return (
      <div
        className="EventScrollpane-container"
        style={{
          // sized to scroll child events
          maxHeight,
          overflowY: 'auto', // Scroll if needed
        }}
      >
        {children}
      </div>
    );
  }
}

EventScrollpane.propTypes = {
  // Children/contents inside the scroll pane
  children: PropTypes.node.isRequired,
};

export default EventScrollpane;
