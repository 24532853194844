/**
 * A notice indicating that no items are left
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKiwiBird } from '@fortawesome/free-solid-svg-icons';

class NothingHereNotice extends Component {
  /**
   * Render NothingHereNotice
   * @author Gabe Abrams
   */
  render() {
    const {
      title,
      subtitle,
    } = this.props;

    // Full UI
    return (
      <div className="alert alert-warning text-center mb-2">
        {/* Bird Icon */}
        <div style={{ fontSize: '40px', lineHeight: '30px' }}>
          <FontAwesomeIcon icon={faKiwiBird} />
        </div>

        {/* Title */}
        <h5 className="text-center m-0 NothingHereNotice-title">
          {title}
        </h5>

        {/* Subtitle */}
        <div className="text-center NothingHereNotice-subtitle">
          {subtitle}
        </div>
      </div>
    );
  }
}

NothingHereNotice.propTypes = {
  // Title of the notice
  title: PropTypes.node,
  // Subtitle of the notice
  subtitle: PropTypes.node,
};

NothingHereNotice.defaultProps = {
  // Default title
  title: 'Nothing Here',
  // Default subtitle
  subtitle: 'Please check back later.',
};

export default NothingHereNotice;
