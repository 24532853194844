let topRef;
let bottomRef;

/**
 * Handlers for scrolling to the top/bottom of the page
 * @author Gabe Abrams
 */
export default {
  /**
   * Save a ref to the top of page element
   * @author Gabe Abrams
   * @param {ref} ref - the ref to the element
   */
  saveTopRef: (ref) => {
    topRef = ref;
  },

  /**
   * Save a ref to the bottom of page element
   * @author Gabe Abrams
   * @param {ref} ref - the ref to the element
   */
  saveBottomRef: (ref) => {
    bottomRef = ref;
  },

  /**
   * Scroll to top of page
   * @author Gabe Abrams
   * @param {boolean} instant - if true, scroll immediately
   */
  toTop: (instant) => {
    // Wait a moment first if not instant
    setTimeout(() => {
      // Only scroll to top if we have an element at the end
      if (topRef) {
        topRef.scrollIntoView({
          behavior: (instant ? undefined : 'smooth'),
        });
      }
    }, instant ? 0 : 100);
  },

  /**
   * Scroll to bottom of page
   * @author Gabe Abrams
   * @param {boolean} instant - if true, scroll immediately
   */
  toBottom: (instant) => {
    // Wait a moment first if not instant
    setTimeout(() => {
      // Only scroll to bottom if we have an element at the end
      if (bottomRef) {
        bottomRef.scrollIntoView({
          behavior: (instant ? undefined : 'smooth'),
        });
      }
    }, instant ? 0 : 100);
  },
};
