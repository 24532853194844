/**
 * Edit/update course settings
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTasks,
  faCheck,
  faSave,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import ExpandableDrawer from '../shared/ExpandableDrawer';
import TabBox from '../shared/TabBox';

// Import helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import logAction from '../helpers/logAction';
import ErrorAlert from '../shared/ErrorAlert';

class EditCourseSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Current state of the settings
      settings: {},
      // True if loading
      loading: true,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
    };
  }

  /**
   * Load settings
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    try {
      const settings = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/settings`,
        method: 'GET',
        params: {
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });

      return this.setState({
        settings: settings || {},
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Save settings and exit
   * @author Gabe Abrams
   */
  async save() {
    // Deconstruct props
    const {
      onDone,
      courseId,
    } = this.props;

    // Show the loading indicator
    this.setState({
      loading: true,
    });

    // Try to save settings
    const { settings } = this.state;
    try {
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/settings`,
        method: 'POST',
        params: {
          settings: JSON.stringify(settings),
          allowCourseMismatch: true, // Admin panel can access all courses
        },
      });

      // Finish
      onDone();

      // Log this
      logAction({
        type: 'save',
        description: 'course settings',
        metadata: {
          isAdminFeature: true,
          settings,
        },
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Render EditCourseSettings
   * @author Gabe Abrams
   */
  render() {
    const {
      courseName,
      onChangesMade,
    } = this.props;
    const {
      settings,
      loading,
      fatalErrorMessage,
      fatalErrorCode,
    } = this.state;

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
          code={fatalErrorCode}
          showReloadButton
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    /* ----------------------- Settings Editor ---------------------- */

    const dceStudentsBannedFromClass = (
      settings.eventTypesBannedForDCEStudents
      && settings.eventTypesBannedForDCEStudents.indexOf('class') >= 0
    );
    const fasStudentsBannedFromClass = (
      settings.eventTypesBannedForFASStudents
      && settings.eventTypesBannedForFASStudents.indexOf('class') >= 0
    );

    return (
      <div>
        <h2 className="m-0">
          Edit Settings for Current Course
        </h2>
        <p className="lead">
          Current course:&nbsp;
          {courseName}
        </p>

        {/* Permissions */}
        <TabBox
          title={(
            <span>
              <FontAwesomeIcon
                icon={faTasks}
                className="mr-2"
              />
              Permissions
            </span>
          )}
          noPaddingOnBottom
        >
          {/* DCE Students */}
          <ExpandableDrawer
            title="DCE Students..."
            noMarginOnBottom
            contents={(
              <span>
                {/* Not Banned */}
                <button
                  type="button"
                  id="EditCourseSettings-allow-dce-in-class-button"
                  className={`btn btn-${!dceStudentsBannedFromClass ? 'warning' : 'secondary'} btn-sm m-0 mr-2`}
                  aria-label="indicate that DCE students can attend class"
                  onClick={() => {
                    // Skip if already changed
                    if (!dceStudentsBannedFromClass) {
                      return;
                    }

                    // Update settings
                    settings.eventTypesBannedForDCEStudents = (
                      (settings.eventTypesBannedForDCEStudents || [])
                        .filter((type) => {
                          return type !== 'class';
                        })
                    );

                    this.setState({ settings });

                    // Note the change
                    onChangesMade();
                  }}
                >
                  {!dceStudentsBannedFromClass && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1"
                    />
                  )}
                  Can Join Class
                </button>

                {/* Ban */}
                <button
                  type="button"
                  id="EditCourseSettings-ban-dce-from-class-button"
                  className={`btn btn-${dceStudentsBannedFromClass ? 'warning' : 'secondary'} btn-sm m-0 mr-2`}
                  aria-label="indicate that DCE students cannot attend class"
                  onClick={() => {
                    // Skip if already changed
                    if (dceStudentsBannedFromClass) {
                      return;
                    }

                    // Update settings
                    settings.eventTypesBannedForDCEStudents = (
                      settings.eventTypesBannedForDCEStudents
                      || []
                    );
                    if (
                      !settings.eventTypesBannedForDCEStudents
                        .some((type) => {
                          return type === 'class';
                        })
                    ) {
                      // Class not in the list. Add it!
                      settings.eventTypesBannedForDCEStudents.push('class');
                    }

                    this.setState({ settings });

                    // Note the change
                    onChangesMade();
                  }}
                >
                  {dceStudentsBannedFromClass && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1"
                    />
                  )}
                  Banned from all events with type &quot;Class&quot;
                </button>
              </span>
            )}
          />
          {/* Description of Class */}
          <div className="text-left small mb-2 text-muted">
            Classes are events where the type is &apos;Class&apos;.
            When a user is banned,
            that event doesn&apos;t show up in their event list.
          </div>

          {/* FAS Students */}
          <ExpandableDrawer
            title="FAS Students..."
            noMarginOnBottom
            contents={(
              <span>
                {/* Not Banned */}
                <button
                  type="button"
                  id="EditCourseSettings-allow-fas-in-class-button"
                  className={`btn btn-${!fasStudentsBannedFromClass ? 'warning' : 'secondary'} btn-sm m-0 mr-2`}
                  aria-label="indicate that FAS students can attend class"
                  onClick={() => {
                    // Skip if already changed
                    if (!fasStudentsBannedFromClass) {
                      return;
                    }

                    // Update settings
                    settings.eventTypesBannedForFASStudents = (
                      (settings.eventTypesBannedForFASStudents || [])
                        .filter((type) => {
                          return type !== 'class';
                        })
                    );

                    this.setState({ settings });

                    // Note the change
                    onChangesMade();
                  }}
                >
                  {!fasStudentsBannedFromClass && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1"
                    />
                  )}
                  Can Join Class
                </button>

                {/* Ban */}
                <button
                  type="button"
                  id="EditCourseSettings-ban-fas-from-class-button"
                  className={`btn btn-${fasStudentsBannedFromClass ? 'warning' : 'secondary'} btn-sm m-0 mr-2`}
                  aria-label="indicate that FAS students cannot attend class"
                  onClick={() => {
                    // Skip if already changed
                    if (fasStudentsBannedFromClass) {
                      return;
                    }

                    // Update settings
                    settings.eventTypesBannedForFASStudents = (
                      settings.eventTypesBannedForFASStudents
                      || []
                    );
                    if (
                      !settings.eventTypesBannedForFASStudents
                        .some((type) => {
                          return type === 'class';
                        })
                    ) {
                      // Class not in the list. Add it!
                      settings.eventTypesBannedForFASStudents.push('class');
                    }

                    this.setState({ settings });

                    // Note the change
                    onChangesMade();
                  }}
                >
                  {fasStudentsBannedFromClass && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1"
                    />
                  )}
                  Banned from all events with type &quot;Class&quot;
                </button>
              </span>
            )}
          />
          {/* Description of Class */}
          <div className="text-left small text-muted mb-1">
            Classes are events where the type is &apos;Class&apos;.
            When a user is banned,
            that event doesn&apos;t show up in their event list.
          </div>
        </TabBox>

        {/* Buttons */}
        <div className="mt-4">
          {/* Save Button */}
          <button
            type="button"
            className="btn btn-dark btn-lg"
            aria-label="save changes to settings"
            onClick={() => {
              this.save();
            }}
          >
            <FontAwesomeIcon
              icon={faSave}
              className="mr-2"
            />
            Save Settings
          </button>
        </div>
      </div>
    );
  }
}

EditCourseSettings.propTypes = {
  // CourseId for the current course
  courseId: PropTypes.number.isRequired,
  // Name of the current course
  courseName: PropTypes.string.isRequired,
  // Handler to call if the user has made changes
  onChangesMade: PropTypes.func.isRequired,
  // Handler to call when user is done
  onDone: PropTypes.func.isRequired,
};

export default EditCourseSettings;
