// Import constants
import EVENT_STATUSES from '../constants/EVENT_STATUSES';
import getBatchCreateSuccessMessage from '../constants/getBatchCreateSuccessMessage';

/* ------------------------ ID Management ----------------------- */

// The next id number (must be incremented before used, so first id is "1")
let nextId = 0;

/**
 * Create a new event ID
 * @author Gabe Abrams
 * @return {number} next unique id for the event
 */
const genID = () => {
  nextId += 1;
  return nextId;
};

/* ---------------------------- Class --------------------------- */

/**
 * A prospective event that might be created
 * @author Gabe Abrams
 */
class ProspectiveEvent {
  /**
   * Initialize the ProspectiveEvent
   * @author Gabe Abrams
   * @param {object} params - an object containing all arguments
   * @param {string} params.crn - the crn for the course
   * @param {string} params.courseCode - the course code
   * @param {string} params.hostEmail - the host email for the event to create
   * @param {function} params.applyUpdates - an async function that takes an
   *   update map and applies the updates to the sheet. Takes just one argument:
   *   { prop => value } where prop can be any of the following:
   *   openZoomLink, gatherLink, zoomPassword, canvasLink, results
   * @param {boolean} [params.isLockAutoRecordSettingOn] - if true, the event
   *   auto-recording setting will be editable
   * @param {boolean} [params.isWaitingRoomOn] - if true, the meeting will have
   *   a waiting room
   * @param {boolean} [params.isAutoRecordOn] - if true, the meeting will be
   *   automatically recorded to the cloud
   * @param {boolean} [params.isDCEBanOn] - if true, DCE students will be banned
   *   from seeing or joining this event
   * @param {boolean} [params.isFASBanOn] - if true, FAS students will be banned
   *   from seeing or joining this event
   * @param {string[]} [params.skipReasons] - if defined, this event will not be
   *   created and this array of strings is a list of human-readable
   *   explanations for why the event cannot be created
   * @param {boolean} [params.alreadyHasResults] - if true, this event will be
   *   skipped because it already has text in one of the result columns
   * @param {boolean} [params.isEmergencyEvent] - if true, this event will
   *   be marked as an emergency event
   * @param {boolean} [params.ensureLoungeExists] - if true and no study lounge
   *   exists, create a study lounge under the same account as the event
   * @param {boolean} [params.hostVideoDisabled] - if true, force host video to
   *   be off
   * @param {string} [params.customEventName] - if defined, the custom name
   *   of the event
   * @param {boolean} [params.isWebinar] - if true, event is a webinar
   */
  constructor(params) {
    // Store params to instance variables
    this.crn = params.crn;
    this.courseCode = params.courseCode;
    this.hostEmail = params.hostEmail;
    this.isLockAutoRecordSettingOnValue = !!params.isLockAutoRecordSettingOn;
    this.isWaitingRoomOnValue = !!params.isWaitingRoomOn;
    this.isAutoRecordOnValue = !!params.isAutoRecordOn;
    this.isDCEBanOnValue = !!params.isDCEBanOn;
    this.isFASBanOnValue = !!params.isFASBanOn;
    this.applyUpdates = params.applyUpdates;
    this.skipReasons = params.skipReasons;
    this.alreadyHasResultsValue = !!params.alreadyHasResults;
    this.isEmergencyEventValue = !!params.isEmergencyEvent;
    this.ensureLoungeExists = !!params.ensureLoungeExists;
    this.hostVideoDisabled = !!params.hostVideoDisabled;
    this.customEventName = params.customEventName;
    this._isWebinar = !!params.isWebinar;
    // Initialize status
    this.status = EVENT_STATUSES.PENDING;

    // Get an id for this event
    this.id = genID();

    // Initialize a map of updates
    this.updates = {}; // prop => new value
  }

  /* --------------------------- Getters -------------------------- */

  /**
   * Get the id of the event
   * @author Gabe Abrams
   * @return {number} the unique id
   */
  getID() {
    return this.id;
  }

  /**
   * Get the current status of the event
   * @author Gabe Abrams
   * @return {string} event status
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get the CRN for the course that will contain this event
   * @author Gabe Abrams
   * @return {number} the crn
   */
  getCRN() {
    return this.crn;
  }

  /**
   * Get the course code for the course that will contain this event
   * @author Gabe Abrams
   * @return {string} the course code
   */
  getCourseCode() {
    return this.courseCode;
  }

  /**
   * Get the email of the zoom host that will own the corresponding zoom meeting
   * @author Gabe Abrams
   * @return {string} the zoom host email
   */
  getHostEmail() {
    return this.hostEmail;
  }

  /**
   * Check if this event auto-record setting will be editable by non-admins
   * @author Gabe Abrams
   * @return {boolean} true if event auto-record setting will be editable
   */
  isLockAutoRecordSettingOn() {
    return this.isLockAutoRecordSettingOnValue;
  }

  /**
   * Check if this event will have a waiting room
   * @author Gabe Abrams
   * @return {boolean} true if event will have a waiting room
   */
  isWaitingRoomOn() {
    return this.isWaitingRoomOnValue;
  }

  /**
   * Check if this event will be auto-recorded to the cloud
   * @author Gabe Abrams
   * @return {boolean} true if event will be auto-recorded
   */
  isAutoRecordOn() {
    return this.isAutoRecordOnValue;
  }

  /**
   * Check if DCE students are banned from this event
   * @author Gabe Abrams
   * @return {boolean} true if banned
   */
  isDCEBanOn() {
    return this.isDCEBanOnValue;
  }

  /**
   * Check if FAS students are banned from this event
   * @author Gabe Abrams
   * @return {boolean} true if banned
   */
  isFASBanOn() {
    return this.isFASBanOnValue;
  }

  /**
   * Check if this event is an emergency meeting
   * @author Gabe Abrams
   * @return {boolean} true if this is an emergency meeting
   */
  isEmergencyEvent() {
    return this.isEmergencyEventValue;
  }

  /**
   * Check if ensuring that a study lounge exists
   * @author Gabe Abrams
   * @returns {boolean} true if ensuring a lounge exists
   */
  isEnsuringLoungeExists() {
    return this.ensureLoungeExists;
  }

  /**
   * Check if host zoom is forced off
   * @author Gabe Abrams
   * @returns {boolean} true if host zoom is off
   */
  isHostVideoDisabled() {
    return this.hostVideoDisabled;
  }

  /**
   * Get custom event name
   * @author Gabe Abrams
   * @returns {string} the custom event name
   */
  getCustomEventName() {
    return this.customEventName;
  }

  /**
   * Check if the event is a webinar
   * @author Gabe Abrams
   * @returns {boolean} true if the event is a webinar
   */
  isWebinar() {
    return this._isWebinar;
  }

  /**
   * Return the reasons why this event was skipped
   * @author Gabe Abrams
   * @return {string[]} skip reasons
   */
  listSkipReasons() {
    return this.skipReasons || [];
  }

  /**
   * Check if this event already has results
   * @author Gabe Abrams
   * @return {boolean} true if the event is being skipped because it already
   *   has text in one of the result columns
   */
  alreadyHasResults() {
    return this.alreadyHasResultsValue;
  }

  /**
   * Get the error message explaining why the event failed
   * @author Gabe Abrams
   * @return {string} error message
   */
  getErrorMessage() {
    return this.errorMessage;
  }

  /**
   * Get the warning message
   * @author Gabe Abrams
   * @return {string} warning message
   */
  getWarningMessage() {
    return this.warningMessage;
  }

  /* --------------------------- Setters -------------------------- */

  /**
   * Set the event Zoom link
   * @author Gabe Abrams
   * @param {string} openZoomLink - the zoom link for the event
   */
  setOpenZoomLink(openZoomLink) {
    if (openZoomLink && openZoomLink.trim().length > 0) {
      this.updates.openZoomLink = openZoomLink.trim();
    }
  }

  /**
   * Set the event Gather link
   * @author Gabe Abrams
   * @param {string} gatherLink - the Gather link for the event
   */
  setGatherLink(gatherLink) {
    if (gatherLink && gatherLink.trim().length > 0) {
      this.updates.gatherLink = gatherLink.trim();
    }
  }

  /**
   * Set the event Zoom password
   * @author Gabe Abrams
   * @param {string} zoomPassword - the zoom meeting password for the event
   */
  setZoomPassword(zoomPassword) {
    if (zoomPassword && zoomPassword.trim().length > 0) {
      this.updates.zoomPassword = zoomPassword.trim();
    }
  }

  /**
   * Set the Canvas course link
   * @author Gabe Abrams
   * @param {string} canvasLink - the link to the Canvas course site
   */
  setCanvasLink(canvasLink) {
    if (canvasLink && canvasLink.trim().length > 0) {
      this.updates.canvasLink = canvasLink.trim();
    }
  }

  /**
   * Set the results of the event creation process
   * @author Gabe Abrams
   * @param {string} status - the status of the event creation
   * @param {string} [message] - a human-readable message describing the results
   *   (required if status is CREATED_WITH_WARNING)
   * @return {object} an update transaction
   */
  setResults(status, message) {
    // Change the status
    this.status = status;

    // Save error message
    if (status === EVENT_STATUSES.FAILED) {
      this.errorMessage = message;
    }

    // Save warning message
    if (status === EVENT_STATUSES.CREATED_WITH_WARNING) {
      this.warningMessage = message;
    }

    // Use success message if relevant
    const newMessage = (
      // Check if successful and another message isn't already provided
      (status === EVENT_STATUSES.SUCCESSFUL && !message)
        ? getBatchCreateSuccessMessage(
          this.ensureLoungeExists
        ) // Default mess.
        : message // Use provided message
    );

    // Store the update
    this.updates.results = status;
    if (newMessage && newMessage.trim().length > 0) {
      this.updates.results += `: ${newMessage.trim()}`;
    }
  }

  /* ---------------------------- Save ---------------------------- */

  /**
   * Save changes to the data store
   * @author Gabe Abrams
   */
  async save() {
    // Perform updates
    await this.applyUpdates(this.updates);

    // Clear updates
    this.updates = {};
  }
}

export default ProspectiveEvent;
