/**
 * Modal with the results of the process
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../../shared/Modal';

class BatchEventCreationResultsModal extends Component {
  /**
   * Render BatchEventCreationResultsModal
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      halted,
      numSuccessful,
      numWarning,
      numFailed,
      numSkipped,
      onClose,
    } = this.props;

    return (
      <Modal
        title={`Results${halted ? ' (Halted)' : ''}`}
        body={(
          <div>
            {/* Results */}
            <ul>
              {/* Num Successful */}
              <li>
                <strong>
                  Events created successfully:&nbsp;
                </strong>
                {numSuccessful}
                {' '}
                event
                {numSuccessful === 1 ? '' : 's'}
              </li>
              {/* Num Warning */}
              <li className={numWarning > 0 ? 'text-danger' : ''}>
                <strong>
                  Events we created with warnings:&nbsp;
                </strong>
                {numWarning}
                {' '}
                event
                {numWarning === 1 ? '' : 's'}
              </li>
              {/* Num Failed */}
              <li className={numFailed > 0 ? 'text-danger' : ''}>
                <strong>
                  Events we failed to create:&nbsp;
                </strong>
                {numFailed}
                {' '}
                event
                {numFailed === 1 ? '' : 's'}
              </li>
              {/* Num Skipped (if there are any) */}
              {numSkipped > 0 && (
                <li>
                  <strong>
                    Events skipped (due to halt):&nbsp;
                  </strong>
                  {numSkipped}
                  {' '}
                  event
                  {numSkipped === 1 ? '' : 's'}
                </li>
              )}
            </ul>

            {/* Notes */}
            <div className="mt-3">
              <strong>
                <FontAwesomeIcon
                  icon={faSave}
                  className="mr-2"
                />
                Saved!&nbsp;
              </strong>
              Results are in your spreadsheet.
              You can also scroll through the results on this page.
            </div>
          </div>
        )}
        type={Modal.TYPES.NO_BUTTONS}
        onClose={onClose}
      />
    );
  }
}

BatchEventCreationResultsModal.propTypes = {
  // Handler for when the modal is closed
  onClose: PropTypes.func.isRequired,
  // True if process was halted
  halted: PropTypes.bool,
  // Number of successfully created events
  numSuccessful: PropTypes.number,
  // Number of events created with warnings
  numWarning: PropTypes.number,
  // Number of failed events
  numFailed: PropTypes.number,
  // Number of events that were skipped
  numSkipped: PropTypes.number,
};

BatchEventCreationResultsModal.defaultProps = {
  // Not halted
  halted: false,
  // None
  numSuccessful: 0,
  // None
  numWarning: 0,
  // None
  numFailed: 0,
  // None
  numSkipped: 0,
};

export default BatchEventCreationResultsModal;
