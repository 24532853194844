/**
 * Manage the events in an event
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import NothingHereNotice from '../shared/NothingHereNotice';
import VisitLinkModal from '../shared/VisitLinkModal';

// Import shared prop types
import Event from '../shared/propTypes/Event';
import Modal from '../shared/Modal';

// Import other components
import RecordingPreview from './RecordingPreview';

// Helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import setPagePath from '../helpers/setPagePath';
import preprocessRecordingPairs from '../helpers/preprocessRecordingPairs';
import logError from '../helpers/logError';
import logAction from '../helpers/logAction';
import formatDate from '../helpers/formatDate';
import scroll from '../helpers/scroll';

// Import constants
import ERROR_CODES from '../constants/ERROR_CODES';
import ATTENDANCE_METHODS from '../constants/ATTENDANCE_METHODS';
import EVENT_TYPES_MAP from '../constants/EVENT_TYPES_MAP';

// Import style
import './style.css';

/* --------------------------- Helpers -------------------------- */

/**
 * Sort recordings by timestamp (newest on top)
 * @author Gabe Abrams
 * @param {object[]} recordingPairs - list of recording pairs to sort
 * @return {object[]} sorted recording pairs
 */
const sortByTimestamp = (recordingPairs) => {
  // Duplicate so we can edit
  const sortedPairs = recordingPairs;

  // Sort by timestamp
  sortedPairs.sort((a, b) => {
    if (a.recording.timestamp < b.recording.timestamp) {
      return 1;
    }
    if (a.recording.timestamp > b.recording.timestamp) {
      return -1;
    }
    return 0;
  });

  // Return
  return sortedPairs;
};

/* --------------------------- Caching -------------------------- */

const BOUNDARY_WIDTH_MS = 2419000000;
const DAY_MS = 86400000;

// Batch date boundaries
const batchDateBoundaries = [];
let boundaryDate = new Date(Date.now() + DAY_MS); // Start 1 day in future
for (let batch = 1; batch <= 6; batch++) {
  // Move date one day earlier
  boundaryDate = new Date(boundaryDate.getTime() - DAY_MS);
  // Save date as the end of the range
  const end = boundaryDate;

  // Move one boundary width earlier
  boundaryDate = new Date(boundaryDate.getTime() - BOUNDARY_WIDTH_MS);
  // Save the date as the beginning of the range
  const start = boundaryDate;

  // Save the pair
  batchDateBoundaries[batch] = {
    start,
    end,
  };
}

const recordingCache = {}; // hostId => batchNumber => recordingPair[]
// Batch number = 1 for the last month, 2 for the month before, etc.

/**
 * Get recordings for a Zoom meeting
 * @author Gabe Abrams
 * @param {number} courseId - the id of the course
 * @param {number[]} zoomIds - the id of the zoom meetings of interest
 * @param {string[]} hostIds - the hostIds for the hosts of the meeting
 * @param {number} numBatches - the number of the batch to load
 * @return {RecordingPair[]} recording pairs
 */
const listZoomRecordingPairs = async (opts) => {
  const {
    courseId,
    zoomIds,
    hostIds,
    numBatches,
  } = opts;

  // Create empty recording pairs list (all pairs will be added to this list)
  const recordingPairs = [];

  // Don't allow duplicates
  const recordingPairAlreadyAdded = {}; // <zoomId>-<timestamp> => true if added

  // Go through hosts
  for (let i = 0; i < hostIds.length; i++) {
    const hostId = hostIds[i];
    // Add entry if cache doesn't contain it
    if (!recordingCache[hostId]) {
      recordingCache[hostId] = {};
    }

    // Loop through all relevant batch numbers
    for (let batch = 1; batch <= numBatches; batch++) {
      // Check for cached recordings
      if (!(batch in recordingCache[hostId])) {
        // Batch not cached. Load it.
        const { start, end } = batchDateBoundaries[batch];

        // Load recordings from server
        const loadedRecordingPairs = await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/hosts/${hostId}/recordings/all`,
          method: 'GET',
          params: {
            startMS: start.getTime(),
            endMS: end.getTime(),
          },
        });

        // Add ids
        const loadedPairsWithIds = loadedRecordingPairs.map((pair) => {
          const newPair = pair;

          // Add id
          const { recording } = pair;
          newPair.id = `${recording.zoomId}-${recording.timestamp}`;
          return newPair;
        });

        // Pre-process and store in cache
        recordingCache[hostId][batch] = (
          preprocessRecordingPairs(loadedPairsWithIds) || []
        );
      }

      // Now we have recordings in the cache. Find the relevant ones and add
      //   them to the running list
      recordingCache[hostId][batch].forEach((recordingPair) => {
        // Make sure the zoom meeting matches
        if (zoomIds.indexOf(recordingPair.recording.zoomId) < 0) {
          return;
        }
        // Make sure the recording pair isn't already in the list
        const key = `${recordingPair.recording.zoomId}-${recordingPair.recording.timestamp}`;
        if (recordingPairAlreadyAdded[key]) {
          return;
        }
        // Add it
        recordingPairs.push(recordingPair);
        // Keep track
        recordingPairAlreadyAdded[key] = true;
      });
    }
  }

  return recordingPairs;
};

/**
 * Update the publish status of a Zoom recording
 * @author Gabe Abrams
 * @param {number} pairId - the id of the pair to update
 * @param {boolean} published - true if the new status for the recording is
 *   published
 */
const updateRecordingPublishStatus = (pairId, published) => {
  Object.keys(recordingCache).forEach((hostId) => {
    Object.keys(recordingCache[hostId]).forEach((batchNumber) => {
      const pairs = recordingCache[hostId][batchNumber];
      for (let i = 0; i < pairs.length; i++) {
        const { id } = pairs[i];

        // Check if this is a match
        if (id === pairId) {
          recordingCache[hostId][batchNumber][i].published = published;
          break;
        }
      }
    });
  });
};

/* -------------------------- Component ------------------------- */

class RecordingsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error message if one occurred
      fatalErrorMessage: null,
      // List of all recording pairs in form { published, recording }
      recordingPairs: [],
      // Link that the current user is opening
      linkToOpen: null,
      // True if the link to open is loading
      linkToOpenLoading: false,
      // The current number of batches to fetch
      numLoadedBatches: 1,
      // True if showing publish successful modal
      showPublishSuccessModal: false,
      // True if showing unpublish successful modal
      showUnpublishSuccessModal: false,
      // List of events in the course
      events: null,
    };
  }

  /*------------------------------------------------------------------------*/
  /*                                 Loaders                                */
  /*------------------------------------------------------------------------*/

  /**
   * Load on mount
   * @author Gabe Abrams
   */
  componentDidMount() {
    this.load();

    // Also scroll to top
    scroll.toTop();
  }

  /**
   * Load recordings
   * @author Gabe Abrams
   * @param {boolean} loadNextBatch - if true, load the next batch of
   *   recordings. Otherwise, just re-load the recordings
   */
  async load(loadNextBatch) {
    // Deconstruct state
    let { numLoadedBatches } = this.state;
    const { events } = this.state;

    // Deconstruct props
    const {
      courseId,
      event,
      isLearner,
    } = this.props;

    // Increment the number of batches (if applicable)
    if (loadNextBatch) {
      numLoadedBatches += 1;
    }

    // Start the loading bar, save the number of batches to the state
    this.setState({
      numLoadedBatches,
      loading: true,
    });

    // If we don't have the list of events yet, load it!
    if (events === null) {
      // Load list of events
      try {
        const loadedEvents = await visitServerEndpoint({
          path: `/api/courses/${courseId}/events`,
          method: 'GET',
        });

        // Save to the state
        this.setState({
          events: loadedEvents,
        });
      } catch (err) {
        return this.setState({
          loading: false,
          fatalErrorMessage: err.message || 'We could not contact the server. Please check your network connection and try again.',
        });
      }
    }

    // Check which page we're on
    if (isLearner || !event) {
      /* ------------------ Published Recordings Page ----------------- */

      // Load just the published recordings
      try {
        const publishedRecordings = await visitServerEndpoint({
          path: `/api/courses/${courseId}/recordings/published`,
          method: 'GET',
        });

        // Turn the list of recordings into recording pairs with ids
        let recordingPairs = publishedRecordings.map((recording) => {
          // We got the list of published recordings. Thus, set published = true
          return {
            recording,
            id: `${recording.zoomId}-${recording.timestamp}`,
            published: true,
          };
        });

        // Sort recordings by timestamp
        recordingPairs = sortByTimestamp(recordingPairs);

        this.setState({
          recordingPairs,
          loading: false,
        });
      } catch (err) {
        return this.setState({
          fatalErrorMessage: err.message,
        });
      }
    } else {
      /* -------------------- Event Recordings Page ------------------- */

      // Load event recordings (the ones for a specific event)
      // Collect a list of zoomIds (including past ones) for the event
      const zoomIds = [];
      // > Add current zoomId
      if (event.currentZoomId) {
        zoomIds.push(event.currentZoomId);
      }
      // > Add past zoomIds
      (event.pastZoomIds || []).forEach((zoomId) => {
        zoomIds.push(zoomId);
      });

      // Collect list of hosts
      const hostIds = [];
      // > Add current host
      if (event.currentZoomHost) {
        hostIds.push(event.currentZoomHost);
      }
      // > Add past hosts
      (event.pastZoomHosts || []).forEach((host) => {
        hostIds.push(host);
      });

      // Load all recordings for the given zoomIds including unpublished
      try {
        let recordingPairs = await listZoomRecordingPairs({
          courseId,
          zoomIds,
          hostIds,
          numBatches: numLoadedBatches,
        });

        // Log this
        logAction({
          type: 'load',
          description: 'zoom recordings',
          metadata: {
            zoomIds,
            hostIds,
          },
        });

        // Augment recordings with ihid and names
        recordingPairs = recordingPairs.map((recordingPair) => {
          const newRecordingPair = recordingPair;
          newRecordingPair.recording.ihid = event.ihid;
          // Fall back to event name when no recording name
          newRecordingPair.recording.name = (
            newRecordingPair.recording.name
            || event.name
          );

          return newRecordingPair;
        });

        // Sort the pairs
        recordingPairs = sortByTimestamp(recordingPairs);

        // Save to state
        this.setState({
          recordingPairs,
          loading: false,
        });

        // Scroll to bottom of page if we loaded more recordings
        if (loadNextBatch) {
          scroll.toBottom();
        }
      } catch (err) {
        return this.setState({
          fatalErrorMessage: err.message,
        });
      }
    }
  }

  /*------------------------------------------------------------------------*/
  /*                                 Actions                                */
  /*------------------------------------------------------------------------*/

  /**
   * Publish a recording
   * @author Gabe Abrams
   * @param {object} recordingPair - the recording pair to publish
   */
  async publish(recordingPair) {
    // Deconstruct recording pair
    const { id, recording } = recordingPair;

    // Get info on course and event
    const { courseId, event } = this.props;

    // Put the loading indicator
    this.setState({
      loading: true,
    });

    // Ask server to publish the recording
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${event.ihid}/recordings/published`,
        method: 'POST',
        params: {
          recording: JSON.stringify(recording),
        },
      });

      // Log this
      logAction({
        type: 'click',
        description: 'publish recording button',
        metadata: {
          recording,
        },
      });

      // Success!

      // Store update
      updateRecordingPublishStatus(id, true);

      // Show confirmation
      this.setState({
        showPublishSuccessModal: true,
      });

      // Re-load to get updated recordings
      this.load();
    } catch (err) {
      this.setState({
        fatalErrorMessage: `The recording could not be published because an error occurred: ${err.message}`,
      });
    }
  }

  /**
   * Unpublish a recording
   * @author Gabe Abrams
   * @param {object} recordingPair - the recording pair to unpublish
   */
  async unpublish(recordingPair) {
    // Deconstruct recording pair
    const { id, recording } = recordingPair;

    // Get info on course and event
    const { courseId, event } = this.props;

    // Put the loading indicator
    this.setState({
      loading: true,
    });

    // Ask server to unpublish the recording
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${recording.ihid}/recordings/published`,
        method: 'DELETE',
        params: {
          recording: JSON.stringify(recording),
        },
      });

      // Success!

      // Log this
      logAction({
        type: 'click',
        description: 'unpublish recording',
        metadata: {
          recording,
        },
      });

      // Store update
      updateRecordingPublishStatus(id, false);

      // Show confirmation
      this.setState({
        showUnpublishSuccessModal: true,
      });

      // Update the state
      if (event) {
        // This is an event page. Need to re-load the recordings
        this.load();
      } else {
        // This is a published recordings-only page. Just remove the pair from
        //   the list
        this.setState((prevState) => {
          const updatedState = prevState;
          updatedState.recordingPairs = (
            prevState
              .recordingPairs
              .filter((pair) => {
                return (pair.id !== id);
              })
          );

          // Stop loading
          updatedState.loading = false;

          return updatedState;
        });
      }
    } catch (err) {
      return this.setState({
        fatalErrorMessage: `The recording could not be unpublished because an error occurred: ${err.message}`,
      });
    }
  }

  /**
   * Open a Zoom recording in a new tab and save attendance
   * @author Gabe Abrams
   * @param {string} ihid - the ihid for the event being attended
   * @param {number} eventTimestamp - the timestamp for when the event occurred
   * @param {string} url - the url to visit
   */
  async openRecording(ihid, eventTimestamp, url) {
    // Deconstruct props
    const { userId, courseId } = this.props;

    // Show the modal that takes the user to the recording
    this.setState({
      linkToOpen: url,
      linkToOpenLoading: true,
    });

    // Save attendance:
    try {
      await visitServerEndpoint({
        path: `/api/courses/${courseId}/events/${ihid}/attendance`,
        method: 'POST',
        params: {
          eventTimestamp,
          method: ATTENDANCE_METHODS.ASYNCHRONOUS,
          isHost: false,
        },
      });

      // Success! Stop the loading
      this.setState({
        linkToOpenLoading: false,
      });
    } catch (err) {
      // Create error message
      const fatalErrorMessage = `We could not save your progress. Please try again. If the issue persists, contact an admin with this code: "${userId}-${courseId}-${ihid}-${Date.now()}"`;

      // Log error
      logError({
        message: fatalErrorMessage,
        code: ERROR_CODES.RECORDING_WATCH_NOT_SAVED,
        metadata: {
          userId,
          courseId,
          ihid,
          timestamp: Date.now(),
          type: 'attendance',
          attendanceType: 'async',
          errorMessage: err.message,
          errorStack: err.stack,
        },
      });

      // eslint-disable-next-line no-console
      console.log('Error occurred while saving attendance:', err);

      // Show error to user
      return this.setState({ fatalErrorMessage });
    }
  }

  /*------------------------------------------------------------------------*/
  /*                                Rendering                               */
  /*------------------------------------------------------------------------*/

  /**
   * Render RecordingsPanel
   * @author Gabe Abrams
   */
  render() {
    const {
      context,
      courseId,
      event,
      isLearner,
    } = this.props;
    const {
      loading,
      fatalErrorMessage,
      recordingPairs,
      linkToOpen,
      linkToOpenLoading,
      numLoadedBatches,
      showPublishSuccessModal,
      showUnpublishSuccessModal,
      events,
    } = this.state;

    // Check if there's another batch available
    const anotherBatchAvailable = (
      // There must be another batch available
      !!batchDateBoundaries[numLoadedBatches + 1]
      // This must be an event page
      && event
    );

    // Set the page path and title
    setPagePath(
      (event ? 'Recordings' : 'Published Recordings'),
      `/courses/${courseId}`
    );

    // Error message
    if (fatalErrorMessage) {
      // Error message
      return (
        <div className="alert alert-warning d-inline-block">
          <h3 className="mb-0">
            An error occurred:
          </h3>
          {fatalErrorMessage}
        </div>
      );
    }

    // Loading spinner
    if (loading) {
      // Loading spinner
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
    }

    /* ------------------------ Played Modal ------------------------ */

    // Create a modal variable
    let modal;

    if (linkToOpen) {
      // Create a modal
      modal = (
        <VisitLinkModal
          title="Play Recording"
          body="Click below to open the recording in a new tab."
          label="recording"
          buttonText="Play Recording"
          icon={faFileVideo}
          link={linkToOpen}
          loading={linkToOpenLoading}
          onClose={() => {
            // Close the visit link modal
            this.setState({ linkToOpen: null });
          }}
        />
      );
    } else if (showPublishSuccessModal) {
      // Confirm successful publish
      let whoCanWatch;
      if (event.banDCEStudents && event.banFASStudents) {
        whoCanWatch = 'Teaching team members (but no students)';
      } else if (event.banDCEStudents) {
        whoCanWatch = 'All FAS students';
      } else if (event.banFASStudents) {
        whoCanWatch = 'All DCE students';
      } else {
        whoCanWatch = 'All students';
      }
      modal = (
        <Modal
          type={Modal.TYPES.OKAY}
          title="Success!"
          body={(
            <div>
              <p>
                {whoCanWatch}
                {' '}
                in the course can watch this recording by
                clicking the &quot;Published Recordings&quot; button
                on the Gather home page.
              </p>
              <p>
                To change the name of this recording, unpublish and
                then re-publish the recording.
              </p>
            </div>
          )}
          onClose={() => {
            this.setState({
              showPublishSuccessModal: false,
            });
          }}
        />
      );
    } else if (showUnpublishSuccessModal) {
      // Confirm unsuccessful publish
      modal = (
        <Modal
          type={Modal.TYPES.OKAY}
          title="Success!"
          body="That recording is no longer available to students."
          onClose={() => {
            this.setState({
              showUnpublishSuccessModal: false,
            });
          }}
        />
      );
    }

    /* ------------- Button for Loading More Recordings ------------- */

    const loadMoreRecordingsButton = (
      anotherBatchAvailable
        ? (
          <button
            type="button"
            id="RecordingsPanel-load-more-recordings-button"
            className="btn btn-secondary"
            aria-label="load more recordings"
            onClick={() => {
              // Load another batch
              this.load(true);

              // Log this
              logAction({
                type: 'click',
                description: 'load more recordings button',
                metadata: {
                  ihid: event.ihid,
                  type: event.type,
                  name: event.name,
                },
              });
            }}
          >
            <FontAwesomeIcon
              icon={faClone}
              rotation={180}
              className="mr-2"
            />
            Load Older Recordings
          </button>
        )
        : null
    );

    /* ----------------------- Recording List ----------------------- */

    // Create message representing fetch date
    const beginningOfFetch = batchDateBoundaries[numLoadedBatches].start;
    const loadStatusMessage = (
      <span>
        {/* Date message */}
        We fetched recordings made as early as
        {' '}
        {/* Date */}
        {formatDate(beginningOfFetch)}
        .
      </span>
    );

    // Create UI
    let itemsContent;
    if (recordingPairs.length === 0) {
      // No recording pairs

      // Create message
      let message = (
        event
          ? 'This event doesn\'t have any recordings yet.'
          : 'This course doesn\'t have any published recordings yet.'
      );
      if (anotherBatchAvailable) {
        message = loadStatusMessage;
      }

      // Create UI
      itemsContent = (
        anotherBatchAvailable
          ? (
            <NothingHereNotice
              title={`No Recordings Since ${formatDate(beginningOfFetch)}`}
              subtitle={(
                <div>
                  {/* Message */}
                  <div id="RecordingsPanel-load-more-recordings-message">
                    {message}
                  </div>

                  {/* Load more button */}
                  <div className="mt-4">
                    {loadMoreRecordingsButton}
                  </div>
                </div>
              )}
            />
          )
          : (
            <NothingHereNotice
              title="No Recordings"
              subtitle={(
                // Subtitle depends on whether this is an event page
                event
                  ? 'This event doesn\'t have any recordings yet.'
                  : 'This course doesn\'t have any published recordings yet.'
              )}
            />
          )
      );
    } else {
      // At least one recording has been loaded
      itemsContent = (
        <div>
          {/* List of Recordings */}
          {
            recordingPairs
              .map((recordingPair) => {
                const { recording, published } = recordingPair;

                // Get the event type
                const { ihid } = recording;
                const matchingEvent = events.find((existingEvent) => {
                  return (ihid === existingEvent.ihid);
                });
                const eventType = (
                  matchingEvent
                    ? matchingEvent.type
                    : EVENT_TYPES_MAP.OTHER
                );

                // Create a RecordingPreview
                return (
                  <RecordingPreview
                    key={`${recording.name}-${recording.timestamp}`}
                    eventType={eventType}
                    recording={recording}
                    onPublish={() => {
                      this.publish(recordingPair);
                    }}
                    onUnpublish={() => {
                      this.unpublish(recordingPair);
                    }}
                    onPlay={(url) => {
                      this.openRecording(
                        recording.ihid,
                        recording.timestamp,
                        url
                      );
                    }}
                    isLearner={isLearner}
                    published={published}
                  />
                );
              })
          }

          {/* Load More Recordings Message */}
          {event && (
            anotherBatchAvailable
              ? (
                <div className="text-center">
                  {loadStatusMessage}

                  {/* Load Button */}
                  <div>
                    {loadMoreRecordingsButton}
                  </div>
                </div>
              )
              : (
                <div className="text-center">
                  That&apos;s it! Zoom does not allow us to load
                  older recordings.
                </div>
              )
          )}
        </div>
      );
    }

    /* ----------------- Student Explanation Message ---------------- */

    const studentExplanationMessage = (
      <div className="alert alert-warning border-0 pt-2 pb-2 pl-3 pr-3 d-inline-block">
        Students can view published recordings.
      </div>
    );

    /* ---------------------- Assemble Full UI ---------------------- */

    return (
      <div>
        {/* Modal (if there is one) */}
        {modal}

        {/* Titles */}
        <div>
          {/* Title */}
          <h2 className="mb-0">
            {/* Icon */}
            <FontAwesomeIcon
              icon={faFileVideo}
              className="d-none d-md-inline mr-2"
            />

            {/* Customize based on if this is an event page */}
            {event ? 'Recordings' : 'Published Recordings'}
          </h2>

          {/* The current context (course, event etc.) */}
          <p className="lead">
            {context}
          </p>

          {/* Help message if on the event page */}
          {(event && recordingPairs.length > 0) && (
            <div>
              {/* Small Screen View */}
              <div className="d-block d-md-none">
                {studentExplanationMessage}
              </div>

              {/* Large Screen View */}
              <div className="d-none d-md-block">
                <div className="RecordingsPanel-publish-description-container">
                  {studentExplanationMessage}

                  {/* Arrow */}
                  <div className="RecordingsPanel-publish-description-arrow-container">
                    <div
                      className="RecordingsPanel-publish-description-arrow alert alert-warning"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Items Content (either items, load button, or both) */}
        {itemsContent}
      </div>
    );
  }
}

RecordingsPanel.propTypes = {
  // Id of the current user
  userId: PropTypes.number.isRequired,
  // True if user is a learner
  isLearner: PropTypes.bool.isRequired,
  // The context description
  context: PropTypes.string.isRequired,
  // Course id
  courseId: PropTypes.number.isRequired,
  // The event to show recordings for
  event: Event,
};

RecordingsPanel.defaultProps = {
  // No event
  event: null,
};

export default RecordingsPanel;
