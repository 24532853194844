/**
 * Fixed-width input label
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import style
import './InputLabel.css';

class InputLabel extends Component {
  /**
   * Render InputLabel
   * @author Gabe Abrams
   */
  render() {
    const {
      icon,
      title,
      isAdminFeature,
    } = this.props;

    return (
      <span className={`InputLabel input-group-text ${isAdminFeature ? 'progress-bar-striped bg-success text-white' : ''}`}>
        {/* Small Screen View */}
        <span className="d-inline d-sm-none">
          {title}
        </span>

        {/* Large Screen View */}
        <span className="InputLabel-container d-none d-sm-inline">
          {/* Optional Icon */}
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="mr-2"
            />
          )}

          {/* Label */}
          {title}
        </span>
      </span>
    );
  }
}

InputLabel.propTypes = {
  // An icon to display next to the title
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object, // FontAwesome icon
  // Title of the input field
  title: PropTypes.node.isRequired,
  // True if this is an admin feature (change the theme)
  isAdminFeature: PropTypes.bool,
};

InputLabel.defaultProps = {
  // No icon
  icon: null,
  // Not an admin feature
  isAdminFeature: false,
};

export default InputLabel;
