/**
 * Get the status message to append to successful event creation events
 * @author Gabe Abrams
 * @param {boolean} ensuringLoungeExists true if ensuring a lounge exists
 * @return {string} status message
 */
export default (ensuringLoungeExists) => {
  let addon = '';
  if (ensuringLoungeExists) {
    addon += ', ensured study lounge exists';
  }
  return `Batch Created by Gather on ${(new Date()).toLocaleDateString()}${addon}`;
};
