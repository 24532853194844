/**
 * A prospective event item
 * @author Karen Dolan
*/

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faFastForward,
  faSpinner,
  faHourglassStart,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

// Import prop element
import ProspectiveEvent from '../helpers/ProspectiveEvent';

// Import Prospective event Details and Badges
import EventDetails from './EventDetails';
import EventBadges from './EventBadges';

// Import constants
import EVENT_STATUSES from '../constants/EVENT_STATUSES';
import COMPONENT_SIZE from '../constants/COMPONENT_SIZE';

// Import style
import './style.css';

class ProspectiveEventPreview extends Component {
  /**
   * Render ProspectiveEvent
   * @author Karen Dolan
   */
  render() {
    const {
      event,
      showSettings,
      beingCreated,
    } = this.props;

    /* ----------- Icon and Details of skipped-error/Prospective ---------- */
    const {
      status,
      skipReasons,
      crn,
    } = event;
    const errorMessage = event.getErrorMessage();
    const warningMessage = event.getWarningMessage();
    const hasSkipReasons = (skipReasons && skipReasons.length > 0);

    let icon;
    let iconColor;
    // On expected status types, set the icon and color
    // Unexpected status will not get an icon
    if (
      status === EVENT_STATUSES.PENDING
      || status === EVENT_STATUSES.SUCCESSFUL
      || status === EVENT_STATUSES.CREATED_WITH_WARNING
      || status === EVENT_STATUSES.FAILED
      || hasSkipReasons
      || beingCreated
    ) {
      // Set the row icon
      if (hasSkipReasons) {
        // Choose an icon
        icon = (
          event.alreadyHasResults()
            ? faFastForward // Skip icon for events with results
            : faExclamationTriangle // Error icon if formatting issues
        );
        iconColor = 'text-dark';
      } else if (status === EVENT_STATUSES.SUCCESSFUL) {
        icon = faCheckCircle;
        iconColor = 'text-dark';
      } else if (beingCreated) {
        icon = faSpinner;
        iconColor = 'fa-pulse text-dark';
      } else if (status === EVENT_STATUSES.FAILED) {
        icon = faTimesCircle;
      } else if (status === EVENT_STATUSES.CREATED_WITH_WARNING) {
        icon = faExclamationTriangle;
      } else {
        icon = faHourglassStart;
        iconColor = 'text-muted';
      }
    }

    // Determine background color
    let backgroundVariant;
    if (status === EVENT_STATUSES.FAILED) {
      backgroundVariant = 'danger';
    } else if (status === EVENT_STATUSES.CREATED_WITH_WARNING) {
      backgroundVariant = 'warning text-dark';
    } else {
      backgroundVariant = 'secondary';
    }

    return (
      <div
        className={`ProspectiveEventPreview ProspectiveEventPreview-crn-${crn} alert alert-${backgroundVariant} d-flex align-items-center font-weight-bold mb-2 p-1`}
        style={{
          // sized to support being scrolled by scrollbar
          minHeight: COMPONENT_SIZE.EVENT_PREVIEW_MIN_HEIGHT,
        }}
      >
        <div className="ProspectiveEventPreview-centered-content w-100">
          <div className="ProspectiveEventPreview-title h4 d-flex flex-row mb-0">
            {/* Event Status Icon */}
            <div className="ProspectiveEventPreview-icons">
              <FontAwesomeIcon
                icon={icon}
                className={`mr-2 ${iconColor}`}
              />
            </div>
            {/* Event Course Code */}
            <div>
              <h4 className="text-truncate mb-0">
                {event.getCourseCode() || '[empty]'}
              </h4>
            </div>
            {/* Event Course Number */}
            <div className="ml-auto align-self-end">
              (
              {event.getCRN() || '[empty]'}
              )
            </div>
          </div>
          {/* Event Badges, Skip details, Error details */}
          <div className="ProspectiveEventPreview-subText d-flex flex-row mb-0">
            <EventDetails
              // isShowMoreSkipped={isShowMoreSkipped}
              skipReasons={skipReasons}
              errorMessage={errorMessage}
              warningMessage={warningMessage}
              crn={crn}
            />
            <EventBadges
              pEvent={event}
              showSettings={showSettings}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProspectiveEventPreview.propTypes = {
  // The event to preview
  event: PropTypes.instanceOf(ProspectiveEvent).isRequired,
  // If true, the event is currently being created
  beingCreated: PropTypes.bool,
  // if true, show the settings badges
  showSettings: PropTypes.bool,
};

ProspectiveEventPreview.defaultProps = {
  // Default to not being created
  beingCreated: false,
  // Don't show settings badges
  showSettings: false,
};

export default ProspectiveEventPreview;
