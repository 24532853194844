/**
 * An individual event item
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faFileVideo,
  faLink,
  faCalendarAlt,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import propTypes
import Event from '../shared/propTypes/Event';
import TooltipButton from '../shared/TooltipButton';

// Import helpers
import eventTypeToIcon from '../helpers/eventTypeToIcon';

// Import style
import './EventItem.css';
import eventTypeToTitle from '../helpers/eventTypeToTitle';
import IN_CASE_OF_EMERGENCY_SUFFIX from '../constants/IN_CASE_OF_EMERGENCY_SUFFIX';

/* -------------------------- Component ------------------------- */

class EventItem extends Component {
  /**
   * Render EventItem
   * @author Gabe Abrams
   */
  render() {
    const {
      event,
      isLearner,
      onGetLink,
      onViewRecordings,
      onEdit,
      onJoin,
      position,
    } = this.props;
    const { highlighted } = event;

    const positionClass = `EventItem-position-${position}`;

    // Create link button
    const linkButton = (
      <TooltipButton
        contents={(
          <FontAwesomeIcon icon={faLink} />
        )}
        id={`EventItem-link-${event.ihid}`}
        className={`EventItem-slim-button ${positionClass} btn btn-secondary mr-1`}
        ariaLabel={`view shareable link to event: ${event.name}`}
        title="Shareable Link"
        onClick={onGetLink}
      />
    );

    // Create recordings button
    let recordingsButton;
    if (event.currentZoomId && !isLearner) {
      recordingsButton = (
        <TooltipButton
          contents={(
            <FontAwesomeIcon icon={faFileVideo} />
          )}
          id={`EventItem-recordings-${event.ihid}`}
          className={`EventItem-slim-button ${positionClass} btn btn-secondary mr-1`}
          ariaLabel={`view all recordings for event: ${event.name}`}
          title="View/Publish Recordings"
          onClick={onViewRecordings}
        />
      );
    }

    // Settings button
    let settingsButton;
    if (!isLearner) {
      settingsButton = (
        <TooltipButton
          contents={(
            <FontAwesomeIcon icon={faCog} />
          )}
          id={`EventItem-settings-${event.ihid}`}
          className={`EventItem-slim-button ${positionClass} btn btn-secondary mr-1`}
          ariaLabel={`view or edit settings for event: ${event.name}`}
          title="View/Edit Settings"
          onClick={onEdit}
        />
      );
    }

    // Create join button
    let joinButton;
    if (event.openZoomLink) {
      const contents = (
        <span>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className="mr-1"
          />
          Join
        </span>
      );
      joinButton = (
        <TooltipButton
          contents={contents}
          id={`EventItem-join-${event.ihid}`}
          className={`EventItem-slim-button ${positionClass} btn btn-dark mr-1`}
          ariaLabel={`join event: ${event.name}`}
          title="Join via Zoom"
          onClick={onJoin}
        />
      );
    }

    // Choose the event icon based on the type
    const eventIcon = eventTypeToIcon(event.type);

    // True if the user can click to join
    const allowClickToJoin = !!joinButton;

    // Divide up event name
    const rawEventName = event.name.trim();
    const isEmergencyEvent = (
      rawEventName
        .toLowerCase()
        .endsWith(IN_CASE_OF_EMERGENCY_SUFFIX)
    );
    const eventNameText = (
      isEmergencyEvent
        ? rawEventName.substring(
          0,
          rawEventName.length - IN_CASE_OF_EMERGENCY_SUFFIX.length
        ).trim()
        : rawEventName
    );
    const eventName = (
      <>
        {eventNameText}
        {isEmergencyEvent && (
          <span
            className="font-weight-normal"
            style={{
              letterSpacing: '-0.05em',
            }}
          >
            {' '}
            (in case of emergency)
          </span>
        )}
      </>
    );

    // Create title area
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    const title = (
      <h3
        className={`EventItem-h3 ${positionClass} m-0 p-0`}
        style={{
          whiteSpace: 'nowrap',
        }}
        title={event.name}
        onClick={(
          allowClickToJoin
            ? onJoin // Join when clicking the title
            : null // Not allowed to join
        )}
      >
        {/* Show icon on larger screens */}
        <div
          className="text-center d-none d-md-inline-block ml-1"
          style={{ minWidth: '50px' }}
          title={`This event's type is: "${eventTypeToTitle(event.type)}"`}
        >
          <FontAwesomeIcon
            icon={eventIcon || faCalendarAlt}
            className="mr-1"
          />
        </div>
        {/* Smaller font on small screens */}
        <span
          className={`EventItem-title EventItem-title-${event.ihid} ${positionClass} d-inline d-md-none`}
          style={{ fontSize: '80%' }}
        >
          {eventName}
        </span>
        <span className={`EventItem-title EventItem-title-${event.ihid} ${positionClass} d-none d-md-inline`}>
          {eventName}
        </span>
      </h3>
    );

    // Create button area
    const buttons = (
      <span>
        {linkButton}
        {recordingsButton}
        {settingsButton}
        {joinButton}
      </span>
    );

    return (
      <div className={`alert alert-${highlighted ? 'warning bg-warning text-dark' : 'dark'} pl-1 pr-1 pt-1 pb-2 mb-2`}>
        {/* Spacer that Appears for md+ Screens */}
        <div className="d-none d-md-block mt-1" />

        {/* Flex Container */}
        <div className="EventItem-container d-flex">
          {/* Title */}
          <div
            className={`EventItem-title-container ${positionClass} ${allowClickToJoin ? 'EventItem-cursor-pointer ' : ''}d-flex align-items-center flex-grow-1`}
            style={{ overflow: 'hidden' }}
          >
            {title}

            {/* Fader to cover end of text (small screens) */}
            <div className="EventItem-text-fader-outer-small d-inline-block d-md-none">
              <div className={`EventItem-text-fader-inner-small EventItem-text-fader-inner-small-${highlighted ? 'warning' : 'dark'}`} />
            </div>
          </div>
          {/* Buttons */}
          <div
            className="EventItem-button-container"
            style={{ whiteSpace: 'nowrap' }}
          >
            {/* Fader to cover end of text (large screens) */}
            <div className="EventItem-text-fader-outer d-none d-md-inline-block">
              <div className={`EventItem-text-fader-inner EventItem-text-fader-inner-${highlighted ? 'warning' : 'dark'}`} />
            </div>

            {/* Buttons */}
            {buttons}
          </div>
        </div>
      </div>
    );
  }
}

EventItem.propTypes = {
  // The event
  event: Event.isRequired,
  // True if user is a learner
  isLearner: PropTypes.bool.isRequired,
  /**
   * Handler to call when requesting to view recordings
   */
  onViewRecordings: PropTypes.func.isRequired,
  /**
   * Handler to call when user requests the link
   */
  onGetLink: PropTypes.func.isRequired,
  /**
   * Handler to call when user wants to edit the event
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * Handler to call when user wants to join the meeting
   */
  onJoin: PropTypes.func.isRequired,
  // Integer position of the item
  position: PropTypes.number.isRequired,
};

export default EventItem;
