/**
 * Top-level React component
 * @author Gabe Abrams
 */

// Import caccl
import initCACCL from 'caccl/client/cached';

// Import React
import React, { Component } from 'react';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from './shared/LoadingSpinner';
import Header from './shared/Header';

// Import other components
import AdminPanel from './AdminPanel';
import AttendancePanel from './AttendancePanel';
import Home from './Home';
import RecordingsPanel from './RecordingsPanel';
import CreateEditEvent from './CreateEditEvent';
import CreateEditLounge from './CreateEditLounge';
import HelpPanel from './HelpPanel';

// Import helpers
import setPagePath from './helpers/setPagePath';
import logAction from './helpers/logAction';
import sessionExpiryHandler from './helpers/sessionExpiryHandler';
import sessionStolenHandler from './helpers/sessionStolenHandler';
import scroll from './helpers/scroll';

// Import constants
import SCHOOLS from './constants/SCHOOLS';

// Import css
import './App.css';

/* ----------------------- Initialization ----------------------- */

// Initialize caccl
const { getStatus } = initCACCL();

/* -------------------------- Constants ------------------------- */

// Views
const VIEWS = {
  ADMIN_PANEL: 'admin-panel',
  ATTENDANCE_PANEL: 'attendance-panel',
  HOME: 'home',
  RECORDINGS_PANEL: 'recordings-panel',
  EDIT_EVENT: 'edit-event',
  CREATE_EVENT: 'create-event',
  EDIT_LOUNGE: 'edit-lounge',
  CREATE_LOUNGE: 'create-lounge',
  HELP_PANEL: 'help-panel',
};

/* -------------------------- Component ------------------------- */

class App extends Component {
  /**
   * Initialize App
   * @author Gabe Abrams
   */
  constructor(props) {
    super(props);

    // Figure out if this is just the tutorials page
    const onStaticTutorialPage = (
      window.location.href.endsWith('#tutorials')
      || window.location.href.endsWith('/tutorials')
    );

    // Set up state
    this.state = {
      // True if on the static tutorial page
      onStaticTutorialPage,
      // True if loading
      loading: true,
      // Error message to display
      fatalErrorMessage: null,
      // True if current user is a learner in the course
      isLearner: true,
      // True if the current user is an admin
      isAdmin: false,
      // Launch course id
      courseId: null,
      // Name of the course
      courseName: null,
      // Name of the term
      termName: null,
      // School tracking field
      school: SCHOOLS.DCE,
      // The first name of the current user
      userFirstName: null,
      // The last name of the current user
      userLastName: null,
      // The id of the user who launched
      userId: null,
      // The email of the user
      userEmail: null,
      // Current view
      currentView: VIEWS.HOME,
      // The current selected event
      currentSelectedEvent: null,
      // The current selected lounge
      currentSelectedLounge: null,
      // The other events excluding the selected event
      otherEvents: [],
      // If true, the user's session has expired
      sessionExpired: false,
      // If true, the user's session has been stolen to another course
      sessionStolen: false,
    };

    // Add handler to session expiry handler
    sessionExpiryHandler.addHandler(() => {
      this.setState({
        sessionExpired: true,
      });
    });

    // Add handler to session stolen handler
    sessionStolenHandler.addHandler(() => {
      this.setState({
        sessionStolen: true,
      });
    });
  }

  /**
   * Called when the component mounted, pulls state and user profile from server
   * @author Gabe Abrams
   */
  async componentDidMount() {
    // Remove the React loading indicator
    document
      .getElementsByClassName('ReactLoadingIndicator-container')[0]
      .style = 'display: none';

    /*----------------------------------------*/
    /*   Skip Load if Just Showing Tutorials  */
    /*----------------------------------------*/

    const { onStaticTutorialPage } = this.state;
    if (onStaticTutorialPage) {
      // Open the tutorials page
      return this.setState({
        loading: false,
        currentView: VIEWS.HELP_PANEL,
      });
    }

    /*----------------------------------------*/
    /*               Load Status              */
    /*----------------------------------------*/

    // Load status
    let launchInfo;
    try {
      // Get status from server
      const status = await getStatus();

      // > App wasn't launched via Canvas
      if (!status.launched) {
        // Display error to user
        return this.setState({
          sessionExpired: true,
        });
      }

      // Get launch info from server
      ({ launchInfo } = status);

      // Launched! Log this
      logAction({ type: 'launch' });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: `Error while requesting state from server: ${err.message}`,
      });
    }

    /*----------------------------------------*/
    /*        Ensure User is in Course        */
    /*----------------------------------------*/

    const {
      notInCourse,
      isAdmin,
      courseId,
    } = launchInfo;
    if (notInCourse && !isAdmin) {
      // Set the path
      setPagePath('Error Occurred', `/courses/${courseId}`);

      // Show an error message
      return this.setState({
        fatalErrorMessage: 'You are not enrolled in this course. To use this tool, please enroll then try again.',
      });
    }

    /*----------------------------------------*/
    /*        Post-process Launch Info        */
    /*----------------------------------------*/

    // Process launch info
    const {
      userId,
      userEmail,
    } = launchInfo;
    const isLearner = (launchInfo.isLearner && !isAdmin);
    const userFirstName = (
      launchInfo.userFirstName
      || 'Student'
    );
    const userLastName = (
      launchInfo.userLastName
      || ''
    );
    const school = (
      launchInfo.customParams
        ? launchInfo.customParams.school || SCHOOLS.DCE
        : SCHOOLS.DCE
    );
    const courseName = launchInfo.contextLabel;
    const termName = (
      launchInfo.customParams
        ? launchInfo.customParams.term_name || '2020-2021 Fall'
        : 'Unknown Term'
    );

    // Save to state
    this.setState({
      isLearner,
      courseId,
      courseName,
      termName,
      userId,
      userEmail,
      school,
      userFirstName,
      userLastName,
      isAdmin: !!isAdmin,
      loading: false,
    });
  }

  /**
   * Render the App
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct the state
    const {
      onStaticTutorialPage,
      loading,
      loadingMessage,
      fatalErrorMessage,
      isLearner,
      isAdmin,
      courseId,
      courseName,
      termName,
      school,
      userFirstName,
      userLastName,
      userId,
      userEmail,
      currentView,
      currentSelectedEvent,
      currentSelectedLounge,
      otherEvents,
      sessionExpired,
      sessionStolen,
    } = this.state;

    /* ------------------ Build Header Right Button ----------------- */

    let headerRightButton;
    if (isAdmin && currentView === VIEWS.HOME) {
      // Go to admin panel button
      headerRightButton = {
        onClick: () => {
          this.setState({
            currentView: VIEWS.ADMIN_PANEL,
          });

          // Log this
          logAction({
            description: 'admin panel',
            type: 'open',
            metadata: {
              isAdminFeature: true,
            },
          });
        },
        contents: (
          <span>
            {/* Icon */}
            <FontAwesomeIcon icon={faUserCog} />

            {/* Small Screen View */}
            <span className="d-none d-sm-inline">
              &nbsp;Admin
            </span>

            {/* Large Screen View */}
            <span className="d-none d-md-inline">
              &nbsp;Panel
            </span>
          </span>
        ),
        isAdminFeature: true,
      };
    }

    /* ------------------ Build Header Left Button ------------------ */

    let headerLeftButton;
    if (onStaticTutorialPage) {
      // No back button to exit the tutorials page
      headerLeftButton = null;
    } else if (
      currentView === VIEWS.RECORDINGS_PANEL
      || currentView === VIEWS.PUBLISHED_RECORDINGS_PANEL
      || currentView === VIEWS.ATTENDANCE_PANEL
      || currentView === VIEWS.HELP_PANEL
    ) {
      // Exit button
      headerLeftButton = {
        onClick: () => {
          // Go back to the course events area
          this.setState({
            currentSelectedEvent: null,
            otherEvents: [],
            currentView: VIEWS.HOME,
          });

          // Log this
          let description = 'event recordings';
          if (currentView === VIEWS.PUBLISHED_RECORDINGS_PANEL) {
            description = 'published recordings';
          } else if (currentView === VIEWS.ATTENDANCE_PANEL) {
            description = 'attendance logs';
          } else if (currentView === VIEWS.HELP_PANEL) {
            description = 'help panel';
          }
          logAction({
            description,
            type: 'close',
          });
        },
        contents: (
          <span>
            {/* XS Screen View */}
            <span className="d-inline d-sm-none">
              Events
            </span>

            {/* Small Screen View */}
            <span className="d-none d-sm-inline d-md-none">
              Course Events
            </span>

            {/* Large Screen View */}
            <span className="d-none d-md-inline">
              Back to Course Events
            </span>
          </span>
        ),
      };
    }

    let body;
    /* ------------------------- Build Body ------------------------- */

    if (sessionExpired) {
      const canRefreshToRestartSession = (
        window.location.pathname.includes('/courses/')
      );

      // Session expired message
      body = (
        <div className="alert alert-warning">
          {/* Title */}
          <h3>
            <FontAwesomeIcon
              icon={faHourglassEnd}
              className="mr-2"
            />
            Session Expired
          </h3>

          {/* Subtitle */}
          <p className="lead font-weight-normal m-0">
            {
              canRefreshToRestartSession
                ? 'Try refreshing the page. If that doesn\'t work, go'
                : 'Go'
            }
            {' '}
            to Canvas and reopen this page.
          </p>
        </div>
      );

      // Remove header buttons
      headerLeftButton = null;
      headerRightButton = null;
    } else if (sessionStolen) {
      const canRefreshToRestartSession = (
        window.location.pathname.includes('/courses/')
      );

      // Session expired message
      body = (
        <div className="alert alert-warning">
          {/* Title */}
          <h3>
            <FontAwesomeIcon
              icon={faHourglassEnd}
              className="mr-2"
            />
            Session Switched
          </h3>

          <p className="lead font-weight-normal m-0">
            You visited another course after opening this page.
          </p>

          <p className="lead font-weight-normal m-0">
            {
              canRefreshToRestartSession
                ? 'Try refreshing the page. If that doesn\'t work, go'
                : 'Go'
            }
            {' '}
            to Canvas and reopen this page.
          </p>
        </div>
      );

      // Remove header buttons
      headerLeftButton = null;
      headerRightButton = null;
    } else if (fatalErrorMessage) {
      // Error message
      body = (
        <div className="alert alert-warning d-inline-block">
          <h3>An error occurred:</h3>
          {fatalErrorMessage}
        </div>
      );

      // Remove header buttons
      headerLeftButton = null;
      headerRightButton = null;
    } else if (loading) {
      // Loading spinner
      body = (
        <div>
          {/* Loading Blips */}
          <LoadingSpinner />

          {/* Message Underneath Loading spinner */}
          <div className="text-muted">
            {loadingMessage}
          </div>
        </div>
      );
    } else if (currentView === VIEWS.ADMIN_PANEL) {
      // Admin panel
      // Don't add a body, just let the admin panel control the whole body
      // NOTE: AdminPanel adds its own scroll items
      return (
        <AdminPanel
          courseId={courseId}
          courseName={courseName}
          onExit={() => {
            // Go back to event list
            this.setState({
              currentView: VIEWS.HOME,
            });

            // Log this
            logAction({
              type: 'close',
              description: 'admin panel',
              metadata: {
                isAdminFeature: true,
              },
            });
          }}
        />
      );
    } else if (currentView === VIEWS.ATTENDANCE_PANEL) {
      // Attendance panel
      body = (
        <AttendancePanel
          courseId={courseId}
          courseName={courseName}
        />
      );
    } else if (currentView === VIEWS.HOME) {
      // Join panel
      body = (
        <Home
          courseId={courseId}
          courseName={courseName}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userEmail={userEmail}
          userId={userId}
          isLearner={isLearner}
          isAdmin={isAdmin}
          onViewEventRecordings={(event) => {
            this.setState({
              currentSelectedEvent: event,
              currentView: VIEWS.RECORDINGS_PANEL,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'event recordings',
            });
          }}
          onEditEvent={(event, newOtherEvents) => {
            this.setState({
              currentSelectedEvent: event,
              otherEvents: newOtherEvents,
              currentView: VIEWS.EDIT_EVENT,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'edit event',
              metadata: {
                ihid: event.ihid,
                name: event.name,
                type: event.type,
              },
            });
          }}
          onEditLounge={(lounge) => {
            this.setState({
              currentSelectedLounge: lounge,
              currentView: VIEWS.EDIT_LOUNGE,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'edit lounge',
              metadata: {
                loungeId: lounge.loungeId,
                name: lounge.name,
              },
            });
          }}
          onViewPublishedRecordings={() => {
            this.setState({
              currentView: VIEWS.RECORDINGS_PANEL,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'published recordings',
            });
          }}
          onCreateEvent={(newOtherEvents) => {
            this.setState({
              otherEvents: newOtherEvents,
              currentView: VIEWS.CREATE_EVENT,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'create event',
            });
          }}
          onCreateLounge={() => {
            this.setState({
              currentView: VIEWS.CREATE_LOUNGE,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'create lounge',
            });
          }}
          onViewAttendancePane={() => {
            this.setState({
              currentView: VIEWS.ATTENDANCE_PANEL,
            });

            // Log this
            logAction({
              type: 'open',
              description: 'attendance logs',
            });
          }}
          onOpenHelp={() => {
            this.setState({
              currentView: VIEWS.HELP_PANEL,
            });

            // Log this
            logAction({
              description: 'help panel',
              type: 'open',
            });
          }}
        />
      );
    } else if (currentView === VIEWS.RECORDINGS_PANEL) {
      // Manage event recordings panel
      body = (
        <RecordingsPanel
          userId={userId}
          isLearner={isLearner}
          context={(
            currentSelectedEvent
              ? `Recordings for event: ${currentSelectedEvent.name}`
              : `Recordings for course: ${courseName}`
          )}
          courseId={courseId}
          event={currentSelectedEvent}
        />
      );
    } else if (currentView === VIEWS.EDIT_EVENT) {
      // Edit event
      body = (
        <CreateEditEvent
          event={currentSelectedEvent}
          otherEvents={otherEvents}
          courseId={courseId}
          courseName={courseName}
          termName={termName}
          school={school}
          userEmail={userEmail}
          isAdmin={isAdmin}
          isLearner={isLearner}
          onDone={() => {
            this.setState({
              currentSelectedEvent: null,
              otherEvents: [],
              currentView: VIEWS.HOME,
            });
          }}
        />
      );
    } else if (currentView === VIEWS.CREATE_EVENT) {
      body = (
        <CreateEditEvent
          otherEvents={otherEvents}
          courseId={courseId}
          courseName={courseName}
          termName={termName}
          school={school}
          userEmail={userEmail}
          isAdmin={isAdmin}
          isLearner={isLearner}
          onDone={() => {
            this.setState({
              currentView: VIEWS.HOME,
            });
          }}
        />
      );
    } else if (currentView === VIEWS.EDIT_LOUNGE) {
      // Edit lounge
      body = (
        <CreateEditLounge
          courseId={courseId}
          courseName={courseName}
          termName={termName}
          lounge={currentSelectedLounge}
          onDone={() => {
            this.setState({
              currentSelectedLounge: null,
              currentView: VIEWS.HOME,
            });
          }}
          defaultZoomHostEmail={userEmail}
          isAdmin={isAdmin}
        />
      );
    } else if (currentView === VIEWS.CREATE_LOUNGE) {
      body = (
        <CreateEditLounge
          courseId={courseId}
          courseName={courseName}
          termName={termName}
          onDone={() => {
            this.setState({
              currentView: VIEWS.HOME,
            });
          }}
          defaultZoomHostEmail={userEmail}
          isAdmin={isAdmin}
        />
      );
    } else if (currentView === VIEWS.HELP_PANEL) {
      body = (
        <HelpPanel />
      );
    }

    /* --------------------------- Full UI -------------------------- */
    return (
      <div className="content-container">
        <Header
          leftButton={headerLeftButton}
          rightButton={headerRightButton}
          isLearner={isLearner}
        />
        {/* Top element (so we can scroll to top of page) */}
        <div ref={(el) => { scroll.saveTopRef(el); }} />

        {/* Below Header */}
        <div className="content-below-header text-center">
          {/* Content */}
          {body}

          {/* Bottom element (so we can scroll to bottom of page) */}
          <div ref={(el) => { scroll.saveBottomRef(el); }} />
        </div>
      </div>
    );
  }
}

export default App;
