/**
 * Set of outcomes for prospective events
 * @author Gabe Abrams
 */
module.exports = {
  PENDING: 'Pending',
  SUCCESSFUL: 'Successful',
  CREATED_WITH_WARNING: 'Created with WARNING',
  FAILED: 'Failed',
};
