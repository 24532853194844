/**
 * Popup for copying a shareable link
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faChevronDown,
  faCog,
  faEllipsisH,
  faExclamationTriangle,
  faLink,
  faList,
  faSignInAlt,
  faUnlock,
  faUserGraduate,
  faLock,
  faStar,
  faCalendar,
  faClock,
  faTag,
} from '@fortawesome/free-solid-svg-icons';

// Shared components
import Modal from '../../shared/Modal';
import CopyButton from '../../shared/CopyButton';
import SimpleDateChooser from '../../shared/SimpleDateChooser';
import ExpandableDrawer from '../../shared/ExpandableDrawer';

// Import helpers
import logAction from '../../helpers/logAction';
import visitServerEndpoint from '../../helpers/visitServerEndpoint';
import getTimeInfoInET from '../../helpers/getTimeInfoInET';

// Date info for tomorrow
const DAY_MS = 86400000;
const tomorrow = getTimeInfoInET(new Date(Date.now() + DAY_MS));
const tomorrowMonth = tomorrow.month;
const tomorrowDay = tomorrow.day;
const tomorrowYear = tomorrow.year;

class ShareableLinkModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Fatal error message
      fatalErrorMessage: null,
      // True if the description is expanded
      expanded: false,
      // The breakthrough link if the user has created one
      breakthroughLink: null,
      // True if user is configuring the breakthrough link
      configuringBreakthrough: false,
      // True if user is customizing the date of the breakthrough
      schedulingBreakthrough: false,
      // Breakthrough schedule information
      startMonth: tomorrowMonth,
      startDay: tomorrowDay,
      startYear: tomorrowYear,
      // Breakthrough link duration
      durationDays: 1,
      // Label for the current breakthrough link
      breakthroughLabel: '',
    };
  }

  /**
   * Get a breakthrough link
   * @author Gabe Abrams
   */
  async getBreakthroughLink() {
    const {
      courseId,
      ihid,
    } = this.props;
    const {
      startMonth,
      startDay,
      startYear,
      durationDays,
      breakthroughLabel,
    } = this.state;

    try {
      const newBreakthroughLink = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${ihid}/breakthroughs`,
        method: 'POST',
        params: {
          startMonth,
          startDay,
          startYear,
          durationDays,
          label: (
            breakthroughLabel.trim().length > 0
              ? breakthroughLabel.trim()
              : undefined
          ),
        },
      });

      // Save link
      this.setState({
        breakthroughLink: newBreakthroughLink,
        configuringBreakthrough: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Render ShareableLinkModal
   * @author Gabe Abrams
   */
  render() {
    const {
      title,
      shareableLink,
      onClose,
      isAdmin,
      isLearner,
    } = this.props;
    const {
      fatalErrorMessage,
      expanded,
      startMonth,
      startDay,
      startYear,
      durationDays,
      breakthroughLink,
      configuringBreakthrough,
      schedulingBreakthrough,
      breakthroughLabel,
    } = this.state;

    // Fatal error message
    if (fatalErrorMessage) {
      // Error message
      const errorMessage = (
        <div className="alert alert-warning d-inline-block">
          <h3>An error occurred:</h3>
          {fatalErrorMessage}
        </div>
      );

      return (
        <Modal
          title="Error Occurred:"
          body={errorMessage}
          onClose={onClose}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    // Link copy box
    const linkCopyBox = (
      <div>
        <div className="input-group">
          {/* Label */}
          <div className="input-group-prepend">
            <span className="input-group-text">
              {/* Small Screen View */}
              <span className="d-inline d-sm-none">
                Link
              </span>
              {/* Large Screen View */}
              <span className="d-none d-sm-inline">
                {breakthroughLink ? 'Breakthrough ' : 'Shareable '}
                Link
              </span>
            </span>
          </div>

          {/* Input Field (just for being copied) */}
          <input
            id="ShareableLinkModal-link-box"
            type="text"
            className="form-control bg-light"
            value={breakthroughLink || shareableLink}
            aria-label={`${breakthroughLink ? 'breakthrough' : 'shareable'} link for ${title}`}
            readOnly
            style={{
              // Keep the same height as the schedule box
              height: '45px',
            }}
          />

          {/* Copy Button */}
          <div className="input-group-append">
            <CopyButton text={breakthroughLink || shareableLink} />
          </div>
        </div>

        {/* Status */}
        {!breakthroughLink && (
          <div className="text-center">
            <FontAwesomeIcon
              icon={faLock}
              className="mr-2"
            />
            This link only works for members of the Canvas course.
            That&apos;s why it&apos;s
            {' '}
            <em>
              safe
            </em>
            {' '}
            and
            {' '}
            <em>
              secure
            </em>
            .
          </div>
        )}
      </div>
    );

    /*------------------------------------------------------------------------*/
    /*                               Normal Link                              */
    /*------------------------------------------------------------------------*/

    if (!breakthroughLink && !configuringBreakthrough) {
      /*----------------------------------------*/
      /*       Create Breakthrough Notice       */
      /*----------------------------------------*/
      let createBreakthroughInfo;
      if (!isLearner) {
        // Breakthrough link can be created
        createBreakthroughInfo = (
          <div className="text-center">
            <div className="alert alert-warning text-dark mt-5 mb-0">
              <h5 className="font-weight-bold mb-1">
                Need a link for guest speakers or
                others who aren&apos;t in Canvas?
              </h5>

              {/* Expanding */}
              <div
                style={{
                  maxHeight: (
                    expanded
                      ? '600px'
                      : '39px'
                  ),
                  transition: 'max-height 1s',
                  overflow: 'hidden',
                }}
              >
                <div>
                  Create a temporary breakthrough link for people who
                  don&apos;t show up in Canvas. Examples:
                  <div>
                    <div>
                      <span className="badge bg-light font-weight-normal">
                        <FontAwesomeIcon
                          icon={faChalkboardTeacher}
                          className="mr-1"
                        />
                        Guest lecturer who is not in Canvas
                      </span>
                    </div>
                    <div>
                      <span className="badge bg-light font-weight-normal">
                        <FontAwesomeIcon
                          icon={faUserGraduate}
                          className="mr-1"
                        />
                        Student who registered but isn&apos;t in Canvas yet
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <strong>
                    Important:
                  </strong>
                  {' '}
                  breakthrough links are temporary links that allow
                  {' '}
                  <span className="font-weight-bold font-italic">
                    anyone
                  </span>
                  {' '}
                  to join the event, even if they aren&apos;t in the course.
                  {' '}
                  <span className="font-weight-bold font-italic">
                    No attendance
                  </span>
                  {' '}
                  is taken for people joining with breakthrough links.
                  Only use breakthrough links if needed!
                </div>

                {/* Create Breakthrough Link Button */}
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm"
                    aria-label="create a breakthrough link (a temporary link that anyone can use to join the event, not just members of the course)"
                    id="ShareableLinkModal-turn-into-breakthrough-link"
                    onClick={() => {
                      // Log this
                      logAction({
                        type: 'click',
                        description: 'create breakthrough link button',
                        metadata: {
                          title,
                          nonBreakthroughLink: shareableLink,
                        },
                      });

                      // Open the configuration page
                      this.setState({
                        configuringBreakthrough: true,
                        schedulingBreakthrough: false,
                        startMonth: tomorrowMonth,
                        startDay: tomorrowDay,
                        startYear: tomorrowYear,
                        durationDays: 1,
                        breakthroughLabel: '',
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUnlock}
                      className="mr-2"
                    />
                    Create Breakthrough Link
                  </button>
                </div>
              </div>
              {!expanded && (
                <div className="mt-2">
                  <button
                    type="button"
                    id="ShareableLinkModal-read-more-on-breakthrough-links"
                    className="btn btn-secondary btn-sm"
                    aria-label="read more on breakthrough links and show button to create breakthrough link"
                    onClick={() => {
                      this.setState({ expanded: true });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="mr-2"
                    />
                    Read More
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      }

      /*----------------------------------------*/
      /*               Link Modal               */
      /*----------------------------------------*/
      return (
        <Modal
          title={(
            <div>
              <FontAwesomeIcon
                icon={faLink}
                className="mr-2"
              />
              {/* Small Screen View */}
              <span className="d-inline d-sm-none text-break">
                {title}
                {' '}
                Link
              </span>
              {/* Large Screen View */}
              <span className="d-none d-sm-inline text-break">
                <strong>
                  {title}
                </strong>
                {' '}
                | Shareable Link
              </span>
            </div>
          )}
          body={(
            <div>
              {/* Box to copy link */}
              {linkCopyBox}

              {/* Read more about breakthrough links */}
              {createBreakthroughInfo}
            </div>
          )}
          onClose={onClose}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                        Configuring Breakthrough                        */
    /*------------------------------------------------------------------------*/

    if (configuringBreakthrough) {
      // Duration options and label customization
      const durationOptions = [];
      let lastUsableDay;
      if (isAdmin) {
        // Create duration options
        for (let i = 0; i < 30; i++) {
          const duration = (i + 1);
          durationOptions.push(
            <option
              key={duration}
              value={duration}
              aria-label={`choose duration ${duration} day(s)`}
            >
              {duration}
              {' '}
              day
              {duration > 1 ? 's' : ''}
            </option>
          );
        }

        // Create description of last usable day
        const lastUsableDate = new Date(
          (new Date(`${startMonth}/${startDay}/${startYear}`)).getTime()
          + (DAY_MS * (durationDays - 1))
        );
        lastUsableDay = lastUsableDate.toLocaleDateString();
      }

      return (
        <Modal
          title={(
            <div>
              <FontAwesomeIcon
                icon={faCog}
                className="mr-2"
              />
              Breakthrough Link
            </div>
          )}
          body={(
            !schedulingBreakthrough
              ? ( // Initial choice
                <div className="text-center">
                  {/* Title */}
                  <h5>
                    When should the breakthrough link be usable?
                  </h5>

                  {/* Buttons */}
                  <div>
                    <button
                      type="button"
                      className="btn btn-lg btn-dark mr-2 mb-1"
                      aria-label="choose today and tomorrow"
                      id="ShareableLinkModal-schedule-today-or-tomorrow"
                      onClick={() => {
                        this.getBreakthroughLink();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        className="mr-2"
                      />
                      Today &amp; Tomorrow
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary mb-1"
                      aria-label="choose custom dates"
                      id="ShareableLinkModal-schedule-custom-date"
                      onClick={() => {
                        this.setState({
                          schedulingBreakthrough: true,
                        });
                      }}
                    >
                      Custom Dates
                    </button>
                  </div>

                  {/* Timezone notice */}
                  <div>
                    <strong>
                      Note:
                    </strong>
                    {' '}
                    all dates are in Eastern Time.
                  </div>
                </div>
              )
              : ( // Custom scheduler
                <div className="text-center">
                  <h5>
                    When should the breakthrough link be usable?
                  </h5>

                  {/* Date Chooser */}
                  <ExpandableDrawer
                    icon={faCalendar}
                    title="Usable on"
                    contents={(
                      <span className="text-left">
                        <SimpleDateChooser
                          name="breakthrough-schedule"
                          month={startMonth}
                          day={startDay}
                          year={startYear}
                          onChange={(newMonth, newDay, newYear) => {
                            this.setState({
                              startMonth: newMonth,
                              startDay: newDay,
                              startYear: newYear,
                            });
                          }}
                          numMonthsToShow={6}
                        />
                        <div className="d-block d-lg-inline">
                          {' '}
                          and the day before
                        </div>
                      </span>
                    )}
                  />

                  {/* Duration Chooser (for admin) */}
                  {isAdmin && (
                    <ExpandableDrawer
                      icon={faClock}
                      title="Duration"
                      isAdminFeature
                      contents={(
                        <span className="text-left">
                          <select
                            className="custom-select d-inline-block"
                            style={{ width: 'auto' }}
                            id="ShareableLinkModal-duration-chooser"
                            value={durationDays}
                            onChange={(e) => {
                              // Set the new duration
                              this.setState({
                                durationDays: e.target.value,
                              });
                            }}
                          >
                            {durationOptions}
                          </select>
                          {' '}
                          {(durationDays > 1) && (
                            <div className="d-block d-md-inline">
                              Last usable on
                              {' '}
                              <span id="ShareableLinkModal-last-usable-on-date">
                                {lastUsableDay}
                              </span>
                            </div>
                          )}
                        </span>
                      )}
                    />
                  )}

                  {/* Label Field (for admin) */}
                  {isAdmin && (
                    <ExpandableDrawer
                      icon={faTag}
                      title="Label"
                      isAdminFeature
                      contents={(
                        <input
                          type="text"
                          id="ShareableLinkModal-breakthrough-link-label-field"
                          className="form-control d-block"
                          placeholder="optional description for organization"
                          value={breakthroughLabel}
                          onChange={(e) => {
                            this.setState({
                              breakthroughLabel: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                  )}

                  {/* Continue Button */}
                  <div className="mt-3">
                    <button
                      id="ShareableLinkModal-continue-to-breakthrough-button"
                      type="button"
                      className="btn btn-dark btn-lg"
                      aria-label="get breakthrough link"
                      onClick={() => {
                        this.getBreakthroughLink();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )
          )}
          onClose={onClose}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }

    /*------------------------------------------------------------------------*/
    /*                              Breakthrough                              */
    /*------------------------------------------------------------------------*/

    // Breakthrough link to copy
    if (breakthroughLink) {
      return (
        <Modal
          title={(
            <div>
              <FontAwesomeIcon
                icon={faLink}
                className="mr-2"
              />
              {/* Small Screen View */}
              <span className="d-inline d-sm-none text-break">
                {title}
                {' '}
                Breakthrough
              </span>
              {/* Large Screen View */}
              <span className="d-none d-sm-inline text-break">
                <strong>
                  {title}
                </strong>
                {' '}
                | Breakthrough Link
              </span>
            </div>
          )}
          body={(
            <div>
              <div className="alert alert-danger p-2 text-dark text-center mb-3">
                <h5 className="font-weight-bold mb-1">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2"
                  />
                  This is a Breakthrough Link!
                </h5>

                <div className="text-left">
                  <div className="alert alert-light text-dark mb-0 mt-2">
                    <FontAwesomeIcon
                      icon={faSignInAlt}
                      className="mr-1"
                    />
                    <strong>
                      Anyone
                    </strong>
                    {' '}
                    with this link can join the event as a participant.
                  </div>

                  <div className="alert alert-light text-dark mb-0 mt-2">
                    <FontAwesomeIcon
                      icon={faList}
                      className="mr-1"
                    />
                    <strong>
                      No attendance
                    </strong>
                    {' '}
                    is taken for people who join with this link.
                  </div>

                  <div className="alert alert-light text-dark mb-0 mt-2">
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="mr-1"
                    />
                    <strong>
                      If the recipient needs to co-host,
                    </strong>
                    {' '}
                    start the event and have them join with this link,
                    then make them a co-host by hovering over their video,
                    clicking
                    {' '}
                    <span className="badge bg-secondary text-light">
                      <FontAwesomeIcon
                        icon={faEllipsisH}
                      />
                    </span>
                    {' '}
                    and then clicking &quot;Make Co-Host&quot;.
                  </div>
                </div>
              </div>

              {/* Box to copy link */}
              {linkCopyBox}
            </div>
          )}
          onClose={onClose}
          type={Modal.TYPES.NO_BUTTONS}
        />
      );
    }
  }
}

ShareableLinkModal.propTypes = {
  // Course ID
  courseId: PropTypes.number.isRequired,
  // IHID of the event to share
  ihid: PropTypes.string.isRequired,
  // Title of the link
  title: PropTypes.string.isRequired,
  // The link to share
  shareableLink: PropTypes.string.isRequired,
  // Handler for when the Modal is closed
  onClose: PropTypes.func.isRequired,
  // True if the current user is a learner
  isLearner: PropTypes.bool.isRequired,
  // True if the current user is an admin
  isAdmin: PropTypes.bool.isRequired,
};

export default ShareableLinkModal;
