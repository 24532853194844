import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * User chooses a course either from a list or by pasting in the CRN
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';

class CourseChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Current text in the Canvas box
      canvasText: '',
      // Current text in the CRN box
      crnText: '',
      // Current text in the year box
      yearText: String((new Date()).getFullYear()),
    };
  }

  /**
   * Render CourseChooser
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct state
    const {
      canvasText,
      crnText,
      yearText,
    } = this.state;

    /* --------------------------- Canvas --------------------------- */

    // Process canvasId
    let courseId;
    // > Try just using as a number
    if (!Number.isNaN(Number.parseInt(canvasText.trim()))) {
      // This is a number!
      courseId = Number.parseInt(canvasText);
    }
    // > Try parse as URL
    const canvasLinkRegex = /https:\/\/.*\.edu\/courses\/([\d]+).*/g;
    if (canvasText.toLowerCase().match(canvasLinkRegex)) {
      // This is a valid Canvas url
      courseId = Number.parseInt(canvasLinkRegex.exec(canvasText)[1]);
    }

    // Create the Canvas button
    const canvasButton = (
      courseId
        ? (
          <a
            id="CourseChooser-jump-via-canvas-button"
            className="btn btn-secondary btn-lg btn-block mt-2"
            aria-label="jump to course based on Canvas link or id"
            href={`https://${window.location.hostname}/courses/${courseId}`}
          >
            Jump to Course
          </a>
        )
        : (
          <div className="alert alert-warning mt-3">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="mr-2"
            />
            Add a valid course link or Canvas course ID
          </div>
        )
    );

    /* ----------------------------- CRN ---------------------------- */

    // Parse the CRN
    const crn = (
      (
        !Number.isNaN(Number.parseInt(crnText.trim()))
        && Number.parseInt(crnText.trim()) > 9999
      )
        ? Number.parseInt(crnText.trim())
        : null
    );

    // Parse the year
    const year = (
      (
        !Number.isNaN(Number.parseInt(yearText.trim()))
        && Number.parseInt(yearText.trim()) > 2000
        && Number.parseInt(yearText.trim()) < 3000
      )
        ? Number.parseInt(yearText.trim())
        : null
    );
    const invalidYear = (
      !year && yearText.trim().length > 0
    );

    // Create the CRN button
    const crnButton = (
      (crn && !invalidYear)
        ? (
          <a
            id="CourseChooser-jump-via-crn-button"
            className="btn btn-secondary btn-lg btn-block mt-2"
            aria-label="jump to course based on CRN"
            href={(
              year
                ? `https://${window.location.hostname}/crns/${crn}/years/${year}`
                : `https://${window.location.hostname}/crns/${crn}`
            )}
          >
            Jump to Course
          </a>
        )
        : (
          <div
            id="CourseChooser-add-valid-crn-warning"
            className="alert alert-warning mt-3"
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="mr-2"
            />
            Add a valid CRN and year
          </div>
        )
    );

    /* --------------------------- Full UI -------------------------- */

    return (
      <div>
        <h1 className="mb-1">
          Jump to a Course
        </h1>
        <h3 className="mb-4">
          Use either method:
        </h3>

        {/* Canvas Identifier */}
        <div className="mb-3">
          <div
            className="alert alert-light text-dark"
            style={{ maxWidth: '500px', margin: 'auto' }}
          >
            <h3>
              CanvasId or Link
            </h3>

            <div>
              Enter the CanvasId or the Canvas Site Link:
            </div>

            <input
              type="text"
              id="CourseChooser-canvas-input-field"
              className="form-control"
              aria-label="canvas id or link"
              placeholder="e.g. 57382 or https://canvas.harvard.edu/..."
              value={canvasText}
              onChange={(e) => {
                this.setState({
                  canvasText: e.target.value,
                });
              }}
            />

            {canvasButton}
          </div>
        </div>

        <h3 className="mb-3">
          or
        </h3>

        {/* CRN */}
        <div>
          <div
            className="alert alert-light text-dark"
            style={{ maxWidth: '500px', margin: 'auto' }}
          >
            <h3>
              CRN
            </h3>

            <div>
              Enter the CRN for the course:
            </div>

            <input
              type="text"
              id="CourseChooser-crn-input-field"
              className="form-control mb-1"
              aria-label="course CRN"
              placeholder="e.g. 24759"
              value={crnText}
              onChange={(e) => {
                this.setState({
                  crnText: e.target.value,
                });
              }}
            />

            <div>
              Enter the calendar year for the course:
            </div>

            <input
              type="text"
              id="CourseChooser-calendar-year-field"
              className="form-control"
              aria-label="course calendar year"
              placeholder="Calendar Year"
              value={yearText}
              onChange={(e) => {
                this.setState({
                  yearText: e.target.value,
                });
              }}
            />

            {crnButton}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseChooser;
