/**
 * Get a date's ordinal
 * @author Gabe Abrams
 * @param {number} day - day of the month
 * @return {string} ordinal
 */
export default (day) => {
  let ordinal;
  if (day > 3 && day < 21) {
    ordinal = 'th';
  } else {
    switch (day % 10) {
      case 1:
        ordinal = 'st';
        break;
      case 2:
        ordinal = 'nd';
        break;
      case 3:
        ordinal = 'rd';
        break;
      default:
        ordinal = 'th';
        break;
    }
  }

  return ordinal;
};
