/**
 * Attendance icon with a tooltip describing the type of attendance
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import tooltip components
// Note regarding OverlayTrigger "findDOMNode is deprecated in StrictMode"
// https://github.com/react-bootstrap/react-bootstrap/issues/5075
import {
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Import style
import './AttendanceMethodTooltip.css';

class AttendanceMethodTooltip extends Component {
  /**
   * Render AttendanceMethodTooltip
   * @author Gabe Abrams
   */
  render() {
    const {
      tooltipText,
      icon,
    } = this.props;
    return (
      <OverlayTrigger
        placement="top"
        rootClose
        overlay={(props) => {
          /* eslint-disable react/jsx-props-no-spreading */
          return (
            <Tooltip
              className="AttendanceMethodTooltip-tooltip AttendanceMethodTooltip-tooltip-top"
              {...props}
            >
              {tooltipText}
            </Tooltip>
          );
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            transform: 'scale(1.1)',
          }}
          aria-label={tooltipText}
        />
      </OverlayTrigger>
    );
  }
}

AttendanceMethodTooltip.propTypes = {
  // Text to show in tooltip
  tooltipText: PropTypes.string.isRequired,
  // FontAwesome icon
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AttendanceMethodTooltip;
