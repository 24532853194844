/**
 * A toggle switch
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import style
import './ToggleSwitch.css';

class ToggleSwitch extends Component {
  /**
   * Render ToggleSwitch
   * @author Gabe Abrams
   */
  render() {
    const {
      labelIfOn,
      labelIfOff,
      isOn,
      onChange,
      size,
    } = this.props;

    const {
      className,
      title,
      ariaLabel,
      text,
    } = (isOn ? labelIfOn : labelIfOff);
    const hiddenTextForSpacing = (isOn ? labelIfOff : labelIfOn).text;

    return (
      <button
        type="button"
        title={title}
        className={`btn btn-light bg-white border text-dark text-white${size ? ` btn-${size}` : ''} ${className || ''}`}
        aria-label={ariaLabel}
        onClick={() => {
          onChange(!isOn);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          {/* Icon/Toggle Indicator */}
          <div className={`ToggleSwitch-switch badge badge-pill badge-${isOn ? 'info' : 'secondary'}`}>
            <div className={`ToggleSwitch-circle ToggleSwitch-circle-${isOn ? 'on' : 'off'} bg-white`} />
          </div>
          {/* Text */}
          <div>
            {/* Actual text to display */}
            <div style={{ transform: 'translate(0, -1px)' }}>
              {text}
            </div>
            {/* Hidden text so toggle doesn't change size when switched */}
            <div
              style={{ height: 0, overflow: 'hidden' }}
              aria-hidden
            >
              {hiddenTextForSpacing}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

ToggleSwitch.propTypes = {
  // Label if on
  labelIfOn: PropTypes.shape({
    // Text for the button
    text: PropTypes.node.isRequired,
    // Title for the button (tooltip text)
    title: PropTypes.string.isRequired,
    // Aria label
    ariaLabel: PropTypes.string.isRequired,
    // Class names to add to the toggle button
    className: PropTypes.string,
  }).isRequired,
  // Label if off
  labelIfOff: PropTypes.shape({
    // Text for the button
    text: PropTypes.node.isRequired,
    // Aria label
    ariaLabel: PropTypes.string.isRequired,
    // Class names to add to the toggle button
    className: PropTypes.string,
  }).isRequired,
  // True if toggle switch is on
  isOn: PropTypes.bool.isRequired,
  /**
   * Handler for when the toggle switch is changed
   * @param {boolean} isOn - new value for whether the switch is on
   */
  onChange: PropTypes.func.isRequired,
  // Toggle size ("lg" or "sm")
  size: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  // Normal toggle size
  size: null,
};

export default ToggleSwitch;
