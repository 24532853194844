/**
 * Lounge item
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faLock,
  faQuestion,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Smiley from '../../shared/Smiley';

// Import lounge furniture
import LoungeChair from './LoungeChair';
import LoungeTable from './LoungeTable';
import LoungeDoor from './LoungeDoor';
import LoungeWindow from './LoungeWindow';

// Import shared prop types
import Lounge from '../../shared/propTypes/Lounge';

// Import style
import './style.css';
import STUDY_LOUNGE_TUTORIAL_URL from '../../constants/STUDY_LOUNGE_TUTORIAL_URL';

class LoungeItem extends Component {
  /**
   * Render LoungeItem
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      index,
      lounge,
      isLearner,
      onEdit,
      onJoin,
    } = this.props;
    const {
      name,
      visitorFirstNames,
    } = lounge;

    // Create smileys
    let visitorSmileys = [];
    if (!lounge.locked) {
      visitorSmileys = visitorFirstNames.map((firstName, i) => {
        const key = `${firstName}-${i}`;
        return (
          <Smiley
            key={key}
            name={firstName}
          />
        );
      });
    }

    // Create name
    const nameElem = (
      name.toLowerCase().includes('lounge')
        ? name
        : `${name} Lounge`
    );

    // Furniture
    const oddIndex = (index % 2 === 1);
    const furniture = (
      <div>
        {/* Door */}
        <LoungeDoor
          onLeft={oddIndex}
        />

        {/* Table */}
        <div className="LoungeItem-hide-375px">
          <LoungeTable
            bottom={10}
            right={
              oddIndex
                ? 30
                : undefined
            }
            left={
              oddIndex
                ? undefined
                : 30
            }
          />
        </div>

        {/* Chairs at Table */}
        <LoungeChair
          angle={oddIndex ? 200 : -20}
          bottom={5}
          left={oddIndex ? undefined : 5}
          right={oddIndex ? 5 : undefined}
        />
        <div className="LoungeItem-hide-415px">
          <LoungeChair
            angle={oddIndex ? -20 : 200}
            bottom={5}
            left={oddIndex ? undefined : 75}
            right={oddIndex ? 75 : undefined}
          />
        </div>

        {/* Chair Alone */}
        <LoungeChair
          angle={oddIndex ? -45 : 225}
          bottom={5}
          left={oddIndex ? 8 : undefined}
          right={oddIndex ? undefined : 8}
        />

        {/* Windows */}
        <LoungeWindow
          onLeft={!oddIndex}
        />
      </div>
    );

    // Render
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div
        className={`LoungeItem-container ${!lounge.locked ? 'LoungeItem-container-hoverable ' : ''}alert alert-dark pl-2 pr-2 pt-2 pb-3 mb-2`}
        onClick={(e) => {
          // Ignore when lounge is locked
          if (lounge.locked) {
            return;
          }

          // Ignore button clicks
          const tagNames = [
            e.target.tagName.toLowerCase(),
            e.target.parentNode.tagName.toLowerCase(),
            e.target.parentNode.parentNode.tagName.toLowerCase(),
          ];
          if (tagNames.includes('button') || tagNames.includes('a')) {
            // Ignore button clicks
            return;
          }

          // Handle join
          onJoin();
        }}
      >
        {/* Flex Container */}
        <div className="text-center">
          {/* Title */}
          <div className="text-center">
            <h3 className="LoungeItem-title m-0">
              <strong>
                {nameElem}
              </strong>
            </h3>
          </div>

          {/* Visitors */}
          {visitorSmileys.length > 0 && (
            <div className="LoungeItem-smiley-container">
              <div
                className="d-inline-block"
                style={{ transform: 'translate(0, -8px)' }}
              >
                {visitorSmileys.length}
                {' '}
                visitor
                {visitorSmileys.length > 1 ? 's' : ''}
                {' '}
                this hour:
                {' '}
              </div>
              {visitorSmileys}
            </div>
          )}

          {/* Buttons and Notices */}
          <div className="d-flex align-items-center justify-content-center">
            {/* Lock Notice */}
            {lounge.locked && (
              <div className="LoungeItem-locked-notice alert alert-warning bg-warning text-dark mb-0 mr-1">
                <FontAwesomeIcon
                  icon={faLock}
                  className="mr-2"
                />
                <strong>
                  <span className="d-none d-sm-inline">
                    Temporarily
                    {' '}
                  </span>
                  Locked
                </strong>
                <span className="d-none d-md-inline">
                  {' '}
                  by Teaching Staff
                </span>
              </div>
            )}
            {/* Edit Button */}
            {!isLearner && (
              <button
                type="button"
                id={`LoungeItem-edit-button-${lounge.loungeId}`}
                className="LoungeItem-edit-button btn btn-secondary mr-1"
                aria-label={`edit the lounge titled "${name}"`}
                onClick={onEdit}
              >
                <FontAwesomeIcon
                  icon={faCog}
                />
              </button>
            )}
            {/* Tutorial Button */}
            {(!lounge.locked && !isLearner) && (
              <a
                className="LoungeItem-tutorial-button btn btn-secondary mr-1"
                href={STUDY_LOUNGE_TUTORIAL_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faQuestion}
                />
              </a>
            )}
            {/* Join Button */}
            {!lounge.locked && (
              <button
                type="button"
                id={`LoungeItem-join-button-${lounge.loungeId}`}
                className="LoungeItem-join-button btn btn-dark"
                aria-label={`join the lounge titled "${name}"`}
                onClick={onJoin}
              >
                <FontAwesomeIcon
                  icon={faSignInAlt}
                  className="mr-2"
                />
                Join
              </button>
            )}
          </div>

          {/* Room Furniture */}
          {furniture}
        </div>
      </div>
    );
  }
}

LoungeItem.propTypes = {
  // Integer index of the lounge item (for varying designs)
  index: PropTypes.number.isRequired,
  // Lounge
  lounge: Lounge.isRequired,
  // True if user is a learner
  isLearner: PropTypes.bool.isRequired,
  // Handler to call when user wants to edit the lounge
  onEdit: PropTypes.func.isRequired,
  // Handler to call when user wants to join the lounge
  onJoin: PropTypes.func.isRequired,
};

export default LoungeItem;
