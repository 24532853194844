/**
 * Attendance chooser for specific event/event type
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import FadedTitleContainer from '../../shared/FadedTitleContainer';

// Import shared propTypes
import Event from '../../shared/propTypes/Event';

// Import helpers
import eventTypeToIcon from '../../helpers/eventTypeToIcon';

class AttendanceEventChooser extends Component {
  /**
   * Render AttendanceEventChooser
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      onChosen,
      events,
      chosenEvent,
    } = this.props;

    // Create list of event items
    const eventItems = (
      events
        // Create elements
        .map((event) => {
          const {
            name,
            type,
            ihid,
          } = event;

          // Create the button
          const button = (
            (chosenEvent && chosenEvent.ihid === ihid)
              // Choose different item button
              ? (
                <button
                  type="button"
                  className="btn btn-sm btn-dark text-nowrap"
                  aria-label="go back to event chooser to show attendance for a different event"
                  id="AttendanceEventChooser-back-to-event-chooser"
                  onClick={() => {
                    onChosen(null);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="mr-2"
                  />
                  <span className="d-inline d-sm-none">
                    Choose Event
                  </span>
                  <span className="d-none d-sm-inline">
                    Choose Different Event
                  </span>
                </button>
              )
              // Choose this item button
              : (
                <button
                  type="button"
                  className="btn btn-sm btn-dark text-nowrap"
                  aria-label={`show attendance for event "${name}"`}
                  id={`AttendanceEventChooser-choose-event-${ihid}`}
                  onClick={() => {
                    onChosen(event);
                  }}
                >
                  Show Attendance
                </button>
              )
          );

          // Check if hidden (there's a chosen event and this isn't it)
          const hidden = (chosenEvent && chosenEvent.ihid !== ihid);

          return (
            <div
              key={ihid}
              className={`AttendanceEventChooser-event AttendanceEventChooser-event-${hidden ? 'hidden' : 'visible'} alert alert-dark d-flex align-items-center mb-0`}
              aria-hidden={hidden}
              style={{
                // Animate over 1s
                transition: 'all 0.5s',
                // Hide overflow for animation sake
                overflow: 'hidden',
                // Shrink height if hidden
                height: (hidden ? 0 : '38px'),
                // Remove padding if hidden
                padding: (hidden ? 0 : '5px'),
                // Remove margin if hidden
                marginTop: (hidden ? 0 : '5px'),
                // Fade out if hidden
                opacity: (hidden ? 0 : 1),
                // No border if hidden
                borderWidth: (hidden ? 0 : 1),
              }}
            >
              <div
                className="flex-grow-1 text-left"
                style={{ overflow: 'hidden' }}
                title={name}
              >
                <FadedTitleContainer alertVariant="dark">
                  <FontAwesomeIcon
                    icon={eventTypeToIcon(type)}
                    className="mr-2"
                  />
                  {name}
                </FadedTitleContainer>
              </div>
              <div>
                {button}
              </div>
            </div>
          );
        })
    );

    return (
      <div className="mt-2">
        {/* Title */}
        <h5 className="mb-1 text-center">
          {
            chosenEvent
              ? 'Chosen Event:'
              : 'Choose an Event:'
          }
        </h5>

        {/* Items */}
        <div className="AttendanceEventChooser-items">
          {eventItems}
        </div>
      </div>
    );
  }
}

AttendanceEventChooser.propTypes = {
  /**
   * Handler to call when chosen
   * @param {Event} specificEvent - the specific event to filter by
   */
  onChosen: PropTypes.func.isRequired,
  // List of events in the course
  events: PropTypes.arrayOf(Event).isRequired,
  // Specific event that's chosen
  chosenEvent: Event,
};

AttendanceEventChooser.defaultProps = {
  // No chosen event
  chosenEvent: null,
};

export default AttendanceEventChooser;
