/**
 * A recording item in the list
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../shared/Modal';
import ChoosePublishNameModal from './ChoosePublishNameModal';

// Import PropTypes
import ZoomRecording from '../shared/propTypes/ZoomRecording';

// Import helpers
import logAction from '../helpers/logAction';
import formatDate from '../helpers/formatDate';
import formatTime from '../helpers/formatTime';
import eventTypeToIcon from '../helpers/eventTypeToIcon';

// Import styles
import './RecordingPreview.css';
import ToggleSwitch from '../shared/ToggleSwitch';

class RecordingPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if play dropdown is open
      playDropdownExpanded: false,
      // True if confirming unpublish
      confirmingUnpublish: false,
      // True if still choosing recording name
      choosingRecordingName: false,
    };
  }

  /**
   * Render RecordingPreview
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      eventType,
      recording,
      onPublish,
      onUnpublish,
      isLearner,
      published,
      onPlay,
    } = this.props;
    const {
      playDropdownExpanded,
      confirmingUnpublish,
      choosingRecordingName,
    } = this.state;

    // Deconstruct recording
    const {
      name,
      ihid,
      timestamp,
      defaultPlayURL,
      otherPlayURLs,
    } = recording;

    // Create description strings
    const date = new Date(timestamp);
    const dayString = formatDate(date);
    const timeString = formatTime(date);

    // --------- Create confirm modal ------------------------- //
    let modal;
    if (confirmingUnpublish) {
      modal = (
        <Modal
          type={Modal.TYPES.OKAY_CANCEL}
          title="Unpublish?"
          body="If you unpublish this recording, students will no longer be able to watch it."
          okayLabel="Yes, Unpublish"
          okayColor="danger"
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Unpublish
              onUnpublish();
            }

            // Close the modal
            this.setState({
              confirmingUnpublish: false,
            });
          }}
        />
      );
    } else if (choosingRecordingName) {
      modal = (
        <ChoosePublishNameModal
          recording={recording}
          onPublish={() => {
            onPublish();
            // Close the modal
            this.setState({
              choosingRecordingName: false,
            });
          }}
          onCancel={() => {
            // Close the modal
            this.setState({
              choosingRecordingName: false,
            });
          }}
        />
      );
    }

    /* ----------------------- Create Buttons ----------------------- */

    const buttons = [];

    // Alternate play buttons (for the dropdown)
    const alternateViews = Object.keys(otherPlayURLs).map((title) => {
      const playURL = otherPlayURLs[title];

      // Create a hyphenated title for the id
      const hyphenatedTitle = (
        title
          .toLowerCase()
          // Split into words
          .split(' ')
          // Trim whitespace
          .map((word) => {
            return word.trim();
          })
          // Remove hyphens and whitespace
          .filter((word) => {
            return (
              word.length > 0
              && word !== '-'
              && word !== '–'
            );
          })
          // Hyphenate all words
          .join('-')
      );

      return (
        <button
          type="button"
          key={title}
          className={`RecordingPreview-play-view-button RecordingPreview-${ihid}-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-play-button-${hyphenatedTitle} dropdown-item btn-secondary`}
          onClick={() => {
            onPlay(playURL);

            // Log this
            logAction({
              type: 'open',
              description: 'recording link',
              metadata: {
                playURL,
                ihid: recording.ihid,
                isDefaultView: false,
              },
            });
          }}
          aria-label={`play the ${title} view for this recording`}
        >
          {title}
        </button>
      );
    });

    // Add the play button and dropdown
    buttons.push(
      <div key="play" className="btn-group">
        {/* Default Play Button */}
        <button
          type="button"
          onClick={() => {
            onPlay(defaultPlayURL);

            // Log this
            logAction({
              type: 'open',
              description: 'recording link',
              metadata: {
                playURL: defaultPlayURL,
                ihid: recording.ihid,
                isDefaultView: true,
              },
            });
          }}
          className={`RecordingPreview-play-button RecordingPreview-${ihid}-button RecordingPreview-${ihid}-play-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-play-button btn btn-secondary text-light`}
          aria-label={`play recording of ${name} from ${dayString} at ${timeString}`}
          title="Play Recording"
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            className="mr-2"
          />
          Play
        </button>

        {/* Dropdown Toggle */}
        <button
          type="button"
          className={`RecordingPreview-expand-play-menu-dropdown RecordingPreview-${ihid}-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-expand-play-menu-dropdown btn btn-secondary dropdown-toggle dropdown-toggle-split border-left`}
          data-toggle="dropdown"
          title="More Playback Views"
          aria-haspopup
          aria-expanded={playDropdownExpanded}
          aria-label="Toggle dropdown: alternate recording views"
          onClick={() => {
            this.setState({
              playDropdownExpanded: !playDropdownExpanded,
            });
          }}
        >
          <span className="sr-only">
            Toggle dropdown: alternate recording views
          </span>
        </button>

        {/* Dropdown menu and items */}
        <div className={`dropdown-menu${playDropdownExpanded ? ' show' : ''} dropdown-menu-right`}>
          {/* Dropdown Title/Spacer */}
          <h6 className="dropdown-header font-weight-bold text-dark">
            Choose a View to Play:
          </h6>

          {/* Default View */}
          <button
            type="button"
            className={`RecordingPreview-${ihid}-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-play-button-dynamic-view dropdown-item btn-secondary`}
            onClick={() => {
              onPlay(defaultPlayURL);

              // Log this
              logAction({
                type: 'open',
                description: 'recording link',
                metadata: {
                  playURL: defaultPlayURL,
                  ihid: recording.ihid,
                  isDefaultView: true,
                },
              });
            }}
            aria-label="Play default view"
          >
            Dynamic View&nbsp;
            <FontAwesomeIcon
              icon={faStar}
              className="text-warning"
            />
          </button>

          {/* Other Views */}
          {alternateViews}
        </div>
      </div>
    );

    // Publish button/mark
    if (!isLearner) {
      buttons.push(
        <span className="ml-1">
          <ToggleSwitch
            labelIfOn={{
              text: 'Published',
              title: 'Click to Unpublish',
              ariaLabel: `unpublish recording of ${name} from ${dayString} at ${timeString}`,
              className: `RecordingPreview-${ihid}-button RecordingPreview-${ihid}-unpublish-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-unpublish-button`,
            }}
            labelIfOff={{
              text: 'Not Published',
              title: 'Click to Publish',
              ariaLabel: `publish recording of ${name} from ${dayString} at ${timeString}`,
              className: `RecordingPreview-${ihid}-button RecordingPreview-${ihid}-publish-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-publish-button`,
            }}
            isOn={published}
            onChange={(beingPublished) => {
              if (beingPublished) {
                // Show the publish modal
                this.setState({
                  choosingRecordingName: true,
                });
              } else {
                // Show the confirmation
                this.setState({
                  confirmingUnpublish: true,
                });
              }
            }}
          />
        </span>
      );
    }

    /* --------------------------- Main UI -------------------------- */

    const title = (
      <h5 className="m-0 RecordingPreview-recording-name-and-date">
        {/* Vertically Centered Title and Icon */}
        <div className="d-flex align-items-center justify-content-center">
          {/* Icon */}
          <div>
            {/* Icon for larger screens */}
            <span className="d-none d-md-inline mr-2">
              <FontAwesomeIcon
                icon={eventTypeToIcon(eventType)}
                className="ml-1 mr-1"
                style={{ fontSize: '25px' }}
              />
            </span>
          </div>
          {/* Text */}
          <div className="flex-grow-1">
            {/* Name */}
            <div>
              <strong className={`RecordingPreview-${ihid} RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-recording-name`}>
                {name}
              </strong>
            </div>
            {/* Date and Time */}
            <div className="small">
              <span className="text-nowrap">
                {/* Date */}
                {dayString}

                {/* Separator */}
                &nbsp;at&nbsp;

                {/* Time */}
                {timeString}
              </span>
            </div>
          </div>
        </div>
      </h5>
    );

    // Create recording item
    return (
      <div className={`RecordingPreview-${ihid} RecordingPreview-${ihid}-at-${timestamp} alert alert-light p-2 bg-light text-dark border`}>
        {modal}
        <div className="RecordingPreview-container d-flex align-items-center justify-content-center">
          <div className="RecordingPreview-title-text flex-grow-1 ml-1">
            {title}
          </div>
          <div
            className="RecordingPreview-buttons d-flex align-items-center justify-content-center"
            style={{ whiteSpace: 'nowrap' }}
          >
            {buttons}
          </div>
        </div>
      </div>
    );
  }
}

RecordingPreview.propTypes = {
  // Type of the event corresponding to this recording
  eventType: PropTypes.string.isRequired,
  // Recording
  recording: ZoomRecording.isRequired,
  // True if published
  published: PropTypes.bool.isRequired,
  /**
   * Handler for when user click to play a recording
   * @param {string} url - the url to play
   */
  onPlay: PropTypes.func.isRequired,
  // True if the current user is a learner
  isLearner: PropTypes.bool,
  // Handler for when user clicks "Publish" - if excluded, no button
  onPublish: PropTypes.func,
  // Handler for when user clicks "Unpublish" - if excluded, no button
  onUnpublish: PropTypes.func,
};

RecordingPreview.defaultProps = {
  // Not a learner
  isLearner: false,
  // No publish function
  onPublish: null,
  // No unpublish function
  onUnpublish: null,
};

export default RecordingPreview;
