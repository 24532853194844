/**
 * Manage lounge bans
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import TabBox from '../shared/TabBox';
import Modal from '../shared/Modal';
import ErrorAlert from '../shared/ErrorAlert';
import NothingHereNotice from '../shared/NothingHereNotice';

// Import helpers
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import logAction from '../helpers/logAction';
import onlyKeepLetters from '../helpers/onlyKeepLetters';

class LoungeBanManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Current list of bans
      bans: [],
      // Students in the current course
      studentsInCourse: [],
      // If defined, creating a new ban and this is the tentative ban
      banBeingCreated: null,
      // If defined, removing an existing ban and this is it
      banBeingDeleted: null,
      // True if loading
      loading: true,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
    };
  }

  /**
   * Load bans
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    try {
      const [
        bans,
        studentsInCourse,
      ] = await Promise.all([
        visitServerEndpoint({
          path: '/api/admin/lounges/bans',
          method: 'GET',
        }),
        visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/students`,
          method: 'GET',
        }),
      ]);

      return this.setState({
        bans,
        studentsInCourse,
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Add the ban that's currently being created
   * @author Gabe Abrams
   */
  async addBan(banBeingCreated) {
    const { bans } = this.state;

    // Start loading
    this.setState({
      loading: true,
    });

    try {
      // Add the ban
      await visitServerEndpoint({
        path: `/api/admin/lounges/bans/users/${banBeingCreated.userId}`,
        method: 'POST',
        params: {
          ban: JSON.stringify(banBeingCreated),
        },
      });

      // Log this
      logAction({
        type: 'create',
        description: 'lounge ban',
        metadata: {
          isAdminFeature: true,
          banBeingCreated,
        },
      });

      // Update the state
      bans.push(banBeingCreated);
      this.setState({
        loading: false,
        bans,
        banBeingCreated: null,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Delete the selected ban
   * @author Gabe Abrams
   */
  async deleteBan() {
    const { banBeingDeleted } = this.state;
    let { bans } = this.state;

    // Start loading
    this.setState({
      loading: true,
    });

    try {
      // Delete the ban
      await visitServerEndpoint({
        path: `/api/admin/lounges/bans/users/${banBeingDeleted.userId}`,
        method: 'DELETE',
      });

      // Log this
      logAction({
        type: 'delete',
        description: 'lounge ban',
        metadata: {
          isAdminFeature: true,
          banBeingDeleted,
        },
      });

      // Update the state
      bans = bans.filter((ban) => {
        return (ban.userId !== banBeingDeleted.userId);
      });
      this.setState({
        loading: false,
        bans,
        banBeingDeleted: null,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Render LoungeBanManager
   * @author Gabe Abrams
   */
  render() {
    const {
      courseId,
      courseName,
    } = this.props;
    const {
      bans,
      studentsInCourse,
      loading,
      fatalErrorMessage,
      fatalErrorCode,
      banBeingCreated,
      banBeingDeleted,
    } = this.state;

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
          code={fatalErrorCode}
          showReloadButton
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    // Get the list of students without bans
    const studentsWithoutBans = studentsInCourse.filter((student) => {
      return bans.every((ban) => {
        return (ban.userId !== student.id);
      });
    });

    /*------------------------------------------------------------------------*/
    /*                                  Modal                                 */
    /*------------------------------------------------------------------------*/

    let modal;

    /*----------------------------------------*/
    /*                 Delete                 */
    /*----------------------------------------*/

    if (banBeingDeleted) {
      modal = (
        <Modal
          key="delete-ban-confirm"
          title="Delete this ban?"
          body={(
            <div>
              Are you sure you want to delete the ban for
              {' '}
              {banBeingDeleted.userFirstName}
              {' '}
              {banBeingDeleted.userLastName}
              ? This action
              {' '}
              <strong>
                cannot be undone
              </strong>
              .
            </div>
          )}
          type={Modal.TYPES.OKAY_CANCEL}
          okayLabel="Delete"
          okayColor="danger"
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed! Delete
              this.deleteBan();
            } else {
              this.setState({
                banBeingDeleted: null,
              });
            }
          }}
        />
      );
    }

    /*----------------------------------------*/
    /*                 Create                 */
    /*----------------------------------------*/

    if (banBeingCreated) {
      if (!banBeingCreated.userId) {
        // User chooser
        modal = (
          <Modal
            key="choose-student"
            title="Choose Student to Ban:"
            type={Modal.TYPES.NO_BUTTONS}
            body={(
              <div>
                {
                  studentsWithoutBans.map((student) => {
                    // Separate name into parts
                    const [userLastName, userFirstName] = student.sortable_name.split(', ');

                    return (
                      <div className="alert alert-secondary mb-2 p-1 d-flex align-items-center justify-content-center">
                        <h4 className="flex-grow-1 m-0">
                          {student.name}
                        </h4>
                        <div>
                          <button
                            type="button"
                            className={`LoungeBanManager-choose-button-named-${onlyKeepLetters(userFirstName)}-${onlyKeepLetters(userLastName)} LoungeBanManager-choose-button btn btn-dark btn-sm`}
                            aria-label={`choose ${student.name} as the target of the ban`}
                            id={`LoungeBanManager-choose-${student.id}`}
                            onClick={() => {
                              // Add info to ban
                              banBeingCreated.userId = student.id;
                              banBeingCreated.userFirstName = userFirstName;
                              banBeingCreated.userLastName = userLastName;

                              // Update state
                              this.setState({
                                banBeingCreated,
                              });
                            }}
                          >
                            Choose
                          </button>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            )}
            onClose={() => {
              // User cancelled
              this.setState({
                banBeingCreated: null,
              });
            }}
          />
        );
      } else {
        // Ban creation modal
        modal = (
          <Modal
            key="choose-ban-target"
            title="Choose Ban Target:"
            type={Modal.TYPES.NO_BUTTONS}
            body={(
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className="LoungeBanManager-target-current-course btn btn-dark btn-block btn-lg"
                    aria-label="set target to current course"
                    onClick={() => {
                      // Update the ban appropriately
                      banBeingCreated.banFromAllLounges = false;
                      banBeingCreated.banFromCourseId = courseId;
                      banBeingCreated.banFromCourseName = courseName;

                      // Add ban
                      this.addBan(banBeingCreated);
                    }}
                  >
                    <h3 className="m-0">
                      Just Current Course
                    </h3>
                    <div>
                      (lounges in
                      {' '}
                      {courseName}
                      )
                    </div>
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="LoungeBanManager-target-all-courses btn btn-dark btn-block btn-lg"
                    aria-label="set target to all courses"
                    onClick={() => {
                      // Update the ban appropriately
                      banBeingCreated.banFromAllLounges = true;
                      banBeingCreated.banFromCourseId = undefined;
                      banBeingCreated.banFromCourseName = undefined;

                      // Add ban
                      this.addBan(banBeingCreated);
                    }}
                  >
                    <h3 className="m-0">
                      All Courses
                    </h3>
                    <div>
                      (lounges in all courses)
                    </div>
                  </button>
                </div>
              </div>
            )}
            onClose={() => {
              // User cancelled
              this.setState({
                banBeingCreated: null,
              });
            }}
          />
        );
      }
    }

    /*------------------------------------------------------------------------*/
    /*                               Ban Manager                              */
    /*------------------------------------------------------------------------*/

    // Display each ban
    const banElems = bans.map((ban) => {
      const {
        userId,
        userFirstName,
        userLastName,
        banFromAllLounges,
        banFromCourseId,
        banFromCourseName,
      } = ban;

      return (
        <div
          key={userId}
          className="alert alert-secondary mb-2 p-2 d-flex align-items-center justify-content-center"
        >
          {/* Info */}
          <div className="flex-grow-1">
            <h3 className="m-0">
              {userFirstName}
              {' '}
              {userLastName}
            </h3>
            <div>
              Banned from
              {' '}
              {banFromAllLounges && (
                <span>
                  all lounges in all courses
                </span>
              )}
              {banFromCourseName && (
                <span>
                  all lounges in
                  {' '}
                  <a
                    href={`https://canvas.harvard.edu/courses/${banFromCourseId}`}
                    target="_blank"
                    className={`LoungeBanManager-course-link-${banFromCourseId}`}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    {banFromCourseName}
                  </a>
                </span>
              )}
            </div>
          </div>

          {/* Buttons */}
          <div>
            <button
              type="button"
              className={`LoungeBanManager-delete-button LoungeBanManager-delete-button-named-${onlyKeepLetters(userFirstName)}-${onlyKeepLetters(userLastName)} LoungeBanManager-delete-${userId} LoungeBanManager-${banFromCourseId ? 'specific-course-ban' : 'all-courses-ban'} btn btn-dark`}
              aria-label={`delete ban for ${userFirstName} ${userLastName}`}
              id={`LoungeBanManager-delete-${userId}-${banFromCourseId || 'all'}`}
              onClick={() => {
                this.setState({
                  banBeingDeleted: ban,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="mr-2"
              />
              Delete
            </button>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Modal */}
        {modal}

        {/* Title */}
        <h2 className="mb-3">
          Study Lounge Ban Manager
        </h2>

        {/* Permissions */}
        <TabBox
          title={(
            <span>
              <FontAwesomeIcon
                icon={faBan}
                className="mr-2"
              />
              Study Lounge Bans
            </span>
          )}
          noPaddingOnBottom
        >
          {/* Bans */}
          {banElems.length === 0 && (
            <NothingHereNotice
              title="No Bans Yet"
              subtitle="Create a ban by clicking below"
            />
          )}
          {banElems}

          {/* Create Button */}
          {studentsWithoutBans.length > 0 && (
            <button
              type="button"
              id="LoungeBanManager-create-lounge-ban"
              className="btn btn-lg btn-light btn-block p-3 mb-2"
              style={{ border: '2px dashed black' }}
              aria-label="create a new lounge ban"
              onClick={() => {
                // Create the initial state of the lounge ban
                this.setState({
                  banBeingCreated: {
                    userId: null, // must be chosen
                    userFirstName: null, // must be chosen
                    userLastName: null, // must be chosen
                    banFromAllLounges: false,
                    banFromCourseId: courseId,
                    banFromCourseName: courseName,
                    createdAt: Date.now(),
                  },
                });
              }}
            >
              <h3 className="m-0">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-2"
                />
                Create Study Lounge Ban
              </h3>
            </button>
          )}
        </TabBox>
      </div>
    );
  }
}

LoungeBanManager.propTypes = {
  // CourseId for the current course
  courseId: PropTypes.number.isRequired,
  // Name of the current course
  courseName: PropTypes.string.isRequired,
};

export default LoungeBanManager;
