/**
 * Analytics panel for analytics and downloads
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorAlert from '../shared/ErrorAlert';

// Import other components
import AttendanceDashboard from './AttendanceDashboard';

// Import helpers
import setPagePath from '../helpers/setPagePath';
import visitServerEndpoint from '../helpers/visitServerEndpoint';

// Import constants
import WEEKDAY_TO_CODE from '../constants/WEEKDAY_TO_CODE';
import USER_ROLES from '../constants/USER_ROLES';
import ATTENDANCE_METHODS from '../constants/ATTENDANCE_METHODS';

/* -------------------------- Constants ------------------------- */

const RECORD_DEFAULTS = {
  userName: 'Name Not Recorded',
  userRole: 'Unknown (Hasn\'t Attended Anything)',
  inAttendance: false,
  method: 'absent',
  groupNumber: 'N/A',
};

/* -------------------------- Component ------------------------- */

class AttendancePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error message
      fatalErrorMessage: null,
      // Fatal error code
      fatalErrorCode: null,
      // Attendance log map (date => ihid => userId => record)
      attendanceLogMap: {},
      // List of all events
      events: null,
    };
  }

  /**
   * Load attendance logs
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    try {
      const [
        attendanceLogs,
        events,
      ] = await Promise.all([
        visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/attendance`,
          method: 'GET',
        }),
        visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/events`,
          method: 'GET',
        }),
      ]);

      // Pre-process events
      const ihidToEvent = {}; // ihid => event
      events.forEach((event) => {
        ihidToEvent[event.ihid] = event;
      });

      // Sort attendance logs (earliest first)
      attendanceLogs.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      // Keep track of user info
      const userIdToRole = {}; // userId => role
      const userIdToName = {}; // userId => name

      // Process attendance logs
      const dateToEventToUserToRecord = {}; // date => ihid => userId => record
      attendanceLogs.forEach((log) => {
        const {
          userId,
          userFirstName,
          userLastName,
          ihid,
          method,
          isLearner,
          isAdmin,
          isHost,
          groupNum,
        } = log;
        const timestamp = (
          method === ATTENDANCE_METHODS.ASYNCHRONOUS
            ? log.eventTimestamp // Use the timestamp of the event if async
            : log.timestamp
        );
        const userName = (
          userFirstName && userLastName
            ? `${userLastName}, ${userFirstName}`
            : null
        );

        // Get other objects
        const event = ihidToEvent[ihid];
        const time = new Date(timestamp);

        // Skip when the event has disappeared
        if (!event) {
          return;
        }

        // Get date key
        const dateKey = time.toLocaleDateString(
          'en-US',
          { timeZone: 'America/New_York' }
        );

        // Add record if there isn't one
        if (!dateToEventToUserToRecord[dateKey]) {
          dateToEventToUserToRecord[dateKey] = {};
        }
        if (!dateToEventToUserToRecord[dateKey][event.ihid]) {
          dateToEventToUserToRecord[dateKey][event.ihid] = {};
        }
        if (!dateToEventToUserToRecord[dateKey][event.ihid][userId]) {
          dateToEventToUserToRecord[dateKey][event.ihid][userId] = {
            userId,
            userName: RECORD_DEFAULTS.userName,
            userRole: RECORD_DEFAULTS.userRole,
            inAttendance: false,
            eventDate: dateKey,
            eventDayOfWeek: null,
            eventFractionalHour: null, // part of hour is indicated by decimal
            joinTimes: [], // [{ method, description }, ...]
            eventId: event.ihid,
            eventName: event.name,
            eventType: event.type,
            userWasHost: false,
            method: RECORD_DEFAULTS.method,
            groupNumber: RECORD_DEFAULTS.groupNumber,
            timestamp: null,
          };
        }

        // Update record for this attendance entry

        // Determine the user's role
        let userRole;
        if (isAdmin) {
          userRole = USER_ROLES.ADMIN;
        } else if (isLearner) {
          userRole = USER_ROLES.STUDENT;
        } else {
          userRole = USER_ROLES.TTM;
        }
        userIdToRole[userId] = userRole;

        // Save user's name
        if (userName) {
          userIdToName[userId] = userName;
        }

        // > Add role
        if (
          dateToEventToUserToRecord[dateKey][event.ihid][userId].userRole
          === RECORD_DEFAULTS.userRole
        ) {
          dateToEventToUserToRecord[dateKey][event.ihid][userId].userRole = (
            userRole
          );
        }
        // > Add name
        if (userName) {
          dateToEventToUserToRecord[dateKey][event.ihid][userId].userName = (
            userName
          );
        }
        // > Add attendance boolean
        dateToEventToUserToRecord[dateKey][event.ihid][userId].inAttendance = (
          true
        );
        // > Save method of attendance
        if (
          dateToEventToUserToRecord[dateKey][event.ihid][userId].method
          === RECORD_DEFAULTS.method
        ) {
          dateToEventToUserToRecord[dateKey][event.ihid][userId].method = (
            method
          );
        }
        // > Add whether the user was a host
        dateToEventToUserToRecord[dateKey][event.ihid][userId].userWasHost = (
          dateToEventToUserToRecord[dateKey][event.ihid][userId].userWasHost
          || isHost
        );
        // > Add group number
        if (groupNum) {
          dateToEventToUserToRecord[dateKey][event.ihid][userId].groupNumber = (
            groupNum
          );
        }
        // > Timestamp, day, time
        if (!dateToEventToUserToRecord[dateKey][event.ihid][userId].timestamp) {
          // Add timestamp
          dateToEventToUserToRecord[dateKey][event.ihid][userId].timestamp = (
            timestamp
          );

          // Add the day of the week in ET
          const weekday = time.toLocaleString(
            'en-US',
            {
              timeZone: 'America/New_York',
              weekday: 'long',
            }
          );
          const weekdayCode = WEEKDAY_TO_CODE[weekday];
          dateToEventToUserToRecord[dateKey][event.ihid][userId]
            .eventDayOfWeek = weekdayCode;

          // Add hours
          const [hours, minutes] = (
            time
              .toLocaleTimeString(
                'en-US',
                {
                  timeZone: 'America/New_York',
                  hour12: false,
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )
              .split(':')
              .map((part) => {
                return Number.parseInt(part);
              })
          );
          dateToEventToUserToRecord[dateKey][event.ihid][userId]
            .eventFractionalHour = hours + (minutes / 60);
        }
        // >> Add join time
        if (method === ATTENDANCE_METHODS.ASYNCHRONOUS) {
          // Add indication of asynchronous watch
          const description = `Watched Recording on ${time.toLocaleDateString('en-US', { timeZone: 'America/New_York' })} at ${time.toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}`;

          // Add to list
          dateToEventToUserToRecord[dateKey][event.ihid][userId]
            .joinTimes.push({
              method,
              description,
            });
        } else {
          const alreadyJoinedLive = (
            dateToEventToUserToRecord[dateKey][event.ihid][userId]
              .joinTimes
              .some((item) => {
                return (item.method === ATTENDANCE_METHODS.LIVE);
              })
          );

          // Add indication of live join
          const description = `${alreadyJoinedLive ? 'Re-joined' : 'Joined'} Live at ${time.toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}`;

          // Add to list
          dateToEventToUserToRecord[dateKey][event.ihid][userId]
            .joinTimes.push({
              method,
              description,
            });
        }
      });

      // Override roles and names for everyone
      Object.keys(dateToEventToUserToRecord).forEach((date) => {
        Object.keys(dateToEventToUserToRecord[date]).forEach((ihid) => {
          Object.keys(dateToEventToUserToRecord[date][ihid]).forEach((id) => {
            // Overwrite role and name
            const knownRole = userIdToRole[id];
            const knownName = userIdToName[id];
            if (knownRole) {
              dateToEventToUserToRecord[date][ihid][id].userRole = knownRole;
            }
            if (knownName) {
              dateToEventToUserToRecord[date][ihid][id].userName = knownName;
            }
          });
        });
      });

      // Filter out archived events
      const unarchivedEvents = events.filter((event) => {
        return !event.archived;
      });

      // Update the state
      return this.setState({
        events: unarchivedEvents,
        attendanceLogMap: dateToEventToUserToRecord,
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
        fatalErrorCode: err.code,
      });
    }
  }

  /**
   * Render AttendancePanel
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct
    const {
      courseName,
    } = this.props;
    const {
      loading,
      fatalErrorMessage,
      fatalErrorCode,
      attendanceLogMap,
      events,
    } = this.state;

    // Set page path
    setPagePath('Attendance Logs');

    // Show error message if there is one
    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
          code={fatalErrorCode}
          showReloadButton
        />
      );
    }

    // Show loading spinner if still loading
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    /* -------------------------- UI Parts -------------------------- */

    return (
      <div>
        {/* Title */}
        <div className="mb-4">
          <h2 className="m-0">
            Attendance Analytics
          </h2>
          <p className="lead m-0">
            Current course:&nbsp;
            {courseName}
          </p>
        </div>

        <div className="alert alert-danger mb-4">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="mr-1"
          />
          <strong>
            Note:
          </strong>
          {' '}
          this feature is in beta.
          Do not rely on this attendance data for grading.
        </div>

        {/* Attendance Dashboard */}
        <AttendanceDashboard
          attendanceLogMap={attendanceLogMap}
          events={events}
        />
      </div>
    );
  }
}

AttendancePanel.propTypes = {
  // Id of the course
  courseId: PropTypes.number.isRequired,
  // Name of the course
  courseName: PropTypes.string.isRequired,
};

export default AttendancePanel;
