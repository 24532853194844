/**
 * Takes a string and only keeps letters. Also makes all letters lowercase
 * @author Gabe Abrams
 * @param {string} str the string to process
 * @returns {string} the same string but with only the letters kept
 */
const onlyKeepLetters = (str) => {
  return str.replace(/[^a-zA-Z]+/g, '').toLowerCase();
};

export default onlyKeepLetters;
