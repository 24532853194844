const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Format a date object
 * @author Gabe Abrams
 * @param {Date} date - the date to format
 * @return {string} formatted date
 */
export default (date) => {
  // Month
  const month = MONTHS[date.getMonth()];

  // Day
  // > Suffix
  let suffix;
  if (date.getDate() >= 4 && date.getDate() <= 20) {
    suffix = 'th';
  } else if (String(date.getDate()).endsWith('1')) {
    suffix = 'st';
  } else if (String(date.getDate()).endsWith('2')) {
    suffix = 'nd';
  } else if (String(date.getDate()).endsWith('3')) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }
  // > Assemble string
  const day = `${date.getDate()}${suffix}`;

  // Year
  const year = (
    (new Date()).getFullYear() === date.getFullYear()
      // Same year, just leave out the year
      ? ''
      // Different year. Leave it in
      : date.getFullYear()
  );

  // Put together
  return `${month} ${day}${year ? ', ' : ''}${year}`;
};
