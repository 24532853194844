/**
 * A very simple, lightweight date chooser
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import helpers
import getOrdinal from '../helpers/getOrdinal';
import getTimeInfoInET from '../helpers/getTimeInfoInET';

// Constants
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class SimpleDateChooser extends Component {
  constructor(props) {
    super(props);

    // Deconstruct props
    const numMonthsToShow = Math.min(props.numMonthsToShow, 12);

    // Determine the set of choices allowed
    const today = getTimeInfoInET();
    const choices = []; // [{ month, days[], year }, ...]
    for (let i = 0; i < numMonthsToShow; i++) {
      // Get month and year info
      const unmoddedMonth = (today.month + i);
      const month = (
        unmoddedMonth > 12
          ? unmoddedMonth - 12
          : unmoddedMonth
      );
      const monthName = MONTH_NAMES[month - 1];
      const year = (
        unmoddedMonth > 12
          ? today.year + 1
          : today.year
      );

      // Figure out which days are allowed
      const days = [];
      const numDaysInMonth = (new Date(year, month, 0)).getDate();
      const firstDay = (
        month === today.month
          ? today.day // Current month: start at current date
          : 1 // Future month: start at beginning of month
      );
      for (let day = firstDay; day <= numDaysInMonth; day++) {
        days.push(day);
      }

      choices.push({
        monthName,
        month,
        year,
        days,
      });
    }

    // Save to state
    this.state = {
      // Allowed choices
      choices,
    };
  }

  /**
   * Render SimpleDateChooser
   * @author Gabe Abrams
   */
  render() {
    const {
      name,
      month,
      day,
      year,
      onChange,
    } = this.props;
    const {
      choices,
    } = this.state;

    // Create choice options
    const monthOptions = [];
    const dayOptions = [];
    choices.forEach((choice) => {
      // Create month option
      monthOptions.push(
        <option
          key={choice.month}
          value={choice.month}
          aria-label={`choose ${choice.monthName}, ${choice.year}`}
          onSelect={() => {
            onChange(choice.month, choice.days[0], choice.year);
          }}
        >
          {choice.monthName}
        </option>
      );

      if (month === choice.month) {
        // This is the currently selected month
        // Create day options
        choice.days.forEach((dayChoice) => {
          const ordinal = getOrdinal(dayChoice);
          dayOptions.push(
            <option
              key={dayChoice}
              value={dayChoice}
              aria-label={`choose date ${dayChoice}`}
            >
              {dayChoice}
              {ordinal}
            </option>
          );
        });
      }
    });

    return (
      <div
        className="SimpleDateChooser d-inline-block"
        aria-label={`date chooser with selected date: ${month} ${day}, ${year}`}
      >
        {/* Month Chooser */}
        <select
          className="custom-select d-inline-block mr-1"
          style={{ width: 'auto' }}
          id={`SimpleDateChooser-${name}-month`}
          value={month}
          onChange={(e) => {
            const choice = choices[e.target.selectedIndex];

            // Change day, month, and year
            onChange(choice.month, choice.days[0], choice.year);
          }}
        >
          {monthOptions}
        </select>

        {/* Day Chooser */}
        <select
          className="custom-select d-inline-block"
          style={{ width: 'auto' }}
          id={`SimpleDateChooser-${name}-day`}
          value={day}
          onChange={(e) => {
            // Only change the day
            onChange(month, e.target.value, year);
          }}
        >
          {dayOptions}
        </select>
      </div>
    );
  }
}

SimpleDateChooser.propTypes = {
  // Name of the chooser (machine-readable, hyphenated)
  name: PropTypes.string.isRequired,
  // Currently selected month
  month: PropTypes.number.isRequired,
  // Currently selected day
  day: PropTypes.number.isRequired,
  // Currently selected year
  year: PropTypes.number.isRequired,
  /**
   * Handler for when date changes
   * @param {number} month - new 1-indexed month number
   * @param {number} day - new 1-indexed day number
   * @param {number} year - new full year number
   */
  onChange: PropTypes.func.isRequired,
  // Number of months in the future to allow the user to schedule into
  // (max is 12)
  numMonthsToShow: PropTypes.number,
};

SimpleDateChooser.defaultProps = {
  // 6 months
  numMonthsToShow: 6,
};

export default SimpleDateChooser;
