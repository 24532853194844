/**
 * Form element for an event's zoom meeting (or webinar)
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faExternalLinkAlt,
  faDotCircle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

// Import shared components
import ExpandableDrawer from '../ExpandableDrawer';
import ButtonLock from '../ButtonLock';

// Import shared constants
import SUPPORT_EMAILS from '../../constants/SUPPORT_EMAILS';

/* -------------------------- Component ------------------------- */

class ZoomMeeting extends Component {
  /**
   * Render ZoomMeeting
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      currentZoomId,
      onAttach,
      onDetach,
      lockZoomToggle,
      lockAutoRecordSetting,
      onLockedFeatureClicked,
      settingsLoaded,
      onStartLoad,
      autoRecordOn,
      isWebinar,
      onAutoRecordChanged,
      waitingRoomOn,
      onWaitingRoomChanged,
      currentZoomLink,
    } = this.props;

    // Return full UI (most logic is inline)
    return (
      <ExpandableDrawer
        key="zoom"
        icon={faHeadset}
        title="Zoom"
        contents={(
          <span>
            {/* Attach button */}
            <button
              type="button"
              className={`btn btn-${currentZoomId ? 'warning selected' : 'light'} btn-sm m-0 mr-2 position-relative`}
              aria-label={`indicate that this event is held in Zoom${currentZoomId ? ': currently selected' : ''}`}
              id="ZoomMeeting-held-in-zoom-button"
              onClick={() => {
                // Skip if already attached
                if (currentZoomId) {
                  return;
                }

                // Show lock
                if (lockZoomToggle) {
                  return onLockedFeatureClicked();
                }

                // Attach
                onAttach();
              }}
            >
              <FontAwesomeIcon
                icon={
                  currentZoomId
                    ? faDotCircle
                    : faCircle
                }
                className="mr-1"
              />
              Held in Zoom

              {/* Show lock icon if this button is locked */}
              {(lockZoomToggle && !currentZoomId) && (
                <ButtonLock />
              )}
            </button>

            {/* Detach button */}
            <button
              type="button"
              className={`btn btn-${!currentZoomId ? 'warning selected' : 'light'} btn-sm m-0 position-relative`}
              aria-label={`indicate that this event is not held in Zoom${!currentZoomId ? ': currently selected' : ''}`}
              id="ZoomMeeting-not-held-in-zoom-button"
              onClick={() => {
                // Skip if already done
                if (!currentZoomId) {
                  return;
                }

                // Show lock
                if (lockZoomToggle) {
                  return onLockedFeatureClicked();
                }

                // Detach
                onDetach();
              }}
            >
              <FontAwesomeIcon
                icon={
                  !currentZoomId
                    ? faDotCircle
                    : faCircle
                }
                className="mr-1"
              />

              {/* Small Screen View */}
              <span className="d-inline d-sm-none">
                Not in Zoom
              </span>

              {/* Large Screen View */}
              <span className="d-none d-sm-inline">
                Not Held in Zoom
              </span>

              {/* Show lock icon if this button is locked */}
              {(lockZoomToggle && currentZoomId) && (
                <ButtonLock />
              )}
            </button>
          </span>
        )}
        expanded={!!currentZoomId}
        renderDrawerContents={() => {
          // Show warning for webinars
          if (isWebinar) {
            return (
              <div>
                <div className="font-weight-bold">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="mr-1"
                  />
                  Managed by DCE Staff
                </div>
                <div>
                  To make changes to this Zoom webinar,
                  please contact DCE support at
                  {' '}
                  <a
                    href={`mailto:${SUPPORT_EMAILS.FOR_TTMS}`}
                    style={{
                      color: 'inherit',
                      textDecoration: 'underline',
                    }}
                  >
                    {SUPPORT_EMAILS.FOR_TTMS}
                  </a>
                  .
                </div>
                {currentZoomLink && (
                  <div className="mt-1">
                    <a
                      href={currentZoomLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ZoomMeeting-webinar-id-link btn progress-bar-striped font-weight-bold bg-success text-white pt-0 pb-0 btn-sm ml-2"
                      aria-label="reveal the webinar in Zoom"
                      id="ZoomMeeting-reveal-webinar-in-zoom-button"
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      Reveal in Zoom Admin Panel
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="ml-1"
                      />
                    </a>
                  </div>
                )}
              </div>
            );
          }

          // Create auto-record toggle
          const autoRecordToggle = (
            <div className="mt-3">
              <ExpandableDrawer
                title="Auto-record"
                noMarginOnBottom
                contents={(
                  settingsLoaded
                    ? (
                      <span>
                        {/* Turn on auto-record Button */}
                        <button
                          type="button"
                          className={`ZoomMeeting-auto-record-on-button btn btn-${autoRecordOn ? 'warning selected' : 'light'} btn-sm mr-2 position-relative`}
                          aria-label={`turn on auto-record${autoRecordOn ? ': currently selected' : ''}`}
                          id="ZoomMeeting-turn-on-auto-record-button"
                          onClick={() => {
                            // Skip if already changed
                            if (autoRecordOn) {
                              return;
                            }

                            // Show lock
                            if (lockAutoRecordSetting) {
                              return onLockedFeatureClicked();
                            }

                            // Change feature
                            onAutoRecordChanged(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              autoRecordOn
                                ? faDotCircle
                                : faCircle
                            }
                            className="mr-1"
                          />
                          on

                          {/* Show lock icon if this button is locked */}
                          {(lockAutoRecordSetting && !autoRecordOn) && (
                            <ButtonLock />
                          )}
                        </button>

                        {/* Turn off auto-record Button */}
                        <button
                          type="button"
                          className={`ZoomMeeting-auto-record-off-button btn btn-${!autoRecordOn ? 'warning selected' : 'light'} btn-sm position-relative`}
                          aria-label={`turn off auto-record${!autoRecordOn ? ': currently selected' : ''}`}
                          id="ZoomMeeting-turn-off-auto-record-button"
                          onClick={() => {
                            // Skip if already changed
                            if (!autoRecordOn) {
                              return;
                            }

                            // Show lock
                            if (lockAutoRecordSetting) {
                              return onLockedFeatureClicked();
                            }

                            // Change feature
                            onAutoRecordChanged(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              !autoRecordOn
                                ? faDotCircle
                                : faCircle
                            }
                            className="mr-1"
                          />
                          off

                          {/* Show lock icon if this button is locked */}
                          {(lockAutoRecordSetting && autoRecordOn) && (
                            <ButtonLock />
                          )}
                        </button>
                      </span>
                    )
                    // Load button (if not loaded)
                    : (
                      <button
                        type="button"
                        className="ZoomMeeting-load-auto-settings btn btn-secondary btn-sm position-relative"
                        aria-label="load Zoom settings so you can view or edit those settings"
                        onClick={onStartLoad}
                        id="ZoomMeeting-load-zoom-settings-via-auto-record-button"
                      >
                        Click to Load
                      </button>
                    )
                )}
              />

              {/* Explanation of auto-record */}
              <div className="text-muted text-left">
                <small>
                  If &quot;Auto-record&quot; is on,
                  whenever this event occurs,
                  it will automatically be recorded.
                </small>
              </div>
            </div>
          );

          // Create waiting room toggle
          const waitingRoomToggle = (
            <div className="mt-3">
              <ExpandableDrawer
                title="Waiting Room"
                noMarginOnBottom
                contents={(
                  settingsLoaded
                    ? (
                      <span>
                        {/* Turn on Waiting Room Button */}
                        <button
                          type="button"
                          className={`ZoomMeeting-waiting-room-on-button btn btn-${waitingRoomOn ? 'warning selected' : 'light'} btn-sm mr-2 position-relative`}
                          aria-label={`turn on waiting room${waitingRoomOn ? ': currently selected' : ''}`}
                          id="ZoomMeeting-turn-waiting-room-on-button"
                          onClick={() => {
                            // Skip if already changed
                            if (waitingRoomOn) {
                              return;
                            }

                            // Change feature
                            onWaitingRoomChanged(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              waitingRoomOn
                                ? faDotCircle
                                : faCircle
                            }
                            className="mr-1"
                          />
                          on
                        </button>

                        {/* Turn off Waiting Room Button */}
                        <button
                          type="button"
                          className={`ZoomMeeting-waiting-room-off-button btn btn-${!waitingRoomOn ? 'warning selected' : 'light'} btn-sm position-relative`}
                          aria-label={`turn off waiting room${!waitingRoomOn ? ': currently selected' : ''}`}
                          id="ZoomMeeting-turn-waiting-room-off-button"
                          onClick={() => {
                            // Skip if already changed
                            if (!waitingRoomOn) {
                              return;
                            }

                            // Change feature
                            onWaitingRoomChanged(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              !waitingRoomOn
                                ? faDotCircle
                                : faCircle
                            }
                            className="mr-1"
                          />
                          off
                        </button>
                      </span>
                    )
                    // Click to load button (if not loaded)
                    : (
                      <button
                        type="button"
                        className="ZoomMeeting-load-waiting-settings btn btn-secondary btn-sm"
                        aria-label="load Zoom settings so you can view or edit those settings (duplicate of previous button)"
                        onClick={onStartLoad}
                        id="ZoomMeeting-load-zoom-settings-via-waiting-room-button"
                      >
                        Click to Load
                      </button>
                    )
                )}
              />

              {/* Explanation of waiting room */}
              <div className="text-muted text-left">
                <small>
                  If &quot;Waiting Room&quot; is on,
                  the host must individually admit each
                  participant as they arrive.
                </small>
              </div>
            </div>
          );

          // Render contents
          return (
            <div className="alert alert-light text-dark m-0 text-center">
              <div>
                <strong>
                  Zoom Meeting Settings&nbsp;
                </strong>
                | ID&nbsp;
                <span id="ZoomMeeting-attached-zoom-meeting-id">
                  {currentZoomId}
                </span>
                {currentZoomLink && (
                  <a
                    href={currentZoomLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ZoomMeeting-id-link btn progress-bar-striped font-weight-bold bg-success text-white pt-0 pb-0 btn-sm ml-2"
                    aria-label="reveal the meeting in Zoom"
                    id="ZoomMeeting-reveal-meeting-in-zoom-button"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    Reveal in Zoom Admin Panel
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="ml-1"
                    />
                  </a>
                )}
              </div>

              {autoRecordToggle}
              {waitingRoomToggle}
            </div>
          );
        }}
      />
    );
  }
}

ZoomMeeting.propTypes = {
  // Handler to call when detaching Zoom
  onDetach: PropTypes.func.isRequired,
  // Handler to call when user chooses to attach Zoom meeting
  onAttach: PropTypes.func.isRequired,
  // The event's zoom meeting
  currentZoomId: PropTypes.number,
  // If true, TTMs cannot toggle whether the event is held in Zoom
  // (cannot add or remove Zoom)
  lockZoomToggle: PropTypes.bool,
  // If true, TTMs cannot edit Zoom auto record setting
  lockAutoRecordSetting: PropTypes.bool,
  // Handler to call when user tries to edit something that's locked
  onLockedFeatureClicked: PropTypes.func,
  // True if settings are loaded
  settingsLoaded: PropTypes.bool,
  // Function to call when user requests that the settings be loaded
  onStartLoad: PropTypes.func,
  // True if auto-record setting is on
  autoRecordOn: PropTypes.bool,
  /**
   * Function to handle an update to auto record setting
   * @param {boolean} on - true if on
   */
  onAutoRecordChanged: PropTypes.func,
  // True if waiting room is on
  waitingRoomOn: PropTypes.bool,
  // True if using a webinar
  isWebinar: PropTypes.bool,
  /**
   * Function to handle an update to waiting room setting
   * @param {boolean} on - true if on
   */
  onWaitingRoomChanged: PropTypes.func,
  // Link to open Zoom meeting in the Zoom website
  currentZoomLink: PropTypes.string,
};

ZoomMeeting.defaultProps = {
  // No zoom meeting
  currentZoomId: null,
  // Not locked
  lockZoomToggle: false,
  // Not locked
  lockAutoRecordSetting: false,
  // No handler for locked features
  onLockedFeatureClicked: () => {},
  // Settings not loaded
  settingsLoaded: false,
  // No handler
  onStartLoad: () => {},
  // Off
  autoRecordOn: false,
  waitingRoomOn: false,
  // Not a webinar
  isWebinar: false,
  // No handlers
  onAutoRecordChanged: () => {},
  onWaitingRoomChanged: () => {},
  // No zoom link
  currentZoomLink: null,
};

export default ZoomMeeting;
