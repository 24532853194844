/**
 * Visualizer for attendance
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import {
  faChalkboardTeacher,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';

// Import constants
import USER_ROLES from '../../../constants/USER_ROLES';

// Import shared components
import NothingHereNotice from '../../../shared/NothingHereNotice';

// Import other components
import AttendanceTableBody from './AttendanceTableBody';
import AttendanceTableHeader from './AttendanceTableHeader';
import AttendanceTableFooter from './AttendanceTableFooter';

// Style
import './index.css';

/* -------------------------- Component ------------------------- */

class AttendanceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // dateKey of column to sort by
      sortBy: null, // If null, sort by name
    };
  }

  /**
   * Click handler for column sorting
   * @author Karen Dolan
   * @param key is a date or null to sort by name
   */
  changeSortColumn(key) {
    this.setState({
      sortBy: key,
    });
  }

  /**
   * Render AttendanceTable
   * @author Gabe Abrams
   */
  render() {
    const {
      attendanceLogMap,
    } = this.props;
    const {
      sortBy,
    } = this.state;

    /* ---------------------------- Empty --------------------------- */

    const atLeastOneItem = (
      Object.values(attendanceLogMap).length > 0
    );
    if (!atLeastOneItem) {
      return (
        <NothingHereNotice
          title="No Attendance Yet"
          subtitle="If you've applied filters, try a broader filter. Or check back later once more people have attended."
        />
      );
    }

    /* ----------------------- Pre-processing ----------------------- */

    // Order the date keys earliest to latest
    const dateKeys = Object.keys(attendanceLogMap);
    dateKeys.sort((a, b) => {
      const timestampA = (new Date(a)).getTime();
      const timestampB = (new Date(b)).getTime();

      return (timestampA - timestampB);
    });

    /* ---------------------------- Rows ---------------------------- */

    // Gather all logs into one array
    const attendanceLogs = [];
    Object.keys(attendanceLogMap).forEach((dateKey) => {
      Object.keys(attendanceLogMap[dateKey]).forEach((ihid) => {
        Object.keys(attendanceLogMap[dateKey][ihid]).forEach((userId) => {
          const log = attendanceLogMap[dateKey][ihid][userId];
          attendanceLogs.push(log);
        });
      });
    });

    // Generate row names
    const rowInfos = []; // List of { name, id, [icon] } for each row
    // Collect the list of attendees
    const userAdded = {}; // id => true if added
    attendanceLogs.forEach((attendanceLog) => {
      const {
        userName,
        userId,
        userRole,
      } = attendanceLog;

      // Skip if already added
      if (userAdded[userId]) {
        return;
      }
      userAdded[userId] = true;

      // Add to list of rows
      rowInfos.push({
        name: userName,
        id: userId,
        icon: (
          userRole === USER_ROLES.STUDENT
            ? faUserGraduate
            : faChalkboardTeacher
        ),
      });
    });

    // Sort rows by name
    rowInfos.sort((a, b) => {
      return (a.name.localeCompare(b.name));
    });

    // Keep track of values for comparator
    const valueWeightMap = {}; // rowId => dateKey => comparisonValue

    // Add values for each row
    // A value can be:
    // - null for not in attendance (relevant for student comparison)
    // - attendance method if in attendance (relevant for student comparison)
    // - number for number attended (relevant for event comparison)
    const rowValueLists = rowInfos.map((rowInfo) => {
      // Find logs for this row
      const relevantLogs = attendanceLogs.filter((log) => {
        return (log.userId === rowInfo.id);
      });

      // Create the row value list for this row
      return dateKeys.map((dateKey) => {
        // Filter logs to only logs for this date
        const relevantLogsOnDate = relevantLogs.filter((log) => {
          return (
            // Must be an attendance log (not absence)
            log.inAttendance
            // Must be for this date
            && log.eventDate === dateKey
          );
        });

        // Initialize weight map for this row
        if (!valueWeightMap[rowInfo.id]) {
          valueWeightMap[rowInfo.id] = {};
        }

        // Set value
        const value = (
          relevantLogsOnDate.length > 0
            ? relevantLogsOnDate[0].method // Attendance method if attended
            : null // not in attendance
        );

        // Log for sorting
        valueWeightMap[rowInfo.id][dateKey] = (value ? 1 : 0);

        // Return the value
        return value;
      });
    });

    /* --------------------------- Table --------------------------- */

    return (
      <div className="AttendanceTable-container align-items-center">
        <div>
          <table className="AttendanceTable-table table">
            <AttendanceTableHeader
              dateKeys={dateKeys}
              sortBy={sortBy}
              changeSortColumn={(bySort) => {
                return this.changeSortColumn(bySort);
              }}
            />
            <AttendanceTableBody
              rowInfos={rowInfos}
              dateKeys={dateKeys}
              valueLists={rowValueLists}
              valueWeightMap={valueWeightMap}
              sortBy={sortBy}
            />
            <AttendanceTableFooter
              dateKeys={dateKeys}
              valueLists={rowValueLists}
            />
          </table>
        </div>
      </div>
    );
  }
}

AttendanceTable.propTypes = {
  // Map of attendance (date => ihid => userId => record)
  attendanceLogMap: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AttendanceTable;
