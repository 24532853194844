/**
 * Capitalize the first letter of each word
 * @author Gabe Abrams
 * @param {string} name - the name of the header
 * @return {string} capitalized header name
 */
export default (name) => {
  return (
    name
      // Split into words
      .split(' ')
      // Go through each word and capitalize it
      .map((word) => {
        return `${word.charAt(0).toUpperCase()}${word.substring(1) || ''}`;
      })
      // Join words with spaces
      .join(' ')
  );
};
