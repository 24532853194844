/**
 * The main join event in Zoom page
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileVideo,
  faPlus,
  faUserCheck,
  faInfoCircle,
  faExclamationTriangle,
  faChalkboardTeacher,
  faUserAlt,
  faUserGraduate,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import LoadingSpinner from '../shared/LoadingSpinner';
import Modal from '../shared/Modal';
import TabBox from '../shared/TabBox';
import NothingHereNotice from '../shared/NothingHereNotice';
import VisitLinkModal from '../shared/VisitLinkModal';

// Import other components
import EventItem from './EventItem';
import LoungeItem from './LoungeItem';

// Import modals
import ShareableLinkModal from './Modals/ShareableLinkModal';
import ChooseJoinTypeModal from './Modals/ChooseJoinTypeModal';

// Import helpers
import genGreeting from '../helpers/genGreeting';
import visitServerEndpoint from '../helpers/visitServerEndpoint';
import setPagePath from '../helpers/setPagePath';
import eventComparator from '../helpers/eventComparator';
import logError from '../helpers/logError';
import logAction from '../helpers/logAction';
import genShareableLink from '../helpers/genShareableLink';

// Import constants
import ERROR_CODES from '../constants/ERROR_CODES';
import ATTENDANCE_METHODS from '../constants/ATTENDANCE_METHODS';

// Import style
import './style.css';
import LoungePreparationModal from './Modals/LoungePreparationModal';

/* -------------------------- Constants ------------------------- */

const JOIN_TYPES = {
  JOIN_AS_PARTICIPANT: 'join-as-participant',
  JOIN_AS_HOST: 'join-as-host',
  JOIN_AS_PANELIST: 'join-as-panelist',
  JOIN_LOUNGE: 'join-lounge',
};

const LOUNGE_REFRESH_MS = 30 * 1000; // 30s

/* -------------------------- Component ------------------------- */

class Home extends Component {
  constructor(props) {
    super(props);

    // Set up state
    this.state = {
      // True if loading
      loading: true,
      // Additional loading message to display
      loadingMessage: '',
      // Error message to display
      fatalErrorMessage: null,
      // The event that will be joined after the user chooses if they want
      //   to join as a host or participant
      eventToJoinAfterChoosingJoinType: null,
      // The lounge to join after preparation
      loungeToJoinAfterPreparation: null,
      // List of events to choose from
      events: [],
      // Welcome greeting
      greeting: 'Welcome!',
      // Shorter greeting
      shortGreeting: 'Welcome!',
      // Popup error message
      modalErrorMessage: null,
      // Sharable link modal info
      shareableLinkTitle: null,
      shareableLink: null,
      // True if showing the published recordings button
      showPublishedRecordings: false,
      // Link that the current user is joining
      linkToJoin: null,
      // True if the link to join is currently loading
      linkToJoinLoading: false,
      // Type of the link to join
      linkToJoinType: null,
      // True if the link being joined is a webinar
      linkToJoinIsWebinar: false,
      // IHID of the event to share
      ihidToShare: null,
      // List of lounges
      lounges: [],
    };
  }

  /**
   * Called when the component mounted, pulls state and user profile from server
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    // Start a lounge refresh interval
    this.loungeRefreshIntervalId = setInterval(
      () => {
        this.refreshLounges();
      },
      LOUNGE_REFRESH_MS
    );

    // Update the loadingMessage after certain amounts of time
    setTimeout(() => {
      this.setState({
        loadingMessage: 'We\'re waiting on Zoom...',
      });
    }, 5 * 1000);
    setTimeout(() => {
      this.setState({
        loadingMessage: 'Thanks for your patience! This might take some time.',
      });
    }, 15 * 1000);
    setTimeout(() => {
      this.setState({
        loadingMessage: 'Sit tight! If lots of people joined at the same time, this could take a few minutes.',
      });
    }, 25 * 1000);

    // Props
    const { userFirstName } = this.props;

    // Load list of events and lounges
    let events;
    let lounges;
    try {
      ([events, lounges] = await Promise.all([
        visitServerEndpoint({
          path: `/api/courses/${courseId}/events`,
          method: 'GET',
        }),
        visitServerEndpoint({
          path: `/api/courses/${courseId}/lounges`,
          method: 'GET',
        }),
      ]));
    } catch (err) {
      return this.setState({
        loading: false,
        fatalErrorMessage: err.message || 'We could not contact the server. Please check your network connection and try again.',
      });
    }

    // Save to state
    this.setState({
      events,
      lounges,
      loading: false,
      greeting: genGreeting(userFirstName),
      shortGreeting: (
        <span>
          Welcome,&nbsp;
          <strong>
            {userFirstName}
          </strong>
        </span>
      ),
    });

    // Check for published recordings asynchronously
    try {
      const showPublishedRecordings = await visitServerEndpoint({
        path: `/api/courses/${courseId}/recordings/published/at_least_one`,
        method: 'GET',
      });

      return this.setState({ showPublishedRecordings });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message || 'We could not check if the current course has published recordings.',
      });
    }
  }

  /**
   * Refresh the lounge list
   * @author Gabe Abrams
   */
  async refreshLounges() {
    const { courseId } = this.props;
    const { lounges } = this.state;

    try {
      const visitorMap = await visitServerEndpoint({
        path: `/api/courses/${courseId}/lounges/visitors`,
        method: 'GET',
        params: {
          loungeIds: JSON.stringify(
            lounges.map((lounge) => {
              return lounge.loungeId;
            })
          ),
        },
      });

      // Update lounges
      lounges.forEach((lounge) => {
        const updatedLounge = lounge;
        updatedLounge.visitorFirstNames = (visitorMap[lounge.loungeId] || []);
      });

      // Save to state
      this.setState({ lounges });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Join an event. If a startURL is included, we assume the user is starting
   *   an event. Otherwise, we assume they're joining as a participant.
   * @author Gabe Abrams
   * @param {object} event - the event to open
   * @param {string} [startURL] - a startURL if the user is joining as a host.
   * @param {boolean} [joiningAsPanelist] - true if the user is joining as a
   *   webinar panelist
   */
  async showJoinModal(opts) {
    const {
      event,
      startURL,
      joiningAsPanelist,
    } = opts;

    // Figure out the link
    const linkToJoin = (startURL || event.openZoomLink);

    // Identify join type
    let linkToJoinType;
    if (joiningAsPanelist) {
      linkToJoinType = JOIN_TYPES.JOIN_AS_PANELIST;
    } else {
      linkToJoinType = (
        startURL
          ? JOIN_TYPES.JOIN_AS_HOST
          : JOIN_TYPES.JOIN_AS_PARTICIPANT
      );
    }

    // Show the join modal
    this.setState({
      linkToJoin,
      linkToJoinType,
      linkToJoinLoading: true,
      linkToJoinIsWebinar: event.isWebinar,
      eventToJoinAfterChoosingJoinType: null, // Put away the chooser
    });

    // Take attendance
    const { userId, courseId } = this.props;

    // Asynchronously:
    try {
      await visitServerEndpoint({
        path: `/api/courses/${courseId}/events/${event.ihid}/attendance`,
        method: 'POST',
        params: {
          method: ATTENDANCE_METHODS.LIVE,
          isHost: !!startURL,
        },
      });

      // Log this
      logAction({
        type: 'join',
        description: 'event',
        metadata: {
          ihid: event.ihid,
          type: event.type,
          name: event.name,
          isHost: !!startURL,
        },
      });

      // Success! Let the user click to join by turning off loading indicator
      this.setState({
        linkToJoinLoading: false,
      });
    } catch (err) {
      const fatalErrorMessage = `We could not save your progress. Please start over and try again. If the issue persists, contact an admin with this code: "${userId}-${courseId}-${event.ihid}-${Date.now()}"`;

      // Log to server
      logError({
        message: fatalErrorMessage,
        code: ERROR_CODES.ATTENDANCE_NOT_SAVED,
        metadata: {
          userId,
          courseId,
          ihid: event.ihid,
          type: 'attendance',
          attendanceType: 'live',
          timestamp: Date.now(),
          errorMessage: err.message,
          errorStack: err.stack,
        },
      });

      // eslint-disable-next-line no-console
      console.log('Error occurred while saving attendance:', err);

      // Show error to user
      return this.setState({
        fatalErrorMessage,
        linkToJoin: false,
        linkToJoinLoading: false,
        linkToJoinIsWebinar: false,
      });
    }
  }

  /**
   * Show the lounge preparation modal (gets a link, asks user to claim account)
   * @author Gabe Abrams
   * @param {object} lounge the lounge to open
   */
  async showLoungePreparationModal(lounge) {
    this.setState({
      loungeToJoinAfterPreparation: lounge,
      linkToJoin: null,
    });
  }

  /**
   * Join a lounge
   * @author Gabe Abrams
   * @param {string} linkToJoin the link to join
   */
  async showJoinLoungeModal(linkToJoin) {
    const {
      courseId,
    } = this.props;
    const {
      loungeToJoinAfterPreparation,
    } = this.state;

    // Show the join modal
    this.setState({
      linkToJoin,
      linkToJoinType: JOIN_TYPES.JOIN_LOUNGE,
      loungeToJoinAfterPreparation: null,
    });

    // Log this
    logAction({
      type: 'click',
      description: 'join lounge',
      metadata: {
        loungeId: loungeToJoinAfterPreparation.loungeId,
        name: loungeToJoinAfterPreparation.name,
      },
    });

    // Add activity
    visitServerEndpoint({
      path: `/api/courses/${courseId}/lounges/${loungeToJoinAfterPreparation.loungeId}/visitors`,
      method: 'POST',
      params: {
        joinedAsHost: (
          linkToJoin.includes('?zak=')
            ? true
            : undefined
        ),
      },
    });
  }

  /**
   * Render the App
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct the props
    const {
      courseId,
      courseName,
      userFirstName,
      userLastName,
      userEmail,
      isLearner,
      isAdmin,
      onViewEventRecordings,
      onEditEvent,
      onViewPublishedRecordings,
      onCreateEvent,
      onCreateLounge,
      onViewAttendancePane,
      onOpenHelp,
      onEditLounge,
    } = this.props;

    // Deconstruct the state
    const {
      loading,
      loadingMessage,
      fatalErrorMessage,
      eventToJoinAfterChoosingJoinType,
      loungeToJoinAfterPreparation,
      events,
      greeting,
      shortGreeting,
      modalErrorMessage,
      shareableLinkTitle,
      shareableLink,
      showPublishedRecordings,
      linkToJoin,
      linkToJoinLoading,
      linkToJoinType,
      linkToJoinIsWebinar,
      ihidToShare,
      lounges,
    } = this.state;

    // Set the page path and title
    setPagePath('Course Events', `/courses/${courseId}`);

    /* ------------------------- Build modal ------------------------ */

    let modal;

    if (modalErrorMessage) {
      modal = (
        <Modal
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />
              An error occurred!
            </span>
          )}
          body={modalErrorMessage}
          type={Modal.TYPES.OKAY}
          onClose={() => {
            this.setState({
              modalErrorMessage: null,
            });
          }}
        />
      );
    } else if (linkToJoin) {
      // Create the text for the modal body
      let modalTitle;
      let modalBody;
      if (linkToJoinType === JOIN_TYPES.JOIN_AS_HOST) {
        modalTitle = 'Join as Host';
        modalBody = 'If you\'re not the first to join as host, you will be a co-host';
      } else if (linkToJoinType === JOIN_TYPES.JOIN_AS_PANELIST) {
        modalTitle = 'Join as Panelist';
        modalBody = 'You may need to wait for DCE staff to start the webinar';
      } else if (linkToJoinType === JOIN_TYPES.JOIN_LOUNGE) {
        modalTitle = 'Join Study Lounge';
        modalBody = 'Click below to join the Gather Study Lounge in Zoom';
      } else {
        modalTitle = 'Join as Participant';
        modalBody = (
          isLearner
            ? 'Click below to join the event in Zoom'
            : (
              <span>
                <strong>
                  Important:&nbsp;
                </strong>
                before continuing,
                make sure you&apos;re logged out of the Zoom app.
                If logged in, you might join as host
              </span>
            )
        );
      }

      // Create the modal itself
      modal = (
        <VisitLinkModal
          title={modalTitle}
          label="zoom meeting"
          body={modalBody}
          buttonText={(
            linkToJoinType === JOIN_TYPES.JOIN_LOUNGE
              ? 'I Agree, Join'
              : 'Join Event'
          )}
          loading={linkToJoinLoading}
          link={linkToJoin}
          icon={
            (
              linkToJoinType === JOIN_TYPES.JOIN_AS_HOST
              || linkToJoinType === JOIN_TYPES.JOIN_AS_PANELIST
            )
              ? faChalkboardTeacher
              : faUserAlt
          }
          onClose={() => {
            this.setState({
              linkToJoin: null,
            });
          }}
          showAgreements
          useWebinarAgreements={linkToJoinIsWebinar}
          isJoiningLounge={linkToJoinType === JOIN_TYPES.JOIN_LOUNGE}
        />
      );
    } else if (shareableLink) {
      modal = (
        <ShareableLinkModal
          courseId={courseId}
          ihid={ihidToShare}
          title={shareableLinkTitle}
          shareableLink={shareableLink}
          onClose={() => {
            this.setState({
              shareableLink: null,
              shareableLinkTitle: null,
              ihidToShare: null,
            });
          }}
          isAdmin={isAdmin}
          isLearner={isLearner}
        />
      );
    } else if (eventToJoinAfterChoosingJoinType) {
      modal = (
        <ChooseJoinTypeModal
          courseId={courseId}
          isLearner={isLearner}
          event={eventToJoinAfterChoosingJoinType}
          userEmail={userEmail}
          onJoinAsParticipant={() => {
            this.showJoinModal({
              event: eventToJoinAfterChoosingJoinType,
            });
          }}
          onJoinAsHost={(startURL) => {
            this.showJoinModal({
              startURL,
              event: eventToJoinAfterChoosingJoinType,
            });
          }}
          onJoinAsPanelist={(startURL) => {
            this.showJoinModal({
              startURL,
              event: eventToJoinAfterChoosingJoinType,
              joiningAsPanelist: true,
            });
          }}
          onCancel={() => {
            this.setState({
              eventToJoinAfterChoosingJoinType: null,
            });
          }}
        />
      );
    } else if (loungeToJoinAfterPreparation) {
      modal = (
        <LoungePreparationModal
          courseId={courseId}
          lounge={loungeToJoinAfterPreparation}
          onContinue={(newLinkToJoin) => {
            this.showJoinLoungeModal(newLinkToJoin);
          }}
          onCancel={() => {
            this.setState({
              loungeToJoinAfterPreparation: null,
            });
          }}
        />
      );
    }

    let body;
    /* ------------------------- Build Body ------------------------- */
    if (fatalErrorMessage) {
      // Error message
      body = (
        <div className="alert alert-warning d-inline-block">
          <h3>An error occurred:</h3>
          {fatalErrorMessage}
        </div>
      );
      // Remove the modal
      modal = null;
    } else if (loading) {
      // Loading spinner
      body = (
        <div>
          <LoadingSpinner />
          <div className="text-muted">
            {loadingMessage}
          </div>
        </div>
      );
    } else {
      // Event chooser
      let eventButtons;

      // Check if there are any events
      if (events.length > 0) {
        // Sort events
        events.sort(eventComparator);

        // Create event buttons
        eventButtons = (
          events
            // Filter out archived events
            .filter((event) => {
              return !event.archived;
            })
            .map((event, i) => {
              return (
                <EventItem
                  key={event.ihid}
                  position={i}
                  isLearner={isLearner}
                  event={event}
                  onGetLink={() => {
                    this.setState({
                      shareableLinkTitle: event.name,
                      shareableLink: genShareableLink(courseId, event.ihid),
                      ihidToShare: event.ihid,
                    });

                    // Log this
                    logAction({
                      type: 'open',
                      description: 'event link',
                      metadata: {
                        ihid: event.ihid,
                        type: event.type,
                        name: event.name,
                      },
                    });
                  }}
                  onViewRecordings={() => {
                    onViewEventRecordings(event);
                  }}
                  onJoin={() => {
                    if (isLearner) {
                      this.showJoinModal({
                        event,
                        joiningAsPanelist: false,
                      });
                    } else {
                      // Allow user to choose a join type
                      this.setState({
                        eventToJoinAfterChoosingJoinType: event,
                      });
                    }
                  }}
                  onEdit={() => {
                    // Get other events by filtering out the event to edit
                    const otherEvents = events.filter((thisEvent) => {
                      return thisEvent.ihid !== event.ihid;
                    });

                    // Call handler
                    onEditEvent(event, otherEvents);
                  }}
                />
              );
            })
        );
      } else {
        // No events notice
        eventButtons = (
          <NothingHereNotice
            title="No events yet"
            subtitle={(
              <span className="d-none d-sm-inline">
                This course doesn&apos;t have any current events.&nbsp;
                {
                  isLearner
                    ? 'Please check back later.'
                    : 'Create one by clicking below.'
                }
              </span>
            )}
          />
        );
      }

      /* --------------------- Create Event Button -------------------- */

      const createEventButton = (
        !isLearner
          ? (
            <button
              type="button"
              id="Home-create-event-button"
              className="btn btn-lg btn-light btn-block p-3 mb-2"
              style={{ border: '2px dashed black' }}
              aria-label="create a new event"
              onClick={() => {
                // Other events is just the full list of events
                onCreateEvent(events);
              }}
            >
              <h3 className="m-0">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-2"
                />
                {/* Small Screen View */}
                <span className="d-inline d-sm-none">
                  Create
                </span>
                {/* Large Screen View */}
                <span className="d-none d-sm-inline">
                  Create Event
                </span>
              </h3>
            </button>
          )
          : null
      );

      /* ----------------------- Lounge Buttons ----------------------- */

      const loungeButtons = lounges.map((lounge, i) => {
        return (
          <LoungeItem
            index={i}
            key={lounge.loungeId}
            lounge={lounge}
            isLearner={isLearner}
            onEdit={() => {
              onEditLounge(lounge);
            }}
            onJoin={() => {
              this.showLoungePreparationModal(lounge);
            }}
            modalVisible={!!modal}
          />
        );
      });

      /* -------------------- Create Lounge Button -------------------- */

      let createLoungeButton;
      if (isAdmin && lounges.length < 1) {
        createLoungeButton = (
          <button
            type="button"
            id="Home-create-lounge-button"
            className="btn btn-lg alert-success progress-bar-striped btn-block p-3 mb-2"
            style={{ border: '2px dashed black' }}
            aria-label="create a new lounge"
            onClick={() => {
              onCreateLounge();
            }}
          >
            <h3 className="m-0">
              <FontAwesomeIcon
                icon={faPlus}
                className="mr-2"
              />
              {/* Small Screen View */}
              <span className="d-inline d-sm-none">
                Create
              </span>
              {/* Large Screen View */}
              <span className="d-none d-sm-inline">
                Create Study Lounge
              </span>
            </h3>
          </button>
        );
      }

      /* ----------- Buttons that Students and TTMs Can See ----------- */

      const morePageButtons = [];

      // Published recordings
      if (showPublishedRecordings) {
        morePageButtons.push(
          <button
            id="Home-view-published-recordings-button"
            key="all-published-recordings"
            type="button"
            aria-label="view the list of published recordings for this course"
            className="Home-shrinking-button btn btn-secondary btn-lg mr-2 mb-2"
            onClick={() => {
              onViewPublishedRecordings();
            }}
          >
            <FontAwesomeIcon
              icon={faFileVideo}
              className="mr-2"
            />
            Published Recordings
          </button>
        );
      }

      // Attendance
      if (!isLearner) {
        morePageButtons.push(
          <button
            id="Home-view-attendance"
            key="view-attendance"
            type="button"
            className="Home-shrinking-button btn btn-secondary btn-lg mr-2 mb-2"
            aria-label="view attendance analytics and data for this course"
            onClick={() => {
              onViewAttendancePane();
            }}
          >
            <FontAwesomeIcon
              icon={faUserCheck}
              className="mr-2"
            />
            Attendance
            <span className="d-none d-md-inline">
              &nbsp;Analytics
            </span>
          </button>
        );
      }

      // Help and Tutorials
      if (!isLearner) {
        morePageButtons.push(
          <button
            id="Home-help-and-tutorials"
            key="help-and-tutorials"
            type="button"
            className="Home-shrinking-button btn btn-secondary btn-lg mr-2 mb-2"
            aria-label="open help and tutorials"
            onClick={() => {
              onOpenHelp();
            }}
          >
            {/* Icon */}
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="mr-2"
            />
            Help

            {/* Large Screen View */}
            <span className="d-none d-md-inline">
              &nbsp;&amp; Tutorials
            </span>
          </button>
        );
      }

      // Put buttons into a TabBox
      const morePageContainer = (
        morePageButtons.length > 0
          ? (
            <TabBox
              title="More Pages"
              noPaddingOnBottom
            >
              {morePageButtons}
            </TabBox>
          )
          : null
      );

      // Create body
      body = (
        <div>
          {/* Header */}
          <h2 id="Home-page-title" className="m-0">
            {/* Short Greeting for Small Screens */}
            <span className="d-inline d-md-none">
              {shortGreeting}
            </span>
            {/* Longer Greeting for Large Screens */}
            <span className="d-none d-md-inline">
              {greeting}
            </span>
          </h2>

          {/* Current Course */}
          <p
            id={`Home-current-course-with-canvas-id-${courseId}`}
            className="lead"
          >
            Current course:&nbsp;
            {courseName}
          </p>

          {/* More Pages (if there are any) */}
          {morePageContainer && (
            <div className="mt-4">
              {morePageContainer}
            </div>
          )}

          {/* Lounges */}
          {(loungeButtons.length > 0 || isAdmin) && (
            <div className="mt-4">
              <TabBox
                title="Study Lounges (open 24/7)"
                noPaddingOnBottom
              >
                {/* Lounge List */}
                {loungeButtons}

                {/* Create Lounge Button (if it is there) */}
                {createLoungeButton}
              </TabBox>
            </div>
          )}

          {/* Events */}
          <div className="mt-4">
            <TabBox
              title="Course Events"
              noPaddingOnBottom
            >
              {/* Event List */}
              {eventButtons}

              {/* Create Event Button (if it is there) */}
              {createEventButton}
            </TabBox>
          </div>

          {/* User Info */}
          <div className="text-muted small mt-3">
            <span className="user-select-none">
              You&apos;re logged in as&nbsp;
            </span>
            <span className="user-select-all">
              {userFirstName}
              {' '}
              {userLastName}
              {' '}
            </span>
            <span className="user-select-none">
              (
            </span>
            <span className="user-select-all">
              {userEmail}
            </span>
            <span className="user-select-none">
              )
            </span>
          </div>

          {/* Role Indicator */}
          <div className="small text-muted">
            {/* Admin */}
            {isAdmin && (
              <div id="Home-role-is-admin-message">
                <FontAwesomeIcon
                  icon={faUserCog}
                  className="mr-1"
                />
                You&apos;re a Canvas admin. Admin-only features have a&nbsp;
                <span className="badge badge-success progress-bar-striped">
                  striped green background
                </span>
              </div>
            )}
            {/* TTM */}
            {(!isAdmin && !isLearner) && (
              <div id="Home-role-is-ttm-message">
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="mr-1"
                />
                You&apos;re a teaching team member for this course.
              </div>
            )}
            {/* Student */}
            {isLearner && (
              <div id="Home-role-is-student-message">
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="mr-1"
                />
                You&apos;re a student in this course.
              </div>
            )}
          </div>
        </div>
      );
    }

    /* --------------------------- Full UI -------------------------- */
    return (
      <div>
        {modal}
        {body}
      </div>
    );
  }
}

Home.propTypes = {
  // Course id
  courseId: PropTypes.number.isRequired,
  // Name of the course
  courseName: PropTypes.string.isRequired,
  // The user's first name
  userFirstName: PropTypes.string.isRequired,
  // The user's last name
  userLastName: PropTypes.string.isRequired,
  // The user's zoom email
  userEmail: PropTypes.string.isRequired,
  // The user's Canvas Id
  userId: PropTypes.number.isRequired,
  // True if the user is a learner
  isLearner: PropTypes.bool.isRequired,
  // True if the user is an admin
  isAdmin: PropTypes.bool.isRequired,
  /**
   * Handler for when non-student wants to view recordings for an event
   * @param {Event} event - the event to view recordings for
   */
  onViewEventRecordings: PropTypes.func.isRequired,
  /**
   * Handler for when non-student wants to edit an event
   * @param {Event} event - the event to edit
   * @param {Event[]} otherEvents - the list of other events
   */
  onEditEvent: PropTypes.func.isRequired,
  // Handler for when anyone wants to view published recordings
  onViewPublishedRecordings: PropTypes.func.isRequired,
  /**
   * Handler for when TTM or Admin wants to create a new event
   * @param {Event[]} otherEvents - the list of other events
   */
  onCreateEvent: PropTypes.func.isRequired,
  // Handler for when TTM or Admin wants to create a new lounge
  onCreateLounge: PropTypes.func.isRequired,
  /**
   * Handler for when TTM or Admin wants to edit a lounge
   * @param {Lounge} lounge - the lounge to edit
   */
  onEditLounge: PropTypes.func.isRequired,
  // Handler to call when user wants to view the attendance page
  onViewAttendancePane: PropTypes.func.isRequired,
  // Handler to call when user wants to open the help docs
  onOpenHelp: PropTypes.func.isRequired,
};

export default Home;
