let currentHandler;

const exports = {
  /**
   * Save a handler function
   * @author Gabe Abrams
   * @param {func} handler - a function to call when the session has expired
   */
  addHandler: (handler) => {
    currentHandler = handler;
  },

  /**
   * Alert system that the session has expired
   * @author Gabe Abrams
   */
  markAsExpired: () => {
    if (currentHandler) {
      currentHandler();
    }
  },
};

/**
 * Handler for session expiry
 * @author Gabe Abrams
 */
export default exports;
