/**
 * Form element for an event type
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDotCircle,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

// Import shared components
import ExpandableDrawer from '../ExpandableDrawer';

// Import helpers
import eventTypeToTitle from '../../helpers/eventTypeToTitle';

// Import constants
import EVENT_TYPES from '../../constants/EVENT_TYPES';
import EVENT_TYPES_MAP from '../../constants/EVENT_TYPES_MAP';

/* -------------------------- Component ------------------------- */

class EventType extends Component {
  /**
   * Render EventType
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      type,
      onChange,
      onlyClassAndSection,
    } = this.props;

    // List of buttons (click to select the type)
    const typeButtons = [];

    // Find the list of relevant event types
    const relevantEventTypes = EVENT_TYPES.filter((currType, i) => {
      return (
        currType === EVENT_TYPES_MAP.SECTION // No matter, class is included
        || currType === EVENT_TYPES_MAP.CLASS // No matter, class is included
        || !onlyClassAndSection // Only include other types if flag is false
        || i === 0 // Keep the initial spacer for 1-indexing
      );
    });

    // Create each type button, skipping the first "unselected" button
    for (let i = 1; i < relevantEventTypes.length; i++) {
      const chosen = (type === relevantEventTypes[i]);
      typeButtons.push(
        <button
          key={relevantEventTypes[i]}
          type="button"
          id={`EventType-choose-type-${relevantEventTypes[i]}`}
          className={`btn btn-sm btn-${chosen ? 'warning selected' : 'light'} mr-2`}
          aria-label={`set this event's type to ${eventTypeToTitle(relevantEventTypes[i])}${chosen ? ': currently selected' : ''}`}
          onClick={() => {
            onChange(relevantEventTypes[i]);
          }}
        >
          <FontAwesomeIcon
            icon={
              chosen
                ? faDotCircle
                : faCircle
            }
            className="mr-1"
          />

          {/* Turn the type into a human-readable title */}
          {eventTypeToTitle(relevantEventTypes[i])}
        </button>
      );

      // Add a spacer every 3 items for very small screens
      if (i % 3 === 0 && i !== relevantEventTypes.length - 1) {
        typeButtons.push(
          <span
            className="d-block d-sm-none mb-2"
            key={`spacer-${i}`}
          />
        );
      }
    }

    // Create the container
    return (
      <ExpandableDrawer
        icon={faList}
        title="Type"
        contents={(
          <div style={{ whiteSpace: 'normal' }}>
            {typeButtons}
          </div>
        )}
      />
    );
  }
}

EventType.propTypes = {
  /**
   * Handler for when the type changes
   * @param {string} newType - the new type that's selected
   */
  onChange: PropTypes.func.isRequired,
  // Type that's selected
  type: PropTypes.oneOf(EVENT_TYPES),
  // If true, only include class and section
  onlyClassAndSection: PropTypes.bool,
};

EventType.defaultProps = {
  // No type is selected
  type: null,
  // All types included
  onlyClassAndSection: false,
};

export default EventType;
