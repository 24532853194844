/**
 * Popup for choosing the publish name of a recording
 * @author Karen Dolan
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
  faFileVideo,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import ZoomRecording from '../shared/propTypes/ZoomRecording';

// Shared components
import Modal from '../shared/Modal';
import InputLabel from '../shared/InputLabel';

// Import helpers
import logAction from '../helpers/logAction';

// Local constants
const NAME_MIN_CHAR = 3;
const NAME_MAX_CHAR = 50;

class ChoosePublishNameModal extends Component {
  constructor(props) {
    super(props);

    const { recording } = this.props;

    this.state = {
      // The currrent name of the recording
      recordingName: recording.name,
    };
  }

  /**
   * Render ChoosePublishNameModal
   * @author Karen Dolan
   */
  render() {
    const {
      recording,
      onPublish,
      onCancel,
    } = this.props;

    const {
      recordingName,
    } = this.state;

    let validationFailureMessage;
    if (recordingName.trim().length < NAME_MIN_CHAR) {
      validationFailureMessage = `The recording name must be at least ${NAME_MIN_CHAR} characters long.`;
    } else if (recordingName.trim().length > NAME_MAX_CHAR) {
      validationFailureMessage = `The recording name must be less than ${NAME_MAX_CHAR} characters long.`;
    }

    const body = (
      <div>
        <div>
          <div className="input-group mb-3" key="name">
            {/* Label */}
            <div className="input-group-prepend">
              <InputLabel
                icon={faFont}
                title="Name"
              />
            </div>

            {/* Text input field for recording name */}
            <input
              type="text"
              className="form-control"
              aria-label="recording name"
              id="ChoosePublishNameModal-name"
              value={recordingName}
              onChange={(e) => {
                this.setState({
                  recordingName: e.target.value,
                });
              }}
            />
          </div>
        </div>
        {/* Already Used Message */}
        {validationFailureMessage && (
          <div className="text-center">
            <div
              id="ChoosePublishNameModal-meeting-id-validation-message"
              className="alert alert-warning d-inline-block m-0"
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="mr-2"
              />
              {validationFailureMessage}
            </div>
          </div>
        )}

        {/* Confirm Button */}
        {!validationFailureMessage && (
          <div>
            <button
              id="ChoosePublishNameModal-publish-button"
              type="button"
              className="btn btn-secondary btn-lg"
              aria-label="publish recording"
              onClick={() => {
                const trimmedName = recordingName.trim();
                // Log if name has changed
                if (recording.name !== trimmedName) {
                  // Log this
                  logAction({
                    type: 'recording name customized',
                    description: 'recording name customized before publish',
                    metadata: {
                      publishName: trimmedName,
                      recording,
                    },
                  });
                  recording.name = trimmedName;
                }
                // Publish the recording
                onPublish();
              }}
            >
              <FontAwesomeIcon
                icon={faFileVideo}
                className="mr-2"
              />
              Publish
              {/* Add Title for Larger Screens */}
              <span className="d-none d-lg-inline">
                {' '}
                as
                &quot;
                {recordingName.trim()}
                &quot;
              </span>
            </button>
          </div>
        )}
      </div>

    );

    // Create the modal
    return (
      <Modal
        type={Modal.TYPES.NO_BUTTONS}
        okayLabel="Publish"
        title="Name the Recording"
        onClose={onCancel}
        body={(
          <div className="text-center">
            {body}
          </div>
        )}
      />
    );
  }
}

ChoosePublishNameModal.propTypes = {
  // Recording
  recording: ZoomRecording.isRequired,
  // Handler for publish
  onPublish: PropTypes.func.isRequired,
  // Handler for cancel
  onCancel: PropTypes.func.isRequired,
};

export default ChoosePublishNameModal;
