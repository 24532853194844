/**
 * Form element for an event name
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { faFont } from '@fortawesome/free-solid-svg-icons';

// Shared components
import InputLabel from '../InputLabel';

/* -------------------------- Component ------------------------- */

class EventName extends Component {
  /**
   * Render EventName
   * @author Gabe Abrams
   */
  render() {
    // Deconstruct props
    const {
      name,
      onChange,
    } = this.props;

    // Full UI
    return (
      <div className="input-group mb-3" key="name">
        {/* Label */}
        <div className="input-group-prepend">
          <InputLabel
            icon={faFont}
            title={(
              <span>
                {/* Small Screen View */}
                <span className="d-inline d-sm-none">
                  Name
                </span>
                {/* Large Screen View */}
                <span className="d-none d-sm-inline">
                  Event Name
                </span>
              </span>
            )}
          />
        </div>

        {/* Text input field for title */}
        <input
          type="text"
          className="form-control"
          aria-label="event name"
          id="CreateEditEvent-name"
          placeholder={'e.g. "Class" or "Office Hours"'}
          value={name}
          onChange={(e) => {
            // Update stat in parent
            return onChange(e.target.value);
          }}
        />
      </div>
    );
  }
}

EventName.propTypes = {
  // Current value of the name
  name: PropTypes.string.isRequired,
  /**
   * Handler for when the name changes
   * @param {string} name - the new name
   */
  onChange: PropTypes.func.isRequired,
};

export default EventName;
