/**
 * Email addresses for support teams
 * @author Gabe Abrams
 */
const SUPPORT_EMAILS = {
  FOR_TTMS: 'webconference@dce.harvard.edu',
  FOR_STUDENTS: 'academictechnology@dce.harvard.edu',
};

export default SUPPORT_EMAILS;
