/**
 * Turn an event type into a human-readable title
 * @author Gabe Abrams
 * @param {string} type - the event type
 * @return {string} title
 */
module.exports = (type) => {
  return (
    type
      // Split into words
      .split('-')
      // Capitalize words
      .map((word) => {
        return `${word.charAt(0).toUpperCase()}${word.substring(1)}`;
      })
      // Put back together
      .join(' ')
  );
};
