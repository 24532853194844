/**
 * Manage the course's breakthrough links
 * @author Gabe Abrams
 */

// Import React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Modal from '../../shared/Modal';
import ErrorAlert from '../../shared/ErrorAlert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import NothingHereNotice from '../../shared/NothingHereNotice';
import CopyButton from '../../shared/CopyButton';

// Import other components
import BreakthroughLinkRow from './BreakthroughLinkRow';

// Import helpers
import visitServerEndpoint from '../../helpers/visitServerEndpoint';
import eventTypeToIcon from '../../helpers/eventTypeToIcon';
import scroll from '../../helpers/scroll';

class BreakthroughLinkManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // True if loading
      loading: true,
      // Fatal error
      fatalErrorMessage: null,
      // List of links that exist in the course
      links: null,
      // Map of events: ihid => { name, icon }
      eventMap: null,
      // The link to that is being confirmed for deletion
      linkToDelete: null,
      // Filter string (pasted in link)
      linkToMatch: '',
      // Full breakthrough link to view and copy
      fullLinkToView: null,
    };
  }

  /**
   * Load breakthrough links and events for the course
   * @author Gabe Abrams
   */
  async componentDidMount() {
    const { courseId } = this.props;

    try {
      const [
        links,
        events,
      ] = await Promise.all([
        (
          visitServerEndpoint({
            path: `/api/admin/courses/${courseId}/breakthroughs`,
            method: 'GET',
          })
        ),
        visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/events`,
          method: 'GET',
        }),
      ]);

      // Turn the event list into a lookup map
      const eventMap = {}; // ihid => { name, icon, archived }
      events.forEach((event) => {
        const {
          ihid,
          name,
          type,
          archived,
        } = event;

        eventMap[ihid] = {
          name,
          archived,
          icon: eventTypeToIcon(type),
        };
      });

      // Update the state
      this.setState({
        links,
        eventMap,
        loading: false,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }

    // Scroll to top on load
    scroll.toTop();
  }

  /**
   * Delete the current selected breakthrough link
   * @author Gabe Abrams
   */
  async deleteBreakthroughLink() {
    const {
      courseId,
    } = this.props;
    const {
      links,
      linkToDelete,
    } = this.state;

    // Start loader
    this.setState({
      loading: true,
    });

    // Try to delete
    try {
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events/${linkToDelete.ihid}/breakthroughs/${linkToDelete.linkId}`,
        method: 'DELETE',
      });

      // Filter out the link we just deleted
      const linksWithoutDeleted = links.filter((link) => {
        return (link.key !== linkToDelete.key);
      });

      // Successfully deleted!
      this.setState({
        links: linksWithoutDeleted,
        loading: false,
        linkToDelete: null,
      });
    } catch (err) {
      return this.setState({
        fatalErrorMessage: err.message,
      });
    }
  }

  /**
   * Render BreakthroughLinkManager
   * @author Gabe Abrams
   */
  render() {
    const {
      courseName,
    } = this.props;
    const {
      loading,
      fatalErrorMessage,
      links,
      eventMap,
      linkToDelete,
      linkToMatch,
      fullLinkToView,
    } = this.state;

    /* ----------------------- Error Screen ----------------------- */

    if (fatalErrorMessage) {
      return (
        <ErrorAlert
          message={fatalErrorMessage}
        />
      );
    }

    /* ----------------------- Loading Screen ----------------------- */

    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    /* ---------------------------- Modal --------------------------- */

    let modal;

    if (linkToDelete) {
      modal = (
        <Modal
          title="Are you sure?"
          body="This link will immediately and permanently stop working."
          type={Modal.TYPES.OKAY_CANCEL}
          okayLabel="Yes, delete"
          okayColor="danger"
          onClose={(button) => {
            if (button === Modal.BUTTONS.OKAY) {
              // Confirmed!
              this.deleteBreakthroughLink();
            } else {
              // Cancel
              this.setState({
                linkToDelete: null,
              });
            }
          }}
        />
      );
    } else if (fullLinkToView) {
      modal = (
        <Modal
          title="Breakthrough Link"
          type={Modal.TYPES.NO_BUTTONS}
          onClose={() => {
            this.setState({
              fullLinkToView: null,
            });
          }}
          body={(
            <div className="input-group">
              {/* Label */}
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Link
                </span>
              </div>

              {/* Input Field (just for being copied) */}
              <input
                id="BreakthroughLinkManager-link-box"
                type="text"
                className="form-control bg-light"
                value={fullLinkToView}
                aria-label="breakthrough link"
                readOnly
              />

              {/* Copy Button */}
              <div className="input-group-append">
                <CopyButton text={fullLinkToView} />
              </div>
            </div>
          )}
        />
      );
    }

    /* ---------------------------- Items --------------------------- */

    let linkTable;

    if (!links || links.length === 0) {
      // No links
      linkTable = (
        <NothingHereNotice
          title="No Breakthrough Links"
          subtitle="This course does not have any breakthrough links."
        />
      );
    } else {
      // Apply filter
      let filteredLinks = links;
      let invalidFilter = false;
      if (linkToMatch.trim().length > 0) {
        // Find information in the link
        let courseId;
        let ihid;
        let linkId;
        const parts = linkToMatch.trim().split('/');
        for (let i = 0; i < parts.length - 1; i++) {
          if (parts[i].toLowerCase() === 'courses') {
            courseId = Number.parseInt(parts[i + 1]);
          } else if (parts[i].toLowerCase() === 'events') {
            ihid = parts[i + 1];
          } else if (parts[i].toLowerCase() === 'breakthroughs') {
            linkId = parts[i + 1];
          }
        }

        // Check for invalid filter
        if (
          !courseId
          || Number.isNaN(courseId)
          || !ihid
          || !linkId
        ) {
          // Invalid filter
          invalidFilter = true;
        } else {
          // Filter the links
          filteredLinks = links.filter((link) => {
            return (
              (link.ihid === ihid)
              && (link.linkId === linkId)
              && (link.courseId === courseId)
            );
          });
        }
      }

      // Create a link table
      linkTable = (
        (filteredLinks.length === 0 || invalidFilter)
          ? (
            <div className="BreakthroughLinkManager-nothing-here-notice">
              <NothingHereNotice
                title={(
                  invalidFilter
                    ? 'Invalid Search Text'
                    : 'No Matches'
                )}
                subtitle={(
                  invalidFilter
                    ? 'Check your search text and try again'
                    : 'Perhaps this link is for another course? Or maybe the link was already deleted.'
                )}
              />
            </div>
          )
          : (
            <table className="table table-striped">
              {/* Header */}
              <thead>
                <tr>
                  <th title="Label">
                    Label
                  </th>
                  <th title="Name of the event being linked to is...">
                    Event
                  </th>
                  <th title="Person who created the link is...">
                    Creator
                  </th>
                  <th title="Link was made on...">
                    Made
                  </th>
                  <th title="Link usable on...">
                    Schedule
                  </th>
                  <th title="Number of times link was used">
                    Visits
                  </th>
                  <th>
                    Buttons
                  </th>
                </tr>
              </thead>
              {/* Rows */}
              <tbody>
                {
                  filteredLinks.map((link) => {
                    return (
                      <BreakthroughLinkRow
                        key={link.linkId}
                        link={link}
                        ihid={link.ihid}
                        event={eventMap[link.ihid]}
                        onView={() => {
                          this.setState({
                            fullLinkToView: `https://${window.location.hostname}/courses/${link.courseId}/events/${link.ihid}/breakthroughs/${link.linkId}`,
                          });
                        }}
                        onDelete={() => {
                          this.setState({
                            linkToDelete: link,
                          });
                        }}
                      />
                    );
                  })
                }
              </tbody>
            </table>
          )
      );
    }

    /* --------------------------- Main UI -------------------------- */

    return (
      <div>
        {modal}

        {/* Title */}
        <h3>
          <FontAwesomeIcon
            icon={faLink}
            className="mr-2"
          />
          Breakthrough Link Manager
        </h3>

        {/* Current Course */}
        <p className="lead">
          Current course:&nbsp;
          {courseName}
        </p>

        {/* Search */}
        <div className="input-group mb-1">
          <div className="input-group-prepend">
            <span className="input-group-text">
              Search:
            </span>
          </div>
          <input
            type="text"
            id="BreakthroughLinkManager-search-field"
            className="form-control"
            placeholder="paste a breakthrough link here"
            aria-label="breakthrough link to search for"
            value={linkToMatch}
            onChange={(e) => {
              this.setState({
                linkToMatch: e.target.value,
              });
            }}
          />
        </div>

        {/* Links */}
        <div className="bg-white">
          {linkTable}
        </div>
      </div>
    );
  }
}

BreakthroughLinkManager.propTypes = {
  // Course id
  courseId: PropTypes.number.isRequired,
  // Name of the current course
  courseName: PropTypes.string.isRequired,
};

export default BreakthroughLinkManager;
